import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql } from 'react-apollo';

import NoticeRow from '../../components/NoticeRow';
import { announcementsListQuery } from '../../graphql';

const NoticeTable = styled.div`
  width: 100%;
  border-radius: 1px;
  background-color: #ffffff;
  word-break: break-all;
  white-space: pre-line;
`;

const NoticeHeader = styled.div`
  width: 100%;
  height: 37px;
  line-height: 37px;
  border-bottom: 1px solid #8a8a8a;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.8px;
  text-align: center;
  color: #000a12;
  display: flex;
  justify-content: space-between;
`;

const HeaderNum = styled.div`
  width: 100px;
  text-align: center;
  line-height: 37px;
`;
const HeaderTitle = styled.div`
  width: 870px;
  text-align: left;
  line-height: 37px;
`;
const HeaderDate = styled.div`
  width: 170px;
  text-align: left;
  line-height: 37px;
`;

class Notice extends Component {
  render() {
    const { announcementsList, loading } = this.props.announcementsList;

    if (loading) {
      return null;
    }

    return (
      <div>
        <div>
          <NoticeTable>
            <NoticeHeader>
              <HeaderNum>번호</HeaderNum>
              <HeaderTitle>제목</HeaderTitle>
              <HeaderDate>등록일</HeaderDate>
            </NoticeHeader>
            {announcementsList &&
              announcementsList.length > 0 &&
              announcementsList.map((data, idx) => (
                <NoticeRow key={`announce_${idx}`} data={data} idx={idx} />
              ))}
          </NoticeTable>
        </div>
      </div>
    );
  }
}

export default graphql(announcementsListQuery, {
  name: 'announcementsList',
  options: {
    variables: { forWho: 'partner' },
  },
})(Notice);
