import React from 'react';
import styled from 'styled-components';
import ArrowImg from '../../images/drop-down.svg';

export const StyledSelect = styled.select`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: solid 1px #e1e3e2;
  background-color: #ffffff;
  cursor: pointer;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: -0.4px;
  color: #000000;
  padding: 14px;
  background-image: url(${ArrowImg});
  background-position-x: calc(100% - 14px);
  background-position-y: 50%;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  &:disabled {
    color: #bbbcbc;
    cursor: not-allowed;
  }
  &:hover,
  &:focus {
    outline: none;
  }
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #bbbcbc;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #bbbcbc;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #bbbcbc;
  }
  &:disabled {
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: transparent;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: transparent;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: transparent;
    }
    + img {
      display: none;
    }
  }
`;

export default ({ renderItem, items, ...rest }) => (
  <StyledSelect
    {...rest}
  >
    {items.map((item, index) => renderItem(item, index))}
  </StyledSelect>
);
