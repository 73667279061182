import React, { Component } from 'react';
import MobileDrawer from './MobileDrawer';
import DesktopTopNav from './DesktopTopNav';

class BSNavigation extends Component {
  render() {
    return (
      <div>
        <DesktopTopNav {...this.props} />
        <MobileDrawer {...this.props} />
      </div>
    );
  }
}

export default BSNavigation;
