import React, { Component } from 'react';
import styled from 'styled-components';
import { withApollo, graphql, compose } from 'react-apollo';
import { PaddingDiv } from '../../components/basic/HtmlComponents';
import { media } from '../../globalStyle';
import DefaultPhoto from '../../images/img-profile-default-80.svg';
import PhotoSettingIcon from '../../images/ic-setting-32.png';
import WhiteArrow from '../../images/ic-arrow-white-20.svg';
import {
  mehQuery,
  updateHuserInfoMutation,
  updateHuserPhotoMutation,
  signS3Mutation,
} from '../../graphql';
import PasswordCheckModal from '../../components/Profile/PasswordCheckModal';
import PhonenumUpdateModal from '../../components/Profile/PhonenumUpdateModal';
import withToast from '../../utils/toasthoc';
import withAlertModal from '../../utils/alerthoc';
import HospitalSearchModal from '../../components/HospitalSearchModal';
import imageUpload from '../../utils/imageUpload';
import PasswordUpdateModal from '../../components/Profile/PasswordUpdateModal';
import withAuth from '../../utils/authhoc';
import { logout } from '../../utils/auth';

const ConfirmBanner = styled.div`
  width: 100%;
  background-color: #ffb3aa;
  padding: 14px 40px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -1px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  ${media.miniDesktop`
    padding: 14px;
  `};
`;

const ConfirmBannerU = styled.u`
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -1px;
  text-align: right;
  color: #ffffff;
  cursor: pointer;
  min-width: 105px;
  text-align: left;
  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-left: 4px;
    vertical-align: middle;
    display: inline-block;
  }
`;

const ProfileEditFormContainer = styled.div`
  padding: 30px 40px 58px;
  border-radius: 1px;
  background-color: #ffffff;
  border: solid 1px #e0e0e0;
  & ~ & {
    margin-top: 10px;
  }
`;

const ProfileEditFormContainer2 = styled(ProfileEditFormContainer)`
  padding: 30px 40px 40px;
`;

const FormTitle = styled.div`
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: -1px;
  color: #000000;
  margin-bottom: 30px;
`;

const FormTopSection = styled.div`
  margin-bottom: 20px;
`;

const SectionTitle = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  margin-bottom: 8px;
`;

const UpdatePasswordRow = styled.div`
  display: inline-block;
  max-width: 400px;
  width: 100%;
  margin-top: 10px;
  u {
    color: #000000;
    font-weight: normal;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -1px;
  }
`;

const FormInput = styled.div`
  display: inline-block;
  max-width: 400px;
  width: 100%;
  border-radius: 1px;
  background-color: #ffffff;
  border: solid 1px #e0e0e0;
  padding: 12px 18px 12px 8px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  position: relative;
`;

const EditBtn = styled.u`
  float: right;
  cursor: pointer;
  color: #033e52;
`;

const PhotoContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
`;

const RightContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const Email = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 8px;
`;

const Name = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
`;

const Photo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50px;
`;

const PhotoBox = styled.div`
  width: 80px;
  height: 80px;
  position: relative;
  input {
    display: none;
  }
`;

const SettingIcon = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 32px;
  height: 32px;
  object-fit: contain;
  cursor: pointer;
`;

const RadioBox = styled.div`
  display: inline-block;
  vertical-align: middle;
  min-width: 107px;
  label {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #000000;
  }
  input {
    display: none;
    & + label {
      width: 18px;
      height: 18px;
      border: solid 1px #d9d9d9;
      border-radius: 50px;
      position: relative;
      margin-right: 8px;
    }
    &:checked + label {
      border-color: #033e52;
      ::after {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        width: 10px;
        height: 10px;
        background-color: #033e52;
        border-radius: 50px;
      }
    }
  }
`;

const EventInfoMsg = styled.div`
  margin-top: 16px;
  opacity: 0.8;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -1px;
  color: #bbbcbc;
`;

const BottomBtns = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.5px;
  color: #000000;
  text-align: right;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  div {
    display: inline-block;
    margin-right: 8px;
  }
`;

const WithdrawBtn = styled.a`
  color: #000000;
  cursor: pointer;
  &:hover,
  &:focus {
    color: #000000;
  }
`;

const LogoutBtn = styled.button`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.5px;
  color: #000000;
  text-decoration: underline;
  padding: 0;
  border: 0;
`;

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agreeMarketing: false,
      isOpenPasswordCheckModal: false,
      isOpenPhonenumUpdateModal: false,
      isOpenPasswordUpdateModal: false,
      isOpenHosModal: false,
    };
    this.updateProfilePhoto = this.updateProfilePhoto.bind(this);
    this.handleLogOut = this.handleLogOut.bind(this);
  }

  componentWillReceiveProps(props) {
    if (props.meh) {
      const { loading, meh, error } = props.meh;

      if (!loading && meh) {
        this.setState({ agreeMarketing: meh.agreemarket });
      }
    }
  }

  onChangeMarketingAgree = async agreeMarketing => {
    // update MarketingAgree
    try {
      const res = await this.props.updateHuserInfo({
        variables: { agreemarket: agreeMarketing },
      });

      const { data: updateHuserInfo } = res;

      if (updateHuserInfo) {
        if (agreeMarketing) {
          this.props.toast(
            <div>이벤트, 혜택 정보 메일 수신 동의 처리 되었습니다.</div>,
            'info',
          );
        } else {
          this.props.toast(
            <div>이벤트, 혜택 정보 메일 수신 거부 처리 되었습니다.</div>,
            'info',
          );
        }
      }

      this.setState({ agreeMarketing });
    } catch (e) {
      console.log(e);
    }
  };

  onSelectHospital = async hospital => {
    try {
      const res = await this.props.updateHuserInfo({
        variables: { hospitalId: hospital.id },
      });

      const { data: updateHuserInfo } = res;

      // success
      if (updateHuserInfo) {
        this.fireSuccessToast();
      }
      // error
    } catch (e) {}
  };

  openPhonenumUpdateModal = () => {
    this.setState({ isOpenPhonenumUpdateModal: true });
  };

  openHospitalModal = () => {
    // this.setState({ isOpenHosModal: true });
    this.props.openAlert({
      title: '소속병원변경',
      message:
        '소속 병원 변경을 원하시면\n partner@odoctor.co.kr로 문의 부탁드립니다.',
      showConfirmBtn: true,
      confirmBtnName: '확인',
      textAlign: 'left',
      confirmBtnOnClick: this.props.closeAlert,
    });
  };

  openPasswordCheckModal = callback => {
    this.setState({
      isOpenPasswordCheckModal: true,
      passwordCheckedCallback: callback,
    });
  };

  // update 성공시 피드백
  fireSuccessToast = () => {
    this.props.toast(<div>변경한 내용이 저장되었습니다.</div>, 'info');
    this.props.meh.refetch();
  };

  openFileSelector = () => {
    const fileSelector = document.getElementById('profile_photo_input');
    fileSelector.click();
  };

  openProfilePhotoCheckModal = () => {
    // 사진 있을때
    const { meh } = this.props.meh;
    if (meh.photos && meh.photos.length > 0) {
      this.props.openAlert({
        title: '프로필 사진 등록',
        message:
          '프로필 이미지를 변경하려면 "수정" 버튼을 누르고, 기본 이미지로 변경하려면 "삭제"를 누르세요.',
        showConfirmBtn: true,
        confirmBtnName: '수정',
        textAlign: 'left',
        confirmBtnOnClick: async () => {
          this.openFileSelector();
        },
        showCancelBtn: true,
        cancleBtnName: '삭제',
        cancleBtnOnClick: () => {
          this.deleteProfilePhoto();
          this.props.closeAlert();
        },
      });
    } else {
      // 사진 없을때
      this.props.openAlert({
        title: '프로필 사진 등록',
        message:
          '[선택] 회원식별 목적으로 프로필 이미지를 수집하며, 삭제 시 지체없이 파기됩니다.',
        showConfirmBtn: true,
        confirmBtnName: '동의',
        textAlign: 'left',
        confirmBtnOnClick: async () => {
          this.openFileSelector();
        },
        showCancelBtn: true,
        cancleBtnName: '동의안함',
        cancleBtnOnClick: this.props.closeAlert,
      });
    }
  };

  deleteProfilePhoto = async () => {
    try {
      // 프로필 사진 삭제 update
      const res = await this.props.updateHuserPhoto({
        variables: { forceDelete: true },
      });

      const { data: updateHuserPhoto } = res;

      // 프로필 성공시 사진이 삭제되었습니다. toast
      if (updateHuserPhoto.updateHuserPhoto.ok) {
        this.props.toast(<div>프로필 사진이 삭제되었습니다.</div>, 'info');
      }
    } catch (e) {}
  };

  updateProfilePhoto = async ({ target }) => {
    const { files } = target;

    try {
      // s3에 사진 업로드
      const url = await imageUpload(files[0], this.props.client);

      // 서버에 사진 url update
      const res = await this.props.updateHuserPhoto({ variables: { url } });
      const { data: updateHuserPhoto } = res;

      // 성공시 프로필 사진이 등록되었습니다. toast
      if (updateHuserPhoto.updateHuserPhoto.ok) {
        this.props.toast(<div>프로필 사진이 등록되었습니다.</div>, 'info');
      }
    } catch (e) {
      console.log(e);
    }
  };

  handleLogOut() {
    logout();
    this.props.updateMeh(null);
    this.props.history.push('/login');
  }

  render() {
    const { meh } = this.props;


    const { username, email, phonenum, status, photos, hospital } = meh;

    return (
      <div>
        <div>
          {status === 'unconfirmed' && (
            <ConfirmBanner>
              <div>의사선생님! 병원 인증을 완료해 주세요 :)</div>
              <ConfirmBannerU
                onClick={() => this.props.history.push('myhospital')}
              >
                인증하러가기
                <img src={WhiteArrow} alt="인증하기" />
              </ConfirmBannerU>
            </ConfirmBanner>
          )}
          <ProfileEditFormContainer>
            <FormTitle>개인정보</FormTitle>
            <FormTopSection>
              <PhotoContainer>
                <PhotoBox>
                  {photos && photos.length > 0 ? (
                    <Photo src={photos[0].url} alt="의사사진" />
                  ) : (
                    <Photo src={DefaultPhoto} alt="의사사진" />
                  )}
                  <SettingIcon
                    src={PhotoSettingIcon}
                    alt="의사사진등록"
                    onClick={this.openProfilePhotoCheckModal}
                  />
                  <input
                    type="file"
                    accept="image/*"
                    id="profile_photo_input"
                    onChange={this.updateProfilePhoto}
                  />
                </PhotoBox>
              </PhotoContainer>
              <RightContainer>
                <Email>{email}</Email>
                <Name>{username}</Name>
              </RightContainer>
            </FormTopSection>
            <div>
              <SectionTitle>휴대폰 번호</SectionTitle>
              <FormInput>
                {phonenum}
                <EditBtn
                  onClick={this.openPasswordCheckModal.bind(
                    this,
                    this.openPhonenumUpdateModal,
                  )}
                >
                  변경
                </EditBtn>
              </FormInput>
            </div>
            <PaddingDiv padding="13px 0" />
            <div>
              <SectionTitle>소속병원</SectionTitle>
              <FormInput>
                {hospital.name}
                <EditBtn onClick={this.openHospitalModal}>변경</EditBtn>
              </FormInput>
            </div>
            <UpdatePasswordRow>
              <EditBtn
                onClick={() =>
                  this.setState({ isOpenPasswordUpdateModal: true })
                }
              >
                비밀번호 변경
              </EditBtn>
            </UpdatePasswordRow>
          </ProfileEditFormContainer>
          <ProfileEditFormContainer2>
            <FormTitle>이벤트/혜택 정보 수신 설정</FormTitle>
            <div>
              <RadioBox>
                <input
                  type="radio"
                  name="marketing"
                  id="agree"
                  onChange={this.onChangeMarketingAgree.bind(this, true)}
                  checked={this.state.agreeMarketing}
                />
                <label htmlFor="agree" />
                <label htmlFor="agree">수신함</label>
              </RadioBox>
              <RadioBox>
                <input
                  type="radio"
                  name="marketing"
                  id="disAgree"
                  onChange={this.onChangeMarketingAgree.bind(this, false)}
                  checked={!this.state.agreeMarketing}
                />
                <label htmlFor="disAgree" />
                <label htmlFor="disAgree">수신하지 않음</label>
              </RadioBox>
              <EventInfoMsg>
                * 수신함으로 선택하실 경우 회원 대상의 다양한 이벤트와
                혜택정보를 이메일로 알려드립니다.
              </EventInfoMsg>
            </div>
          </ProfileEditFormContainer2>
          <BottomBtns>
            <LogoutBtn onClick={() => this.handleLogOut()}>로그아웃</LogoutBtn>
            <div>
              <div>오닥터를 더 이상 사용하지 않는다면</div>
              <WithdrawBtn href="mailto: partner@odoctor.co.kr?subject=탈퇴요청&body=이메일:%0A탈퇴사유:">
                <u>서비스탈퇴</u>
              </WithdrawBtn>
            </div>
          </BottomBtns>
        </div>
        <PasswordCheckModal
          isOpen={this.state.isOpenPasswordCheckModal}
          onClose={() => this.setState({ isOpenPasswordCheckModal: false })}
          callback={this.state.passwordCheckedCallback}
        />
        <PhonenumUpdateModal
          myPhonenum={phonenum}
          isOpen={this.state.isOpenPhonenumUpdateModal}
          onClose={() => this.setState({ isOpenPhonenumUpdateModal: false })}
          onSuccess={this.fireSuccessToast}
        />
        <PasswordUpdateModal
          isOpen={this.state.isOpenPasswordUpdateModal}
          onClose={() => this.setState({ isOpenPasswordUpdateModal: false })}
          onSuccess={this.fireSuccessToast}
        />
        <HospitalSearchModal
          isOpen={this.state.isOpenHosModal}
          closeModal={() => this.setState({ isOpenHosModal: false })}
          onSelectHospital={this.onSelectHospital}
        />
      </div>
    );
  }
}

export default compose(
  graphql(updateHuserInfoMutation, {
    name: 'updateHuserInfo',
    options: {
      refetchQueries: [
        {
          query: mehQuery,
        },
      ],
    },
  }),
  graphql(updateHuserPhotoMutation, {
    name: 'updateHuserPhoto',
    options: {
      refetchQueries: [
        {
          query: mehQuery,
        },
      ],
    },
  }),
  graphql(signS3Mutation, { name: 'signS3' }),
)(withToast(withAlertModal(withApollo(withAuth(Profile)))));
