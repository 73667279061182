import React from 'react';
import Modal from 'react-modal';
import { withApollo } from 'react-apollo';
import styled from 'styled-components';
import CloseImg from '../images/ic-close-24.svg';
import { findHospitalByNameQuery } from '../graphql';
import SearchImage from '../images/ic-search-white-32.svg';
import TextClearBtnImg from '../images/ic-close-typing-24.svg';
import { ModalTitle, ModalContentContainer, ModalCloseBtn, ModalBtnContainer, ModalConfirmBtn } from './basic/ModalComponents';

const HospitalSearchStyledModal = styled(Modal)`
  width: 460px;
  position: relative;
  margin: auto;
  top: 10%;
  background: white;
  padding: 30px;
  &:focus,
  &:active {
    outline: none;
  }
  @media (max-width: 450px) {
    width: 100%;
    top: 0;
    height: 100%;
  }
`;

const HospitalSearchInput = styled.input`
  width: 100%;
  height: 52px;
  padding: 14px 10px 14px 6px;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #000000;
  border: none;
  border-bottom: solid 1px #e0e0e0;
`;

const TextClearBtn = styled.img`
  position: absolute;
  top: 14px;
  right: 10px;
  z-index: 2;
  cursor: pointer;
`;

const InputContiner = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const HospitalSearchBtn = styled.button`
  min-width: 80px;
  height: 52px;
  border-radius: 26px;
  background-color: #033e52;
  padding: 10px 20px;
  vertical-align: middle;
  opacity: 1;
`;

const HospitalResultContainer = styled.div`
  margin-top: 16px;
`;

const HospitalList = styled.div`
  margin-top: 10px;
  width: 100%;
  background-color: #ffffff;
  border: solid 1px #e0e0e0;
  height: 250px;
  overflow-y: auto;
`;

const Hosptial = styled.div`
  width: 100%;
  height: 68px;
  padding: 12px 14px;
  cursor: pointer;
  position: relative;
  border: 0;
  border-bottom: 1px solid rgba(224, 224, 224, 0.3);
  box-sizing: border-box;
  &:hover {
    background-color: #fafafa;
  }
`;

const HosName = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #000000;
`;

const HosAdd = styled.div`
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.69;
  letter-spacing: normal;
  color: #000000;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const NoResultNoti = styled.div`
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  padding: 42px 63px;
  color: #bcbcbc;
  width: 100%;
  height: 100%;
`;

const NoResultDesc = styled.div`
  margin-bottom: 6px;
`;

const NoResultSubDesc = styled.div`
  font-size: 13px;
`;

const ResultContainerTitle = styled.div`
  opacity: 0.78;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
`;

const Keyword = styled.span`
  font-weight: bold;
  color: #000000;
`;

const LinkContainer = styled.div`
  margin-top: 10px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: #000000;
  a {
    cursor: pointer;
    color: #000000;
  }
`;

const InputBox = styled.div`
  display: flex;
`;

class HospitalSearchModal extends React.Component {
  state = {
    name: '',
    hospitalList: null,
  };

  searchHospitalByName = async () => {
    const { name } = this.state;

    if (name) {
      const res = await this.props.client.query({
        query: findHospitalByNameQuery,
        variables: { name },
      });

      const { findHospitalByName } = res.data;
      this.setState({ hospitalList: findHospitalByName });
    }
  };

  close = () => {
    this.setState({
      name: '',
      hospitalList: null,
    });
    this.props.closeModal();
  }

  render() {
    const { isOpen, onSelectHospital } = this.props;
    return (
      <HospitalSearchStyledModal
        isOpen={isOpen}
        ariaHideApp={false}
        style={{
          overlay: {
            zIndex: 100,
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
          },
        }}
      >
        <ModalCloseBtn src={CloseImg} alt="closeImg" onClick={this.close} />
        <ModalTitle>병원 찾기</ModalTitle>
        <ModalContentContainer>
          <InputBox>
            <InputContiner>
              <HospitalSearchInput
                type="text"
                name="name"
                value={this.state.name}
                onChange={e =>
                  this.setState({ name: e.target.value, hospitalList: null })
                }
                placeholder="병원명으로 검색"
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.searchHospitalByName();
                  }
                }}
              />
              {this.state.name && (
                <TextClearBtn
                  src={TextClearBtnImg}
                  alt="병원찾기"
                  onClick={() => this.setState({ name: '' })}
                />
              )}
            </InputContiner>
            <HospitalSearchBtn disabled={!this.state.name} onClick={this.searchHospitalByName}>
              <img src={SearchImage} alt="병원찾기" />
            </HospitalSearchBtn>
          </InputBox>
          <HospitalResultContainer>
            <ResultContainerTitle>
              {this.state.hospitalList && this.state.name ? (
                <div>
                  <Keyword>{`'${this.state.name} '`}</Keyword>검색결과 입니다.
                </div>
              ) : (
                <div>검색결과</div>
              )}
            </ResultContainerTitle>
            <HospitalList>
              {this.state.hospitalList &&
                this.state.hospitalList.length > 0 &&
                this.state.name &&
                this.state.hospitalList.map(hospital => (
                  <Hosptial
                    key={hospital.id}
                    onClick={() => {
                      onSelectHospital(hospital);
                      this.close();
                    }}
                  >
                    <HosName>{hospital.name}</HosName>
                    <HosAdd>{hospital.address}</HosAdd>
                  </Hosptial>
                ))}
              {this.state.hospitalList &&
                this.state.hospitalList.length === 0 &&
                this.state.name && (
                  <NoResultNoti>
                    <NoResultDesc>
                      검색어에 대한 검색결과가 없습니다.
                    </NoResultDesc>
                    <NoResultSubDesc>
                      검색어의 단어 수를 줄이거나, 보다 일반적인<br />단어로
                      검색해 보세요.
                    </NoResultSubDesc>
                  </NoResultNoti>
                )}
              {!this.state.hospitalList && (
                <NoResultNoti>
                  <NoResultSubDesc>병원명 입력후에 검색 버튼을 눌러주세요.</NoResultSubDesc>
                </NoResultNoti>
              )}
            </HospitalList>
          </HospitalResultContainer>
          <LinkContainer>
            찾으시는 병원이 없나요?{' '}
            <a href="https://docs.google.com/forms/d/e/1FAIpQLScI9kBqCNa7XbHX89e5tBzfG3S2ix21sRaykVA_5nsxpNzCUA/viewform?usp=sf_link" target="_blank">
              <u>병원추가하기</u>{' >'}
            </a>
          </LinkContainer>
        </ModalContentContainer>
        <ModalBtnContainer>
          <ModalConfirmBtn onClick={this.close}>닫기</ModalConfirmBtn>
        </ModalBtnContainer>
      </HospitalSearchStyledModal>
    );
  }
}

export default withApollo(HospitalSearchModal);
