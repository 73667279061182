import React, { Component } from 'react';
import styled from 'styled-components';
import CloseImg from '../images/ic-close-24.svg';
import { StyledModal, ModalTitle, ModalContentContainer, ModalMessage, ModalCloseBtn, ModalBtnContainer, ModalConfirmBtn, ModalCancleBtn } from './basic/ModalComponents';
import { media } from '../globalStyle';

const AlertStyledModal = styled(StyledModal)`
  ${media.tablet`
    padding: 20px;
  `};
`;

const AlertCloseBtn = styled(ModalCloseBtn)`
  ${media.tablet`
    top: 32px;
    right: 20px;
  `};
`;

class AlertModal extends Component {
  render() {
    return (
      <AlertStyledModal
        style={{
          overlay: {
            zIndex: 100,
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
          },
        }}
        ariaHideApp={false}
        isOpen={this.props.isOpen}
      >
        {
          !this.props.hideCloseBtn && (
            <AlertCloseBtn src={CloseImg} alt="closeImg" onClick={this.props.onClose} />
          )
        }
        {this.props.title && (
          <div>
            <ModalTitle>{this.props.title}</ModalTitle>
          </div>
        )}
        <ModalContentContainer>
          {this.props.message && (
            <ModalMessage textAlign={this.props.textAlign}>
              {this.props.message}
            </ModalMessage>
          )}
        </ModalContentContainer>
        {(this.props.showConfirmBtn || this.props.showCancelBtn) && (
          <ModalBtnContainer>
            {this.props.showCancelBtn && (
              <ModalCancleBtn
                isExistAnotherBtn={this.props.showConfirmBtn}
                onClick={this.props.cancleBtnOnClick || this.props.onClose}
              >
                {this.props.cancleBtnName}
              </ModalCancleBtn>
            )}
            {this.props.showConfirmBtn && (
              <ModalConfirmBtn
                isExistAnotherBtn={this.props.showCancelBtn}
                onClick={this.props.confirmBtnOnClick || this.props.onClose}
              >
                {this.props.confirmBtnName}
              </ModalConfirmBtn>
            )}
          </ModalBtnContainer>
        )}
      </AlertStyledModal>
    );
  }
}

export default AlertModal;
