import React from 'react';
import styled from 'styled-components';
import DeleteIcon from '../../images/ic-close-gray-24-px.svg';
import { media } from '../../globalStyle';
import HosPeoplePhonenumInput from './HosPeoplePhonenumInput';

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
  & ~ & {
    margin-top: 10px;
  }
`;

const TextInput = styled.input`
  width: 100%;
  max-width: ${props => props.width || '400px'};
  height: 48px;
  border-radius: 1px;
  background-color: #ffffff;
  border: solid 1px #e0e0e0;
  padding: 12px 8px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  text-align: ${props => props.textAlign || 'start'};
  margin-right: 4px;
  &:disabled {
    background-color: #f5f5f5;
    color: #bcbcbc;
  }
  ${media.miniDesktop`
    min-width: 79px;
    max-width: 130px;
    /* max-width: none; */
  `};
`;

const DeleteBtn = styled.div`
  cursor: pointer;
  align-items: center;
  display: flex;
  margin-left: 6px;
  img {
    width: 13px;
    height: 13px;
    justify-content: contain;
  }
`;

const HospitalPeopleInputs = (props) => {
  if (props.confirmed) {
    return props.people.map((val, idx) => {
      const nameId = `name-${idx}`;
      const phonenumId = `phonenum-${idx}`;
      return (
        <InputContainer key={nameId + phonenumId}>
          <TextInput
            width="130px"
            type="text"
            name={nameId}
            data-id={idx}
            id={nameId}
            placeholder="이름"
            value={props.people[idx].name}
            onChange={props.handleChange(idx)}
            maxLength={40}
          />
          <HosPeoplePhonenumInput
            id={phonenumId}
            data-id={idx}
            placeholder="휴대폰 번호 (예시: 01012345678)"
            name={phonenumId}
            onChange={props.handleChange(idx)}
            value={props.people[idx].phonenum}
          />
          <DeleteBtn type="button" onClick={props.handleRemovePeople(idx)} className="small">
            <img src={DeleteIcon} alt="담당자휴대폰번호" />
          </DeleteBtn>
        </InputContainer>
      );
    });
  }

  return (
    <InputContainer>
      <TextInput
        width="130px"
        type="text"
        placeholder="이름"
        disabled
      />
      <HosPeoplePhonenumInput
        placeholder="휴대폰 번호 (예시: 01012345678)"
        disabled
      />
    </InputContainer>
  );
};

export default HospitalPeopleInputs;
