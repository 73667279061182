import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import styled from 'styled-components';

import { allUserReqsQuery } from '../../graphql';
import withAlertModal from '../../utils/alerthoc';
import ReqListComponent from '../../components/ReqList/ReqListComponent';
import QueryOptionComponent from '../../components/ReqList/QueryOptionComponent';
import BalanceInfoAlert from '../../components/alertMessage/BalanceInfoAlert';
import { Separator } from '../../components/HosResps/HosRespsCommonComponents';

const TitleLabel = styled.div`
  height: 24px;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: -1.11px;
  color: #000000;
`;

class AllUserReqsContainer extends Component {
  constructor(props) {
    super(props);
    this.filterUserReqs = this.filterUserReqs.bind(this);
  }

  componentWillReceiveProps(props) {
    const { loading, allUserReqs } = props;

    if (
      !this.props.reqId &&
      !loading &&
      allUserReqs &&
      allUserReqs.userReqs &&
      allUserReqs.userReqs.length > 0
    ) {
      this.props.onClickCard(allUserReqs.userReqs[0].id);
    }
  }

  filterUserReqs = (state) => {
    this.props.setFilterState(state);
  };

  openChargeInfo = () => {
    this.props.openAlert({
      title: '과금 레벨',
      message: <BalanceInfoAlert />,
      showConfirmBtn: true,
      confirmBtnName: '확인',
      textAlign: 'left',
      confirmBtnOnClick: this.props.closeAlert,
      showCancelBtn: false,
    });
  };

  render() {
    const { loading, reqType, replied, username } = this.props;

    return (
      <>
        <TitleLabel>상담 조건 조회</TitleLabel>
        <Separator style={{ marginTop: 10 }}></Separator>
        <Separator style={{ marginTop: 60 }}></Separator>
        <div>
          <QueryOptionComponent
            filterUserReqs={this.filterUserReqs}
            reqType={reqType}
            replied={replied}
            username={username}
          />
          {!loading && (
            <ReqListComponent {...this.props} itemPerPage={ITEM_PER_PAGE} />
          )}
        </div>
      </>
    );
  }
}

const ITEM_PER_PAGE = 12;

export default graphql(allUserReqsQuery, {
  name: 'allUserReqs',
  options: ({ reqType, replied, username, locationSi, locationGu }) => ({
    fetchPolicy: 'network-only',
    variables: {
      perPage: ITEM_PER_PAGE,
      currentPage: 1,
      reqType,
      replied,
      username,
      locationSi,
      locationGu,
    },
  }),
  props: ({ allUserReqs: { loading, allUserReqs, fetchMore } }) => ({
    loading,
    allUserReqs,
    loadMoreReqs(page) {
      return fetchMore({
        variables: {
          currentPage: page,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previousResult;
          }
          return Object.assign({}, previousResult, {
            allUserReqs: fetchMoreResult.allUserReqs,
          });
        },
      });
    },
  }),
})(withAlertModal(withRouter(AllUserReqsContainer)));
