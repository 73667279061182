import React, { useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { StyledModal as Modal } from '../components/basic/ModalComponents';
import Img from '../images/popup.png';
import { setCookie, getCookie } from '../utils/cookie';
import { media } from '../globalStyle';
import withAuth from '../utils/authhoc';

const Line = styled.div``;

const StyledModal = styled(Modal)`
  width: 454px;
  padding: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  img {
    width: 100%;
    vertical-align: middle;
  }
  div.img {
    background-image: url(${Img});
    background-repeat: no-repeat;
    background-size: contain;
    width: 454px;
    height: 362px;
    ${media.tablet`
      width: 280px;
      height: 280px;
    `};
  }
  div.link-btn-group {
    display: flex;
    align-items: center;
    background-color: #1a1a1a;
    button {
      width: 100%;
      height: 64px;
      background-color: #1a1a1a;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.58px;
      color: #ffffff;
    }
    ${Line} {
      display: block;
      width: 1px;
      height: 20px;
      background-color: rgba(216, 216, 216, 0.2);
    }
  }

  div.close-btn-group {
    background-color: transparent;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    button {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.5px;
      color: #bbbcbc;
      border: 0;
      padding: 0;
      background-color: transparent;
      text-decoration: underline;
    }
  }
`;

const cookieName = 'main-modal';

// 팝업 노출 병원
const HospitalIds = [
  // '19977',
  '21165',
  '9',
  '21161',
  '3288',
  '11133',
  '21163',
  '5000',
  '18952',
  '21160',
  '21158',
  '21166',
  '21148',
  '7082',
  '21157',
  '21159',
  '5384',
  '21147',
];

const NoticeModal = ({ meh }) => {
  // cookie 확인
  const isExistCookie = !!getCookie(cookieName);

  // 쿠키가 없으면 open
  const [isOpen, setIsOpen] = useState(!isExistCookie);
  const onClose = () => setIsOpen(false);

  // 병원 아이디에따라 노출 여부 결정.
  if (HospitalIds.indexOf(meh?.hospital?.id) === -1) {
    return null;
  }

  return (
    <StyledModal
      isOpen={isOpen}
      style={{
        overlay: {
          zIndex: 100,
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
        },
      }}
    >
      <div className="img" src={Img} alt="odoctor_notice" />
      <div className="close-btn-group">
        <button
          type="button"
          onClick={() => {
            setCookie(cookieName, 1, 1);
            onClose();
          }}
        >
          오늘 하루 닫기
        </button>
        <button type="button" onClick={() => onClose()}>닫기</button>
      </div>
    </StyledModal>
  );
};

export default withRouter(withAuth(NoticeModal));
