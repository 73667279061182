import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import styled from 'styled-components';
import AuthFooter from '../components/AuthFooter';
import Logo from '../images/img-logo-gnb.svg';

import { logout } from '../utils/auth';
import withToast from '../utils/toasthoc';
import {
  FormBackground,
  FormInputRow,
  FormInputRowTitle,
  FormConfirmBtn,
  FormBtnContainer,
} from '../components/basic/HtmlComponents';
import PasswordInput from '../components/form/PasswordInput';
import EmailInput from '../components/form/EmailInput';
import { isValidEmail } from '../utils/validationInput';
import withAuth from '../utils/authhoc';
import ComplimentInvitation from '../components/compliment/ComplimentInvitation';
import { media } from '../globalStyle';

const queryString = require('query-string');

const Content = styled.div``;

const InputContainer = styled.div`
  max-width: 400px;
  padding: 0 20px;
  width: 100%;
  margin: 0 auto;
`;

const InputContainerHeader = styled.div`
  object-fit: contain;
  margin-bottom: 30px;
  text-align: left;
`;

const LogoImg = styled.img`
  display: block;
  margin-bottom: 6px;
`;

const StyledSpan = styled.span`
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -1.5px;
  text-align: left;
  color: #033e52;
`;

const StyledLink = styled(Link)`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.8px;
  text-align: right;
  color: #000000;
  &:hover,
  &:focus {
    color: #000000;
    opacity: 1;
    text-decoration: underline;
  }
  ${media.miniDesktop`
    font-size: 14px;
  `};
`;

const SignBtn = styled.button`
  margin-top: 10px;
  width: 100%;
  height: 52px;
  border: solid 1px #033e52;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #033e52;
`;

const Inform = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.8px;
  color: #bbbcbc;
  margin-top: 20px;
  ${media.tablet`
    br {
      display: none;
    }
  `};
`;

const FooterContainer = styled.div`
  margin-top: 112px;
`;

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      fireSubmitValidation: false,
    };
  }

  componentDidMount() {
    logout();
    this.props.updateMeh(null);
    this.props.client.resetStore();
  }

  onSubmit = async () => {
    const { email, password } = this.state;
    const { location } = this.props;

    if (!email || !password) {
      this.setState({ fireSubmitValidation: true });
      return;
    }

    if (!isValidEmail(email)) {
      return;
    }

    try {
      const loginRes = await this.props.login({
        variables: { email, password },
      });

      if (loginRes) {
        // huser의 status가 unconfirmed일 경우 인증 진행중 페이지로 redirect
        
        // 이전 route정보가가 있다면 그쪽으로 redirect
        if (location?.state?.referrer) {
          this.props.history.push(location?.state?.referrer);
          return;
        }

        // 상담 관리로 redirect
        if (loginRes.status === 'confirmed') {
          this.props.history.push('/reqlist');
          return;
        }

        this.props.history.push('/myhospital');
        return;
      }      
    } catch (error) {
      console.error(error);
      logout();
      this.props.updateMeh(null);
    }
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, fireSubmitValidation: false });
  };

  render() {
    const { email, password, fireSubmitValidation } = this.state;
    const searchParams = queryString.parse(this.props.location.search);
    const { compId } = searchParams;

    return (
      <div>
        <FormBackground>
          {compId && <ComplimentInvitation compId={compId} />}
          <Content>
            <InputContainer>
              <InputContainerHeader>
                <LogoImg alt="logo" src={Logo} />
                <StyledSpan>오닥터 파트너병원</StyledSpan>
              </InputContainerHeader>
              <div>
                <FormInputRow>
                  <FormInputRowTitle>이메일</FormInputRowTitle>
                  <EmailInput
                    name="email"
                    onChange={this.onChange}
                    value={email}
                    placeholder="이메일 입력"
                    fireSubmitValidation={fireSubmitValidation}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        document.getElementById('loginPassword').focus();
                        this.onSubmit();
                      }
                    }}
                  />
                </FormInputRow>
                <FormInputRow>
                  <FormInputRowTitle>비밀번호</FormInputRowTitle>
                  <PasswordInput
                    id="loginPassword"
                    name="password"
                    onChange={this.onChange}
                    value={password}
                    placeholder="비밀번호 입력"
                    noValidate
                    fireSubmitValidation={fireSubmitValidation}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        this.onSubmit();
                      }
                    }}
                  />
                </FormInputRow>
                <FormBtnContainer>
                  <FormConfirmBtn
                    disabledView={!(email && password) || !isValidEmail(email)}
                    onClick={this.onSubmit}
                  >
                    로그인
                  </FormConfirmBtn>
                  {/* <div>
                    {compId ? (
                      <SignBtn onClick={() => this.props.history.push(`/complimentsignup?compId=${compId}`)}>
                        회원가입
                      </SignBtn>
                    ) : (
                      <SignBtn onClick={() => this.props.history.push('/signup')}>
                        회원가입
                      </SignBtn>
                    )}
                  </div> */}
                  <div>
                    <SignBtn onClick={() => window.open('https://odoctor.imweb.me/')}>
                      회원가입
                    </SignBtn>
                  </div>
                </FormBtnContainer>
                <StyledLink to="/findemail">아이디</StyledLink>
                <span>{'  ' + String.fromCharCode(183) + '  '}</span>
                <StyledLink to="/findpassword">비밀번호 찾기</StyledLink>
              </div>
              <Inform>
                오닥터는 의료광고법을 준수하며, 각 병원의 의료광고 및 <br />
                비급여 치료에 직접 관여하지 않습니다.
              </Inform>
            </InputContainer>
          </Content>
          <FooterContainer>
            <AuthFooter />
          </FooterContainer>
        </FormBackground>
      </div>
    );
  }
}

export default withToast(withAuth(withRouter(withApollo(Login))));
