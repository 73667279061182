import React from 'react';

export default (props) => {
  const { startTime, closeTime } = props;

  if (!startTime || !closeTime) {
    return (<span></span>);
  }

  let paddedSTime = startTime;
  let paddedCTime = closeTime;

  if (startTime.length === 3) {
    paddedSTime = "0" + startTime;
  }

  if (closeTime.length === 3) {
    paddedCTime = "0" + closeTime;
  }

  const sTime = paddedSTime.slice(0, 2) + ":" + paddedSTime.slice(2);
  const cTime = paddedCTime.slice(0, 2) + ":" + paddedCTime.slice(2);

  return (
    <span>{sTime} ~ {cTime}</span>
  );
};

export const getFourDigitSingleTime = (time) => {
  if (!time) {
    return '';
  }

  return time.slice(0, -2) + ":" + time.slice(-2);
};

export const translateDBOperTime = (time) => {
  if (!time) {
    return '';
  }

  let paddedTime = time.replace(':', '');
  if (time.length === 4 && time.slice(0, 1) === '0') {
    paddedTime = time.slice(1);
  }
  return paddedTime;
};
