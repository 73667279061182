import React from 'react';
import styled from 'styled-components';
import { media } from '../../globalStyle';
import Nav from '../basic/Nav';
import Logo from '../../images/logo.png';
import NavChargeStateQuery from '../queries/NavChargeStateQuery';
import { Query } from 'react-apollo';
import { getHospitalRespPlanQuery } from '../../graphql';

const OdocCash = styled.div`
  padding-right: 20px;
  position: relative;
  cursor: pointer;
`;

const Separater = styled.div`
  width: 1px;
  height: 16px;
  background-color: #d8d8d8;
  margin-right: 20px;
`;

const DesktopNav = styled.div`
  z-index: 100;
  margin-bottom: 0;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 40px;
  height: 80px;
  box-shadow: none;
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  ${media.miniDesktop`
    display: none;
  `};
`;

const StyledNavHeader = styled.div`
  background-color: #ffffff;
  height: 100%;
  display: flex;
  align-items: center;
  ${media.miniDesktop`
    float: none;
  `};
`;

const MyInfoNav = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  .my-menu ~ .my-menu {
    margin-left: 10px;
  }
`;

// 충전금 표시
const NavCashChargeInfo = ({ goToCash }) => (
  <OdocCash>
    <NavChargeStateQuery goToCash={goToCash} />
  </OdocCash>
);

// 내 메뉴 스타일
const MyMenu = ({ to, children, className }) => (
  <Nav.Menu className={className ? `${className} my-menu` : 'my-menu'} to={to}>
    {children}
  </Nav.Menu>
);

// 브랜드 로고
const Brand = () => (
  <Nav.Brand to='/reqlist' logoImg={Logo} appName='파트너 병원' />
);

const DesktopTopNav = ({ meh, history }) => {
  // 오른쪽 메뉴
  let userMenu = (
    <MyInfoNav>
      <MyMenu to='/login'>로그인</MyMenu>
      <MyMenu to='/cs'>고객센터</MyMenu>
    </MyInfoNav>
  );

  // 가운데메뉴
  let centerMenu = null;

  // 로그인시
  if (meh) {
    userMenu = (
      <MyInfoNav>
        {/* 충전금 정보 */}
        <NavCashChargeInfo
          goToCash={() =>
            history.push({
              pathname: '/odoctorcash',
              state: { openedTab: 'list' },
            })
          }
        />
        <Separater></Separater>
        <MyMenu to='/myhospital'>나의 정보</MyMenu>
        <MyMenu to='/cs'>고객센터</MyMenu>
      </MyInfoNav>
    );
    centerMenu = (
      <Nav>
        <Nav.Menu to='/reqlist'>상담관리</Nav.Menu>
        <Nav.Menu to='/hosresps'>답변관리</Nav.Menu>
        <Query query={getHospitalRespPlanQuery}>
          {({ loading, data, refetch, error }) => {
            if (!loading && data && data.getHospitalRespPlan) {
              return null;
            } else {
              return <Nav.Menu to='/odoctorcash'>충전금관리</Nav.Menu>;
            }
          }}
        </Query>
      </Nav>
    );
  }

  return (
    <DesktopNav>
      {/* 왼쪽 Brand */}
      <StyledNavHeader>
        <Brand onClick={() => history.push('/userreq')} />
      </StyledNavHeader>

      {/* 가운데 메뉴 */}
      {centerMenu}

      {/* 오른쪽 메뉴 */}
      {userMenu}
    </DesktopNav>
  );
};

export default DesktopTopNav;
