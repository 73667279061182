import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { graphql, compose } from 'react-apollo';
import { paddingProps, PageTitle } from '../components/basic/HtmlComponents';
import {
  publishComplimentMutation,
  myComplimentsForMeQuery,
  myComplimentsToOtherQuery,
  mehQuery,
  deleteCompliment,
} from '../graphql';
import ComplimentReplyModal from '../components/compliment/ComplimentReplyModal';
import ComplimentViewModal from '../components/compliment/ComplimentViewModal';
import ComplimentViewIcon from '../images/ic-view-16.svg';
import ComplimentSendModal from '../components/compliment/ComplimentSendModal';
import withToast from '../utils/toasthoc';
import withAlertModal from '../utils/alerthoc';
import StyledPagination from '../components/basic/StyledPagination';
import { media } from '../globalStyle';
import { AuthContext } from '../utils/AuthProvider';

const Header = styled.div`
  width: 100%;
  background-color: #ffffff;
  ${media.miniDesktop`
    margin-bottom: 8px;
  `};
`;

const PaginationContainer = styled.div`
  margin-top: 35px;
`;

const PraiseTable = styled.div`
  display: table;
  width: 100%;
  border-collapse: collapse;
  ${media.miniDesktop`
    display: block;
  `};
`;

const PraiseTh = styled.div`
  border-top: 1px solid #767676;
  border-bottom: 1px solid #767676;
  height: 49px;
  width: 100%;
  display: table-header-group;
  ${media.miniDesktop`
    display: none;
  `};
`;

const PraiseTr = styled.div`
  border-bottom: solid 1px #e0e0e0;
  height: auto;
  width: 100%;
  display: table-row;
  ${media.miniDesktop`
    display: block;
    background-color: #ffffff;
    border: solid 1px #cccccc;
    padding: 18px 20px 62px;
    position: relative;
    & ~ & {
      margin-top: 9px;
    }
    ${props => props.send && `
      padding-bottom: 20px;
    `};
  `};
`;

const PraiseDesc = styled.div`
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative;
  /* use this value to count block height */
  line-height: 1.2em;
  /* max-height = line-height (1.2) * lines max number (3) */
  max-height: 3.4em;
  word-break: break-word;
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;
  /* place for '...' */
  margin-right: -1em;
  padding-right: 1em;

  &::before {
    /* points in the end */
    content: '...';
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of block */
    right: 0;
    bottom: 0;
    width: 1em;
  }
  &::after {
    /* points in the end */
    content: '';
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of text */
    right: 0;
    /* set width and height */
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    /* bg color = bg color under block */
    background: white;
  }
`;

const PraiseTd = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
  max-width: ${props => (props.width ? `${props.width}px` : 'auto')};
  width: ${props => (props.width ? `${props.width}px` : 'auto')};
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #000000;
  padding: 12px 20px;
  ${paddingProps};
  ${media.miniDesktop`
    display: block;
    padding: 0;
    width: auto;
  `};
`;

const PraiseBtnContainer = styled(PraiseTd)`
  ${media.miniDesktop`
    width: 100%;
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: solid 1px #eeeeee;
    border-radius: 2px;
    height: 44px;
  `};
`;

const PraiseCreatedAt = styled(PraiseTd)`
  span {
    display: none;
  }
  ${media.miniDesktop`
    margin-top: 10px;
    text-align: left;
    span {
      display: inline-block;
      margin-right: 22px;
    }
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #bbbcbc;
  `};
`;

const PraisePublishedAt = styled(PraiseCreatedAt)`
  ${media.miniDesktop`
    margin-top: 6px;
  `};
`;

const PraisePublishType = styled(PraiseTd)`
  ${media.miniDesktop`
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
  `};
`;

const PraiseDoctorName = styled(PraiseTd)`
  ${media.miniDesktop`
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
    color: #000000;
  `};
`;
const PraiseHosName = styled(PraiseTd)`
  ${media.miniDesktop`
    padding-left: 64px;
    text-align: left;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #000000;
    margin-top: 2px;
    margin-bottom: 12px;
  `};
`;

const PraiseThCell = styled(PraiseTd)`
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: -1px;
  text-align: center;
  color: #000000;
  padding: 12px 0;
  ${media.miniDesktop`
    display: none;
  `};
`;

const PublishedTag = styled.span`
  display: inline-block;
  width: 56px;
  height: 25px;
  border-radius: 12.5px;
  background-color: #bcbcbc;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.62;
  letter-spacing: -1px;
  text-align: center;
  color: #ffffff;
  padding: 2px 0;
  ${props =>
    props.blue &&
    `
    background-color: #033e52;
  `};
`;

const TabBtns = styled.div`
  padding-left: 30px;
  border-bottom: 1px solid #e0e0e0;
  ${media.miniDesktop`
    padding: 0;
  `};
`;

const TabBtn = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 120px;
  height: 56px;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: -1px;
  text-align: center;
  color: #000000;
  box-sizing: border-box;
  padding-top: 16px;
  cursor: pointer;
  ${props =>
    props.active &&
    `
    font-weight: bold;
  `};

  ${media.miniDesktop`
    width: 50%;
    padding: 9px 0;
    height: auto;
    font-size: 16px;
    line-height: 1.5;
  `};
`;

const TabContentContainer = styled.div`
  background-color: #ffffff;
  padding: 0 15px 0 30px;
  margin-bottom: 70px;
  min-height: 640px;
  max-width: 1080px;
  width: 100%;
  ${media.miniDesktop`
    padding: 0;
  `};
`;

const TabContent = styled.div`
  width: 100%;
  border-radius: 1px;
  background-color: #ffffff;
  border: solid 1px #e0e0e0;
  ${media.miniDesktop`
    border: none;
  `};
`;

const SendPriseSection = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: -1px;
  color: #484848;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 40px;
  @media(max-width: 960px) {
    display: block;
  }

  ${media.miniDesktop`
    padding: 18px 20px 20px;
    position: relative;
    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 1px;
      background-color: #e0e0e0;
      padding: 0 20px;
      background-clip: content-box;
    }
  `};
`;

const SendPriseSectionInfo = styled.div`
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: -1px;
  color: #000000;
  margin-right: 20px;
  ${media.miniDesktop`
    margin: 0;
  `};
`;

const SendBtn = styled.button`
  width: 240px;
  height: 52px;
  border: none;
  border-radius: 1px;
  background-color: #033e52;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  ${media.miniDesktop`
    width: 100%;
    margin-top: 12px;
  `};
`;

const PriseTableContainer = styled.div`
  padding: 0 40px 30px;
  ${media.miniDesktop`
    padding: 20px 20px 30px;
  `};
`;

const ReplyBtn = styled.div`
  margin: auto;
  width: 88px;
  height: 32px;
  border-radius: 1px;
  background-color: #ffffff;
  border: solid 1px #033e52;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #033e52;
  padding-top: 7px;
  cursor: pointer;
  ${media.miniDesktop`
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    border: 1px solid #033e52;
    height: 100%;
    padding: 12px 0;
  `};
`;

const PublishBtn = styled(ReplyBtn)`
  ${props => props.cancelPublish && 'display: none'};
  background-color: #033e52;
  color: #ffffff;
  margin-top: 8px;
  border: none;
  ${props => props.cancelPublish && 'background-color: #bcbcbc'};
  ${media.miniDesktop`
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    border: none;
    height: 100%;
    margin: 0;
    ${props => props.cancelPublish && 'display: none'};
  `};
`;

const ComplimentViewModalOpenBtnContainer = styled.div`
  margin-top: 4px;
  ${media.miniDesktop`
    margin-top: 2px;
    margin-bottom: 10px;
  `};
`;

const ComplimentViewModalOpenBtnText = styled.span`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: -1px;
  color: #bbbcbc;
  text-decoration: underline;
  cursor: pointer;
`;

const ComplimentViewModalDeleteBtnText = styled(ComplimentViewModalOpenBtnText)`
  margin-left: 10px;
`;

const ComplimentViewModalOpenBtnImg = styled.img`
  cursor: pointer;
  margin-left: 6px;
  vertical-align: middle;
`;

const ITEM_PER_PAGE = 6;

const OdoctorCashInfo = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 30px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: -1.6px;
  color: #000000;
  ${media.miniDesktop`
    margin-bottom: 4px;
    padding: 0px 20px;
    font-size: 18px;
    line-height: 1.44;
    letter-spacing: -1.4px;
    text-align: left;
  `};
  strong {
    color: #033e52;
    font-weight: bold;
  }
`;

class Compliment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalComplimentData: null, // 모달, 팝업에 사용되는 칭찬 데이터
      isOpenParisedTab: true, // 받은 칭찬 탭 활성화 여부
      isModalOpen: false, // 칭찬 답변하기 모달 오픈 여부
      isOpenComplimentViewModal: false, // 칭찬 미리보기 모달 오픈 여부
      isOpenComplimenSendModal: false, // 칭찬 보내기 모달 오픈 여부
      complimentViewModalType: '', // 칭찬 미리보기 팝업 타입 (받은칭찬/보낸칭찬)
    };
  }

  openComplimentViewModal = (compliment, type) => {
    this.setState({
      isOpenComplimentViewModal: true,
      modalComplimentData: compliment,
      complimentViewModalType: type,
    });
  };

  updateComplimentPublishedState = async (compId, publish) => {
    try {
      if (publish) {
        const res = await this.props.publishCompliment({
          variables: {
            compId,
            publish,
          },
        });
        this.props.toast(<div>칭찬이 공개되었습니다.</div>, 'info');
      } else {
        this.props.openAlert({
          title: '칭찬을 비공개하겠습니까?',
          message: <div>확인을 누르시면 칭찬이 비공개됩니다.</div>,
          showConfirmBtn: true,
          confirmBtnName: '확인',
          textAlign: 'left',
          confirmBtnOnClick: async () => {
            const res = await this.props.publishCompliment({
              variables: {
                compId,
                publish,
              },
            });
            this.props.toast(<div>칭찬이 비공개되었습니다.</div>, 'info');
            return res;
          },
          showCancelBtn: true,
          cancleBtnName: '취소',
          cancleBtnOnClick: this.props.closeAlert,
        });
      }
      await this.props.myComplimentsForMe.refetch();
    } catch (e) {
      console.error(e);
    }
  };

  openDeleteConfirmPopup = (id) => {
    this.props.openAlert({
      title: '칭찬 삭제',
      message: '선택하신 칭찬을 정말로 삭제하시겠습니까?',
      showConfirmBtn: true,
      confirmBtnName: '확인',
      textAlign: 'left',
      confirmBtnOnClick: this.removeCompliment.bind(this, id),
      showCancelBtn: true,
      cancleBtnName: '취소',
      cancleBtnOnClick: this.props.closeAlert,
    });
  }

  removeCompliment = async (compId) => {
    const response = await this.props.deleteCompliment({ variables: { compId }});
    if (response.data.deleteCompliment && response.data.deleteCompliment.ok) {
      this.props.toast('칭찬을 삭제하였습니다.', 'info');
      await this.props.myComplimentsToOther.refetch();
    } else {
      this.props.toast('칭찬이 이미 삭제되었습니다.', 'error');
    }
  }

  openReplyModal = (compliment) => {
    this.setState({
      isModalOpen: true,
      modalComplimentData: compliment,
    });
  };

  closeSendModal = () => {
    this.setState({
      isOpenComplimenSendModal: false,
    });
    this.props.myComplimentsToOther.refetch();
  }

  closeReplyModal = () => {
    this.setState({
      isModalOpen: false,
      modalComplimentData: null,
    })
    this.props.myComplimentsToOther.refetch();
  }

  handleReceivedComplimentPageChange = async (page) => {
    if (
      this.props.myComplimentsForMe.myComplimentsForMe.pagination
        .currentPage !== page
    ) {
      this.props.myComplimentsForMe.loadMore(page);
    }
  };

  handleSendComplimentPageChange = async (page) => {
    if (
      this.props.myComplimentsToOther.myComplimentsToOther.pagination
        .currentPage !== page
    ) {
      this.props.myComplimentsToOther.loadMore(page);
    }
  };

  render() {
    const { meh } = this.context;

    if (!meh) {
      return null;
    }

    return (
      <div>
        <Header>
          <PageTitle>동료의사 칭찬하기</PageTitle>
          <TabBtns>
            <TabBtn
              active={this.state.isOpenParisedTab}
              onClick={() => this.setState({ isOpenParisedTab: true })}
            >
              받은칭찬
            </TabBtn>
            <TabBtn
              active={!this.state.isOpenParisedTab}
              onClick={() => this.setState({ isOpenParisedTab: false })}
            >
              보낸칭찬
            </TabBtn>
          </TabBtns>
        </Header>
        <TabContentContainer>
          <OdoctorCashInfo>
            동료의사 분을 칭찬하고 <strong>₩25,000</strong>의
            <br />
            무료 홍보 비용을 선물하세요!
          </OdoctorCashInfo>
          <TabContent>
            <SendPriseSection>
              <SendPriseSectionInfo>
                동료 의사들에게 칭찬을 보내 보세요. 가입하지 않은 동료
                의사에게도 칭찬을 보낼 수 있습니다. (게시는 가입 후 가능합니다)
                <br />
                등록된 칭찬은 유저들이 볼 수 있는 병원 상세페이지에 노출됩니다.
              </SendPriseSectionInfo>
              <div>
                <SendBtn
                  onClick={() =>
                    this.setState({ 
                      isOpenComplimenSendModal: true,
                      isOpenParisedTab: false,
                    })
                  }
                >
                  칭찬보내기
                </SendBtn>
              </div>
            </SendPriseSection>
            {this.state.isOpenParisedTab ? (
              // 받은칭찬
              <PriseTableContainer>
                <PraiseTable>
                  <PraiseTh>
                    <PraiseThCell>구분</PraiseThCell>
                    <PraiseThCell>보낸사람</PraiseThCell>
                    <PraiseThCell>병원</PraiseThCell>
                    <PraiseThCell>칭찬 한마디</PraiseThCell>
                    <PraiseThCell>수신일</PraiseThCell>
                    <PraiseThCell>관리</PraiseThCell>
                  </PraiseTh>
                  {!this.props.myComplimentsForMe.loading &&
                    this.props.myComplimentsForMe.myComplimentsForMe &&
                    this.props.myComplimentsForMe.myComplimentsForMe.compliments
                      .length > 0 &&
                    this.props.myComplimentsForMe.myComplimentsForMe.compliments.map(compliment => (
                      <PraiseTr key={`praisedm_${compliment.id}`}>
                        <PraisePublishType width="80" paddingHorizontal="0">
                          <PublishedTag
                            blue={compliment.status === 'published'}
                          >
                            {compliment.status === 'published'
                                ? '공개'
                                : '비공개'}
                          </PublishedTag>
                        </PraisePublishType>
                        <PraiseDoctorName width="110">
                          {compliment.fromHuser.username}
                        </PraiseDoctorName>
                        <PraiseHosName width="200">
                          {compliment.fromHuser.hospital.name}
                        </PraiseHosName>
                        <PraiseTd width="366" textAlign="left">
                          <PraiseDesc>{compliment.desc}</PraiseDesc>
                          <ComplimentViewModalOpenBtnContainer>
                            <ComplimentViewModalOpenBtnText
                              onClick={this.openComplimentViewModal.bind(
                                  this,
                                  compliment,
                                  'received',
                                )}
                            >
                                칭찬 미리보기
                            </ComplimentViewModalOpenBtnText>
                            <ComplimentViewModalOpenBtnImg
                              onClick={this.openComplimentViewModal.bind(
                                  this,
                                  compliment,
                                  'received',
                                )}
                              src={ComplimentViewIcon}
                              alt="칭찬미리보기"
                            />
                          </ComplimentViewModalOpenBtnContainer>
                        </PraiseTd>
                        <PraiseCreatedAt width="120">
                          <span>수신일</span>
                          {moment(compliment.createdAt).format('YYYY.M.D')}
                        </PraiseCreatedAt>
                        <PraiseBtnContainer paddingHorizontal="0">
                          <ReplyBtn
                            onClick={this.openReplyModal.bind(
                                this,
                                compliment,
                              )}
                          >
                              답변하기
                          </ReplyBtn>
                          <PublishBtn
                            onClick={this.updateComplimentPublishedState.bind(
                                this,
                                compliment.id,
                                !(compliment.status === 'published'),
                              )}
                            cancelPublish={compliment.status === 'published'}
                          >
                            {compliment.status === 'published'
                                ? '비공개하기'
                                : '공개하기'}
                          </PublishBtn>
                        </PraiseBtnContainer>
                      </PraiseTr>
                      ))}
                </PraiseTable>
                {!this.props.myComplimentsForMe.loading &&
                  this.props.myComplimentsForMe.myComplimentsForMe &&
                  this.props.myComplimentsForMe.myComplimentsForMe.pagination &&
                  !(
                    this.props.myComplimentsForMe.myComplimentsForMe.pagination
                      .total < ITEM_PER_PAGE
                  ) && (
                    <PaginationContainer>
                      <StyledPagination
                        activePage={
                          this.props.myComplimentsForMe.myComplimentsForMe
                            .pagination.currentPage
                        }
                        itemsCountPerPage={ITEM_PER_PAGE}
                        totalItemsCount={
                          this.props.myComplimentsForMe.myComplimentsForMe
                            .pagination.total
                        }
                        pageRangeDisplayed={5}
                        onChange={this.handleReceivedComplimentPageChange}
                      />
                    </PaginationContainer>
                  )}
              </PriseTableContainer>
            ) : (
              // 보낸칭찬
              <PriseTableContainer>
                <PraiseTable>
                  <PraiseTh>
                    <PraiseThCell>구분</PraiseThCell>
                    <PraiseThCell>받는사람</PraiseThCell>
                    <PraiseThCell>병원</PraiseThCell>
                    <PraiseThCell>칭찬 한마디</PraiseThCell>
                    <PraiseThCell>발송일</PraiseThCell>
                    <PraiseThCell>등록일</PraiseThCell>
                  </PraiseTh>
                  {!this.props.myComplimentsToOther.loading &&
                    this.props.myComplimentsToOther.myComplimentsToOther &&
                    this.props.myComplimentsToOther.myComplimentsToOther
                      .compliments.length > 0 &&
                    this.props.myComplimentsToOther.myComplimentsToOther.compliments.map(compliment => (
                      <PraiseTr key={`praisem_${compliment.id}`} send>
                        <PraisePublishType width="80" paddingHorizontal="0">
                          <PublishedTag
                            blue={compliment.status === 'published'}
                          >
                            {compliment.status === 'published'
                                ? '공개'
                                : '비공개'}
                          </PublishedTag>
                        </PraisePublishType>
                        <PraiseDoctorName width="110">
                          {compliment.toHuser
                              ? compliment.toHuser.username
                              : compliment.toName}
                        </PraiseDoctorName>
                        <PraiseHosName width="200">
                          {compliment.toHuser && compliment.toHuser.hospital
                              ? compliment.toHuser.hospital.name
                              : compliment.toHosName || '-'}
                        </PraiseHosName>
                        <PraiseTd width="366" textAlign="left">
                          <PraiseDesc>{compliment.desc}</PraiseDesc>
                          <ComplimentViewModalOpenBtnContainer>
                            <ComplimentViewModalOpenBtnText
                              onClick={this.openComplimentViewModal.bind(
                                  this,
                                  compliment,
                                  'send',
                                )}
                            >
                                칭찬 미리보기
                            </ComplimentViewModalOpenBtnText>
                            <ComplimentViewModalOpenBtnImg
                              onClick={this.openComplimentViewModal.bind(
                                  this,
                                  compliment,
                                  'send',
                                )}
                              src={ComplimentViewIcon}
                              alt="칭찬미리보기"
                            />
                            {compliment.status !== 'published' && (
                              <ComplimentViewModalDeleteBtnText
                                onClick={this.openDeleteConfirmPopup.bind(this, compliment.id)}
                              >
                                삭제
                              </ComplimentViewModalDeleteBtnText>
                            )}
                          </ComplimentViewModalOpenBtnContainer>
                        </PraiseTd>
                        <PraiseCreatedAt width="120">
                          <span>발송일</span>{moment(compliment.createdAt).format('YYYY.M.D')}
                        </PraiseCreatedAt>
                        <PraisePublishedAt>
                          <span>등록일</span>
                          {compliment.status === 'published' &&
                            compliment.publishedAt
                              ? moment(compliment.publishedAt).format('YYYY.M.D')
                              : '-'}
                        </PraisePublishedAt>
                      </PraiseTr>
                      ))}
                </PraiseTable>
                {!this.props.myComplimentsToOther.loading &&
                  this.props.myComplimentsToOther.myComplimentsToOther &&
                  this.props.myComplimentsToOther.myComplimentsToOther
                    .pagination &&
                  !(
                    this.props.myComplimentsToOther.myComplimentsToOther
                      .pagination.total < ITEM_PER_PAGE
                  ) && (
                    <PaginationContainer>
                      <StyledPagination
                        activePage={
                          this.props.myComplimentsToOther.myComplimentsToOther
                            .pagination.currentPage
                        }
                        itemsCountPerPage={ITEM_PER_PAGE}
                        totalItemsCount={
                          this.props.myComplimentsToOther.myComplimentsToOther
                            .pagination.total
                        }
                        pageRangeDisplayed={5}
                        onChange={this.handleSendComplimentPageChange}
                      />
                    </PaginationContainer>
                  )}
              </PriseTableContainer>
            )}
          </TabContent>
        </TabContentContainer>
        {/* 칭찬 보내기 모달 */}
        <ComplimentSendModal
          {...this.props}
          meh={meh}
          isOpen={this.state.isOpenComplimenSendModal}
          closeModal={this.closeSendModal}
        />
        {/* 칭찬 답변 모달 */}
        <ComplimentReplyModal
          {...this.props}
          isOpen={this.state.isModalOpen}
          compliment={this.state.modalComplimentData}
          replySubmit={this.replySubmit}
          closeModal={this.closeReplyModal}
        />

        {/* 칭찬 미리보기 모달 */}
        <ComplimentViewModal
          isOpen={this.state.isOpenComplimentViewModal}
          compliment={this.state.modalComplimentData}
          type={this.state.complimentViewModalType}
          closeModal={() =>
            this.setState({
              isOpenComplimentViewModal: false,
            })
          }
          openComplimentReplyModal={() =>
            this.setState({
              isModalOpen: true,
              isOpenComplimentViewModal: false,
            })
          }
        />
      </div>
    );
  }
}

Compliment.contextType = AuthContext;

const ComplimentWithFunction = compose(
  graphql(myComplimentsForMeQuery, {
    name: 'myComplimentsForMe',
    options: () => ({
      fetchPolicy: 'network-only',
      variables: {
        perPage: ITEM_PER_PAGE,
        currentPage: 1,
      },
    }),
    props: ({
      myComplimentsForMe: { loading, myComplimentsForMe, fetchMore, refetch },
    }) => ({
      myComplimentsForMe: {
        loading,
        myComplimentsForMe,
        loadMore(page) {
          return fetchMore({
            variables: {
              currentPage: page,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) {
                return previousResult;
              }
              return Object.assign({}, previousResult, {
                myComplimentsForMe: fetchMoreResult.myComplimentsForMe,
              });
            },
          });
        },
        refetch,
      },
    }),
  }),
  graphql(myComplimentsToOtherQuery, {
    name: 'myComplimentsToOther',
    options: () => ({
      fetchPolicy: 'network-only',
      variables: {
        perPage: ITEM_PER_PAGE,
        currentPage: 1,
      },
    }),
    props: ({
      myComplimentsToOther: { loading, myComplimentsToOther, fetchMore, refetch },
    }) => ({
      myComplimentsToOther: {
        loading,
        myComplimentsToOther,
        loadMore(page) {
          return fetchMore({
            variables: {
              currentPage: page,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) {
                return previousResult;
              }
              return Object.assign({}, previousResult, {
                myComplimentsToOther: fetchMoreResult.myComplimentsToOther,
              });
            },
          });
        },
        refetch,
      },
    }),
  }),
  graphql(publishComplimentMutation, {
    name: 'publishCompliment',
    options: {
      refetchQueries: [
        {
          query: mehQuery,
        },
      ],
    },
  }),
  graphql(deleteCompliment, {
    name: 'deleteCompliment',
  }),
)(withToast(withAlertModal(Compliment)));

export default ComplimentWithFunction;
