import React from 'react';
import styled from 'styled-components';
import InfoImg from '../../images/img-hospital-info.png';
import InfoImgMobile from '../../images/img-hospital-info-mobile.png';
import { media } from '../../globalStyle';

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 30px 40px;
  border-radius: 1px;
  background-color: #ffffff;
  border: solid 1px #e0e0e0;
  ${media.tablet`
    padding: 0 0 20px;
    border: 0;
  `};
`;

const InfoContainer = styled.div`
`;

const Img = styled.img`
  vertical-align: middle;
  width: 100%;
  height: auto;
  object-fit: contain;
  ${media.tablet`
    content: url(${InfoImgMobile});
  `};
`;

const Row = styled.div`
  max-width: 780px;
  width: 100%;
  height: 1px;
  opacity: 0.6;
  border-radius: 1px;
  background-color: #e0e0e0;
  margin: 16px auto 30px;
`;

const Info = styled.div`
  max-width: 780px;
  width: 100%;
  margin: auto;
`;

const Msg = styled.div`
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: -1px;
  color: #000000;
  ${media.tablet`
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: -0.8px;
    padding: 0 20px;
  `};
`;

const DocList = styled.div`
  margin-top: 20px;
  width: 100%;
  padding: 20px;
  border-radius: 1px;
  background-color: #fafafa;
`;

const DocTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: -1px;
  color: #000000;
  margin-bottom: 12px;
`;

const Docs = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: -1px;
  color: #000000;
  margin-bottom: 20px;
  ${media.tablet`
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: -0.9px;
  `};
`;

const DocDownloadBtn = styled.a`
  display: inline-block;
  vertical-align: middle;
  padding-top: 8px;
  width: 152px;
  height: 34px;
  border-radius: 1px;
  border: solid 1px #000000;
  background-color: #ffffff;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.9px;
  text-align: center;
  color: #000000;
  &:hover, &:focus {
    color: #888888;
  }
`;

const Msg2 = styled.div`
  margin: 20px 0 60px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  ${media.tablet`
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: -0.9px;
    padding: 0 20px;
  `};
`;

const ConfirmBtn = styled.a`
  display: inline-block;
  width: 240px;
  border-radius: 1px;
  background-color: #033e52;
  border: 0;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 14px 0;
  &:active, &:focus, &:hover {
    color: #ffffff;
  }
  ${media.tablet`
    width: 100%;
  `};
`;

const AbsoluteConfirmBtn = styled(ConfirmBtn)`
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 1px;
  border: solid 1px #ffffff;
  background-color: #033e52;
  ${media.desktop`
    font-size: 18px;
  `};
`;

const ButtonContainer = styled.div`
  text-align: center;
  ${media.desktop`
    padding: 0 20px;
  `};
`;

const ImgContainer = styled.div`
  position: relative;
`;

const AbsoluteBtnContainer = styled.div`
  position: absolute;
  top: 43.2%;
  left: 38%;
  right: 38%;
  bottom: 52.4%;
  ${media.tablet`
    top: 48.2%;
    bottom: 49%;
    left: 12.5%;
    right: 12.5%;
  `};
`;

const CsMsg = styled.div`
  margin-top: 30px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -1px;
  text-align: right;
  color: #bbbcbc;
  ${media.miniDesktop`
    margin-top: 20px;
    padding: 0 20px;
    font-size: 12px;
    line-height: 1.5;
    text-align: left;
  `};
`;

const AbsoluteConfirmedBtn = styled(AbsoluteConfirmBtn)`
  border-radius: 1px;
  background-color: #878787;
  text-decoration: line-through;
  &:active, &:hover, &:link, &:visited {
    text-decoration: line-through;
  }
`;

const ConfirmedMsgContainer = styled.div`
  width: 100%;
  padding: 14px 40px;
  background-color: #ffb3aa;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -1px;
  color: #ffffff;
  b {
    font-weight: bold;
  }
`;


export default class HosConfirmContainer extends React.Component {
  render() {
    const { confirmed } = this.props;
    return (
      <div>
        {confirmed && (
          <ConfirmedMsgContainer>
            병원인증이 완료되어 상담, 답변관리 메뉴를 이용하실 수 있습니다.
          </ConfirmedMsgContainer>
        )}
        <Container>
          <ImgContainer>
            <Img src={InfoImg} />
            <AbsoluteBtnContainer>
              {confirmed ? (
                <AbsoluteConfirmedBtn>병원 인증하기</AbsoluteConfirmedBtn>
              ) : (
                <AbsoluteConfirmBtn href="https://docs.google.com/forms/d/e/1FAIpQLSdIGgOWfuAbuXlGuiJFHDNfx5aPL3A3T4uqp2qpkRkwv475YA/viewform?usp=sf_link" target="_blank">병원 인증하기</AbsoluteConfirmBtn>
              )}
            </AbsoluteBtnContainer>
          </ImgContainer>
          {
            !confirmed && (
              <InfoContainer>
                <Row />
                <Info>
                  <Msg>병원 인증 진행시 필요한 서류는 아래와 같습니다.</Msg>
                  <DocList>
                    <DocTitle>
                      필요서류 안내
                    </DocTitle>
                    <Docs>
                      1. 병원 인증 접수자의  의사확인 서류(면허 번호가 보이면 됩니다.)<br />
                      2. 사업자등록증 사본 1부<br />
                      3. 병원 증빙서류에 기재된 대표자가 아닐 경우, 단체 직인이나 대표의 인감 또는 서명이 날인된 위임장 1부
                    </Docs>
                    <DocDownloadBtn href="https://s3.ap-northeast-2.amazonaws.com/odoctor-assets/files/%EC%9C%84%EC%9E%84%EC%9E%A5.doc" download>위임장 문서 다운받기</DocDownloadBtn>
                  </DocList>
                  <Msg2>필요한 서류를 준비하셨으면 아래 보내기를 통해 메일로 보내주시기 바랍니다.</Msg2>
                </Info>
                <ButtonContainer>
                  <ConfirmBtn href="https://docs.google.com/forms/d/e/1FAIpQLSdIGgOWfuAbuXlGuiJFHDNfx5aPL3A3T4uqp2qpkRkwv475YA/viewform?usp=sf_link" target="_blank">병원 인증하기</ConfirmBtn>
                </ButtonContainer>
              </InfoContainer>
            )
          }
        </Container>
        <CsMsg>
          서비스 이용 중 궁금하신 점이 있다면 partner@odoctor.co.kr로 문의해 주세요.
        </CsMsg>
      </div>
    );
  }
}
