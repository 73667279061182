import React from 'react';
import styled, { css } from 'styled-components';
import { media } from '../../globalStyle';

export const textProps = props => css`
  ${props.color && `color: ${props.color || 'inherit'}`};
  ${props.fontSize && `font-size: ${props.fontSize || 'inherit'}`};
  ${props.fontWeight && `font-weight: ${props.fontWeight || 'inherit'}`};
  ${props.textAlign && `text-align: ${props.textAlign || 'inherit'}`};
  ${props.lineHeight && `line-height: ${props.lineHeight || 'normal'}`};
`;

export const borderProps = props => css`
  ${props.borderBottom && `border-bottom: ${props.borderWidth || '1px'} solid ${props.borderColor}`};
  ${props.borderTop && `border-top: ${props.borderWidth || '1px'} solid ${props.borderColor}`};
  ${props.borderLeft && `border-left: ${props.borderWidth || '1px'} solid ${props.borderColor}`};
  ${props.borderRight && `border-right: ${props.borderWidth || '1px'} solid ${props.borderColor}`};
`;

export const marginProps = props => css`
  ${props.marginBottom && `margin-bottom: ${typeof (props.marginBottom) === 'string' ? props.marginBottom : '1em'}`};
  ${props.marginTop && `margin-top: ${typeof (props.marginTop) === 'string' ? props.marginTop : '1em'}`};
  ${props.marginLeft && `margin-left: ${typeof (props.marginLeft) === 'string' ? props.marginLeft : '1em'}`};
  ${props.marginRight && `margin-right: ${typeof (props.marginRight) === 'string' ? props.marginRight : '1em'}`};
  ${props.margin && `margin: ${typeof (props.margin) === 'string' ? props.margin : '1em'}`};
  ${props.marginVertical && `
    margin-top: ${typeof (props.marginVertical) === 'string' ? props.marginVertical : '1em'};
    margin-bottom: ${typeof (props.marginVertical) === 'string' ? props.marginVertical : '1em'};
  `};
  ${props.marginHorizontal && `
    margin-left: ${typeof (props.marginHorizontal) === 'string' ? props.marginHorizontal : '1em'};
    margin-right: ${typeof (props.marginHorizontal) === 'string' ? props.marginHorizontal : '1em'};
  `};
`;

export const paddingProps = props => css`
  ${props.paddingBottom && `padding-bottom: ${typeof (props.paddingBottom) === 'string' ? props.paddingBottom : '1em'}`};
  ${props.paddingTop && `padding-top: ${typeof (props.paddingTop) === 'string' ? props.paddingTop : '1em'}`};
  ${props.paddingLeft && `padding-left: ${typeof (props.paddingLeft) === 'string' ? props.paddingLeft : '1em'}`};
  ${props.paddingRight && `padding-right: ${typeof (props.paddingRight) === 'string' ? props.paddingRight : '1em'}`};
  ${props.padding && `padding: ${typeof (props.padding) === 'string' ? props.padding : '1em'}`};
  ${props.paddingVertical && `
    padding-top: ${typeof (props.paddingVertical) === 'string' ? props.paddingVertical : '1em'};
    padding-bottom: ${typeof (props.paddingVertical) === 'string' ? props.paddingVertical : '1em'};
  `};
  ${props.paddingHorizontal && `
    padding-left: ${typeof (props.paddingHorizontal) === 'string' ? props.paddingHorizontal : '1em'};
    padding-right: ${typeof (props.paddingHorizontal) === 'string' ? props.paddingHorizontal : '1em'};
  `};
`;

export const sizeProps = props => css`
  ${props.width && `width: ${typeof (props.width) === 'string' ? props.width : 'auto'}`};
  ${props.height && `height: ${typeof (props.height) === 'string' ? props.height : 'auto'}`};
`;

export const FormBackground = styled.div`
  min-height: 100vh;
  background-color: #ffffff;
  padding: 110px 0 80px;
  ${media.miniDesktop`
    padding: 60px 0 80px;
  `};
`;

export const FormWrapper = styled.div`
  max-width: 400px;
  padding: 0 20px;
  width: 100%;
  margin: 0 auto;
`;

export const FormTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: -1px;
  text-align: left;
  color: #000000;
  margin: 0 auto 8px;
  max-width: 360px;
  ${media.miniDesktop`
    font-size: 30px;
    font-weight: 300;
    line-height: 1.4;
    letter-spacing: -1.5px;
  `};
`;

export const FormSubTitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -1px;
  text-align: left;
  color: #000000;
  margin: 8px auto 30px;
  max-width: 360px;
  ${media.miniDesktop`
    font-size: 14px;
    font-weight: normal;
    letter-spacing: -0.6px;
  `};
`;

export const FormContent = styled.div`
  max-width: 400px;
  margin: 0 auto;
`;

export const FormInputRow = styled.div`
  min-height: 86px;
  width: 100%;
  position: relative;
  & ~ & {
    margin-top: 12px;
  }
`;

export const FormInputRowTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 8px;
`;

export const FormBtnContainer = styled.div`
  margin: 22px 0 14px;
  text-align: center;
  ${marginProps}
`;

export const FormConfirmBtn = styled.button`
  width: 100%;
  height: 52px;
  background-color: #033e52;
  border: 0;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 52px;
  letter-spacing: -1px;
  text-align: center;
  color:  #ffffff;
  &:disabled {
    background-color: #bbbcbc;
  }
  ${props => props.disabledView
    && `
    background-color: #bbbcbc;
  `}
  ${sizeProps}
`;

export const Col = styled.div`
  display: inline-block;
  width: ${props => props.width || 'auto'};
  margin: ${props => props.margin || '0'};
`;

export const Hr = styled.hr`
  margin: ${props => props.margin || 0};
  border-top: ${props => props.height || 1}px solid ${props => props.color || '#8a8a8a'};
`;

export const PaddingDiv = styled.div`
  ${paddingProps}
`;

export const FlexRow = styled.div`
  display: flex;
  width: ${props => props.width || '100%'};
  justify-content: ${props => props.justifyContent || 'initial'};
  align-items: ${props => props.alignItems || 'initial'};
`;

export const CheckBoxList = styled.div`
  display: inline-block;
  margin-left: 16px;
`;

export const TextInput = styled.input`
  width: ${props => props.width || 'initial'};
  height: ${props => props.height || 'initial'};
  border-radius: 3px;
  border: 1px solid #d6d6d6;
  font-size: 16px;
  text-align: ${props => props.textAlign || 'center'};
  color: #6b6b6b;
  padding: 6px;
  ${media.miniDesktop`
    width: auto;
  `};
`;

export const RoundLabel = styled.div`
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 6px 10px;
  display: inline-block;
  border: 1px solid ${props => (props.active ? '#38c8e5' : '#dfdfdf')};
  border-radius: 13px;
  color: ${props => (props.active ? '#38c8e5' : '#dfdfdf')};
  font-size: 13px;
`;

export const TextDiv = styled.div`
  color: ${props => props.color || 'inherit'};
  font-size: ${props => props.fontSize || 'inherit'};
  font-weight: ${props => props.fontWeight || 'inherit'};
  text-align: ${props => props.textAlign || 'inherit'};
  line-height: ${props => props.lineHeight || 'normal'};
  margin: ${props => props.margin || 0};
  width: ${props => props.width || 'auto'};
  white-space: ${props => props.whiteSpace || 'normal'};
`;

export const InputLabel = styled.label`
  font-size: 14px;
  text-align: left;
  color: #000000;
  margin: 0 24px 0 5px;
  cursor: pointer;
  font-weight: normal;
`;

export const WidthFixedContainer = styled.div`
  width: 1000px;
`;

export const PrimaryCardHeader = styled.div`
  width: 100%;
  padding: 0 0 18px;
  width: 100%;
  border-bottom: 2px solid #000000;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  @media(max-width: 800px) {
    font-size: 17px;
  }
`;

export const CardSmallHeader = styled.span`
  margin-left: 14px;
  font-size: 12px;
  font-weight: normal;
  @media(max-width: 800px) {
    display: none;
  }
`;

export const PrimaryCard = styled.div`
  background-color: #ffffff;
  margin-bottom: 34px;
`;

export const Accordion = styled.div`
  cursor: pointer;
  font-size: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #000000;
  border-top: 1px solid #d6d6d6;
`;

export const AccordionBody = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
  padding-bottom: 20px;
  background-color: #ffffff;
`;

export const RoundSquare = styled.div`
  padding: 8px 20px;
  margin-right: 10px;
  display: inline-block;
  border-radius: 16px;
  border: solid 1px #033e52;
  font-size: 14px;
  letter-spacing: -0.9px;
  text-align: center;
  color: #033e52;
`;

export const BigBtn = styled.div`
  cursor: pointer;
  display: inline-block;
  text-align: center;
  width: 100%;
  height: 52px;
  line-height: 52px;
  border: 1px solid #033e52;
  background-color: ${props => (props.danger ? '#ffffff' : '#033e52')};
  font-size: 16px;
  font-weight: bold;
  color: ${props => (props.danger ? '#033e52' : '#ffffff')};;
`;

export const Container = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 768px) {
    width: 480px;
  }

  @media (min-width: 992px) {
    width: 770px;
  }

  @media (min-width: 1200px) {
    width: 1020px;
  }
`;

export const CardHeaderBtn = styled.div`
  width: 58px;
  height: 32px;
  border-radius: 3px;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #38c8e5;
  float: right;
  margin-top: 13px;
  line-height: 32px;
  cursor: pointer;
`;

export const PageContainer = styled.div`
  padding-bottom: 150px;
  padding-top: 90px;
  ${media.desktop`
    padding: 60px 20px 150px;
  `};
`;

export const PageHeader = styled.div`
  width: 100%;
  border-radius: 1px;
  background-color: #ffffff;
`;

export const PageTabBtnContainer = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid #000000;
`;

export const PageTabBtn = styled.a`
  display: inline-block;
  vertical-align: middle;
  height: 24px;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: -1.11px;
  color: #bbbcbc;
  cursor: pointer;
  &:active, &:focus, &:hover {
    text-decoration: none;
    color: #bbbcbc;
  }
  ${props => props.active
    && `
    font-weight: bold;
    color: #000000;

    &:active, &:focus, &:hover {
      text-decoration: none;
      color: #000000;
    }
  `};
  & ~ & {
    margin-left: 20px;
  }
`;

export const PageTabContent = styled.div`
  padding: 60px 0 25px;
  max-width: 1130px;
  position: relative;
  ${media.miniDesktop`
    padding-top: 40px;
  `};
`;

export const PageTitle = styled.div`
  font-size: 26px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.15;
  letter-spacing: -1.1px;
  color: #000000;
  padding: 36px 38px 26px;
  background-color: #ffffff;
  ${media.miniDesktop`
    padding: 24px 30px 18px;
    font-size: 18px;
    line-height: 1.11;
    letter-spacing: -1px;
  `};
`;

export const StyledRadioInput = styled.input`
  display: none;
  & ~ label {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: #000000;
    cursor: pointer;
  }
  &:checked + label {
    ::after {
      display: inline-block;
    }
  }
  &:disabled + label {
    background-color: rgba(224, 224, 224, 0.4);
    border-color: #e0e0e0;
  }
  &:disabled:checked + label {
    background-color: rgba(224, 224, 224, 0.4);
    border-color: #e0e0e0;
    ::after {
      background-color: #e0e0e0;
    }
  }
  &:disabled ~ label {
    cursor: default;
  }
`;

export const ScrollBarStyledTextArea = styled.textarea`
  /* chrome, safari */
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    cursor: pointer;
    width: 10px;
    height: 10px;
    background-color: #ffffff;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: #cccccc;
    cursor: pointer;
  }

  /* firefox, mozila */
  scrollbar-width: 10px;
  scrollbar-color: #cccccc #cccccc;

  /* IE */
  scrollbar-face-color: #cccccc;
  scrollbar-track-color: #fff;
  scrollbar-arrow-color: none;
  scrollbar-highlight-color: #cccccc;
  scrollbar-3dlight-color: none;
  scrollbar-shadow-color: #cccccc;
  scrollbar-darkshadow-color: none;
`;

export const RadioLabel = styled.label`
  width: 18px;
  height: 18px;
  border: solid 1px #d9d9d9;
  border-radius: 50px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  ::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #033e52;
    border-radius: 50px;
    left: 3px;
    top: 3px;
    display: none;
    vertical-align: middle;
  }
`;

export const DesktopShow = styled.div`
  display: block;
  ${media.miniDesktop`
    display: none;
  `};
`;

export const MobileShow = styled.div`
  display: none;
  ${media.miniDesktop`
    display: block;
  `};
`;

export const RadioInput = ({
  labelName,
  id,
  name,
  onChange,
  value,
  defaultChecked,
  ...rest
}) => (
  <React.Fragment>
    <StyledRadioInput
      type="radio"
      id={id}
      name={name}
      onChange={onChange}
      value={value}
      defaultChecked={defaultChecked}
      {...rest}
    />
    <RadioLabel htmlFor={id} />
    <InputLabel htmlFor={id}>{labelName}</InputLabel>
  </React.Fragment>
);

export default Container;
