import decode from 'jwt-decode';
import { channelCheckOut } from './channel';

export const setToken = (token, refreshToken) => {
  localStorage.setItem('hospitalToken', token);
  localStorage.setItem('refreshHospitalToken', refreshToken);
};

export const setMehStatus = (status) => {
  localStorage.setItem('mehStatus', status);
};

export const removeToken = () => {
  localStorage.removeItem('hospitalToken');
  localStorage.removeItem('refreshHospitalToken');
};

export const removeMehStatus = () => {
  localStorage.removeItem('mehStatus');
};

export const logout = () => {
  removeToken();
  removeMehStatus();
  // channelCheckOut();
};

export const isAuthenticated = () => {
  const token = localStorage.getItem('hospitalToken');
  const refreshToken = localStorage.getItem('refreshHospitalToken');
  try {
    decode(token);
    decode(refreshToken);
  } catch (err) {
    // decode 가 안되는 token 을 받은 경우 token 지우고 로그아웃 시킨다.
    localStorage.removeItem('hospitalToken');
    localStorage.removeItem('refreshHospitalToken');
    return false;
  }

  return true;
};

export const isConfirmedAuthenticated = () => {
  const token = localStorage.getItem('hospitalToken');
  const refreshToken = localStorage.getItem('refreshHospitalToken');
  const mehStatus = localStorage.getItem('mehStatus');
  try {
    decode(token);
    decode(refreshToken);
    if (!mehStatus || mehStatus !== 'confirmed') {
      throw new Error('Not Authenticated');
    }
  } catch (err) {
    // decode 가 안되는 token 을 받은 경우 token 지우고 로그아웃 시킨다.
    localStorage.removeItem('hospitalToken');
    localStorage.removeItem('refreshHospitalToken');
    return false;
  }

  return true;
};

const auth = {};
export default auth;
