import React, { Component } from 'react';
import styled from 'styled-components';
import { media } from '../../globalStyle';

const BannerA = styled.a`
  display: block;
  ${media.miniDesktop`
    display: none;
  `};
`;

export default class DesktopBanner extends Component {
  render() {
    const { srcImg, url } = this.props;

    return (
      <BannerA href={url} target="_blank">
        <img src={srcImg} alt="event" width="1080px" style={{ marginTop: '30px' }} />
      </BannerA>
    );
  }
}
