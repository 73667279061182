import React, { Component } from 'react';
import styled from 'styled-components';
import Selector from '../form/Selector';
import {
  RespCallStateOptions,
  blockEscape,
  unBlockEscape,
} from '../HosResps/HosRespsCommonComponents';

const Container = styled.div`
  width: 100%;
`;

const MemoTextarea = styled.textarea`
  width: 100%;
  height: 100%;
  padding: 20px 16px 10px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -1px;
  text-align: justify;
  color: #000000;
  border-radius: 4px;
  border: solid 1px #e1e3e2;
  resize: none;
`;

const Button = styled.button`
  width: 120px;
  height: 46px;
  border: solid 1px #033e52;
  background-color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: -1px;
  text-align: center;
  color: #033e52;
  border-radius: 4px;
  &.blue {
    background-color: #033e52;
    color: #ffffff;
    font-weight: bold;
  }
  &:disabled {
    border: none;
    background-color: #e1e3e2;
  }
`;

const AnswerDetailLink = styled.p`
  height: 20px;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: -0.4px;
  color: #033e52;
  text-decoration-color: #033e52;
  text-decoration: underline;
`;

class HosRespMemoEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      memo: props.initMemo,
      callState: props.initCallState,
      initCallState: props.initCallState,
      initMemo: props.initMemo,
    };
  }
  onUnloadListener = (e) => {
    const { memo, callState, initMemo, initCallState } = this.state;
    const { caId, saveMemo } = this.props;

    if (initMemo !== memo || callState !== initCallState) {
      saveMemo(caId, memo, callState).then().catch();
      return;
    }
  };
  componentDidMount = () => {
    blockEscape(this.onUnloadListener);
  };
  componentWillUnmount = () => {
    unBlockEscape(this.onUnloadListener);
  };
  componentDidUpdate = (prevProps) => {
    if (
      prevProps.initMemo !== this.props.initMemo ||
      prevProps.initCallState !== this.props.initCallState
    ) {
      this.setState({
        memo: this.props.initMemo,
        callState: this.props.initCallState,
        initCallState: this.props.initCallState,
        initMemo: this.props.initMemo,
      });
    }
  };
  render() {
    const { caId, hrId, setCurrentHosResp, saveMemo } = this.props;
    const { memo, callState, initMemo, initCallState } = this.state;
    return (
      <Container
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onBlur={async () => {
          if (initMemo === memo && callState === initCallState) return;
          await saveMemo(caId, memo, callState);
          this.setState({
            initMemo: memo,
            initCallState: callState,
          });
        }}>
        <div style={{ height: 46, marginTop: 19 }}>
          <Selector
            value={callState}
            items={RespCallStateOptions}
            renderItem={(option, index) => (
              <option key={index} value={option.value} label={option.label}>
                {option.labelName}
              </option>
            )}
            onChange={({ target }) => {
              this.setState({
                callState: target.value,
              });
            }}
          />
        </div>
        <div style={{ height: 88, marginTop: 10 }}>
          <MemoTextarea
            value={memo ? memo : ''}
            onChange={({ target }) => {
              this.setState({
                memo: target.value,
              });
            }}
            maxLength='200'
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 20,
          }}>
          <AnswerDetailLink
            onClick={() => {
              setCurrentHosResp(hrId);
            }}>
            자세히보기
          </AnswerDetailLink>
          <Button // 버튼 부활
            disabled={initMemo === memo && callState === initCallState}
            className='blue'
            onClick={async () => {
              try {
                await saveMemo(caId, memo, callState);
                this.setState({
                  initCallState: callState,
                  initMemo: memo,
                });
              } catch (e) {
                console.log(e);
                alert('다시 시도해주세요!');
              }
            }}>
            저장
          </Button>
        </div>
      </Container>
    );
  }
}

export default HosRespMemoEditor;
