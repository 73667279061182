import React, { Component } from 'react';
import moment from 'moment';

export default class MomentDiff extends Component {
  calcDiff(eventAt, timeUnit) {
    return moment().diff(moment(eventAt), timeUnit);
  }

  render() {
    const { eventAt } = this.props;
    let diffString = '';
    let diffUnit = '분';

    const diffMinute = this.calcDiff(eventAt, 'minutes');

    if (diffMinute < 60) {
      diffString = this.calcDiff(eventAt, 'minutes');
    } else if (diffMinute > 60 && diffMinute < 1440) {
      diffString = this.calcDiff(eventAt, 'hours');
      diffUnit = '시간';
    } else {
      diffString = this.calcDiff(eventAt, 'days');
      diffUnit = '일';
    }

    return (
      <React.Fragment>
        <span>{diffString}</span>{diffUnit}
      </React.Fragment>
    );
  }
}
