import React, { Component } from 'react';
import styled from 'styled-components';
import { Title, SubTitle, TextInput, Section } from './MyHospitalCommonComponents';

const TitleSmall = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.8px;
  color: #bcbcbc;
  margin-top: -20px;
  margin-bottom: 20px;
  a, a:active, a:hover, a:focus, a:link {
    color: #bcbcbc;
    text-decoration: underline;
  }
`;

export default class HosBasicInfoEditableComponent extends Component {
  render() {
    const {
      name,
      phone,
      address,
      onChange,
    } = this.props;

    return (
      <div>
        <Title>병원 기본정보</Title>
        <TitleSmall>기본정보 수정이 필요할 경우, <a href="mailto: partner@odoctor.co.kr?subject=문의하기&body=오닥터%20고객센터입니다.%0A문의할%20사항이나%20서비스%20사용중%20궁금하신점을%20작성해주세요%0A%0A성함%20혹은%20아이디%20:%0A%0A문의내용%20:">고객센터</a>로 문의주시기 바랍니다.</TitleSmall>
        <Section>
          <SubTitle marginBottom="8px">병원명</SubTitle>
          <TextInput disabled name="name" type="text" value={name} onChange={onChange} placeholder="병원이름을 입력해주세요" />
        </Section>

        <Section>
          <SubTitle marginBottom="8px">위치</SubTitle>
          <TextInput
            disabled
            type="text"
            name="address"
            value={address}
            onChange={onChange}
            placeholder="ex) 서울특별시 강남구 강남로 323 8층"
          />
        </Section>

        <Section>
          <SubTitle marginBottom="8px">연락처</SubTitle>
          <TextInput
            disabled
            type="text"
            name="phone"
            value={phone}
            onChange={onChange}
            placeholder="ex) 02-000-0000"
          />
        </Section>
      </div>
    );
  }
}
