import gql from 'graphql-tag';

export const mehQuery = gql`
  query {
    meh {
      id
      username
      email
      phonenum
      role
      status
      agreemarket
      photos {
        id
        url
      }
      hospital {
        id
        address
        phone
        name
      }
    }
  }
`;

export const updateHospitalAddInfoMutate = gql`
  mutation($tags: [String], $infos: [String]) {
    updateHospitalAddInfo(tags: $tags, infos: $infos) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const updateHospitalPeopleMutate = gql`
  mutation($people: [HospitalPeopleInput]) {
    updateHospitalPeople(people: $people) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const myHospitalQuery = gql`
  query {
    myHospital {
      id
      name
      phone
      address
      metadata
      photos {
        id
        url
        category
        title
        description
      }
      counselSetting {
        autoCounsel
        greeting
        closing
      }
      tags {
        id
        tagName
      }
      infos {
        id
        infoText
      }
      hospitalPeople {
        name
        phonenum
      }
    }
  }
`;

export const myCounselSettingQuery = gql`
  query {
    myCounselSetting {
      autoCounsel
      greeting
      closing
      showGreeting
      showClosing
      showDescriptions
      showPrices
    }
  }
`;

export const myPriceTableQuery = gql`
  query {
    myPriceTable {
      txPrices {
        id
        itemcode
        price
        description
      }
      orthoPrices {
        id
        itemcode
        price
        part
        description
      }
    }
  }
`;

export const userRequestQuery = gql`
  query($reqId: ID!) {
    getUserReq(reqId: $reqId) {
      id
      reqType
      createdAt
      txItems {
        itemcode
        unit
      }
      orthoItems {
        itemcode
        part
      }
      chargePrice
      status
      txMaxPrice
      orthoMaxPrice
      carePrice
      txImageUrls
      orthoImageUrls
      orthoWanna
      alarmOn
      considerTags
      wantLevel
      gender
      ageGroup
      desc
      locations {
        id
        siName
        gunguName
      }
      wantLevel
      user {
        id
        username
        sex
        phonenum
        favHospitals {
          id
        }
      }
      hosResps {
        id
        ureqId
        hospitalId
        txMaxPrice
        orthoMaxPrice
        txPrices {
          itemcode
          price
        }
        orthoPrices {
          itemcode
          price
          part
        }
        symptomAnswer
        treatmentAnswer
        discount {
          id
          discountRatio
          startAt
          endAt
        }
        metadata
        checkedAns {
          id
        }
      }
      directReq {
        id
      }
    }
  }
`;

export const myComplimentsForMeQuery = gql`
  query($perPage: Int, $currentPage: Int) {
    myComplimentsForMe(perPage: $perPage, currentPage: $currentPage) {
      compliments {
        id
        desc
        status
        phonenum
        toName
        toHosName
        fromHuser {
          id
          username
          email
          phonenum
          hospital {
            id
            name
          }
        }
        toHuser {
          id
          username
          email
          phonenum
          hospital {
            id
            name
          }
        }
        publishedAt
        createdAt
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const myComplimentsToOtherQuery = gql`
  query($perPage: Int, $currentPage: Int) {
    myComplimentsToOther(perPage: $perPage, currentPage: $currentPage) {
      compliments {
        id
        desc
        status
        phonenum
        toName
        toHosName
        fromHuser {
          id
          username
          email
          phonenum
          hospital {
            id
            name
          }
        }
        toHuser {
          id
          username
          email
          phonenum
          hospital {
            id
            name
          }
        }
        publishedAt
        createdAt
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const getComplimentFromName = gql`
  query($compId: ID) {
    getCompliment(compId: $compId) {
      fromHuser {
        username
      }
    }
  }
`;

export const getComplimentQuery = gql`
  query($compId: ID) {
    getCompliment(compId: $compId) {
      id
      desc
      status
      phonenum
      toName
      toHosName
      fromHuser {
        id
        username
        email
        phonenum
        hospital {
          id
          name
        }
      }
      toHuser {
        id
        username
        email
        phonenum
        hospital {
          id
          name
        }
      }
      publishedAt
      createdAt
    }
  }
`;

export const publishComplimentMutation = gql`
  mutation($compId: ID!, $publish: Boolean!) {
    publishCompliment(compId: $compId, publish: $publish) {
      id
      desc
      status
      phonenum
      toName
      toHosName
      fromHuser {
        id
        username
        email
        phonenum
        hospital {
          id
          name
        }
      }
      toHuser {
        id
        username
        email
        phonenum
        hospital {
          id
          name
        }
      }
      publishedAt
      createdAt
    }
  }
`;

export const loginMutation = gql`
  mutation($email: String!, $password: String!) {
    loginUserByEmail(email: $email, password: $password) {
      ok
      token
      refreshToken
      errors {
        path
        message
      }
      isPartner
    }
  }
`;

export const addComplimentMutation = gql`
  mutation($toId: ID, $toHosName: String, $toName: String, $desc: String!) {
    addCompliment(
      toId: $toId
      toHosName: $toHosName
      toName: $toName
      desc: $desc
    ) {
      compliment {
        id
        desc
        toName
        toHosName
        fromHuser {
          id
          username
        }
        toHuser {
          id
          username
        }
        createdAt
      }
      error {
        path
        message
        code
      }
    }
  }
`;

export const updateCompliment = gql`
  mutation($compId: ID!, $desc: String!) {
    updateCompliment(compId: $compId, desc: $desc) {
      id
      status
      desc
    }
  }
`;

export const deleteCompliment = gql`
  mutation($compId: ID!) {
    deleteCompliment(compId: $compId) {
      ok
      error {
        message
      }
    }
  }
`;

export const submitHosRespsMutation = gql`
  mutation(
    $ureqId: ID!
    $txMaxPrice: Float
    $orthoMaxPrice: Float
    $txPrices: [TxPriceInput]
    $orthoPrices: [OrthoPriceInput]
    $symptomAnswer: String
    $treatmentAnswer: String
    $discountRatio: Int
    $metadata: JSON
  ) {
    submitHosResps(
      ureqId: $ureqId
      txMaxPrice: $txMaxPrice
      orthoMaxPrice: $orthoMaxPrice
      txPrices: $txPrices
      orthoPrices: $orthoPrices
      discountRatio: $discountRatio
      symptomAnswer: $symptomAnswer
      treatmentAnswer: $treatmentAnswer
      metadata: $metadata
    ) {
      id
      ureqId
      hospitalId
      txMaxPrice
      orthoMaxPrice
      txPrices {
        itemcode
        price
      }
      orthoPrices {
        itemcode
        price
        part
      }
      treatmentAnswer
      symptomAnswer
      discount {
        id
        discountRatio
        startAt
        endAt
      }
      metadata
    }
  }
`;

export const updateHosRespsMutation = gql`
  mutation(
    $id: ID!
    $ureqId: ID!
    $txMaxPrice: Float
    $orthoMaxPrice: Float
    $txPrices: [TxPriceInput]
    $orthoPrices: [OrthoPriceInput]
    $discountRatio: Int
    $symptomAnswer: String
    $treatmentAnswer: String
    $metadata: JSON
  ) {
    updateHosResps(
      id: $id
      ureqId: $ureqId
      txMaxPrice: $txMaxPrice
      orthoMaxPrice: $orthoMaxPrice
      txPrices: $txPrices
      orthoPrices: $orthoPrices
      treatmentAnswer: $treatmentAnswer
      symptomAnswer: $symptomAnswer
      discountRatio: $discountRatio
      metadata: $metadata
    ) {
      id
      ureqId
      hospitalId
      txMaxPrice
      orthoMaxPrice
      txPrices {
        itemcode
        price
      }
      orthoPrices {
        itemcode
        price
        part
      }
      symptomAnswer
      treatmentAnswer
      discount {
        id
        discountRatio
        startAt
        endAt
      }
      metadata
    }
  }
`;

export const allUserReqsQuery = gql`
  query(
    $perPage: Int
    $currentPage: Int
    $reqType: ReqType
    $replied: String
    $username: String
    $locationSi: ID
    $locationGu: ID
  ) {
    allUserReqs(
      perPage: $perPage
      currentPage: $currentPage
      reqType: $reqType
      replied: $replied
      username: $username
      locationSi: $locationSi
      locationGu: $locationGu
    ) {
      userReqs {
        id
        createdAt
        reqType
        status
        txMaxPrice
        orthoMaxPrice
        carePrice
        chargeTier
        user {
          id
          username
          sex
          phonenum
          favHospitals {
            id
          }
        }
        res {
          id
        }
        locations {
          id
          siName
          gunguName
        }
        directReq {
          id
        }
        txItems {
          itemcode
          unit
        }
        orthoItems {
          itemcode
          part
        }
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

// export const allReservationsQuery = gql`
//   query(
//     $perPage: Int
//     $currentPage: Int
//     $startDate: Date
//     $endDate: Date
//     $reqType: ReqType
//     $state: String
//     $username: String
//   ) {
//     allReservations(
//       perPage: $perPage
//       currentPage: $currentPage
//       startDate: $startDate
//       endDate: $endDate
//       reqType: $reqType
//       state: $state
//       username: $username
//     ) {
//       reservations {
//         id
//         ureqId
//         hospitalId
//         state
//         callReceivedAt
//         createdAt
//         availtime
//         requirements
//         metadata
//         hosResps {
//           id
//           ureqId
//           hospitalId
//           txMaxPrice
//           orthoMaxPrice
//           txPrices {
//             itemcode
//             price
//           }
//           orthoPrices {
//             itemcode
//             price
//             part
//           }
//           treatmentAnswer
//           symptomAnswer
//           metadata
//         }
//         userReqs {
//           id
//           reqType
//           createdAt
//           txItems {
//             itemcode
//             unit
//           }
//           orthoItems {
//             itemcode
//             part
//           }
//           chargePrice
//           chargeLevel
//           status
//           txMaxPrice
//           orthoMaxPrice
//           carePrice
//           txImageUrls
//           orthoImageUrls
//           orthoWanna
//           alarmOn
//           considerTags
//           gender
//           ageGroup
//           desc
//           locations {
//             id
//             siName
//             gunguName
//           }
//           directReq {
//             id
//           }
//           user {
//             id
//             username
//             sex
//             phonenum
//           }
//         }
//       }
//       pagination {
//         total
//         perPage
//         currentPage
//         lastPage
//       }
//     }
//   }
// `;

export const findHospitalByNameQuery = gql`
  query($name: String!) {
    findHospitalByName(name: $name) {
      id
      name
      address
    }
  }
`;

export const findHuserByNameQuery = gql`
  query($name: String!) {
    findHuserByName(name: $name) {
      id
      username
      email
      phonenum
      hospital {
        id
        name
      }
    }
  }
`;

// export const updateReservationMutation = gql`
//   mutation(
//     $reservationId: ID!
//     $state: String
//     $willvisitDate: String
//     $willvisitTime: String
//   ) {
//     updateReservation(
//       reservationId: $reservationId
//       state: $state
//       willvisitDate: $willvisitDate
//       willvisitTime: $willvisitTime
//     ) {
//       id
//       ureqId
//       hospitalId
//       state
//       createdAt
//       availtime
//       requirements
//       metadata
//       hosResps {
//         id
//       }
//       userReqs {
//         id
//         reqType
//         createdAt
//         txItems {
//           itemcode
//           unit
//         }
//         orthoItems {
//           itemcode
//           part
//         }
//         status
//         txMaxPrice
//         orthoMaxPrice
//         carePrice
//         txImageUrls
//         orthoImageUrls
//         orthoWanna
//         alarmOn
//         considerTags
//         desc
//         user {
//           id
//           username
//           sex
//           phonenum
//         }
//       }
//     }
//   }
// `;

export const signS3Mutation = gql`
  mutation($filename: String!, $filetype: String!) {
    signS3(filename: $filename, filetype: $filetype) {
      signedRequest
      url
    }
  }
`;

export const myPriceTableByIdQuery = gql`
  query($reqId: ID!) {
    getPriceTableByReqId(reqId: $reqId) {
      txPrices {
        id
        itemcode
        price
        description
      }
      orthoPrices {
        id
        itemcode
        price
        part
        description
      }
    }
  }
`;

export const addHospitalPhotoMutation = gql`
  mutation(
    $url: String!
    $category: String
    $title: String
    $description: String
  ) {
    addHospitalPhoto(
      url: $url
      category: $category
      title: $title
      description: $description
    ) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const deleteHospitalPhotoMutation = gql`
  mutation($id: ID!) {
    deleteHospitalPhoto(id: $id) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const updatePriceTableMutate = gql`
  mutation(
    $txPriceArr: [TxPriceTableInput]
    $orthoPriceArr: [OrthoPriceTableInput]
  ) {
    updatePriceTable(txPriceArr: $txPriceArr, orthoPriceArr: $orthoPriceArr) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const updateHosBasicInfoMutate = gql`
  mutation($name: String, $phone: String, $address: String, $metadata: JSON) {
    updateHospitalBasicInfo(
      name: $name
      phone: $phone
      address: $address
      metadata: $metadata
    ) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const updateCounselSettingMutate = gql`
  mutation(
    $autoCounsel: Boolean
    $greeting: String
    $closing: String
    $showGreeting: Boolean
    $showClosing: Boolean
    $showDescriptions: Boolean
    $showPrices: Boolean
  ) {
    updateCounselSetting(
      autoCounsel: $autoCounsel
      greeting: $greeting
      closing: $closing
      showGreeting: $showGreeting
      showClosing: $showClosing
      showDescriptions: $showDescriptions
      showPrices: $showPrices
    ) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const registerHuserMutation = gql`
  mutation(
    $username: String!
    $email: String!
    $phonenum: String!
    $hospitalId: ID!
    $password: String!
    $compId: ID
    $referralId: String
  ) {
    registerHuser(
      username: $username
      email: $email
      phonenum: $phonenum
      hospitalId: $hospitalId
      password: $password
      compId: $compId
      referralId: $referralId
    ) {
      ok
      user {
        id
        username
        email
      }
      errors {
        message
      }
    }
  }
`;

export const updateComplimentToIdMutation = gql`
  mutation($compId: ID!) {
    updateComplimentToId(compId: $compId) {
      id
      desc
      status
      phonenum
      toName
      toHosName
      fromHuser {
        id
        username
        email
        phonenum
        hospital {
          id
          name
        }
      }
      toHuser {
        id
        username
        email
        phonenum
        hospital {
          id
          name
        }
      }
      publishedAt
      createdAt
    }
  }
`;

export const updateHuserPhotoMutation = gql`
  mutation($url: String, $forceDelete: Boolean) {
    updateHuserPhoto(url: $url, forceDelete: $forceDelete) {
      ok
      errors {
        message
      }
    }
  }
`;

export const sendPasswordUpdateMailToHuserMutation = gql`
  mutation($email: String) {
    sendPasswordUpdateMailToHuser(email: $email) {
      ok
      errors {
        message
      }
    }
  }
`;

export const updatePasswordMutation = gql`
  mutation($origpass: String!, $newpass: String!) {
    updatePassword(origpass: $origpass, newpass: $newpass) {
      ok
      errors {
        message
      }
    }
  }
`;

export const isSafeEmailMutation = gql`
  mutation($email: String!) {
    isSafeEmail(email: $email) {
      ok
      isPartner
    }
  }
`;

export const isSafePhoneMutation = gql`
  mutation($phonenum: String!) {
    isSafePhone(phonenum: $phonenum) {
      ok
      email
      isPartner
    }
  }
`;

export const findEmailForHuserMutation = gql`
  mutation($username: String!, $phonenum: String!) {
    findEmailForHuser(username: $username, phonenum: $phonenum)
  }
`;

export const passwordResetMutation = gql`
  mutation(
    $email: String
    $resetToken: String
    $newPass: String
    $forHuser: Boolean
  ) {
    passwordReset(
      email: $email
      resetToken: $resetToken
      newPass: $newPass
      forHuser: $forHuser
    ) {
      ok
    }
  }
`;

export const passwordResetAvailableQuery = gql`
  query($email: String!, $forHuser: Boolean) {
    passwordResetAvailable(email: $email, forHuser: $forHuser) {
      ok
    }
  }
`;

export const checkPasswordMutation = gql`
  mutation($password: String!) {
    checkPassword(password: $password) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const updateHuserInfoMutation = gql`
  mutation($phonenum: String, $hospitalId: ID, $agreemarket: Boolean) {
    updateHuserInfo(
      phonenum: $phonenum
      hospitalId: $hospitalId
      agreemarket: $agreemarket
    ) {
      id
      username
      email
      phonenum
      hospital {
        id
        name
      }
      agreemarket
    }
  }
`;

export const announcementsListQuery = gql`
  query($forWho: String!) {
    announcementsList(forWho: $forWho) {
      id
      title
      forWho
      description
      importance
      createdAt
    }
  }
`;

export const addOdocLogMutation = gql`
  mutation($message: String!) {
    addOdocLog(message: $message) {
      id
      message
    }
  }
`;

export const getBalanceQuery = gql`
  query {
    getBalance {
      balanceTotal
    }
  }
`;

export const getHospitalRespPlanQuery = gql`
  query {
    getHospitalRespPlan {
      startDate
      endDate
      usedRespCount
      maxRespCount
    }
  }
`;

export const getAccountQuery = gql`
  query {
    getBalance {
      balanceTotal
    }
    getHospitalRespPlan {
      startDate
      endDate
      usedRespCount
      maxRespCount
    }
  }
`;

export const getAccountHistoryQuery = gql`
  query(
    $perPage: Int
    $currentPage: Int
    $startDate: Date
    $endDate: Date
    $direction: String
  ) {
    getAccountHistory(
      perPage: $perPage
      currentPage: $currentPage
      startDate: $startDate
      endDate: $endDate
      direction: $direction
    ) {
      history {
        id
        amount
        desc
        cumSum
        direction
        method
        createdAt
        hosResps {
          id
        }
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const locationSiQuery = gql`
  query {
    locationSi {
      code
      name
    }
  }
`;

export const locationGunguQuery = gql`
  query($siName: String!) {
    locationGungu(siName: $siName) {
      id
      code
      name
      siCode
    }
  }
`;

export const checkedHosRespsForPartnerQuery = gql`
  query(
    $perPage: Int
    $currentPage: Int
    $startDate: Date
    $endDate: Date
    $callState: String
    $username: String
    $phonenum: String
    $locationSi: ID
    $locationGu: ID
  ) {
    checkedHosRespsForPartner(
      perPage: $perPage
      currentPage: $currentPage
      startDate: $startDate
      endDate: $endDate
      callState: $callState
      username: $username
      phonenum: $phonenum
      locationSi: $locationSi
      locationGu: $locationGu
    ) {
      hosResps {
        id
        ureqId
        hospitalId
        txMaxPrice
        orthoMaxPrice
        txPrices {
          itemcode
          price
        }
        orthoPrices {
          itemcode
          price
          part
        }
        userRequest {
          id
          reqType
          createdAt
          locations {
            id
            siName
            gunguName
          }
          user {
            id
            username
            sex
            phonenum
            metadata
          }
          directReq {
            id
          }
        }
        treatmentAnswer
        symptomAnswer
        metadata
        checkedAns {
          id
          createdAt
          callState
          memo
        }
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const unCheckedHosRespsForPartnerQuery = gql`
  query(
    $perPage: Int
    $currentPage: Int
    $startDate: Date
    $endDate: Date
    $username: String
    $locationSi: ID
    $locationGu: ID
  ) {
    unCheckedHosRespsForPartner(
      perPage: $perPage
      currentPage: $currentPage
      startDate: $startDate
      endDate: $endDate
      username: $username
      locationSi: $locationSi
      locationGu: $locationGu
    ) {
      hosResps {
        id
        ureqId
        hospitalId
        txMaxPrice
        orthoMaxPrice
        txPrices {
          itemcode
          price
        }
        orthoPrices {
          itemcode
          price
          part
        }
        userRequest {
          id
          reqType
          createdAt
          locations {
            id
            siName
            gunguName
          }
          user {
            id
            username
            sex
            phonenum
          }
          directReq {
            id
          }
        }
        treatmentAnswer
        symptomAnswer
        metadata
        checkedAns {
          id
          createdAt
          callState
        }
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const updateCheckedRespCallStateMutation = gql`
  mutation($caId: ID!, $callState: String, $memo: String) {
    updateCheckedAnsState(caId: $caId, callState: $callState, memo: $memo) {
      id
      createdAt
      updatedAt
      callState
      memo
      hosResps {
        id
      }
    }
  }
`;

export const getHosRespsDetailForPartnerQuery = gql`
  query($hrid: ID!) {
    getHosRespsDetailForPartner(hrid: $hrid) {
      id
      ureqId
      hospitalId
      reserved
      userRequest {
        id
        reqType
        gender
        ageGroup
        txImageUrls
        orthoImageUrls
        wantLevel
        considerTags
        desc
        locations {
          id
          siName
          gunguName
        }
        user {
          id
          username
          phonenum
          metadata
        }
        txItems {
          itemcode
          unit
        }
        orthoItems {
          itemcode
          part
        }
        directReq {
          id
        }
        createdAt
        orthoWanna
      }
      txMaxPrice
      orthoMaxPrice
      txPrices {
        itemcode
        price
      }
      orthoPrices {
        itemcode
        price
        part
      }
      treatmentAnswer
      symptomAnswer
      metadata
      checkedAns {
        id
        createdAt
        callState
        memo
      }
      discount {
        id
        discountRatio
      }
    }
  }
`;

export const myDiscountSettingQuery = gql`
  query {
    myDiscountSetting {
      discountRatio
    }
  }
`;

export const updateDiscountSettingMutate = gql`
  mutation($discountRatio: Int!) {
    updateDiscountSetting(discountRatio: $discountRatio) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const idk = {};
