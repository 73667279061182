import React from 'react';
import styled from 'styled-components';
import { media } from '../../globalStyle';

const StyledBtn = styled.div`
  position: fixed;
  right: 40px;
  bottom: 90px;
  ${media.miniDesktop`
    right: 20px;
    bottom: 20px;
  `}
`;

class KakaoChannelBtn extends React.Component {
  componentDidMount() {
    window.Kakao.Channel.createChatButton({
      container: '#kakao-talk-channel-chat-button',
      channelPublicId: '_bxcTxnT',
      title: 'consult',
      size: 'small',
      color: 'yellow',
      shape: 'pc',
      supportMultipleDensities: true,
    });
  }

  render() {
    return (
      <StyledBtn id="kakao-talk-channel-chat-button" />
    );
  }
}

export default KakaoChannelBtn;
