import React, { Component } from 'react';
import styled from 'styled-components';
import { Textarea } from './MyHospitalCommonComponents';
import { media } from '../../globalStyle';

const ItemWrapper = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;
`;

const ItemContainer = styled.div`
  display: block;
  background-color: rgba(248, 248, 248, 0.6);
  padding: 20px 20px;
`;

const ItemLabel = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  margin-bottom: 12px;
`;

const RightContainer = styled.div``;

const HalfContainer = styled.div`
  width: 50%;
  display: inline-block;
  vertical-align: middle;
  min-width: 340px;
  ${media.desktop`
    display: block;
    width: 100%;
    min-width: 0;
  `};
`;

class TxDescEditContainer extends Component {
  render() {
    const {
      title, itemcode, prices, onTextChange, type,
    } = this.props;

    let desc = '';

    if (prices[itemcode]) {
      desc = prices[itemcode].desc || '';
    }

    const renderComponent = (
      <ItemWrapper>
        <ItemContainer>
          <ItemLabel>{title}</ItemLabel>
          <RightContainer>
            <Textarea
              name={itemcode}
              onChange={onTextChange}
              active={desc}
              value={desc}
              height="100px"
              placeholder="치료 과정이나 방법, 예상 치료비 등을 설명해주세요"
            />
          </RightContainer>
        </ItemContainer>
      </ItemWrapper>
    );

    if (type && type === 'half') {
      return (
        <HalfContainer>
          {renderComponent}
        </HalfContainer>
      );
    }

    return renderComponent;
  }
}

export default TxDescEditContainer;
