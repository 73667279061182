import React from 'react';
import { compose, graphql } from 'react-apollo';
import styled from 'styled-components';
import ProfileCommonModal, {
  ProfileContentContiner,
} from './ProfileCommonModal';
import withToast from '../../utils/toasthoc';
import { updatePasswordMutation } from '../../graphql';
import PasswordInput from '../form/PasswordInput';
import FormInput from '../form/FormInput';
import { ModalCancleBtn, ModalConfirmBtn, ModalBtnContainer, ModalMessage, ModalContentContainer } from '../basic/ModalComponents';

const InputBox = styled.div`
  position: relative;
  input {
    width: 100%;
    height: 48px;
    border-radius: 1px;
    background-color: #ffffff;
  }
  & ~ & {
    margin-top: 16px;
  }
`;

class PasswordUpdateModal extends React.Component {
  state = {
    origPass: '',
    newPass: '',
    newPassConfirm: '',
    fireSubmitValidation: false,
    errorMsg: '',
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      fireSubmitValidation: false,
      errorMsg: '',
    });
  };

  handleSubmit = async () => {
    const { newPass, newPassConfirm, origPass, errorMsg } = this.state;
    
    if (errorMsg) {
      return;
    }
    
    if (!origPass || !newPass || !newPassConfirm ) {
      this.setState({ fireSubmitValidation: true });
      return;
    }

    if (newPass !== newPassConfirm) {
      return;
    }

    try {
      const res = await this.props.updatePassword({
        variables: {
          origpass: origPass,
          newpass: newPass,
        }
      });

      const { data: updatePassword } = res;

      // success
      if (updatePassword && updatePassword.updatePassword && updatePassword.updatePassword.ok) {
        this.props.onSuccess();
        this.close();
        return;
      }

      // 비밀번호 오류
      this.props.toast(<div>현재 비밀번호를 확인해주세요</div>, 'warn');
      return;

      // error
    } catch (e) {
      console.error(e);
      
    }
  };

  close = () => {
    this.setState({
      origPass: '',
      newPass: '',
      newPassConfirm: '',
      fireSubmitValidation: false,
      errorMsg: '',
    });
    this.props.onClose();
  };

  render() {
    const { isOpen } = this.props;
    return (
      <ProfileCommonModal
        title="비밀번호 변경"
        isOpen={isOpen}
      >
        <ModalContentContainer>
          <ModalMessage>현재 비밀번호와 새 비밀번호를 입력해 주세요.</ModalMessage>
          <ProfileContentContiner>
            <InputBox>
              <FormInput
                name="origPass"
                type="password"
                onChange={this.onChange}
                value={this.state.origPass}
                placeholder="현재 비밀번호"
                fireSubmitValidation={this.state.fireSubmitValidation}
                errorMsg={this.state.errorMsg}
                border
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    document.getElementById('updatePassNewPass').focus();
                    this.handleSubmit();
                  }
                }}
              />
            </InputBox>
            <InputBox>
              <PasswordInput
                id="updatePassNewPass"
                name="newPass"
                type="password"
                onChange={this.onChange}
                value={this.state.newPass}
                placeholder="새 비밀번호"
                fireSubmitValidation={this.state.fireSubmitValidation}
                errorMsg={this.state.errorMsg}
                border
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    document.getElementById('updatePassNewPassConfirm').focus();
                    this.handleSubmit();
                  }
                }}
              />
            </InputBox>
            <InputBox>
              <FormInput
                type="password"
                placeholder="새 비밀번호 확인"
                id="updatePassNewPassConfirm"
                name="newPassConfirm"
                onChange={this.onChange}
                value={this.state.newPassConfirm}
                errorMsg={
                  this.state.newPass &&
                  this.state.newPassConfirm &&
                  this.state.newPass !== this.state.newPassConfirm
                    ? '비밀번호가 일치하지 않습니다. 다시 시도해 주세요.'
                    : ''
                }
                fireSubmitValidation={this.state.fireSubmitValidation}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.handleSubmit();
                  }
                }}
                border
              />
            </InputBox>
          </ProfileContentContiner>
        </ModalContentContainer>
        <ModalBtnContainer>
          <ModalCancleBtn onClick={this.close}>취소</ModalCancleBtn>
          <ModalConfirmBtn onClick={this.handleSubmit}>확인</ModalConfirmBtn>
        </ModalBtnContainer>
      </ProfileCommonModal>
    );
  }
}

export default graphql(updatePasswordMutation, {
    name: 'updatePassword',
  })
(withToast(PasswordUpdateModal));
