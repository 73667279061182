import React, { Component } from 'react';

import { PageTitle, PageContainer, PageHeader, PageTabBtnContainer, PageTabContent, PageTabBtn } from '../components/basic/HtmlComponents';
import CheckedResps from '../components/HosResps/CheckedResps';
import UncheckedResps from '../components/HosResps/UncheckedResps';
import DesktopBanner from '../components/event/DesktopBanner';
import EventBannerImg from '../images/event-banner.png';

class HosResps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 1,
    };
  }

  render() {
    const { selectedTab } = this.state;

    return (
      <>
        <DesktopBanner srcImg={EventBannerImg} url="https://docs.google.com/forms/d/1tjUE-AfWMqWHxAc2le1x4ICMmIaLQ3KufFynpTvcjns" />
        <PageContainer id="hosresps-container">
          <PageHeader>
            <PageTabBtnContainer>
              <PageTabBtn
                onClick={() => this.setState({ selectedTab: 1 })}
                active={selectedTab === 1}
              >
                확인된 답변
              </PageTabBtn>
              <PageTabBtn
                onClick={() => this.setState({ selectedTab: 2 })}
                active={selectedTab === 2}
              >
                미확인 답변
              </PageTabBtn>
            </PageTabBtnContainer>
          </PageHeader>
          <PageTabContent>
            <CheckedResps visible={selectedTab === 1} />
            <UncheckedResps visible={selectedTab === 2} />
          </PageTabContent>
        </PageContainer>
      </>
    );
  }
}

export default HosResps;
