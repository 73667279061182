import React, { Component } from 'react';

export const AuthContext = React.createContext({
  meh: null,
  updateMeh: () => {},
});

export const AuthConsumer = AuthContext.Consumer;

class AuthProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      meh: null,
    };
  }

  updateMeh = (meh) => {
    this.setState({ meh });
  };

  render() {
    return (
      <AuthContext.Provider
        value={{
          meh: this.state.meh,
          updateMeh: this.updateMeh,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export default AuthProvider;
