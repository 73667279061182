import React from 'react';
import { compose, graphql } from 'react-apollo';
import ProfileCommonModal, {
  InputBox,
  ProfileContentContiner,
} from './ProfileCommonModal';
import PhonenumInput from '../form/PhonenumInput';
import withToast from '../../utils/toasthoc';
import { isSafePhoneMutation, updateHuserInfoMutation } from '../../graphql';
import { ModalCancleBtn, ModalConfirmBtn, ModalBtnContainer, ModalMessage, ModalContentContainer } from '../basic/ModalComponents';

class PhonenumUpdateModal extends React.Component {
  state = {
    phonenum: '',
    fireSubmitValidation: false,
    errorMsg: '',
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      fireSubmitValidation: false,
      errorMsg: '',
    });
  };

  handleSubmit = async () => {
    const { phonenum, errorMsg } = this.state;

    if (errorMsg) {
      return;
    }

    if (!phonenum || !(phonenum.length === 10 || phonenum.length === 11)) {
      this.setState({ fireSubmitValidation: true });
      return;
    }

    try {
      const res = await this.props.updateHuserInfo({ variables: { phonenum } });

      const { data: updateHuserInfo } = res;

      // success
      if (updateHuserInfo) {
        this.props.onSuccess();
        this.close();
        return;
      }
 
      // error
    } catch (e) {}
  };

  checkPhonenum = async (e) => {
    const phonenum = e.target.value;
    const { myPhonenum } = this.props;

    // 내것 제외하고 확인.
    if (phonenum && myPhonenum !== phonenum) {
      const res = await this.props.isSafePhone({
        variables: {
          phonenum,
          forHuser: true,
        },
      });

      const {
        isSafePhone: { ok },
      } = res.data;

      if (!ok) {
        // error
        this.setState({ errorMsg: '이미 사용중인 휴대폰 번호입니다.' });
      }
    }
  };

  close = () => {
    this.setState({
      phonenum: '',
      fireSubmitValidation: false,
      errorMsg: '',
    });
    this.props.onClose();
  };

  render() {
    const { isOpen } = this.props;
    return (
      <ProfileCommonModal
        title="휴대폰 번호 변경"
        isOpen={isOpen}
      >
        <ModalContentContainer>
          <ModalMessage>변경을 원하는 휴대폰 번호를 입력해 주세요.</ModalMessage>
          <ProfileContentContiner>
            <InputBox>
              <PhonenumInput
                name="phonenum"
                onChange={this.onChange}
                value={this.state.phonenum}
                placeholder="휴대폰 번호 입력"
                fireSubmitValidation={this.state.fireSubmitValidation}
                onBlur={this.checkPhonenum}
                errorMsg={this.state.errorMsg}
                border
              />
            </InputBox>
          </ProfileContentContiner>
        </ModalContentContainer>
        <ModalBtnContainer>
          <ModalCancleBtn onClick={this.close}>취소</ModalCancleBtn>
          <ModalConfirmBtn onClick={this.handleSubmit}>확인</ModalConfirmBtn>
        </ModalBtnContainer>
      </ProfileCommonModal>
    );
  }
}

export default compose(
  graphql(isSafePhoneMutation, {
    name: 'isSafePhone',
  }),
  graphql(updateHuserInfoMutation, {
    name: 'updateHuserInfo',
  }),
)(withToast(PhonenumUpdateModal));
