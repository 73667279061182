import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import UserRequestComponent from '../../components/ReqList/UserRequestComponent';

import {
  userRequestQuery,
  myCounselSettingQuery,
  myPriceTableByIdQuery,
} from '../../graphql/index';
import {
  getTxPriceTableByReq,
  getOrthoPriceTableByReq,
} from '../../utils/getPriceTableByReq';

const removeDuplicatedOrtho = (orthoAnswers) => { 
  if (orthoAnswers && orthoAnswers.length > 0) {
    return orthoAnswers.filter((v, i, a) => a.findIndex((t) => t.itemcode === v.itemcode) === i);
  }
  return [];
}

class UserRequestContainer extends Component {
  createTxPriceData = (array) =>
    array.reduce((obj, item) => {
      obj[item.itemcode] = item.price;
      return obj;
    }, {});

  createOrthoPriceData = (array) =>
    array.reduce((obj, item) => {
      obj[item.itemcode] = {
        ...obj[item.itemcode],
        [item.part]: item.price,
      };
      return obj;
    }, {});

  render() {
    const {
      priceTable,
      myCounselSetting,
      userReq,
      reqId,
      closeModal,
    } = this.props;
    let treatmentAnswer = '';
    let symptomAnswer = '';
    let txPrices = [];
    let orthoPrices = [];
    let closing = '';
    const value = localStorage.getItem('discountRatio');
    let discountRatio = parseInt(value) ? parseInt(value) : 0;

    if (
      !userReq ||
      !userReq.getUserReq ||
      !priceTable ||
      !priceTable.getPriceTableByReqId
    ) {
      return null;
    }

    // 치료 설명 넣기
    if (
      priceTable &&
      priceTable.getPriceTableByReqId &&
      (priceTable.getPriceTableByReqId.txPrices ||
        priceTable.getPriceTableByReqId.orthoPrices)
    ) {
      priceTable.getPriceTableByReqId.txPrices.map((priceObj) => {
        treatmentAnswer += priceObj.description;
        treatmentAnswer += '\n';
      });

      // 교정의 경우 같은 교정아이템이어도 파트가 나뉘어져 있지만 설명은 공통된 내용을 써서 중복표기되는 문제를 해결
      let orthoAnswers = priceTable.getPriceTableByReqId.orthoPrices
      orthoAnswers = removeDuplicatedOrtho(orthoAnswers)
      orthoAnswers.map((priceObj) => {
        treatmentAnswer += priceObj.description;
        treatmentAnswer += '\n';
      });
    }

    // 맺음말이 있으면 맺음말 넣기.
    if (myCounselSetting && myCounselSetting.myCounselSetting) {
      closing = myCounselSetting.myCounselSetting.closing || '';
    }

    // 상담 답변이 있는경우 답변을 보여줌
    const { hosResps } = userReq.getUserReq;
    if (hosResps && hosResps.length > 0) {
      txPrices = hosResps[0].txPrices || [];
      orthoPrices = hosResps[0].orthoPrices || [];
      treatmentAnswer = hosResps[0].treatmentAnswer || '';
      symptomAnswer = hosResps[0].symptomAnswer || '';
      if (hosResps[0]?.discount?.discountRatio > 0) {
        discountRatio = hosResps[0].discount.discountRatio;
      }
    }

    const txPriceTable = getTxPriceTableByReq(
      userReq.getUserReq.txItems,
      txPrices,
    );
    const orthoPriceTable = getOrthoPriceTableByReq(
      userReq.getUserReq.orthoItems,
      orthoPrices,
    );

    return (
      <UserRequestComponent
        reqId={reqId}
        request={userReq.getUserReq}
        refetchUserReq={userReq.refetch}
        txPriceTable={txPriceTable}
        orthoPriceTable={orthoPriceTable}
        treatmentAnswer={treatmentAnswer}
        symptomAnswer={symptomAnswer}
        closing={closing}
        discountRatio={discountRatio}
        closeModal={closeModal}
      />
    );
  }
}

export default compose(
  graphql(userRequestQuery, {
    options: ({ reqId }) => ({
      variables: { reqId },
      fetchPolicy: 'network-only',
    }),
    name: 'userReq',
  }),
  graphql(myCounselSettingQuery, {
    options: {
      fetchPolicy: 'network-only',
    },
    name: 'myCounselSetting',
  }),
  graphql(myPriceTableByIdQuery, {
    options: ({ reqId }) => ({
      fetchPolicy: 'network-only',
      variables: { reqId },
    }),
    name: 'priceTable',
  }),
)(UserRequestContainer);
