// Customer front 에 있는 Footer 가져오기 (2018/12/5 kosick)
import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import icFacebook from '../images/ic-facebook.svg';
import icInstagram from '../images/ic-instagram.svg';
import icTwitter from '../images/ic-twitter.svg';

const Background = styled.div`
  color: white;
  padding: 18px 0;
  width: 100%;
  background-color: #017fa0;
  @media(max-width: 640px) {
    display: block;
    padding: 0;
    padding-bottom: 60px;
  }
`;

const Wrapper = styled.div`
  max-width: 1080px;
  padding: 0 20px;
  margin: 0 auto;
  @media(max-width: 900px) {
    display: block;
    padding: 0 15px;
  }
`;

const A = styled.a`
  display: inline-block;
  color: #fff;
  &:hover, &:active, &:focus{
    text-decoration: underline;
    color: #fff;
  }
  font-size: 14px;
  font-weight: normal;
  @media(max-width: 640px) {
    font-size: 12px;
    letter-spacing: -1px;
  }
  margin-left: 20px;
`;

const Span1 = styled.span`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  font-size: 14px;
  font-weight: normal;
  @media(max-width: 640px) {
    font-size: 12px;
    letter-spacing: -1px;
  }
  & ~ & {
    margin-left: 20px;
  }
`;

const P1 = styled.p`
  color: #eeeeee;
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 8px;
  @media(max-width: 640px) {
    font-size: 14px;
    text-align: center;
  }
`;

const Copyright = styled.p`
  font-size: 13px;
  opacity: 0.6;
  margin-top: 20px;
  @media(max-width: 640px) {
    margin-top: 18px;
    letter-spacing: -1px;
    text-align: center;
  }
`;

const SpanPR16 = styled.span`
  padding-right: 16px;
  @media(max-width: 640px) {
    font-size: 12px;
    letter-spacing: -1px;
  }
`;

const BrandP = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 12px;
  @media (max-width: 640px) {
    margin-top: 18px;
    text-align: center;
  }
`;

const MenuContainer = styled.div`
  padding-top: 15px;
`;

const LeftContainer = styled.div`
  float: left;
  @media (max-width: 640px) {
    text-align: center;
    float: inherit;
  }
`;

const RightContainer = styled.div`
  float: right;
  @media (max-width: 640px) {
    display: none;
  }
`;

const Clear = styled.div`
  clear: both;
`;

class Footer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Background id="footer">
        <Wrapper>
          <div>
            <MenuContainer>
              <LeftContainer>
                <Span1 onClick={() => this.props.history.push('/cs/notice')}>공지사항</Span1>
                <Span1 onClick={() => this.props.history.push('/terms')}>이용약관</Span1>
                <A href="mailto: partner@odoctor.co.kr?subject=문의하기&body=오닥터%20고객센터입니다.%0A문의할%20사항이나%20서비스%20사용중%20궁금하신점을%20작성해주세요%0A%0A성함%20혹은%20아이디%20:%0A%0A문의내용%20:" >고객센터</A>
              </LeftContainer>
              <RightContainer>
                <img src={icFacebook} alt="" />
                <img style={{ marginLeft: '37px' }} src={icTwitter} alt="" />
                <img style={{ marginLeft: '37px' }} src={icInstagram} alt="" />
              </RightContainer>
              <Clear />
              <BrandP>오닥터</BrandP>
              <P1>
                <SpanPR16>서울 강남구 언주로85길 29 5층</SpanPR16>
                <SpanPR16>사업자번호: 522-81-01167</SpanPR16>
              </P1>
              <P1>
                <SpanPR16>대표: 고석호</SpanPR16>
                <SpanPR16>전화 02-3443-1357 (평일 10:00 - 19:00)</SpanPR16>
                <SpanPR16>이메일 partner@odoctor.co.kr</SpanPR16>
              </P1>
              <Copyright>ⓒ 2018 Odoctor</Copyright>
            </MenuContainer>
          </div>
        </Wrapper>
      </Background>
    );
  }
}

export default withRouter(Footer);
