import React, { Component } from 'react';
import styled from 'styled-components';
import { media } from '../../globalStyle';

const DesktopViewsable = styled.div`
  display: block;
  ${media.desktop`
    display: none;
  `};
`;

const MobileViewsable = styled.div`
  display: none;
  ${media.desktop`
    display: block;
    width: 100%;
    padding: 110px 20px 0;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -1px;
    color: #000000;
  `};
`;

const Blue = styled.div`
  font-size: 20px;
  letter-spacing: -1.58px;
  color: #033e52;
  margin-bottom: 10px;
  line-height: 1.4;
`;

const En = styled.span`
  letter-spacing: 0;
`;

const MobileView = () => (
  <MobileViewsable>
    <Blue>
      <En>PC</En> 혹은 태블릿(가로 모드)에서
      <br />
      이용해주세요.
    </Blue>
    해당 페이지는 넓은 화면에 최적화되어 있습니다.
    <br />
    <br />
  </MobileViewsable>
);

class DesktopOnlyContent extends Component {
  render() {
    window.scrollTo(0, 0);
    return (
      <React.Fragment>
        <DesktopViewsable>
          {this.props.children}
        </DesktopViewsable>
        <MobileView />
      </React.Fragment>
    );
  }
}

export default DesktopOnlyContent;
