// 환자들 견적 상담 요청서들 모음 페이지
import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { PageContainer } from '../../components/basic/HtmlComponents';
import { media, isMobile } from '../../globalStyle';
import NoticeModal from '../NoticeModal';
import DesktopReqListComponent from '../../components/ReqList/DesktopReqListComponent';
import MobileReqListComponent from '../../components/ReqList/MobileReqListComponent';

const PageContent = styled.div`
  max-width: 1080px;
  position: relative;
  ${media.miniDesktop`
    padding: 0;
  `};
`;

class ReqListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reqId: null,
      reqType: 'all',
      replied: 'all',
      username: '',
      locationSi: '',
      locationGu: '',
      mobile: isMobile(),
    };
  }

  componentWillMount = () => {
    window.addEventListener('resize', this.handleWindowSizeChange);
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  };

  handleWindowSizeChange = () => {
    this.setState({ mobile: isMobile() });
  };

  onClickCard = async (reqId) => {
    this.setState({ reqId });
  };

  setFilterState = ({ reqType, replied, username, locationSi, locationGu }) => {
    this.setState({
      reqType,
      replied,
      username,
      locationSi,
      locationGu,
    });
  };

  render() {
    const {
      reqId,
      reqType,
      replied,
      username,
      locationSi,
      locationGu,
      mobile,
    } = this.state;

    return (
      <PageContainer>
        <PageContent>
          {!mobile ? (
            <DesktopReqListComponent
              reqId={reqId}
              reqType={reqType}
              replied={replied}
              username={username}
              locationSi={locationSi}
              locationGu={locationGu}
              onClickCard={this.onClickCard}
              setFilterState={this.setFilterState}
            />
          ) : (
            <MobileReqListComponent
              reqId={reqId}
              reqType={reqType}
              replied={replied}
              username={username}
              locationSi={locationSi}
              locationGu={locationGu}
              onClickCard={this.onClickCard}
              setFilterState={this.setFilterState}
            />
          )}
        </PageContent>
        <NoticeModal />
      </PageContainer>
    );
  }
}

export default withRouter(ReqListContainer);
