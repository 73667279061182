import React, { useState } from 'react';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import isSameDay from '../../utils/isSameDay';
import { locationGunguQuery } from '../../graphql';
import ArrowImg from '../../images/drop-down.svg';
import { media } from '../../globalStyle';
import { CheckBoxList, RadioInput, TextInput } from '../basic/HtmlComponents';
import CloseIcon from '../../images/ic-close-24.svg';
import StyledPagination from '../basic/StyledPagination';
import DisabledArrowImg from '../../images/drop-down-disabled.svg';
import MultiImg from '../../images/multi.svg';
import EqualImg from '../../images/equals.svg';
import { therapyInfoById } from '../UserRequest/therapyInfo';

export const RespCallStateOptions = [
  {
    id: 'allState',
    value: '',
    labelName: '전체',
  },
  {
    id: 'needcontact',
    value: 'needcontact',
    labelName: '연락필요',
  },
  {
    id: 'notanswer',
    value: 'notanswer',
    labelName: '부재중',
  },
  {
    id: 'etc',
    value: 'etc',
    labelName: '기타',
  },
  {
    id: 'reservedvisit',
    value: 'reservedvisit',
    labelName: '내원예정',
  },
  {
    id: 'cancelled',
    value: 'cancelled',
    labelName: '취소',
  },
  {
    id: 'duplicated',
    value: 'duplicated',
    labelName: '중복',
  },
  {
    id: 'invalidphone',
    value: 'invalidphone',
    labelName: '결번',
  },
];

export const datePresets = [
  {
    text: '오늘',
    start: moment(),
    end: moment(),
  },
  {
    text: '1주일',
    start: moment().subtract(1, 'week'),
    end: moment(),
  },
  {
    text: '1개월',
    start: moment().subtract(1, 'month'),
    end: moment(),
  },
  {
    text: '3개월',
    start: moment().subtract(3, 'months'),
    end: moment(),
  },
];

const locationSiNames = {
  11: '서울',
  41: '경기',
  28: '인천',
};

export const HosRespTabContent = styled.div`
  ${(props) => (props.visible ? 'display: block' : 'display: none')};
`;

export const FilterContainer = styled.div`
  width: 100%;
`;

export const RespsTableContainer = styled.div`
  margin-top: 120px;
  width: 1080px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
`;

export const HosRespTable = styled.table`
  width: 100%;
  padding: 16px 32px;
  /* display: table; */
  border-collapse: collapse;
`;

export const HosRespTableHeader = styled.tr`
  /* display: table-row; */
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #000000;
`;

export const HosRespTh = styled.td`
  /* display: table-cell; */
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.8px;
  text-align: center;
  color: #000a12;
  padding: 10px 0;
  text-align: center;
  width: ${(props) => `${props.width}px;`};
`;

export const HosRespTr = styled.tr`
  /* display: table-row; */
  width: 100%;
  border-bottom: 1px solid #d6d6d6;
  cursor: pointer;
  &:hover {
    background-color: #f9fafb;
  }
`;

export const HosRespTd = styled.td`
  padding: ${(props) => props.padding || '0'};
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.8px;
  text-align: center;
  color: #000000;
  width: ${(props) => `${props.width}px;`};
  /* display: table-cell; */
  vertical-align: middle;
  height: 74px;
  img {
    vertical-align: middle;
  }
  ${(props) => props.number && 'letter-spacing: normal'};
  white-space: ${(props) => (props.whiteSpace ? props.whiteSpace : 'normal')};
`;

export const FilterRow = styled.div`
  height: 74px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0;
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  & ~ & {
    border-top: 1px solid #d6d6d6;
  }
`;

export const SeparatorDash = styled.div`
  border-bottom: 1px dashed #d6d6d6;
`;

export const Separator = styled.div`
  border-bottom: 1px solid #000000;
`;

export const FilterLabel = styled.div`
  width: 98px;
  text-align: left;
  height: 20px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  letter-spacing: -0.4px;
  color: #000000;
`;

export const StyledDateRangePicker = styled.div`
  display: inline-block;
  position: relative;
  .DateRangePicker {
    .DateRangePickerInput__withBorder {
      border: none;
      .DateInput {
        width: 160px;
        height: 46px;
        margin-left: 10px;
        input {
          border-radius: 4px;
          border: solid 1px #e1e3e2;
          text-align: left;
          width: 100%;
          height: 100%;
          padding: 0 14px;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.54;
          letter-spacing: -0.4px;
          color: #000000;
        }
      }
      .DateRangePickerInput_arrow {
        display: none;
      }
    }
    .DateRangePickerInput_clearDates {
      right: -23px;
    }
  }
  .CalendarDay__default {
    line-height: 38px;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover,
  .CalendarDay__selected_span {
    background: #033e52;
    border-color: #033e52;
  }
`;

export const UserFilterInput = styled.input`
  width: 160px;
  height: 46px;
  margin-left: 10px;
  border: solid 1px #e1e3e2;
  border-radius: 4px;
  padding-left: 14px;
  font-size: 13px;
  text-align: left;
  color: #000000;
`;

export const SearchBtn = styled.button`
  border-radius: 4px;
  background-color: #033e52;
  min-width: 245px;
  height: 56px;
  padding: 0 2px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: -1px;
  text-align: center;
  color: #ffffff;
  margin: 0 auto;
  display: block;
`;

export const PresetDatePickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 30px;
`;

export const PresetDatePicker = styled.div`
  margin-left: 10px;
  width: 100px;
  height: 46px;
  line-height: 46px;
  border: solid 1px #e1e3e2;
  border-radius: 4px;
  font-size: 13px;
  vertical-align: middle;
  text-align: center;
  letter-spacing: -0.6px;
  color: #000000;
  cursor: pointer;
  &.active {
    border: solid 1px #033e52;
  }
`;

export const SelectBox = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  select {
    height: ${(props) => (props.height ? `${props.height}px` : '46px')};
    width: ${(props) => (props.width ? `${props.width}px` : '160px')};
    border: solid 1px #e1e3e2;
    border-radius: 4px;
    background-color: #ffffff;
    cursor: pointer;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: -0.8px;
    color: #000000;
    padding: 14px;
    background-image: url(${ArrowImg});
    background-position: right 14px bottom 50%;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: none;
    &:disabled {
      color: #bbbcbc;
      cursor: not-allowed;
    }
    &:hover,
    &:focus {
      outline: none;
    }
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #bbbcbc;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #bbbcbc;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #bbbcbc;
    }
    &:disabled {
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: transparent;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: transparent;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: transparent;
      }
      + img {
        display: none;
      }
      background-image: url(${DisabledArrowImg});
    }
  }
`;

const DateClearBtn = styled.img`
  cursor: pointer;
  position: absolute;
  right: -33px;
  top: 8px;
`;

export const RightRow = styled.div`
  width: 490px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
`;

export const RightRowItem = styled.div`
  text-align: left;
  width: 245px;
  font-size: 14px;
  letter-spacing: -0.4px;
  color: #000000;
  span {
    font-family: Saira Semi Condensed;
    font-stretch: semi-condensed;
    letter-spacing: normal;
  }
`;

export const RespDateRangePicker = ({ startDate, endDate, onDatesChange }) => {
  const [focusedInput, setFocusedInput] = useState(null);
  return (
    <StyledDateRangePicker>
      <DateRangePicker
        startDate={startDate} // momentPropTypes.momentObj or null,
        startDateId='your_unique_start_date_id' // PropTypes.string.isRequired,
        endDate={endDate} // momentPropTypes.momentObj or null,
        endDateId='your_unique_end_date_id' // PropTypes.string.isRequired,
        onDatesChange={({ startDate, endDate }) =>
          onDatesChange({ startDate, endDate })
        } // PropTypes.func.isRequired,
        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={setFocusedInput}
        isOutsideRange={() => false}
        startDatePlaceholderText='시작 날짜'
        endDatePlaceholderText='종료 날짜'
        displayFormat='YYYY.MM.DD'
      />
      {startDate && endDate && (
        <DateClearBtn
          src={CloseIcon}
          onClick={() => onDatesChange({ startDate: null, endDate: null })}
        />
      )}
    </StyledDateRangePicker>
  );
};

export const RespPresetDatePicker = ({ startDate, endDate, onDatesChange }) => (
  <PresetDatePickerContainer>
    {datePresets.map(({ text, start, end }) => {
      const isSelected = isSameDay(start, startDate) && isSameDay(end, endDate);
      return (
        <PresetDatePicker
          key={text}
          onClick={() =>
            onDatesChange({
              startDate: start,
              endDate: end,
            })
          }
          className={isSelected ? 'active' : ''}>
          {text}
        </PresetDatePicker>
      );
    })}
  </PresetDatePickerContainer>
);

export const AddressSelector = ({
  locationSi,
  locationGu,
  onLocationSiChange,
  onLocationGuChange,
}) => (
  <React.Fragment>
    <SelectBox>
      <select
        name='locationSi'
        placeholder='시/도 선택'
        onChange={onLocationSiChange}
        value={locationSi}
        required>
        <option key='' value=''>
          전체 지역
        </option>
        <option key={11} value='11'>
          서울
        </option>
        <option key={41} value='41'>
          경기
        </option>
        <option key={28} value='28'>
          인천
        </option>
      </select>
    </SelectBox>
    {locationSi && (
      <SelectBox>
        <select
          name='locationGu'
          placeholder='군/구 선택'
          value={locationGu}
          onChange={onLocationGuChange}
          required>
          <option key='' value=''>
            전체 지역
          </option>
          <Query
            query={locationGunguQuery}
            variables={{ siName: locationSiNames[locationSi] }}>
            {({ data, loading }) =>
              !loading &&
              data &&
              data.locationGungu &&
              data.locationGungu.map(({ id, code, name }) => (
                <option key={`${id}-${code}`} value={id}>
                  {name}
                </option>
              ))
            }
          </Query>
        </select>
      </SelectBox>
    )}
  </React.Fragment>
);

export const UserNameFilter = ({ username, onChange }) => (
  <UserFilterInput
    type='text'
    name='username'
    value={username}
    placeholder='이름 검색'
    onChange={onChange}
  />
);

export const UserPhoneFilter = ({ phonenum, onChange }) => (
  <UserFilterInput
    type='text'
    name='phonenum'
    value={phonenum}
    placeholder='전화번호 검색'
    onChange={onChange}
  />
);

export const RespCallStateFilter = ({ onChange }) => (
  <CheckBoxList>
    <RadioInput
      id='allState'
      name='callState'
      onChange={onChange}
      value=''
      defaultChecked
      labelName='전체'
    />
    <RadioInput
      id='needcontact'
      name='callState'
      onChange={onChange}
      value='needcontact'
      labelName='연락필요'
    />
    <RadioInput
      id='notanswer'
      name='callState'
      onChange={onChange}
      value='notanswer'
      labelName='부재중'
    />
    <RadioInput
      id='etc'
      name='callState'
      onChange={onChange}
      value='etc'
      labelName='기타'
    />
    <RadioInput
      id='reservedvisit'
      name='callState'
      onChange={onChange}
      value='reservedvisit'
      labelName='내원예정'
    />
    <RadioInput
      id='cancelled'
      name='callState'
      onChange={onChange}
      value='cancelled'
      labelName='취소'
    />
    <RadioInput
      id='duplicated'
      name='callState'
      onChange={onChange}
      value='duplicated'
      labelName='중복'
    />
    <RadioInput
      id='invalidphone'
      name='callState'
      onChange={onChange}
      value='invalidphone'
      labelName='결번'
    />
  </CheckBoxList>
);

const PaginationWrapper = styled.div`
  padding: 32px 0;
`;

export const HosRespsPagination = ({ pagination, onPageChange }) => (
  <PaginationWrapper>
    <StyledPagination
      activePage={pagination.currentPage}
      itemsCountPerPage={pagination.perPage}
      totalItemsCount={pagination.total}
      pageRangeDisplayed={1}
      onChange={onPageChange}
    />
  </PaginationWrapper>
);

const EmptyDataMsg = styled.div`
  width: 100%;
  height: 20px;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: -0.4px;
  text-align: center;
  color: #b1b3b2;
`;

export const EmptyDataInfoMsg = ({ children }) => {
  return <EmptyDataMsg>{children}</EmptyDataMsg>;
};

export const CardItemContainer = styled.div`
  padding: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 333px;
  border-radius: 4px;
  border: solid 1px #e1e3e2;
  &:hover {
    cursor: pointer;
  }
`;

export const CardItemContainerAccent = styled.div`
  padding: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 333px;
  border-radius: 4px;
  border: solid 1px #e1e3e2;
  border-top-width: 4px;
  &:hover {
    cursor: pointer;
  }
  border-top-color: ${(props) => (props.accent ? '#033e52' : '#bbbcbc')};
`;

export const UserName = styled.div`
  height: 34px;
  font-size: 26px;
  font-weight: bold;
  line-height: 1.31;
  letter-spacing: -0.6px;
  color: #000000;
`;

export const UserLocation = styled.div`
  height: 20px;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: -0.4px;
  color: #000000;
`;

export const PriceConatiner = styled.div`
  border-top: solid 1px #e1e3e2;
  height: 74px;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
`;

export const PriceLabel = styled.div`
  width: 108px;
`;

export const PriceUnit = styled.div`
  width: 24px;
  margin-left: 6px;
  height: 17px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  color: #000000;
`;

export const PriceMultiple = styled.div`
  margin-left: 7px;
  height: 10px;
  width: 10px;
  background-image: url(${MultiImg});
  object-fit: contain;
`;

export const PriceCount = styled.div`
  min-width: 43px;
  margin-left: 14px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  text-align: center;
  color: #000000;
  letter-spacing: -0.4px;
  span {
    font-family: Saira Semi Condensed;
    font-stretch: semi-condensed;
    letter-spacing: normal;
  }
`;

export const PriceEqual = styled.div`
  margin-left: 9px;
  background-image: url(${EqualImg});
  width: 10px;
  height: 10px;
  object-fit: contain;
`;

export const PriceSum = styled.div`
  margin-left: 6px;
  width: 58px;
  font-size: 14px;
  text-align: right;
  color: #000000;
  font-family: Saira Semi Condensed;
`;

export const PricePart = styled.div`
  margin-left: 7px;
  width: 82px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  text-align: center;
  color: #000000;
`;

export const ExpectedPrice = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 17px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  color: #000000;

  div:last-of-type {
    font-size: 14px;
    letter-spacing: -0.4px;
    color: #000000;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: -0.4px;
    big {
      height: 22px;
      font-family: Saira Semi Condensed;
      font-size: 14px;
      font-weight: 600;
      font-stretch: semi-condensed;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: #000000;
    }
  }
`;

export const PriceInput = styled(TextInput)`
  border-radius: 4px;
  width: 120px;
  height: 46px;
  border: solid 1px #e1e3e2;
  font-family: Saira Semi Condensed;
  font-size: 13px;
  font-stretch: semi-condensed;
  line-height: 46px;
  text-align: right;
  color: #000000;
  &:hover,
  &:focus {
    outline: none;
  }
  &:disabled {
    color: #b1b3b2;
  }
`;

export const Separater = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.color || '#000000'};
`;

export const RightTitle = styled.div`
  height: 24px;
  line-height: 24px;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.5px;
  color: #000000;
  text-align: left;
`;

export const LeftTitle = styled.div`
  height: 34px;
  display: flex;
  flex-direction: row;
`;

export const LeftTitleText = styled.div`
  height: 34px;
  font-size: 36px;
  line-height: 0.94;
  letter-spacing: -0.83px;
  color: #000000;
`;

export const LeftRow = styled.div`
  width: 510px;
  display: flex;
  flex-direction: row;
`;

export const LeftLabel = styled.div`
  line-height: 20px;
  width: 88px;
  font-size: 14px;
  letter-spacing: -0.4px;
  color: #000000;
  vertical-align: center;
  text-align: left;
`;

export const PeriodPresetShort = ['1주', '2주', '3주', '한달', '2~3개월'];
export const PeriodPresetLong = ['4~5개월', '6개월~1년', '1년 이상'];

export const TreatPreriodContainer = styled.div`
  width: 422px;
  display: flex;
  flex-direction: row;
`;

export const TreatPeriodButton = styled.div`
  width: 76px;
  height: 46px;
  border-radius: 4px;
  border: solid 1px #e1e3e2;
  text-align: center;
  line-height: 46px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.6px;
  color: #000000;
`;

export const RadioWrapper = styled.div`
  height: 17px;
  margin-right: 24px;
`;

export const DiscountRatioInput = styled(TextInput)`
  border-radius: 4px;
  width: 100px;
  height: 46px;
  border: solid 1px #e1e3e2;
  font-family: Saira Semi Condensed;
  font-size: 13px;
  font-stretch: semi-condensed;
  line-height: 46px;
  letter-spacing: normal;
  text-align: right;
  color: #000000;
  &:hover,
  &:focus {
    outline: none;
  }
  &:disabled {
    color: #b1b3b2;
  }
`;

export const UnitPercent = styled.div`
  margin-left: 6px;
  font-family: Saira Semi Condensed;
  font-size: 14px;
  font-stretch: semi-condensed;
  color: #000000;
`;

export const QuestionMark = styled.img`
  margin-left: 5px;
  width: 16px;
  height: 16px;
  color: #8a8a8a;
  line-height: 16px;
  vertical-align: middle;
  cursor: pointer;
`;

export const InfoWrapper = styled.div`
  margin-top: 11px;
  padding: 20px 14px;
  position: relative;
  z-index: 400;
  width: 190px;
  height: 76px;
  line-height: 18px;
  border-radius: 4px;
  background-color: #1a1a1a;
  font-size: 12px;
  letter-spacing: -0.44px;
  color: #ffffff;
`;

export const SymptomRespInfo = () => {
  return (
    <InfoWrapper>{`증상에 따라 예상되는 문제점과 어떤\n치료가 필요한지 안내해주세요`}</InfoWrapper>
  );
};

export const TreatRespInfo = () => {
  return (
    <InfoWrapper>
      {`치료에 대한 전반적인 설명과 예상\n치료비용에 대해 안내해주세요`}
    </InfoWrapper>
  );
};

export const SymptomAnswerTextarea = styled.textarea`
  width: 422px;
  height: 230px;
  border-radius: 4px;
  border: solid 1px #e1e3e2;
  padding: 14px;
  font-size: 13px;
  line-height: 1.54;
  letter-spacing: -0.6px;
  text-align: justify;
  color: #000000;
  resize: none;
  /* white-space: pre; */
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b1b3b2;
    opacity: 1; /* Firefox */
  }
`;

export const TreatmentTextNoti = styled.div`
  height: 18px;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: -0.34px;
  color: #b1b3b2;
  text-align: left;
`;

export const MaxDiscountRatio = 45;

export const Greeting = styled.div`
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  letter-spacing: -0.4px;
  color: #000000;
`;

export const EndGreeting = styled.div`
  width: 326px;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.4px;
  color: #000000;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
`;

export const CheckEndGreeting = styled.p`
  margin-left: 10px;
  width: 86px;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.4px;
  color: #033e52;
  text-decoration-color: #033e52;
  text-decoration: underline;
  :hover {
    cursor: pointer;
  }
`;

export const blockEscape = (onUnloadListener) => {
  window.addEventListener('beforeunload', onUnloadListener);
  window.addEventListener('unload', onUnloadListener);
};

export const unBlockEscape = (onUnloadListener) => {
  window.removeEventListener('beforeunload', onUnloadListener);
  window.removeEventListener('unload', onUnloadListener);
};

export const getTherapyString = (orthoArray, txArray) => {
  let therapy = '';

  if (orthoArray && orthoArray.length > 0) {
    therapy += therapyInfoById[orthoArray[0].itemcode].viewName
  }
  if (txArray.length > 0) {
    if (therapy) {
      therapy += ', '
    }
    txArray.forEach((tx, index) => {
      therapy +=
        therapyInfoById[tx.itemcode].viewName +
        (txArray.length - 1 !== index ? ', ' : '');
    });
  }
  return therapy;
};

export const getOrthoWannaString = (orthoWanna) => {
  let value = '';
  const length = orthoWanna.length;
  if (length > 0) {
    orthoWanna.forEach((e, index) => {
      value += e + (length - 1 !== index ? ', ' : '');
    });
  }
  return value;
};

export const TherapyItemTitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.4px;
  color: #000000;
  font-weight: bold;
`;

export const TherapyItem = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.4px;
  color: #000000;
`;

export const Therapy = styled.div`
  margin-top: 19px;
  height: 40px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #000000;
`;

export const calcPriceInputType = (
  discountRatio,
  txMaxPrice,
  orthoMaxPrice,
) => {
  const totalPrice = txMaxPrice + orthoMaxPrice;
  if (discountRatio) {
    // 할인율 존재, 가격은 입력안한 상태
    // 할인율 존재, 가격 입력한 상태
    return 'discount';
  } else {
    if (totalPrice > 0) {
      return 'normal';
    } else {
      return 'noinput';
    }
  }
};
