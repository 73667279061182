import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import AsyncCreatableSelect from 'react-select/async';
import AsyncSelect from 'react-select/async'
import ClearIcon from '../../images/ic-close-typing-24.svg';

const SelectWapper = styled.div`
  .Select--multi  {
    .Select-value {
      display: inline-flex;
      align-items: center;
    }
  }

  .Select-placeholder {
    color: #bbbcbc;
  }
`;

const ControlStyles = (base, state) => ({
  ...base,
  minHeight: '48px',
  borderRadius: '1px',
  backgroundColor: '#ffffff',
  display: 'flex',
  padding: '9px 0',
  fontSize: '16px',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: '1.13',
  letterSpacing: '-1px',
  color: '#000000',
  boxShadow: 'none',
  borderColor: state.isFocused ? '#033e52' : '#e0e0e0',
  '&:hover': {
    borderColor: state.isFocused ? '' : '#e0e0e0',
  },
});

const MenuStyles = (base, state) => ({
  ...base,
  padding: '6px 0 0',
  borderRadius: '1px',
  boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.2)',
  backgroundColor: 'rgb(255, 255, 255)',
  border: 'solid 1px rgb(224, 224, 224)',
  margin: '0',
});

const MenuListStyles = (base, state) => ({
  ...base,
  padding: '0',
  maxHeight: '100%',
});

const InputStyles = (base, state) => ({
  ...base,
  color: '#000000',
});

const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: 'pointer',
  display: state.isFocused ? 'inline-block' : 'none',
  padding: '0',
});

const PlaceholderStyles = (base, state) => ({
  ...base,
  color: '#bbbcbc',
});

const styles = {
  clearIndicator: ClearIndicatorStyles,
  placeholder: PlaceholderStyles,
  input: InputStyles,
  singleValue: InputStyles,
  menu: MenuStyles,
  menuList: MenuListStyles,
  control: ControlStyles,
};


const ClearIndicator = (props) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles('clearIndicator', props)}
    >
      <div style={{ padding: '0px 5px' }}>
        <img src={ClearIcon} alt="칭찬보내기" />
      </div>
    </div>
  );
};

export const CustomAsyncCreatableSelect = props => (
  <AsyncCreatableSelect
    styles={styles}
    {...props}
    components={{
      DropdownIndicator: null,
      ClearIndicator,
      ...props.components,
    }}
  />
);

export const CustomAsyncSelect = props => (
  <AsyncSelect
    styles={styles}
    {...props}
    components={{
      DropdownIndicator: null,
      ClearIndicator,
      ...props.components,
    }}
  />
);

const CustomSelect = props => (
  <SelectWapper>
    <Select
      styles={styles}
      {...props}
      components={{
        DropdownIndicator: null,
        ClearIndicator,
        ...props.components,
      }}
    />
  </SelectWapper>
);

export default CustomSelect;
