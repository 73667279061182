import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import CustomerFooter from '../components/CustomerFooter';

import introImg from '../images/signup-new-page.jpg';
import Logo from '../images/img-logo-gnb.svg';
import BlueLogo from '../images/img-logo-gnb-blue.svg';
import icon1 from '../images/signup-icon-01.svg';
import icon2 from '../images/signup-icon-02.svg';
import icon3 from '../images/signup-icon-03.svg';
import { media } from '../globalStyle';
import ComplimentInvitation from '../components/compliment/ComplimentInvitation';
import SignupComponent from '../components/SignupComponent';

const queryString = require('query-string');

const Container = styled.div`
`;


const MobileServiceBanner = styled.div`
  display: none;
  padding: 15px 20px;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1.3px;
  color: #38c8e5;
  cursor: pointer;
  border-bottom: 1px solid #e1f6fb;
  span {
    vertical-align: middle;
    float: right;
  }
  ${media.tablet`
    display: block;
  `};
`;

const Intro1Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 940px;
  color: #fff;
  background-size: cover;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-image: url(${introImg});
  ${media.tablet`
    background-image: none;
    height: auto;
    color: #017fa0;
  `};
`;

const Intro1Inner = styled.div`
  width: 100%;
  padding: 0 9%; 
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  ${media.desktop`
    padding: 0 0 0 30px;
  `};
  ${media.tablet`
    flex-direction: column;
    padding: 0;
    height: auto;
    color: #017fa0;
  `};
`;

const Intro1Container = styled.div`
  padding-top: 500px;
  display: inline-block;
  ${media.tablet`
    position: static;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 60px;
    padding-bottom: 30px;
  `};
`;

const FormContainer = styled.div`
  float: right;
  vertical-align: middle;
  padding-left: 30px;
  padding-right: 30px;
  width: 420px;
  height: 940px;
  background-color: #38c8e5;
  ${media.tablet`
    padding-left: 20px;
    padding-right: 20px;
    position: static;
    width: auto;
    height: auto;
  `};
`;

const MainIntroHead = styled.h1`
  margin-bottom: 30px;
  font-size: 48px;
  font-weight: 300;
  line-height: 1.13;
  letter-spacing: -3.1px;
  max-width: 516px;
  word-break: keep-all;
  ${media.tablet`
    position: static;
    width: auto;
    font-size: 26px;
    font-weight: 500;
    line-height: 1.23;
    letter-spacing: -1.7px;
  `};
`;

const MainIntroP = styled.p`
  max-width: 432px;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -1.2px;
  word-break: keep-all;
  ${media.tablet`
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.9px;
  `};
`;

const IntroHead1 = styled.h1`
  font-size: 36px;
  line-height: 1.33;
  letter-spacing: -1.4px;
  color: #017fa0;
  ${media.tablet`
    font-size: 24px;
    max-width: 205px;
    margin: 0 auto;
  `};
`;

const IntroP1 = styled.p`
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -1.2px;
  color: #017fa0;
  max-width: 400px;
  margin: 0 auto;
  word-break: keep-all;
  ${media.tablet`
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.8px;
    text-align: center;
    max-width: 280px;
  `};
`;

const Intro2Wrapper = styled.div`
  padding: 120px 20px;
  text-align: center;
  ${media.tablet`
    padding: 60px 20px;
  `};
`;

const Intro3Wrapper = styled.div`
  padding: 120px 20px;
  text-align: center;
  background-color: #e1f6fb;
  ${media.tablet`
    height: auto;
    padding: 60px 20px;
  `};
`;

const IconsWrapper = styled.div`
  max-width: 680px;
  margin: 60px auto 0;
`;

const Icon = styled.div`
  display: inline-block;
  width: 33.333%;
  ${media.tablet`
    display: block;
    width: 100%;
    margin-bottom: 20px;
  `};
`;
const P1 = styled.p`
  font-size: 16px;
  line-height: 1.63;
  letter-spacing: -0.9px;
  color: #017fa0;
  ${props => props.bold && 'font-weight: bold'};
  strong {
    font-weight: bold;
  }
  ${media.tablet`
    max-width: 280px;
    margin: 0 auto;
    font-size: 14px;
  `};
`;

const Nav = styled.div`
  background-color: #ffffff;
  padding: 13px 30px;
  ${media.tablet`
    background-color: #38c8e5;
    padding: 15px 0;
    text-align: center;
  `};
`;

const NavInner = styled.div`
  width: 100%;
  padding: 0 9%; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  ${media.desktop`
    padding: 0 0 0 30px;
  `};
  ${media.tablet`
    flex-direction: column;
    padding: 0;
    height: auto;
    color: #017fa0;
  `};
`;

const DesktopServiceMenu = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1.5px;
  color: #017fa0;
  cursor: pointer;
  ${media.tablet`
    display: none;
  `};
`;

const LogoImage = styled.img`
  width: 120px;
  height: 24px;
  object-fit: contain;
  vertical-align: middle;
  ${media.tablet`
    content: url(${Logo});
  `};
`;

const AppName = styled.div`
  margin-left: 8px;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1.4px;
  color: #ffffff;
  display: inline-block;
  vertical-align: middle;
`;

class SignupNew extends Component {
  render() {
    const searchParams = queryString.parse(this.props.location.search);
    const { compId, referral } = searchParams;

    return (
      <div>
        <Nav>
          <NavInner>
            <div>
              <LogoImage src={BlueLogo} />
              <AppName>파트너 병원</AppName>
            </div>
            <DesktopServiceMenu onClick={() => this.props.history.push('/signup')}>
              오닥터 파트너 서비스 소개 >
            </DesktopServiceMenu>
          </NavInner>  
        </Nav>
        <MobileServiceBanner onClick={() => this.props.history.push('/signup')}>
          <div>
            오닥터 파트너 서비스 소개
            <span>></span>
          </div>
        </MobileServiceBanner>
        {compId && <ComplimentInvitation compId={compId} />}

        <Container>
          <Intro1Wrapper>
            <Intro1Inner>
              <Intro1Container>
                <MainIntroHead>너무나 뛰어난 동료 의료인을 칭찬해주세요</MainIntroHead>
                <MainIntroP>
                  항상 진심을 다해 진료하며 더 나은 진료실을 만들기 위해 분주한
                  동료 의사분들, 동료 직원분들을 칭찬해주세요!
                  <br />
                  칭찬과함께 적립된 금액은 병원 홍보 비용 혹은 동료 의료인 분의
                  컨텐츠 제작비용으로 사용이 가능합니다.
                </MainIntroP>
              </Intro1Container>
              <FormContainer>
                <SignupComponent referral={referral} />
              </FormContainer>
            </Intro1Inner>
          </Intro1Wrapper>
          <Intro2Wrapper>
            <IntroHead1>더 나은, 의료인에 대한 인식 개선을 위한</IntroHead1>
            <IntroHead1>좀 다른, 칭찬 프로젝트</IntroHead1>
            <IntroP1 style={{ paddingTop: '20px' }}>
              의료인들은 좀더 건강하고 , 더 안전하고 편안한 사회를 만들기위해
              매순간 전심을 다하는 분들입니다.
              <br />
              그 사소한 진심이라도 많은 사람들이 공유하고 이해할 수 있는
              컨텐츠를 만들어 나가기 위한 오닥터의 캠페인입니다.
            </IntroP1>
          </Intro2Wrapper>
          <Intro3Wrapper>
            <IntroHead1>당신은 좋은 아빠, 좋은 엄마, 좋은 친구</IntroHead1>
            <IntroHead1>그리고 우리의 든든한 의료인 입니다.</IntroHead1>
            <IntroP1 style={{ paddingTop: '30px' }}>
              오닥터의 창찬프로젝트로 만들어진 의료인분들의 긍정적인 피드백들이
              사회 보이지 않는 곳에서 큰 역할을 해주고 계신 의료인분들의 힘이되길 바랍니다.
              <br />
              오닥터는 좀더 나은 의료환경에 보탬이 컨텐츠를 만들겠습니다.
            </IntroP1>
            <P1 style={{ paddingTop: '8px' }}>
              <strong>
                칭찬을 보내거나 , 받을때 적립되는 충전금은 다음와 같이 사용하실 수 있습니다.
              </strong>
            </P1>
            <IconsWrapper>
              <Icon padRight>
                <img src={icon1} alt="" />
                <P1 bold style={{ marginTop: '16px' }}>
                  병원 홍보비용
                </P1>
              </Icon>
              <Icon padRight>
                <img src={icon2} alt="" />
                <P1 bold style={{ marginTop: '16px' }}>
                  병원 컨텐츠 제작{' '}
                </P1>
              </Icon>
              <Icon>
                <img src={icon3} alt="" />
                <P1 bold style={{ marginTop: '16px' }}>
                  의료인 컨텐츠 제작
                </P1>
              </Icon>
            </IconsWrapper>
          </Intro3Wrapper>
          <CustomerFooter />
        </Container>
      </div>
    );
  }
}

export default withRouter(SignupNew);
