export const getAccountHistoryMethod = (method) => {
  if (!method) {
    return '';
  }

  // 충전
  if(method === 'charge_account') {
    return '계좌이체';
  }

  if(method === 'charge_compliment') {
    return '칭찬 적립';
  }

  if(method === 'charge_free') {
    return '서비스 적립';
  }

  if(method === 'refund_invalidphone') {
    return '환불: 결번';
  }

  if(method === 'refund_duplicated') {
    return '환불: 중복';
  }

  if(method === 'refund_etc') {
    return '기타';
  }

  // 소진
  if(method === 'withdraw_counsel') {
    return '상담답변 과금';
  }

  // 소진
  if(method === 'withdraw_counsel_by_package_plan') {
    return '상담답변 과금';
  }

  if(method === 'withdraw_etc') {
    return '기타';
  }

  return '';
};
