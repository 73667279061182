import React, { Component } from 'react';
import styled from 'styled-components';
import { PageTitle } from '../components/basic/HtmlComponents';
import { media } from '../globalStyle';

const PageContent = styled.div`
  padding: 35px 17px;
  background-color: #ffffff;
  max-width: 1080px;
  width: 100%;
  ${media.miniDesktop`
    padding: 0;
  `};
`;

const ContentContainer = styled.div`
  width: 100%;
  border-radius: 1px;
  background-color: #ffffff;
  border: solid 1px #e0e0e0;
  padding: 30px 40px;
  word-break: break-all;
  white-space: pre-line;
  opacity: 0.8;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: -1px;
  color: #000000;
`;

class Terms extends Component {
  render() {
    return (
      <div>
        <div>
          <PageTitle>이용약관</PageTitle>
        </div>
        <div>
          <PageContent>
            <ContentContainer>
              {`
              파트너병원 및 닥터 이용약관

목적
이 약관은 오닥터(이하 ‘회사’)가 운영하는 치과상담플랫폼을 이용하는 MOU 체결 병의원(이하 ‘파트너병원’) 및 ‘닥터’의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.

정의
‘파트너병원’이라 함은 회사가 운영하는 치과상담플랫폼에 올려진 이용고객의 개인정보를 제공받아 이용고객에게 치과 상담 내용을 올리는 서비스를 제공하는 주체를 말합니다. 
‘닥터’라 함은 회사가 운영하는 치과상담플랫폼에 올려진 이용고객의 개인정보를 제공받아 회원에게 치과상담 내용을 올리는 서비스를 제공하는 의사면허를 가진 개인으로서의 주체를 말합니다.
‘서비스’라 함은, 구현되는 단말기(PC, 태블릿, 휴대용 단말기 등의 각종 유무선 장치)와 상관없이 오닥터 브랜드명을 가지고 오닥터가 제공하는 제반 서비스를 말합니다. 
‘회원’이라 함은, 회사의 서비스에 접속하여 본 약관에 동의하고, 회원 등록을 완료한 파트너병원 및 닥터를 말합니다.
‘이용고객’이라 함은, 회사의 ‘서비스’에 접속하여 ‘사용자 이용약관’에 따라 회사, 파트너병원 및 닥터가 제공하는 서비스를 받는 자를 말합니다. 
‘게시물’이라 함은, 회원이 서비스를 이용함에 있어 서비스 상에 게시한 부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.
‘처리’라 함은 개인정보의 수집, 생성, 연계, 연동, 기록, 저장, 보유, 가공, 편집, 검색, 출력, 정정, 복구, 이용, 제공, 공개, 파기 그 밖에 이와 유사한 행위를 말합니다.

약관의 명시와 개정
회사는 약관의 내용과 상호, 영업소 소재지, 대표자의 성명, 사업자등록번호, 연락처 등을 파트너병원이 쉽게 알 수 있도록 회사의 홈페이지에 게재합니다.	
회사는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 전자거래 기본법, 전자서명법, 정보통신망 이용촉진 등에 관한 법률, 소비자기본법 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 회사의 관련화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 회사는 개정전과 개정 후 내용을 명확하게 비교하여 회원이 알기 쉽도록 표시합니다.
회사가 전항에 따라 회원에게 통지하면서 공지 또는 공지∙고지일로부터 개정약관 시행일 7일 후까지 거부의사를 표시하지 않으면 승인한 것으로 본다는 뜻을 명확하게 고지하였음에도, 회원이 명시적으로 거부의 의사 표시를 하지 않은 경우에는 변경된 약관을 승인한 것으로 봅니다.
회사가 약관을 개정할 경우에 개정약관은 그 이전에 이미 체결된 계약에 대해소급하여 적용됩니다.
회원이 이 개정약관에 동의하지 않을 경우 제11조 규정에 따라 이용계약을 해지할 수 있습니다.
본 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 정부가 제정한 전자거래소비자보호지침 및 관계법령 또는 상관례에 따릅니다.

서비스의 제공 및 변경
회사와 파트너병원 및 닥터는 다음과 같은 업무를 수행합니다.
회원이 올리는 개인정보 및 신체일부를 포함한 민감정보 수집 및 처리
수집한 정보를 바탕으로 하는 치과적인 온라인 상담 및 상담 사례정보 제공
기타 회사와 파트너병원이 정하는 업무
서비스 이용은 회사의 서비스 사용 승낙 직후부터 가능합니다.
서비스 이용시간은 회사의 업무상 또는 기술상 불가능한 경우를 제외하고는 연중무휴 1일 24시간(00:00-24:00)으로 함을 원칙으로 합니다. 다만, 서비스 설비의 정기점검 등의 사유로 회사가 서비스를 특정범위로 분할하여 별도로 날짜와 시간을 정할 수 있습니다.

서비스 이용의 제한 및 중단
회사는 컴퓨터 등 정보통신설비의 보수점검•교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
회사는 다음 사유가 발생한 경우에는 회원의 서비스 이용을 제한하거나 중지시킬 수 있습니다. 
회원이 회사 서비스의 운영을 고의 또는 중과실로 방해하는 경우 
회원이 제6조의 의무를 위반한 경우 
서비스용 설비 점검, 보수 또는 공사로 인하여 부득이한 경우 
전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우 
국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 서비스 이용에 지장이 있는 때 
기타 중대한 사유로 인하여 회사가 서비스 제공을 지속하는 것이 부적당하다고 인정하는 경우

회원의 의무
회원은 다음 행위를 하여서는 안 됩니다.
회원정보에 허위 내용을 등록하는 행위
회사의 서비스에 게시된 정보를 변경하거나 서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 영리 또는 비영리의 목적으로 복제, 출판, 방송 등에 사용하거나 제3자에게 제공하는 행위
회사 기타 제3자의 명예를 훼손하거나 지적재산권을 침해하는 등 회사나 제3자의 권리를 침해하는 행위 
다른 회원의 ID 및 비밀번호를 도용하여 부당하게 서비스를 이용한 경우 
정크메일(junk mail), 스팸메일(spam mail), 행운의 편지(chain letters), 피라미드 조직에 가입할 것을 권유하는 메일, 외설 또는 폭력적인 메시지 ·화상·음성 등이 담긴 메일을 보내거나 기타 공서양속에 반하는 정보를 공개 또는 게시하는 행위
정보통신망이용촉진및정보보호등에관한법률 등 관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터 프로그램 등)를 전송하거나 게시하는 행위 
공공질서 또는 미풍양속에 위배되는 내용의 정보, 문장, 도형, 음성 등을 유포하는 행위
회사의 직원이나 서비스의 관리자를 가장하거나 사칭하여 또는 타인의 명의를 모용하여 글을 게시하거나 메시지를 발송하는 행위
컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시하거나 전자우편, SMS로 발송하는 행위
스토킹(stalking) 등 다른 회원의 서비스 이용을 방해하는 행위
다른 회원의 개인정보를 그 동의 없이 수집, 저장, 공개하는 행위
불특정 다수의 회원을 대상으로 하여 광고 또는 선전을 게시하거나 스팸메일을 전송하는 등의 방법으로 회사에서 제공하는 서비스를 이용하여 영리목적의 활동을 하는 행위
현행 법령, 회사가 제공하는 서비스에 정한 약관 기타 서비스 이용에 관한 규정을 위반하는 행위
회사는 회원이 제1항의 행위를 하는 경우 서비스의 이용을 제한하거나 일방적으로 본 계약을 해지할 수 있습니다.

회사의 의무
회사는 회사의 서비스 제공 및 보안과 관련된 설비를 지속적이고 안정적인 서비스 제공에 적합하도록 유지, 점검 또는 복구 등의 조치를 성실히 이행하여야 합니다.
회사는 서비스의 제공과 관련하여 알게 된 회원의 개인정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않고, 이를 보호하기 위하여 노력합니다. 회원의 개인정보보호에 관한 기타의 사항은 정보통신망 이용촉진 및 정보 보호 등에 관한 법률(이하 ‘정보통신망법’이라 합니다) 등 관계법령 및 회사가 별도로 정한 ‘개인정보처리방침’에 따릅니다.

회원에 대한 통지
회사는 회원에 대한 통지를 하는 경우, 회원이 미리 약정하여 지정한 전자우편 주소로 할 수 있습니다.
회사는 불특정다수 회원에 대한 통지의 경우 1주일 이상 회사 홈페이지에 게시함으로써 개별 통지에 갈음할 수 있습니다.

개인정보보호
회사는 파트너병원 및 닥터의 개인정보를 보호하기 위하여 정보통신망법 및 개인정보보호법 등 관계 법령에서 정하는 바를 준수합니다. 
회사는 회원의 개인정보를 보호하기 위해 ‘개인정보처리방침’을 수립하고 개인정보 보호 책임자를 지정하여 이를 게시하고 운영합니다.
회사는 회원의 정보수집 시 서비스 제공과 중개를 위해 최소한의 정보를 수집하고 처리합니다.
회사는 닥터의 이름, 근무병원, 닥터가 작성한 컨텐츠 등 정보를 오닥터 내에서 활용할 수 있습니다.

회원의 ID 및 비밀번호에 대한 의무
ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.
회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.
회원이 자신의 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 회사에 통보하고 회사의 안내에 따라야 합니다.

이용계약의 해지
회원은 언제든지 회사가 정하는 절차에 따라 이용계약 해지 신청을 할 수 있으며, 회사는 관련법 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다.
회원이 계약을 해지할 경우, 관련법 및 회사가 별도로 정하는 ‘개인정보처리방침’에 따라 회사가 회원의 개인정보 등을 보유하는 경우를 제외하고는 해지 즉시 회원의 모든 개인정보 등은 삭제됩니다.
회원이 계약을 해지하는 경우, 회원이 작성한 게시물 중 프로필 등과 같이 본인 계정에 등록된 게시물 일체는 삭제됩니다. 다만, 다른 회원에 의해 담기, 스크랩 등이 되어 재게시되거나, 공용게시판에 등록된 게시물 등은 삭제되지 않으니 사전에 삭제 후 탈퇴하시기 바랍니다.
회원이 제6조의 규정을 위반한 경우 회사는 사전통보 없이 일방적으로 본 계약을 해지할 수 있고, 이로 인하여 서비스 운영에 손해가 발생한 경우 이에 대한 민, 형사상 책임도 물을 수 있습니다.

면책
회사는 다음 사유로 서비스를 제공할 수 없는 경우 이로 인하여 회원에게 발생한 손해에 대해서는 책임을 부담하지 않습니다.
천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우
서비스 제공을 위하여 회사와 서비스 제휴계약을 체결한 제3자의 고의적인 서비스 방해가 있는 경우
회원의 귀책사유로 서비스 이용에 장애가 있는 경우
기타 회사의 고의∙과실이 없는 사유로 인한 경우	
회사는 회원이 서비스를 이용하여 기대하는 수익을 얻지 못하거나 상실한 것에 대하여 책임을 지지 않고 회원 상호간 및 회원과 제3자 상호간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해를 배상할 책임도 부담하지 않습니다.

회사의 법적 책임
회사는 회원과 이용고객을 중개하는 역할을 할 뿐 의료광고 및 온라인 상담의 주체가 아니며, 각 의료광고 및 온라인 상담의 주체는 해당 파트너병원 및 닥터입니다. 
의료 광고 및 온라인 상담에 대한 법적인 의무 및 책임은 각 의료광고 주체에 있습니다.

서비스 이용과 지식 재산권
회사와 파트너병원 및 회원과 닥터가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에 귀속합니다.
회원 및 파트너병원은 회사의 치과상담플랫폼을 이용함으로써 얻은 정보 중 지적재산권이 귀속된 정보를 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
회사와 파트너병원 및 닥터는 약정에 따라 이용고객에게 귀속된 저작권을 사용하는 경우 당해 이용고객에게 통보하여야 합니다.
회원이 창작하여 회사에 게재 또는 등록한 게시물에 대한 저작권은 회원 본인에게 있으며 해당 게시물이 타인의 지적재산권을 침해하여 발생되는 모든 책임은 회원 본인에게 있습니다.
회원이 창작, 등록한 저작물을 회사에 게시한 후 회사에 삭제 요청을 할 경우 회사는 즉시 삭제합니다. 다만 회원이 해당 게시물의 삭제를 요청하지 않는 경우 회사는 계속해서 게시할 수 있습니다.
회원의 게시물에 대해 제3자로부터 저작권 및 기타 권리의 침해로 이의가 제기된 경우 회사는 해당 게시물을 법령에 따라 정지 또는 삭제할 수 있습니다.

재판권 및 준거법
회사와 파트너병원 및 닥터 간에 발생한 분쟁에 관한 소송은 서울중앙지방법원을 관할로 합니다. 
회사와 회원 간에 제기된 소송에는 한국법을 적용합니다.

부칙
제1조  본 약관은 2018년 6월 20일부터 적용됩니다.
              `}
            </ContentContainer>
          </PageContent>
        </div>
      </div>
    );
  }
}

export default Terms;
