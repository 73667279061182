import React from 'react';
import NumberFormat from 'react-number-format';

export const getOrthoPartName = (part) => {
  if (part === 'PU') {
    return '윗니 부분';
  }

  if (part === 'AU') {
    return '윗니 전체';
  }

  if (part === 'PD') {
    return '아랫니 부분';
  }

  if (part === 'AD') {
    return '아랫니 전체';
  }
};

export const getPriceFormat = (price) => {
  if (price) {
    return <NumberFormat value={price} thousandSeparator displayType="text" />;
  }
  return '0';
};

export const getReqChargeLevel = (chargeLevel) => {
  if (chargeLevel === '무료') {
    return '';
  }

  return chargeLevel;
};

export const getReqChargePrice = (chargePrice, isDirect) => {
  if (!chargePrice) {
    return '';
  }

  if (chargePrice && isDirect) {
    return '무료';
  }

  if (chargePrice && !isDirect) {
    return (
      <span>
        {getPriceFormat(chargePrice)} 원
      </span>
    );
  }

  return '';
};

export const getReqCharge = (chargeLevel, chargePrice, isDirect) => {
  if (chargePrice) {
    return <span>{getReqChargeLevel(chargeLevel)} ({getReqChargePrice(chargePrice, isDirect)})</span>;
  }

  return getReqChargeLevel(chargeLevel);
};

export const getReqWantLevelLable = (wantLevel) => {
  if (!wantLevel) {
    return '';
  }

  if (wantLevel === 5) {
    return '최대한 빨리 치료를 원함';
  }

  if (wantLevel === 3) {
    return '치료의향을 느끼고 있음';
  }

  if (wantLevel === 1) {
    return '치료를 고민하는 중';
  }

  return '';
};

export const getCarePriceLabelText = carePrice => (carePrice === 'dontcare' ? '잘 모르겠음' : '없음');

export const getGenderLabelText = gender => (gender === 'female' ? '여자' : '남자');

export const getReqTypeLabel = (reqType) => {
  if (!reqType) {
    return '';
  }

  if (reqType === 'all') {
    return '일반/교정';
  }

  if (reqType === 'tx') {
    return '일반';
  }

  if (reqType === 'ortho') {
    return '교정';
  }

  return '';
};


const reqUtils = {};
export default reqUtils;
