import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import CustomerFooter from '../components/CustomerFooter';
import introImg from '../images/signup-service-image.jpg';
import Logo from '../images/img-logo-gnb.svg';
import BlueLogo from '../images/img-logo-gnb-blue.svg';
import icon1 from '../images/service-info-icon-1.png';
import icon2 from '../images/service-info-icon-2.png';
import icon3 from '../images/service-info-icon-3.png';
import icon4 from '../images/service-info-icon-4.png';
import icon5 from '../images/service-info-icon-5.png';
import miniIcon1 from '../images/service-info-sm-icon-1.png';
import miniIcon2 from '../images/service-info-sm-icon-2.png';
import miniIcon3 from '../images/service-info-sm-icon-3.png';
import miniIcon4 from '../images/service-info-sm-icon-4.png';
import miniIcon5 from '../images/service-info-sm-icon-5.png';
import effectIcon1 from '../images/effect-icon-1.png';
import effectIcon2 from '../images/effect-icon-2.png';
import effectIcon3 from '../images/effect-icon-3.png';
import contentsIcon1 from '../images/contents-icon-1.png';
import contentsIcon2 from '../images/contents-icon-2.png';
import contentsIcon3 from '../images/contents-icon-3.png';
import { media } from '../globalStyle';

import SignupComponent from '../components/SignupComponent';

const queryString = require('query-string');

const Container = styled.div``;

const Intro1Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 940px;
  color: #fff;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(${introImg});
  ${media.tablet`
    background-image: none;
    height: auto;
    color: #017fa0;
  `};
`;

const Intro1Inner = styled.div`
  width: 100%;
  padding: 0 9%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  ${media.desktop`
    padding: 0 0 0 30px;
  `};
  ${media.tablet`
    flex-direction: column;
    padding: 0;
    height: auto;
    color: #017fa0;
  `};
`;

const Intro1Container = styled.div`
  padding-top: 500px;
  display: inline-block;
  ${media.tablet`
    position: static;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 60px;
    padding-bottom: 30px;
  `};
`;

const FormContainer = styled.div`
  float: right;
  vertical-align: middle;
  padding-left: 30px;
  padding-right: 30px;
  width: 420px;
  height: 940px;
  background-color: #38c8e5;
  ${media.tablet`
    padding-left: 20px;
    padding-right: 20px;
    position: static;
    width: auto;
    height: auto;
  `};
`;

const MainIntroHead = styled.h1`
  margin-bottom: 30px;
  font-size: 48px;
  font-weight: 300;
  line-height: 1.13;
  letter-spacing: -3.1px;
  max-width: 516px;
  word-break: keep-all;
  b {
    font-weight: bold;
  }
  ${media.tablet`
    position: static;
    width: auto;
    font-size: 26px;
    font-weight: 500;
    line-height: 1.23;
    letter-spacing: -1.7px;
  `};
`;

const MainIntroP = styled.p`
  max-width: 432px;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -1.2px;
  word-break: keep-all;
  ${media.tablet`
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.9px;
  `};
`;

const EffectHead1 = styled.div`
  font-size: 36px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: -1.4px;
  text-align: center;
  color: #017fa0;
  max-width: 480px;
  margin: auto;
  word-break: keep-all;
  ${media.tablet`
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: -0.9px;
  `};
`;

const IntroHead1 = styled.h1`
  font-size: 36px;
  line-height: 1.33;
  letter-spacing: -1.4px;
  color: #017fa0;
  ${media.tablet`
    font-size: 24px;
    max-width: 205px;
    margin: 0 auto;
  `};
`;

const IntroHead2 = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 3;
  letter-spacing: -0.9px;
  text-align: center;
  color: #017fa0;
`;

const Intro2Wrapper = styled.div`
  padding: 120px 20px;
  text-align: center;
  ${media.tablet`
    padding: 60px 20px;
  `};
`;

const Intro3Wrapper = styled.div`
  padding: 120px 20px;
  text-align: center;
  background-color: #e1f6fb;
  ${media.tablet`
    height: auto;
    padding: 60px 20px;
  `};
`;

const IconsWrapper = styled.div`
  max-width: 900px;
  margin: 60px auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${media.tablet`
    flex-wrap: wrap;
  `};
`;

const Icon = styled.div`
  display: inline-block;
  text-align: center;
  ${media.tablet`
    display: block;
    width: 100%;
    margin-bottom: 20px;
    img {
      max-width: 130px;
    }
  `};
`;

const EffectIconsWrapper = styled(IconsWrapper)`
  max-width: 700px;
  img {
    width: 116px;
  }
`;

const Intro3IconsWrapper = styled(IconsWrapper)`
  flex-wrap: wrap;
  justify-content: flex-start;
  ${media.tablet`
    margin-top: 30px;
    max-width: 260px;
    justify-content: center;
  `};
`;

const Intro3Icon = styled(Icon)`
  ${media.tablet`
    width: 60px;
    img {
      width: 60px;
      height: 60px;
      ${props => props.miniIcon && `content: url(${props.miniIcon});`};
    }
    p {
      margin-top: 0;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  `};
`;

const ContentsIconsWrapper = styled(EffectIconsWrapper)`
  img {
    width: 40px;
  }
`;

const ContentsBtnContainer = styled.div`
  margin-top: 62px;
`;

const ContentsBtn = styled.button`
  border-radius: 30px;
  background-color: #ffffff;
  border: none;
  padding: 18px 20px;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: -1.5px;
  text-align: center;
  color: #38c8e5;
  min-width: 218px;
  span {
    float: right;
  }
  &:last-of-type {
    margin-left: 30px;
  }
  ${media.tablet`
    width: 100%;
    max-width: 280px;
    display: block;
    margin: 0 auto;
    &:last-of-type {
      margin: 10px auto 0;
    }
  `};
`;

const ContentsInfo = styled.div`
  padding: 120px 20px;
  background-color: #38c8e5;
  text-align: center;
  ${media.tablet`
    padding: 60px 20px;
  `};
`;

const ContentsInfoTitle = styled.div`
  font-size: 36px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.28;
  letter-spacing: -1.4px;
  text-align: center;
  color: #ffffff;
  word-break: keep-all;
  ${media.tablet`
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: -0.9px;
    max-width: 280px;
    margin: auto;
  `};
`;

const ContentsInfoSubTitle = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.13;
  letter-spacing: -1.2px;
  text-align: center;
  color: #ffffff;
  margin-top: 9px;
  word-break: keep-all;
  ${media.tablet`
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: -1.1px;
    text-align: center;
    color: #ffffff;
    max-width: 160px;
    margin: 10px auto 0;
  `};
`;

const ContentsIconTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: -0.9px;
  text-align: center;
  color: #ffffff;
  margin-top: 17px;
`;

const ContentsDesc = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -1.2px;
  text-align: center;
  color: #ffffff;
  margin-top: 10px;
`;

const EffectPer = styled.div`
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: #38c8e5;
  margin-top: 6px;
  div {
    display: inline-block;
    vertical-align: bottom;
  }
`;

const EffectDesc = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.6px;
  text-align: center;
  color: #017fa0;
  margin-top: 4px;
`;

const Arrow = styled.div`
  display: inline-block;
  border: solid 6px transparent;
  border-left-color: #1c8daa;
  margin: 0 19px 47px 25px;
  ${media.tablet`
    margin: 0 7px 47px 13px;
  `};
`;

const Head1 = styled.h1`
  font-size: 36px;
  line-height: 1.33;
  letter-spacing: -1.4px;
  color: #017fa0;
`;

const P1 = styled.p`
  font-size: 16px;
  line-height: 1.63;
  letter-spacing: -0.9px;
  color: #017fa0;
  ${props => props.bold && 'font-weight: bold'};
  strong {
    font-weight: bold;
  }
  ${media.tablet`
    max-width: 280px;
    margin: 0 auto;
    font-size: 14px;
  `};
`;

const Nav = styled.div`
  background-color: #ffffff;
  padding: 13px 30px;
  ${media.tablet`
    background-color: #38c8e5;
    padding: 15px 0;
    text-align: center;
  `};
`;

const NavInner = styled.div`
  width: 100%;
  padding: 0 9%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  ${media.desktop`
    padding: 0 0 0 30px;
  `};
  ${media.tablet`
    flex-direction: column;
    padding: 0;
    height: auto;
    color: #017fa0;
  `};
`;

const Banner = styled.div`
  background-color: #e8f7fa;
  padding: 17px 0;
  font-size: 18px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1.7px;
  text-align: center;
  font-weight: normal;
  letter-spacing: normal;
  color: #38c8e5;
`;

const StrongBlue = styled.span`
  font-weight: bold;
  font-size: 18px;
  color: #017fa0;
`;

const LogoImage = styled.img`
  width: 120px;
  height: 24px;
  object-fit: contain;
  vertical-align: middle;
  ${media.tablet`
    content: url(${Logo});
  `};
`;

const AppName = styled.div`
  margin-left: 8px;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1.4px;
  color: #ffffff;
  display: inline-block;
  vertical-align: middle;
`;

const CounterContainer = styled.div`
  font-size: 74px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -1.5px;
  text-align: center;
  color: #38c8e5;
  padding-top: 20px;
  max-width: 640px;
  display: flex;
  margin: auto;
  justify-content: space-between;
  ${media.tablet`
    display: block;
  `};
`;

const PercentNum = styled.div`
  div {
    display: inline-block;
    vertical-align: top;
  }
`;

const Counter = styled.div`
  display: inline-block;
  span {
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.08;
    letter-spacing: -1.3px;
    color: #38c8e5;
  }
  ${media.tablet`
    display: block;
    & ~ & {
      margin-top: 18px;
    }
  `};
`;

const NumbersContainer = styled.div`
  width: 90px;
  height: 50px;
  overflow:hidden;
`;

const countUpAnimation = (props) => {
  return keyframes`
    0% {
      margin-left: 0px
    }
    100% {
      margin-left: ${`-${(props.length - 1) * 90}px`}
    }
  `;
}

const Numbers = styled.div`
  white-space:nowrap;
  width:auto;
  margin-left: ${props => `-${(props.length - 1) * 90}px`};
  &.animation {
    animation: ${props => countUpAnimation} ${props => props.length * 0.04}s;
    animation-fill-mode: forwards;
    animation-timing-function: steps(${props => props.length - 1});
  }
  div {
    float: left;
    text-align: center;
    height: 50px;
    width: 90px;
    font-size: 50px;
  }
`;


const CounteDesc = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: -0.9px;
  text-align: center;
  color: #017fa0;
`;


const visitCount = [];
const salesCount = [];
const roasCount = [];

const setCounts = () => {
  for (let i = 130; i < 151; i++) {
    visitCount.push(i);   
  }
  for (let i = 105; i < 121; i++) {
    salesCount.push(i);   
  }
  for (let i = 275; i < 301; i++) {
    roasCount.push(i);   
  }
}

class SignupNew2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      num: 0,
    };
  }

  componentWillMount() {
    setCounts();
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScrollEvt);
    this.handleScrollEvt();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScrollEvt);
  }

  handleScrollEvt = () => {
    // 카운터 들어가는 영역 위치, 높이
    const counterEl = document.getElementById('counterContainer');
    const counterElTop = counterEl.offsetTop;

    // 윈도우 브라우저의 top, bottom 구하기
    const { scrollTop } = document.scrollingElement || document.documentElement;
    const scrollBottom = scrollTop + window.innerHeight;

    // 윈도우 브라우저 top, bottom 안에 카운터 영역이 존재 할 때 animation 클래스 추가해서 숫자 롤링 애니메이션 적용
    const elements = document.getElementsByClassName("numbers");
    if (scrollTop < counterElTop && counterElTop < scrollBottom) {
      for (let i = 0; i < elements.length; i++) {
        const name = "animation"
        const arr = elements[i].className.split(" ");
        if (arr.indexOf(name) === -1) {
          elements[i].className += " " + name;
        }
      }
      return;
    }

    // 윈도우 브라우저 top, bottom 안에 밖에 있을때에는 삭제.(class를 추가/삭제 반복해야 애니메이션이 매번 시작됨)
    for (let i = 0; i < elements.length; i++) {
      const name = "animation"
      const arr = elements[i].className.split(" ");
      if (arr.indexOf(name) !== -1) {
        elements[i].classList.remove(name);
      }
    }
  }

  render() {
    const searchParams = queryString.parse(this.props.location.search);
    const { referral } = searchParams;
    
    return (
      <div>
        <Nav>
          <NavInner>
            <div>
              <LogoImage src={BlueLogo} />
              <AppName>파트너 병원</AppName>
            </div>
          </NavInner>
        </Nav>
        <Banner>
          [NOW] 오닥터 <StrongBlue>1차 파트너</StrongBlue> 가입자 모집중!!
        </Banner>
        <Container>
          <Intro1Wrapper>
            <Intro1Inner>
              <Intro1Container>
                <MainIntroHead>
                  환자들은 점점 <br />
                  <b>스마트</b> 해지고 있습니다.
                </MainIntroHead>
                <MainIntroP>
                  점점 더 손쉽게 많은 정보를 찾아보고 그만큼 오류가 있는 정보도 많아지고 있습니다.
                  가격이 곧 양심인 시대가 되었습니다.
                  <br />
                  정말 가격이 저렴하다고만 좋은 병원일까요?
                  <br />
                  언제까지 끝나지 않는 가격 경쟁만 해야할까요?
                </MainIntroP>
              </Intro1Container>
              <FormContainer>
                <SignupComponent referral={referral} />
              </FormContainer>
            </Intro1Inner>
          </Intro1Wrapper>
          <Intro2Wrapper>
            <IntroHead1>
              쉽지 않은 병원운영, <br />
              해결방법이 있을까요?
            </IntroHead1>
            <CounterContainer>
              <Counter>
                <PercentNum>
                  <div>50</div>
                  <span>%</span>
                </PercentNum>
                <CounteDesc>평균 예약 부도율(신환기준)</CounteDesc>
              </Counter>
              <Counter>
                <PercentNum>
                  <div>40</div>
                  <span>%</span>
                </PercentNum>
                <CounteDesc>상담 실패율</CounteDesc>
              </Counter>
              <Counter>
                <PercentNum>
                  <div>10</div>
                  <span>% 이하의</span>
                </PercentNum>
                <CounteDesc>광고 효율</CounteDesc>
              </Counter>
            </CounterContainer>
          </Intro2Wrapper>
          <Intro3Wrapper>
            <IntroHead1>
              예약부터 상담, 치료까지 이어지는 프로세스는 잘 이루어 지고 있나요?
            </IntroHead1>
            <IntroHead2>촘촘하고 효율적인 프로세스가 절실합니다!</IntroHead2>
            <Intro3IconsWrapper>
              <Intro3Icon padRight miniIcon={miniIcon1}>
                <img src={icon1} alt="" />
                <P1 bold style={{ marginTop: '16px' }}>
                  인바운드
                </P1>
              </Intro3Icon>
              <Arrow />
              <Intro3Icon padRight miniIcon={miniIcon2}>
                <img src={icon2} alt="" />
                <P1 bold style={{ marginTop: '16px' }}>
                  예약
                </P1>
              </Intro3Icon>
              <Arrow />
              <Intro3Icon miniIcon={miniIcon3}>
                <img src={icon3} alt="" />
                <P1 bold style={{ marginTop: '16px' }}>
                  내원
                </P1>
              </Intro3Icon>
              <Arrow />
              <Intro3Icon miniIcon={miniIcon4}>
                <img src={icon4} alt="" />
                <P1 bold style={{ marginTop: '16px' }}>
                  상담
                </P1>
              </Intro3Icon>
              <Arrow />
              <Intro3Icon miniIcon={miniIcon5}>
                <img src={icon5} alt="" />
                <P1 bold style={{ marginTop: '16px' }}>
                  치료
                </P1>
              </Intro3Icon>
            </Intro3IconsWrapper>
          </Intro3Wrapper>
          <Intro2Wrapper>
            <EffectHead1>오닥터와 함께 클리닉의 각단계 효율을 20%씩만 높여보세요!</EffectHead1>
            <EffectIconsWrapper id="counterContainer">
              <Icon padRight>
                <img src={effectIcon1} alt="" />
                <EffectPer>
                  <NumbersContainer>
                    <Numbers
                      className="numbers"
                      length={visitCount.length}
                    >
                      {visitCount.map(num => (
                        <div key={`visitCount${num}`}>{num}</div>
                      ))}
                    </Numbers>
                  </NumbersContainer>
                  %
                </EffectPer>
                <EffectDesc>늘어나는 내원율</EffectDesc>
              </Icon>
              <Icon padRight>
                <img src={effectIcon2} alt="" />
                <EffectPer>
                  <NumbersContainer>
                    <Numbers
                      className="numbers"
                      length={salesCount.length}
                    >
                      {salesCount.map(num => (
                        <div key={`salesCount${num}`}>{num}</div>
                      ))}
                    </Numbers>
                  </NumbersContainer>
                  %
                </EffectPer>
                <EffectDesc>상승하는 매출량</EffectDesc>
              </Icon>
              <Icon>
                <img src={effectIcon3} alt="" />
                <EffectPer>
                  <NumbersContainer>
                    <Numbers
                      className="numbers"
                      length={roasCount.length}
                    >
                      {roasCount.map(num => (
                        <div key={`roasCount${num}`}>{num}</div>
                      ))}
                    </Numbers>
                  </NumbersContainer>
                  %
                </EffectPer>
                <EffectDesc>광고비용 대비 효율상승</EffectDesc>
              </Icon>
            </EffectIconsWrapper>
          </Intro2Wrapper>
          <ContentsInfo>
            <ContentsInfoTitle>
              오닥터 무료가입을 통해
              <br />
              병원과 의사분의 콘텐츠 파워를 높이세요!
            </ContentsInfoTitle>
            <ContentsInfoSubTitle>
              스마트한 환자분들에게 맞는 스마트한 콘텐츠가 자산입니다.
            </ContentsInfoSubTitle>
            <ContentsIconsWrapper>
              <Icon padRight>
                <img src={contentsIcon1} alt="" />
                <ContentsIconTitle>
                  지속 가능한
                  <br />
                  맞춤형 콘텐츠 개발
                </ContentsIconTitle>
                <ContentsDesc>
                  병원과 의사분의 지속적인
                  <br />
                  성장을 위한 콘텐츠 강화
                </ContentsDesc>
              </Icon>
              <Icon padRight>
                <img src={contentsIcon2} alt="" />
                <ContentsIconTitle>
                  효과적인
                  <br />
                  상담시스템 개발
                </ContentsIconTitle>
                <ContentsDesc>
                  효율이 높은 상담시스템으로
                  <br />
                  지속적으로 업그레이드!
                </ContentsDesc>
              </Icon>
              <Icon>
                <img src={contentsIcon3} alt="" />
                <ContentsIconTitle>
                  콘텐츠를 이용한
                  <br />
                  Life time value 창출
                </ContentsIconTitle>
                <ContentsDesc>
                  환자와의 일시적인 관계가 아닌
                  <br />
                  지속적인 LTV 창출
                </ContentsDesc>
              </Icon>
            </ContentsIconsWrapper>
            <ContentsBtnContainer>
              <ContentsBtn
                onClick={() =>
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  })
                }
              >
                파트너 가입하기
                <span>></span>
              </ContentsBtn>
              <ContentsBtn onClick={() => this.props.history.push('/complimentsignup')}>
                칭찬프로젝트 참여
                <span>></span>
              </ContentsBtn>
            </ContentsBtnContainer>
          </ContentsInfo>
          <CustomerFooter />
        </Container>
      </div>
    );
  }
}

export default withRouter(SignupNew2);
