import React, { Component } from 'react';
import { graphql, Query } from 'react-apollo';
import moment from 'moment';
import styled from 'styled-components';
import {
  HosRespTabContent,
  FilterContainer,
  FilterRow,
  RespDateRangePicker,
  RespPresetDatePicker,
  AddressSelector,
  UserNameFilter,
  RespCallStateFilter,
  SearchBtn,
  RespsTableContainer,
  UserPhoneFilter,
  HosRespsPagination,
  EmptyDataInfoMsg,
  CardItemContainer,
  FilterLabel,
  Separator,
  SeparatorDash,
  UserName,
  UserLocation,
} from './HosRespsCommonComponents';
import {
  checkedHosRespsForPartnerQuery,
  updateCheckedRespCallStateMutation,
} from '../../graphql';
import { getOdocDateWithHourMin } from '../../utils/date';
import { getReqTypeLabel } from '../../utils/req-utils';
import { rightContainerScrollTop } from '../../utils/dom-util';
import { getLocationNames } from '../../utils/locationdata';
import withToast from '../../utils/toasthoc';
import { parsePhonenum } from '../../utils/parser';
import HosRespMemoEditor from '../HosRespDetail/HosRespMemoEditor';
import { FullScreenContainerModal } from '../basic/ModalComponents';
import HosRespDetail from '../../routes/HosRespDetail';

const ITEM_PER_PAGE = 9;

const UserReqCreatedAt = styled.div`
  font-size: 14px;
  color: #b1b3b2;
  letter-spacing: -0.4px;
  span {
    font-family: Saira Semi Condensed;
    font-stretch: semi-condensed;
    letter-spacing: normal;
  }
`;

const UserReqType = styled.div`
  font-size: 14px;
  letter-spacing: -0.4px;
  text-align: right;
  color: #b1b3b2;
`;

const UserPhonenum = styled.div`
  height: 22px;
  font-family: Saira Semi Condensed;
  font-stretch: semi-condensed;
  letter-spacing: normal;
  font-size: 14px;
  font-weight: normal;
  color: #000000;
`;

const UserContact = styled.div`
  height: 17px;
  font-size: 14px;
  line-height: 1.21;
  letter-spacing: -0.4px;
  text-align: right;
  color: #000000;
`;

class CheckedResps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      locationSi: '',
      locationGu: '',
      username: '',
      phonenum: '',
      variables: {
        // filtering variables, 검색 버튼 클릭시에만 update 하여 필터링한다.
        startDate: null,
        endDate: null,
        locationSi: '',
        locationGu: '',
        username: '',
        phonenum: '',
        callState: '',
        currentPage: 1,
        perPage: ITEM_PER_PAGE,
      },
      curHosRespId: null,
      showModal: false,
      lastMemo: '',
      lastCallState: '',
    };
  }

  componentDidUpdate = (prevProps) => {
    if (!prevProps.visible && this.props.visible !== prevProps.visible) {
      this.refetch();
    }
  };

  onChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  onLocationSiChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value, locationGu: '' });
  };

  onDatesChange = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate });
  };

  // filtering variables 셋팅
  filterHosResps = () => {
    const {
      startDate,
      endDate,
      locationSi,
      locationGu,
      username,
      phonenum,
      callState,
    } = this.state;

    const variables = {
      locationSi,
      locationGu,
      username,
      phonenum,
      callState,
      currentPage: 1,
      startDate: null,
      endDate: null,
      perPage: ITEM_PER_PAGE,
    };

    if (startDate && endDate) {
      variables.startDate = moment(startDate).format('YYYY-MM-DD');
      variables.endDate = moment(endDate).format('YYYY-MM-DD');
    }

    this.setState({ variables });
  };

  // filteringVariable에 currentPage update
  onPageChange = (page) => {
    this.setState({
      variables: { ...this.state.variables, currentPage: page },
    });
    rightContainerScrollTop();
  };

  setCurrentHosResp = (curHosRespId) => {
    this.setState({
      curHosRespId,
      showModal: true,
    });
  };
  closeModal = () => {
    this.setState(
      {
        showModal: false,
      },
      () => {
        this.refetch().then().catch();
      },
    );
  };

  saveMemo = async (caId, memo, callState) => {
    try {
      await this.props.updateMemo({
        variables: {
          caId,
          memo,
          callState,
        },
      });
      this.setState({
        lastMemo: memo,
        lastCallState: callState,
      });
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const {
      startDate,
      endDate,
      locationSi,
      locationGu,
      username,
      phonenum,
      variables,
      curHosRespId,
      lastCallState,
      lastMemo,
    } = this.state;
    const { visible } = this.props;

    return (
      <HosRespTabContent visible={visible}>
        <Separator></Separator>
        <FilterContainer>
          <FilterRow>
            <FilterLabel>날짜 검색</FilterLabel>
            <RespDateRangePicker
              startDate={startDate}
              endDate={endDate}
              onDatesChange={this.onDatesChange}
            />
            <RespPresetDatePicker
              startDate={startDate}
              endDate={endDate}
              onDatesChange={this.onDatesChange}
            />
          </FilterRow>
          <FilterRow>
            <FilterLabel>연락 현황</FilterLabel>
            <RespCallStateFilter onChange={this.onChange} />
          </FilterRow>
          <FilterRow>
            <FilterLabel>지역 검색</FilterLabel>
            <AddressSelector
              locationSi={locationSi}
              locationGu={locationGu}
              onLocationSiChange={this.onLocationSiChange}
              onLocationGuChange={this.onChange}
            />
          </FilterRow>
          <FilterRow>
            <FilterLabel>고객 검색</FilterLabel>
            <UserNameFilter username={username} onChange={this.onChange} />
            <UserPhoneFilter phonenum={phonenum} onChange={this.onChange} />
          </FilterRow>
          <Separator></Separator>
          <SearchBtn style={{ marginTop: 30 }} onClick={this.filterHosResps}>
            검색
          </SearchBtn>
        </FilterContainer>
        <Query
          query={checkedHosRespsForPartnerQuery}
          variables={variables}
          fetchPolicy='network-only'>
          {({ data, refetch }) => {

            const { checkedHosRespsForPartner } = data;
            this.refetch = refetch;
            return (
              <>
                <RespsTableContainer>
                  {checkedHosRespsForPartner &&
                  checkedHosRespsForPartner?.hosResps &&
                  checkedHosRespsForPartner?.hosResps?.length > 0 ? (
                    checkedHosRespsForPartner?.hosResps?.map(
                      (hosResp, index) => (
                        <CardItemContainer
                          key={hosResp.id}
                          onClick={() => {
                            this.setCurrentHosResp(hosResp.id);
                          }}>
                          <div
                            style={{
                              height: 22,
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}>
                            <UserReqCreatedAt>
                              답변확인:{' '}
                              <span>
                                {getOdocDateWithHourMin(
                                  hosResp.checkedAns.createdAt,
                                )}
                              </span>
                            </UserReqCreatedAt>
                            <UserReqType>
                              {getReqTypeLabel(hosResp.userRequest.reqType)}
                            </UserReqType>
                          </div>
                          <UserName style={{ marginTop: 62 }}>
                            {hosResp.userRequest.user.username}
                          </UserName>
                          <UserLocation style={{ marginTop: 8 }}>
                            {hosResp.userRequest.directReq
                              ? '다이렉트 상담'
                              : getLocationNames(
                                  hosResp.userRequest.locations,
                                  ', ',
                                )}
                          </UserLocation>
                          <div
                            style={{
                              marginTop: 30,
                              height: 22,
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}>
                            <UserPhonenum>
                              {parsePhonenum(hosResp.userRequest.user.phonenum)}
                            </UserPhonenum>
                            <UserContact>
                              {hosResp.userRequest.user.metadata &&
                                hosResp.userRequest.user.metadata.callmethod}
                            </UserContact>
                          </div>
                          <SeparatorDash
                            style={{ marginTop: 20 }}></SeparatorDash>
                          <div style={{ marginTop: 10 }}>
                            {hosResp.checkedAns ? (
                              <HosRespMemoEditor
                                hrId={hosResp.id}
                                caId={hosResp.checkedAns.id}
                                initCallState={
                                  hosResp.checkedAns.id !== curHosRespId
                                    ? hosResp.checkedAns.callState
                                    : lastCallState
                                }
                                initMemo={
                                  hosResp.checkedAns.id !== curHosRespId
                                    ? hosResp.checkedAns.memo
                                    : lastMemo
                                }
                                setCurrentHosResp={this.setCurrentHosResp}
                                saveMemo={this.saveMemo}
                              />
                            ) : null}
                          </div>
                        </CardItemContainer>
                      ),
                    )
                  ) : (
                    <EmptyDataInfoMsg style={{ marginTop: 120 }}>
                      조회 내역이 없습니다.
                    </EmptyDataInfoMsg>
                  )}
                </RespsTableContainer>
                {checkedHosRespsForPartner &&
                  checkedHosRespsForPartner?.pagination && (
                    <HosRespsPagination
                      pagination={checkedHosRespsForPartner?.pagination}
                      onPageChange={this.onPageChange}
                    />
                  )}
                <FullScreenContainerModal
                  shouldReturnFocusAfterClose={false}
                  isOpen={this.state.showModal}
                  ariaHideApp={false}
                  style={{
                    overlay: {
                      zIndex: 100,
                      height: '100%',
                    },
                  }}>
                  <HosRespDetail
                    hrId={curHosRespId}
                    closeModal={this.closeModal}
                    saveMemo={this.saveMemo}></HosRespDetail>
                </FullScreenContainerModal>
              </>
            );
          }}
        </Query>
      </HosRespTabContent>
    );
  }
}

export default graphql(updateCheckedRespCallStateMutation, {
  name: 'updateMemo',
})(withToast(CheckedResps));
