import React from 'react';
import styled from 'styled-components';
import FormInput from './FormInput';

const PhonenumInputContainer = styled.div`
  position: relative;
  input {
    letter-spacing: 0px;
  }
`;

class PhonenumInput extends React.Component {
  onChange = (e) => {
    const { value } = e.target;
    if (value.match(/^\d+$/) || value === '') {
      this.props.onChange(e);
    }
  };

  render() {
    const {
      name, value, placeholder, fireSubmitValidation, onBlur, errorMsg,
    } = this.props;

    let phoneValidationMsg = '';

    if (value && fireSubmitValidation) {
      if (value.length !== 10 && value.length !== 11) {
        phoneValidationMsg = '올바른 휴대폰 번호를 입력해주세요.';
      }
    }

    return (
      <PhonenumInputContainer>
        <FormInput
          {...this.props}
          type="tel"
          name={name}
          onChange={this.onChange}
          value={value}
          placeholder={placeholder}
          autoComplete="off"
          fireSubmitValidation={!value && fireSubmitValidation}
          errorMsg={phoneValidationMsg || errorMsg || ''}
          onBlur={onBlur}
          maxLength="11"
        />
      </PhonenumInputContainer>
    );
  }
}

export default PhonenumInput;
