import React from 'react';
import FormInput from './FormInput';
import { isValidEmail } from '../../utils/validationInput';

class EmailInput extends React.Component {
  state = {
    emailValidationMsg: '',
  };

  onChange = (e) => {
    this.props.onChange(e);
    if (isValidEmail(e.target.value)) {
      this.setState({ emailValidationMsg: '' });
    } else {
      this.setState({ emailValidationMsg: '올바른 이메일을 입력해 주세요.' });
    }
  };

  render() {
    const {
      name, value, placeholder, fireSubmitValidation, onBlur, errorMsg,
    } = this.props;
    const { emailValidationMsg } = this.state;

    return (
      <React.Fragment>
        <FormInput
          {...this.props}
          type="email"
          name={name}
          onChange={this.onChange}
          value={value}
          placeholder={placeholder}
          autoComplete="off"
          fireSubmitValidation={fireSubmitValidation}
          errorMsg={emailValidationMsg || errorMsg || ''}
          onBlur={onBlur}
        />
      </React.Fragment>
    );
  }
}

export default EmailInput;
