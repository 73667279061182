export const rightContainerScrollTop = () => {
  const container = document.getElementById('app-right-container');
  container.scrollTo({
    top: 0,
    left: 0,
    behavior: 'auto',
  });
};

export default {};
