import React, { Component } from 'react';
import { compose, graphql } from 'react-apollo';
import _ from 'lodash';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import { myHospitalQuery, addHospitalPhotoMutation, deleteHospitalPhotoMutation, signS3Mutation } from '../../graphql';
import { uploadToS3, formatFilename } from '../../utils/imageUpload';
import PlusImg from '../../images/ic-plus-gray-40.svg';
import withToast from '../../utils/toasthoc';
import { PhotoCard } from './HosAddInfoEditComponent';

const StyledDropZone = styled(Dropzone)`
  display: inline-block;
  width: 100%;
  height: 140px;
  background-color: #fafafa;
  border: solid 1px #e0e0e0;
  text-align: center;
  cursor: pointer;
  ${props => props.disabled && 'cursor: default'};
`;

const DropzoneIcon = styled.div`
  padding-top: 50px;
`;

const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
`;

const PreviewImageContainer = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
  &:hover {
    img {
      z-index: 1;
    }
    div {
      z-index: 2;
    }
  }
`;

const CaptionInputTitle = styled.input`
  width: 100%;
  height: 24px;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: -1px;
  text-align: center;
  color: #000000;
  border: 0;
  background: transparent;
`;

const CaptionInputBody = styled.input`
  width: 100%;
  height: 24px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: -1px;
  text-align: center;
  color: #000000;
  border: 0;
  background: transparent;
`;

const CaptionInputContainer = styled.div`
  width: 100%;
  padding: 10px 0 12px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-top: 0;
`;

const SaveBtnContainer = styled.div`
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-top: 0;
  ${props => props.disabled && 'display: none'};
`;

const SaveBtn = styled.button`
  height: 32px;
  background-color: #ffffff;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #033e52;
`;

class AddPhotoWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imgFile: null, // 병원 소개 사진.
      title: '',
      description: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  onDrop = async (files, rejectedFiles, evt, tag) => {
    const { target } = evt;
    let newFileState = this.state.imgFile;
    const newFile = files[0];
    newFile.original = newFile.preview;
    newFile.thumbnail = newFile.preview;
    newFileState = newFile;
    this.setState({ imgFile: newFileState });
  };

  imageUpload = async (file) => {
    if (!file) {
      this.props.toast('사진을 등록해주세요.', 'warn');
      return;
    }

    const result = await this.props.signS3({
      variables: {
        filename: formatFilename(file.name),
        filetype: file.type,
      },
    });

    const { signedRequest, url } = result.data.signS3;

    try {
      await uploadToS3(file, signedRequest);
      return url;
    } catch (e) {
      console.error('Image Upload Failed!');
      return null;
    }
  };

  handleUploadBtnClick = async () => {
    const { imgFile } = this.state;
    const newImageUrl = await this.imageUpload(imgFile);

    if (!newImageUrl) {
      return;
    }

    const { title, description } = this.state;
    const result = await this.props.addHospitalPhoto({
      variables: {
        title,
        description,
        url: newImageUrl,
        category: this.props.category,
      },
    });

    if (result.data.addHospitalPhoto.ok) {
      this.props.toast('사진을 등록하였습니다.', 'info');
      this.setState({ title: '', description: '', imgFile: null });
    } else {
      this.props.toast('사진을 등록 실패', 'warn');
    }
  }

  handleInputChange({ target }) {
    const { name, value } = target;
    this.setState({
      [name]: value,
    });
  }

  render() {
    const { imgFile } = this.state;
    const { disabled } = this.props;

    return (
      <PhotoCard>
        <StyledDropZone name="tx" onDrop={this.onDrop} disabled={disabled}>
          {imgFile && imgFile.preview ? (
            <PreviewImageContainer>
              <PreviewImage src={imgFile.preview} alt="preview" />
            </PreviewImageContainer>
          ) : (
            <DropzoneIcon>
              <img src={PlusImg} alt="dropzonePlus" />
            </DropzoneIcon>
          )}
        </StyledDropZone>
        <CaptionInputContainer>
          <CaptionInputTitle
            type="text"
            value={this.state.title}
            name="title"
            placeholder="사진 제목을 입력해 주세요"
            onChange={this.handleInputChange}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                this.handleUploadBtnClick();
              }
            }}
            disabled={disabled}
          />
          <CaptionInputBody
            type="text"
            value={this.state.description}
            name="description"
            onChange={this.handleInputChange}
            placeholder="코멘트를 입력해 주세요"
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                this.handleUploadBtnClick();
              }
            }}
            disabled={disabled}
          />
        </CaptionInputContainer>
        <SaveBtnContainer disabled={disabled}>
          <SaveBtn onClick={this.handleUploadBtnClick}>저장</SaveBtn>
        </SaveBtnContainer>
      </PhotoCard>
    );
  }
}

export default compose(
  graphql(addHospitalPhotoMutation, {
    name: 'addHospitalPhoto',
    options: {
      refetchQueries: [
        {
          query: myHospitalQuery,
        },
      ],
    },
  }),
  graphql(deleteHospitalPhotoMutation, {
    name: 'deleteHospitalPhoto',
    options: {
      refetchQueries: [
        {
          query: myHospitalQuery,
        },
      ],
    },
  }),
  graphql(signS3Mutation, { name: 'signS3' }),
)(withToast(AddPhotoWidget));
