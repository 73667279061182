import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { Title, DisabledPlusBtn } from './MyHospitalCommonComponents';
import { Hr, PaddingDiv } from '../basic/HtmlComponents';
import TxDescEditContainer from './TxDescEditContainer';
import OrthoDescEditContainer from './OrthoDescEditContainer';

const SubTitle = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  margin-top: 30px;
  margin-bottom: 12px;
`;

const OptionMailtoBtn = styled.a`
  height: 32px;
  border-radius: 1px;
  background-color: #ffffff;
  border: solid 1px #d9d9d9;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #484848;
  padding: 6px 10px;
  float: right;
  &:active,
  &:focus,
  &:hover {
    color: #484848;
  }
`;

export default class HosDescTemplateEditableComponent extends Component {
  constructor(props) {
    super(props);

    const { txPriceTable, orthoPriceTable } = props;
    this.state = {
      txPriceTable,
      orthoPriceTable,
    };
  }

  handleTxDescChange = ({ target }) => {
    const { txPriceTable } = this.state;
    const { name, value } = target;

    const newTxPrice = { ...txPriceTable[name] };
    _.set(newTxPrice, 'desc', value);

    const newTxPriceTable = { ...txPriceTable };
    _.set(newTxPriceTable, name, newTxPrice);

    this.setState({ txPriceTable: newTxPriceTable });
    this.props.updatePriceState('tx', newTxPriceTable);
  };

  handleTxPriceChange = ({ target }) => {
    const { txPriceTable } = this.state;
    const { name, value } = target;

    let newVal = value;

    if (value === '0') {
      newVal = '';
    }

    const re = /^[0-9\b]+$/;
    if (value === '' || re.test(value)) {
      const newTxPrice = { ...txPriceTable[name] };
      _.set(newTxPrice, 'price', newVal);

      const newTxPriceTable = { ...txPriceTable };
      _.set(newTxPriceTable, name, newTxPrice);

      this.setState({ txPriceTable: newTxPriceTable });
      this.props.updatePriceState('tx', newTxPriceTable);
    }
  };

  handleOrthoDescChange = (e, name) => {
    const { value } = e.target;
    const { orthoPriceTable } = this.state;

    const newOrthoPrice = orthoPriceTable[name]
      ? { ...orthoPriceTable[name] }
      : {};

    _.set(newOrthoPrice, 'AU', { ...newOrthoPrice.AU, desc: value });
    _.set(newOrthoPrice, 'AD', { ...newOrthoPrice.AD, desc: value });
    _.set(newOrthoPrice, 'PU', { ...newOrthoPrice.PU, desc: value });
    _.set(newOrthoPrice, 'PD', { ...newOrthoPrice.PD, desc: value });

    const newOrthoPriceTable = { ...orthoPriceTable, [name]: newOrthoPrice };

    this.setState({ orthoPriceTable: newOrthoPriceTable });
    this.props.updatePriceState('ortho', newOrthoPriceTable);
  };

  handleOrthoPriceChange = (e, name) => {
    const { value } = e.target;
    const { orthoPriceTable } = this.state;

    let newVal = value;

    if (value === '0') {
      newVal = '';
    }

    const re = /^[0-9\b]+$/;
    if (value === '' || re.test(value)) {
      const newOrthoPrice = orthoPriceTable[name]
        ? { ...orthoPriceTable[name] }
        : {};

      _.set(newOrthoPrice, 'AU', { ...newOrthoPrice.AU, price: newVal });
      _.set(newOrthoPrice, 'AD', { ...newOrthoPrice.AD, price: newVal });
      _.set(newOrthoPrice, 'PU', { ...newOrthoPrice.PU, price: newVal });
      _.set(newOrthoPrice, 'PD', { ...newOrthoPrice.PD, price: newVal });
      const newOrthoPriceTable = { ...orthoPriceTable, [name]: newOrthoPrice };

      this.setState({ orthoPriceTable: newOrthoPriceTable });
      this.props.updatePriceState('ortho', newOrthoPriceTable);
    }
  };

  render() {
    const { txPriceTable, orthoPriceTable } = this.props;

    if (!this.props.confirmed) {
      return (
        <div>
          <Title>
            치료별 상담내용 설정
            <OptionMailtoBtn href="mailto: partner@odoctor.co.kr?subject=치료옵션제안&body=오닥터에%20필요한%20치료옵션을%20제안해주세요%20!%0A%0A병원만의%20특화된%20치료나%20추가되었으면%20하는%20치료를%20제안해%20주시면%20검토후에%20오닥터의%20치료설명%20옵션에%0A추가해드립니다.%0A%0A-치료명:%0A-치료의%20간단한%20설명:">
              치료항목 제안하기
            </OptionMailtoBtn>
          </Title>
          <Hr margin="30px 0 0" />
          <PaddingDiv padding="34px 0 14px">
            <DisabledPlusBtn title="치료항목추가" />
          </PaddingDiv>
        </div>
      );
    }

    return (
      <div>
        <Title>
          치료별 상담내용 설정
          <OptionMailtoBtn href="mailto: partner@odoctor.co.kr?subject=치료항목제안&body=오닥터에%20필요한%20치료항목을%20제안해주세요%20!%0A%0A병원만의%20특화된%20치료나%20추가되었으면%20하는%20치료를%20제안해%20주시면%20검토후에%20오닥터의%20치료설명%20항목에%0A추가해드립니다.%0A%0A-치료명:%0A-치료의%20간단한%20설명:">
            치료항목 제안하기
          </OptionMailtoBtn>
        </Title>
        <Hr margin="30px 0 0" />
        <div>
          <SubTitle>임플란트</SubTitle>
          <TxDescEditContainer
            title="임플란트 식립"
            itemcode="IMP-001"
            prices={txPriceTable}
            onChange={this.handleTxPriceChange}
            onTextChange={this.handleTxDescChange}
          />
        </div>
        <div>
          <SubTitle>충치치료</SubTitle>
          <TxDescEditContainer
            title="충치 때우기"
            itemcode="CAV"
            prices={txPriceTable}
            onChange={this.handleTxPriceChange}
            onTextChange={this.handleTxDescChange}
          />
        </div>
        <div>
          <SubTitle>일반치료</SubTitle>
          <TxDescEditContainer
            type="half"
            title="신경치료"
            itemcode="GEN-001"
            prices={txPriceTable}
            onChange={this.handleTxPriceChange}
            onTextChange={this.handleTxDescChange}
          />
          <TxDescEditContainer
            type="half"
            title="사랑니발치"
            itemcode="GEN-002"
            prices={txPriceTable}
            onChange={this.handleTxPriceChange}
            onTextChange={this.handleTxDescChange}
          />
          <TxDescEditContainer
            type="half"
            title="스케일링"
            itemcode="AES-005"
            prices={txPriceTable}
            onChange={this.handleTxPriceChange}
            onTextChange={this.handleTxDescChange}
          />
          <TxDescEditContainer
            type="half"
            title="불소도포"
            itemcode="GEN-003"
            prices={txPriceTable}
            onChange={this.handleTxPriceChange}
            onTextChange={this.handleTxDescChange}
          />
        </div>
        <div>
          <SubTitle>잇몸치료</SubTitle>
          <TxDescEditContainer
            title="잇몸치료"
            itemcode="GUM-001"
            prices={txPriceTable}
            onChange={this.handleTxPriceChange}
            onTextChange={this.handleTxDescChange}
          />
        </div>
        <div>
          <SubTitle>미용, 심미치료</SubTitle>
          <TxDescEditContainer
            type="half"
            title="앞니 심미치료"
            itemcode="AES-002"
            prices={txPriceTable}
            onChange={this.handleTxPriceChange}
            onTextChange={this.handleTxDescChange}
          />
          <TxDescEditContainer
            type="half"
            title="치아미백"
            itemcode="AES-003"
            prices={txPriceTable}
            onChange={this.handleTxPriceChange}
            onTextChange={this.handleTxDescChange}
          />
          <TxDescEditContainer
            type="half"
            title="보톡스"
            itemcode="AES-004"
            prices={txPriceTable}
            onChange={this.handleTxPriceChange}
            onTextChange={this.handleTxDescChange}
          />
        </div>
        <div>
          <SubTitle>턱/얼굴치료</SubTitle>
          <TxDescEditContainer
            type="half"
            title="턱관절치료"
            itemcode="FACE-001"
            prices={txPriceTable}
            onChange={this.handleTxPriceChange}
            onTextChange={this.handleTxDescChange}
          />
          <TxDescEditContainer
            type="half"
            title="양악수술"
            itemcode="FACE-002"
            prices={txPriceTable}
            onChange={this.handleTxPriceChange}
            onTextChange={this.handleTxDescChange}
          />
        </div>
        <div>
          <SubTitle>치아교정</SubTitle>
          <OrthoDescEditContainer
            type="half"
            title="보이는 교정"
            onChange={this.handleOrthoPriceChange}
            itemcode="ORT-001"
            prices={orthoPriceTable}
            onTextChange={this.handleOrthoDescChange}
          />
          <OrthoDescEditContainer
            type="half"
            title="안보이는 교정"
            onChange={this.handleOrthoPriceChange}
            itemcode="ORT-002"
            prices={orthoPriceTable}
            onTextChange={this.handleOrthoDescChange}
          />
          <OrthoDescEditContainer
            type="half"
            title="투명 교정"
            onChange={this.handleOrthoPriceChange}
            itemcode="ORT-003"
            prices={orthoPriceTable}
            onTextChange={this.handleOrthoDescChange}
          />
        </div>
      </div>
    );
  }
}
