import moment from 'moment';

const Cookie = {};

export const setCookie = (cname, cvalue, exdays) => {
  const d = moment();
  let expires = '';

  // 만료일의 만료시간은 0시 0분으로 고정.
  if (exdays) {
    d.add(exdays, 'days');
    d.hour(0);
    d.minute(0);
    expires = `expires=${d.utc().toDate()};`;
  }

  document.cookie = `${cname}=${cvalue};${expires}`;
};

export const getCookie = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const deleteCookie = (name) => {
  if (getCookie(name)) {
    document.cookie = `${name}=; Max-Age=0`;
  }
};

export default Cookie;
