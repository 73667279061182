import React from 'react';
import styled from 'styled-components';

const InfoMessage = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.8px;
  color: #000000;
  div {
    margin-bottom: 10px;
  }
  a, a:link, a:active, a:focus {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: -0.8px;
    color: #000000;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const BalanceInfoAlert = () => (
  <InfoMessage>
    <div>
      치료항목과 정확도를 토대로 Tier를 결정하고<br />
      이에 지역, 치료의향 등을 종합적으로 고려하여<br />
      총 7단계의 Level로 과금됩니다.
    </div>
    <a href="https://odoctor-assets.s3.ap-northeast-2.amazonaws.com/files/odoctor_%E1%84%91%E1%85%A1%E1%84%90%E1%85%B3%E1%84%82%E1%85%A5%E1%84%80%E1%85%AA%E1%84%80%E1%85%B3%E1%86%B7%E1%84%8B%E1%85%A1%E1%86%AB%E1%84%82%E1%85%A2.pdf" target="_blank">파트너 과금안내 자세히 보기</a>
  </InfoMessage>
);

export default BalanceInfoAlert;
