import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql, compose } from 'react-apollo';
import CloseImg from '../../images/ic-close-24.svg';
import {
  ComplimentStyledModal,
  ModalBody,
  ModalBodySection,
  ModalBodyTitle,
  ButtonContainer,
  Button,
  DescCountNum,
  ComplimentTextarea,
  TextBox,
} from './ComplimentCommonComponents';
import {
  findHuserByNameQuery,
  addComplimentMutation,
  findHospitalByNameQuery,
} from '../../graphql';
import CheckboxUncheckedIcon from '../../images/checkbox-unchecked-32.svg';
import CheckboxDisabledIcon from '../../images/checkbox-disabled-32.svg';
import CheckboxCheckedIcon from '../../images/checkbox-checked-32.png';
import {
  CustomAsyncCreatableSelect,
  CustomAsyncSelect,
} from '../basic/CustomSelect';
import { FlexRow } from '../basic/HtmlComponents';
import { sendComplimentLink } from '../../utils/kakao';
import { ModalCloseBtn, ModalTitle } from '../basic/ModalComponents';

const HospitalCheckboxContainer = styled.div`
  margin-top: 10px;
`;

const HospitalCheckbox = styled.input`
  display: none;
  :checked + label {
    background-image: url(${CheckboxCheckedIcon});
    ${props =>
    props.disabled && `background-image: url(${CheckboxDisabledIcon});`};
  }
  ${props =>
    props.error &&
    `
    border-color: red;
  `};
`;

const HospitalCheckboxIcon = styled.label`
  width: 20px;
  height: 20px;
  background-image: url(${CheckboxUncheckedIcon});
  background-size: cover;
  vertical-align: middle;
  cursor: pointer;
  ${props =>
    props.disabled &&
    `
    background-image: url(${CheckboxDisabledIcon});
    cursor: auto;
  `};
  ${props =>
    props.error &&
    `
    display: inline-block;
    background: none;
    width: 17.5px;
    height: 17.5px;
    border: solid 1px #f6501b;
    border-radius: 3px;
  `};
`;

const HospitalCheckboxLabel = styled.label`
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  cursor: pointer;
  ${props => props.disabled && 'cursor: auto'};
`;

const AgreeMsg = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
`;

const NewLabelPlus = styled.span`
  color: #000000;
  font-weight: bold;
`;

const NewLabelText = styled.span`
  color: #000000;
  font-weight: bold;
`;

const SelectOption = styled.div`
  width: 100%;
  height: 70px;
  padding: 12px 10px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  cursor: pointer;
  position: relative;
  :after {
    position: absolute;
    display: block;
    content: '';
    width: 90%;
    margin: auto;
    height: 1px;
    opacity: 0.3;
    background-color: rgb(224, 224, 224);
    bottom: 0;
  }
  & ~ & {
  }
  &:hover,
  &:focus {
    background-color: #fafafa;
  }
`;

const OptionName = styled.div`
  line-height: 24px;
`;

const OptionInfo = styled.div`
  margin-top: 2px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  width: 100%;
  overflow: hidden;
`;

const NewOption = styled.div`
  width: 100%;
  height: 52px;
  border-radius: 1px;
  background-color: #f2f2f2;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  padding: 16px 9px;
  cursor: pointer;
`;

const CustomDoctorOption = (props) => {
  if (!props.isDisabled) {
    if (props.data.name && props.data.hospital) {
      return (
        <div {...props.innerProps}>
          <SelectOption>
            <OptionName>{props.data.name}</OptionName>
            <OptionInfo>{props.data.hospital}</OptionInfo>
          </SelectOption>
        </div>
      );
    }

    return (
      <div {...props.innerProps}>
        <NewOption>
          <NewLabelPlus>+</NewLabelPlus>
          <NewLabelText>{props.data.value}</NewLabelText>
          을(를) 새로운 의사로 등록
        </NewOption>
      </div>
    );
  }

  return null;
};
const CustomHospitalOption = (props) => {
  if (!props.isDisabled) {
    if (props.data.name && props.data.address) {
      return (
        <div {...props.innerProps}>
          <SelectOption>
            <OptionName>{props.data.name}</OptionName>
            <OptionInfo>{props.data.address}</OptionInfo>
          </SelectOption>
        </div>
      );
    }
  }

  return null;
};

class ComplimentSendModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      desc: '',
      selectedHuser: null,
      hospital: '',
      fireSubmitValidation: false,
      hospitalCheckboxChecked: false,
      agree: false,
    };
  }

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value, fireSubmitValidation: false });
  };

  handleSubmit = async () => {
    const { selectedHuser, desc, hospital, hospitalCheckboxChecked, agree } = this.state;

    // validation(모두 필수 입력)
    if (!selectedHuser || !desc || (!hospital && !hospitalCheckboxChecked) || !agree) {
      this.setState({ fireSubmitValidation: true });
      this.props.toast(<div>모든 항목은 필수입니다</div>, 'warn');
      return;
    }

    // 칭찬메세지가 없거나 50자 이하 일 때
    if (!desc || desc.length < 50) {
      this.setState({ fireSubmitValidation: true });
      return;
    }

    const variables = { desc };

    // 병원이 입력되었을 때
    if (hospital) {
      variables.toHosName = hospital.name;
    }

    // 기존 유저를 선택 했을 때
    if (selectedHuser.name && selectedHuser.obj) {
      variables.toId = selectedHuser.obj.id;
    } else {
      // 유저이름을 추가해서 선택 했을 때
      variables.toName = selectedHuser.value;
    }

    try {
      const res = await this.props.addCompliment({ variables });
      const { compliment, error } = res.data.addCompliment;

      if (error) {
        this.props.toast(<div>{error.message}</div>, 'warn');
        this.initComplimentForm();
        this.props.closeModal();
        return;
      }

      if (!error && compliment) {
        const { id, fromHuser: { username }, toHuser, toName } = compliment;

        // 카카오링크로 칭찬 공유
        const kakaoLinkRes = await sendComplimentLink({complimentId: id, from: username, to: (toHuser && toHuser.username) || toName});
        this.initComplimentForm();
        this.props.closeModal();
      }
    } catch (error) {
      this.props.toast(<div>칭찬 전송 실패.</div>, 'warn');
      this.initComplimentForm();
      this.props.closeModal();
    }
  };

  // 병원을 선택하기
  selectHospital = (selectedHospital) => {
    // input clear 될 때
    if (!selectedHospital) {
      this.setState({
        hospital: null,
      });
      return;
    }

    this.setState({
      hospital: selectedHospital.obj,
      hospitalCheckboxChecked: false,
    });
  };

  // 의사를 선택하기
  selectHuser = async (selectedHuser) => {
    // input clear 될 때
    if (!selectedHuser) {
      this.setState({
        hospital: null,
        selectedHuser: null,
      });
      return;
    }

    // 존재하는 의사를 선택한 경우
    if (selectedHuser.obj && selectedHuser.obj.hospital) {
      this.setState({
        hospital: selectedHuser.obj.hospital,
        selectedHuser,
        hospitalCheckboxChecked: false,
      });
    } else {
      // 새로 생성한 의사인 경우
      this.setState({
        selectedHuser,
      });
    }
  };

  initComplimentForm = () => {
    // form clear
    this.setState({
      desc: '',
      selectedHuser: null,
      hospital: '',
      agree: false,
      hospitalCheckboxChecked: false,
    });
  };

  closeModal = () => {
    this.initComplimentForm();
    this.props.closeModal();
  };

  searchHospital = inputValue =>
    new Promise(async (resolve) => {
      try {
        // inputValue가 null 이나 null String 일 때 쿼리 하지 않는다.
        if (!inputValue) {
          resolve([]);
        }

        // inputValue로 병원 찾기.
        const res = await this.props.findHospitalByName.refetch({
          name: inputValue,
        });

        const { data, errors, loading } = res;

        if (!errors && !loading && data && data.findHospitalByName) {
          let hospitalOptions = [];

          // 병원목록으로 select options 만들기
          hospitalOptions = await data.findHospitalByName.map(hospital => ({
            value: `${hospital.name}`,
            label: `${hospital.name}`,
            name: `${hospital.name}`,
            address: `${hospital.address}`,
            obj: hospital,
          }));

          resolve(hospitalOptions);
        }
      } catch (e) {
        console.error(e);
      }
    });

  searchHuser = inputValue =>
    new Promise(async (resolve) => {
      try {
        // inputValue가 null 이나 null String 일 때 쿼리 하지 않는다.
        if (!inputValue) {
          resolve([]);
        }

        // inputValue로 의사 찾기.
        const res = await this.props.findHuserByName.refetch({
          name: inputValue,
        });

        const { data, errors, loading } = res;

        if (!errors && !loading && data && data.findHuserByName) {
          let huserOptions = [];
          // 의사 목록에서 자신을 제외
          const husers = data.findHuserByName.filter(huser => huser.id !== this.props.meh.id);

          // 의사목록으로 select options 만들기
          huserOptions = husers.map(huser => ({
            value: `${huser.username}`,
            label: `${huser.username}(${
              huser.hospital ? huser.hospital.name : '병원 정보 없음'
            })`,
            name: huser.username,
            hospital: huser.hospital ? huser.hospital.name : '병원 정보 없음',
            obj: huser,
          }));

          // 새로운 의사 추가
          huserOptions.push({
            value: inputValue,
            label: inputValue,
          });

          resolve(huserOptions);
        }
      } catch (e) {
        console.error(e);
      }
    });

  render() {
    const { isOpen } = this.props;

    return (
      <ComplimentStyledModal
        isOpen={isOpen}
      >
        <ModalCloseBtn src={CloseImg} alt="closeImg" onClick={this.closeModal} />
        <ModalTitle>칭찬 보내기</ModalTitle>
        <ModalBody>
          <ModalBodySection>
            <ModalBodyTitle>이름</ModalBodyTitle>
            <CustomAsyncCreatableSelect
              placeholder="칭찬을 보낼 동료 의사의 이름 입력"
              cacheOptions
              defaultOptions
              loadOptions={this.searchHuser.bind(this)}
              isClearable
              onChange={this.selectHuser}
              components={{
                Option: CustomDoctorOption,
              }}
            />
          </ModalBodySection>
          <ModalBodySection>
            <ModalBodyTitle>소속병원</ModalBodyTitle>
            {this.state.hospitalCheckboxChecked ? (
              <TextBox disabled>잘 모르겠어요</TextBox>
            ) : (
              <div>
                {this.state.selectedHuser &&
                this.state.hospital &&
                this.state.selectedHuser.obj &&
                this.state.selectedHuser.obj.hospital ? (
                  <TextBox disabled>{this.state.hospital.name}</TextBox>
                ) : (
                  <CustomAsyncSelect
                    placeholder="동료의사의 소속병원 입력"
                    onChange={this.selectHospital}
                    isClearable
                    components={{
                      Option: CustomHospitalOption,
                    }}
                    cacheOptions
                    defaultOptions
                    loadOptions={this.searchHospital.bind(this)}
                    value={
                      this.state.hospital
                        ? {
                            value: this.state.hospital.name,
                            label: this.state.hospital.name,
                            name: this.state.hospital.name,
                            address: this.state.hospital.address,
                            obj: this.state.hospital,
                          }
                        : null
                    }
                  />
                )}
              </div>
            )}
            <HospitalCheckboxContainer>
              <HospitalCheckbox
                type="checkbox"
                id="hospital"
                disabled={this.state.hospital}
                checked={this.state.hospitalCheckboxChecked}
                onChange={() =>
                  this.setState({
                    hospitalCheckboxChecked: !this.state
                      .hospitalCheckboxChecked,
                  })
                }
              />
              <HospitalCheckboxIcon
                htmlFor="hospital"
                disabled={this.state.hospital}
              />
              <HospitalCheckboxLabel
                htmlFor="hospital"
                disabled={this.state.hospital}
              >
                잘 모르겠어요
              </HospitalCheckboxLabel>
            </HospitalCheckboxContainer>
          </ModalBodySection>
          <ModalBodySection>
            <ModalBodyTitle>칭찬 한마디</ModalBodyTitle>
            <ComplimentTextarea
              onChange={this.handleChange}
              value={this.state.desc}
              fireSubmitValidation={this.state.fireSubmitValidation}
            />
            <DescCountNum>
              <span>{this.state.desc.length}자</span> / 1000자 (최소 50자)
            </DescCountNum>
          </ModalBodySection>
          <ModalBodySection>
            <FlexRow justifyContent="space-between" alignItems="center">
              <div>
                <HospitalCheckbox
                  type="checkbox"
                  id="agree"
                  checked={this.state.agree}
                  onChange={() =>
                    this.setState({
                      agree: !this.state.agree,
                      fireSubmitValidation: false,
                    })
                  }
                />
                <HospitalCheckboxIcon
                  htmlFor="agree"
                  error={this.state.fireSubmitValidation && !this.state.agree}
                />
                <HospitalCheckboxLabel htmlFor="agree">동의</HospitalCheckboxLabel>
              </div>
              <AgreeMsg>위와 같이 칭찬 메세지를 전송합니다.</AgreeMsg>
            </FlexRow>
          </ModalBodySection>
          <ButtonContainer>
            <Button onClick={this.handleSubmit}>칭찬보내고 공유하기</Button>
          </ButtonContainer>
        </ModalBody>
      </ComplimentStyledModal>
    );
  }
}

export default compose(
  graphql(addComplimentMutation, {
    name: 'addCompliment',
  }),
  graphql(findHuserByNameQuery, {
    options: {
      variables: {
        name: '',
      },
    },
    name: 'findHuserByName',
  }),
  graphql(findHospitalByNameQuery, {
    options: {
      variables: {
        name: '',
      },
    },
    name: 'findHospitalByName',
  }),
)(ComplimentSendModal);
