import React, { Component } from 'react';
import { Query } from 'react-apollo';
import styled from 'styled-components';
import { getAccountQuery } from '../../graphql';


const OdocCashTitle = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.7px;
  text-align: right;
  color: #000000;
  margin-right: 6px;
`;

const OdocCashCurrent = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  text-align: right;
  color: #033e52;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: -0.7px;
`;

const ChargeState = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

// const ChargeMenuBtns = styled.div`
//   float: right;
//   vertical-align: middle;
// `;

// const ChargeBtn = styled.button`
//   font-size: 14px;
//   font-weight: normal;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   color: #000000;
//   border: 0;
//   padding: 0;
//   text-decoration: underline;
//   background: none;
//   &:first-of-type {
//     margin-right: 8px;
//   }
// `;

const OdocCashTitleMobile = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.8px;
  color: #033e52;
  margin-right: 4px;
  display: inline-block;
  vertical-align: middle;
`;

const OdocCashCurrentMobile = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #033e52;
  display: inline-block;
  vertical-align: middle;
`;

class NavChargeStateQuery extends Component {
  render() {
    const { goToCash, isMobile } = this.props;
    return (
      <Query query={getAccountQuery}>
        {({ loading, data, refetch, error }) => {
          if (!error && this.refetch) {
            this.refetch();
          }
          
          if (!loading && data) {
            this.refetch = refetch;
            
            if (isMobile) {
              if (data.getHospitalRespPlan) {
                const plan = data.getHospitalRespPlan;
                return (
                  <ChargeState>
                    <OdocCashTitleMobile>상담패키지 이용중</OdocCashTitleMobile>
                    <OdocCashCurrentMobile style={{ color: '#dd4124' }}>
                      {plan.maxRespCount - plan.usedRespCount} 건 남음
                    </OdocCashCurrentMobile>
                  </ChargeState>
                );
              } else if (data.getBalance) {
                const balance = parseInt(data?.getBalance?.balanceTotal);
                const LocaledBalance = balance?.toLocaleString();
                return (
                  <>
                    <ChargeState>
                      <OdocCashTitleMobile>충전금</OdocCashTitleMobile>
                      <OdocCashCurrentMobile>
                        <span
                          style={{
                            color: balance < 100000 && '#dd4124',
                          }}>
                          {LocaledBalance}원
                        </span>
                      </OdocCashCurrentMobile>
                    </ChargeState>
                  </>
                );
              }
          
            } else {
              if (data.getHospitalRespPlan) {
                const plan = data.getHospitalRespPlan;
                return (
                  <>
                    <OdocCashTitle>상담패키지 이용중 </OdocCashTitle>
                    <OdocCashCurrent>
                      <span style={{ color: '#dd4124' }}>
                        {plan.maxRespCount - plan.usedRespCount} 건 남음
                      </span>
                    </OdocCashCurrent>
                  </>
                );
              } else if (data.getBalance) {
                const balance = parseInt(data?.getBalance?.balanceTotal);
                const LocaledBalance = balance?.toLocaleString();

                return (
                  <div onClick={goToCash}>
                    <OdocCashTitle>충전금</OdocCashTitle>
                    <OdocCashCurrent>
                      <span
                        style={{
                          color: balance < 100000 && '#dd4124',
                        }}>
                        {LocaledBalance}원
                      </span>
                    </OdocCashCurrent>
                  </div>
                );
              }
            }
          }

          return '-';
        }}
      </Query>
    );
  }
}

export default NavChargeStateQuery;
