import React from 'react';
import styled from 'styled-components';
import { media } from '../../globalStyle';
import FormInput from '../form/FormInput';

const StyledPhonenumInput = styled(FormInput)`
  min-width: 216px;
  border-radius: 1px;
  border: solid 1px #e0e0e0;
  padding: 12px 8px;
  ${media.miniDesktop`
    min-width: 184px;
    max-width: unset;
    max-width: none;
  `};
  &:disabled {
    background-color: #f5f5f5;
    color: #bcbcbc;
  }
`;

class HosPeoplePhonenumInput extends React.Component {
  onChange = e => {
    const { value } = e.target;
    if (value.match(/^\d+$/) || value === '') {
      this.props.onChange(e);
    }
  };

  render() {
    const { name, value, placeholder, fireSubmitValidation, onBlur, errorMsg } = this.props;

    let phoneValidationMsg = '';

    if (value && fireSubmitValidation) {
      if (value.length !== 10 && value.length !== 11) {
        phoneValidationMsg = '올바른 휴대폰 번호를 입력해주세요.';
      }
    }

    return (
      <StyledPhonenumInput
        {...this.props}
        name={name}
        onChange={this.onChange}
        value={value}
        placeholder={placeholder}
        autoComplete="off"
        fireSubmitValidation={!value && fireSubmitValidation}
        errorMsg={phoneValidationMsg || errorMsg || ''}
        onBlur={onBlur}
        maxLength="11"
      />
    );
  }
}

export default HosPeoplePhonenumInput;
