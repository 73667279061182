import React, { Component } from 'react';
import styled from 'styled-components';
import logoSVG from '../images/logo-white.svg';
import icFacebook from '../images/ic-facebook.svg';
import icInstagram from '../images/ic-instagram.svg';
import icTwitter from '../images/ic-twitter.svg';

const Background = styled.div`
  color: white;
  padding: 36px 0;
  width: 100%;
  background-color: #017fa0;
  @media(max-width: 900px) {
    padding: 36px;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 1440px;
  margin: 0 auto;
  @media(max-width: 900px) {
    display: block;
  }
`;

const Span1 = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

const P1 = styled.p`
  color: #eeeeee;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const MenuContainer = styled.div`
  margin-top: 15px;
`;

export default class Footer extends Component {
  render() {
    return (
      <Background>
        <FlexDiv>
          <div>
            <img src={logoSVG} alt="" />
          </div>
          <div>
            <MenuContainer>
              <div>
                <Span1>이용약관</Span1>
                {/* <Span1> | </Span1> */}
                <Span1 style={{ marginLeft: '20px' }}>개인정보처리방침</Span1>
              </div>
              <P1 style={{ marginTop: '16px' }}>
                Tel) +82 1062 6172 15 <br />
                email: partner@odoctor.co.kr
              </P1>
              <P1>42-10, Bongeunsa-ro 1-gil, Gangnam-gu, Seoul, Republic of Korea</P1>
              <P1>ⓒ Odoctor corp.</P1>
            </MenuContainer>
          </div>
          <div>
            <img src={icFacebook} alt="" />
            <img style={{ marginLeft: '37px' }} src={icInstagram} alt="" />
            <img style={{ marginLeft: '37px' }} src={icTwitter} alt="" />
          </div>
        </FlexDiv>
      </Background>
    );
  }
}
