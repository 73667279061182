import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { compose, graphql } from 'react-apollo';
import { getPriceFormat, getOrthoPartName } from '../../utils/req-utils';
import { therapyInfoById } from '../UserRequest/therapyInfo';
import {
  getTxPriceTableByReq,
  getOrthoPriceTableByReq,
} from '../../utils/getPriceTableByReq';
import { updateHosRespsMutation, myCounselSettingQuery } from '../../graphql';
import withAlertModal from '../../utils/alerthoc';
import withToast from '../../utils/toasthoc';
import {
  PriceUnit,
  PriceSum,
  ExpectedPrice,
  PriceLabel,
  PriceConatiner,
  PriceCount,
  PriceEqual,
  PriceInput,
  PricePart,
  PriceMultiple,
  TreatPreriodContainer,
  PeriodPresetShort,
  PeriodPresetLong,
  TreatPeriodButton,
  RadioWrapper,
  DiscountRatioInput,
  UnitPercent,
  LeftLabel,
  SymptomAnswerTextarea,
  TreatmentTextNoti,
  MaxDiscountRatio,
  Greeting,
  EndGreeting,
  CheckEndGreeting,
  blockEscape,
  unBlockEscape,
  calcPriceInputType,
} from '../HosResps/HosRespsCommonComponents';
import { RadioInput } from '../basic/HtmlComponents';
import withAuth from '../../utils/authhoc';

const HosRespEditorContainer = styled.div`
  input:disabled,
  select:disabled,
  textarea:disabled {
    color: #b1b3b2;
  }
`;

const LeftRow = styled.div`
  width: 510px;
  display: flex;
  flex-direction: row;
`;

const Separater = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.color || '#000000'};
`;

const RespsTextarea = styled.textarea`
  width: 422px;
  height: 230px;
  border-radius: 4px;
  border: solid 1px #e1e3e2;
  padding: 14px;
  font-size: 13px;
  line-height: 1.54;
  letter-spacing: -0.6px;
  text-align: justify;
  color: #000000;
  resize: none;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b1b3b2;
    opacity: 1; /* Firefox */
  }
`;

const SubmitBtn = styled.button`
  width: 244px;
  height: 56px;
  border-radius: 4px;
  background-color: #033e52;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: -0.6px;
  text-align: center;
  color: #ffffff;
  &:disabled {
    background-color: #bbbcbc;
    border-color: #bbbcbc;
  }
`;

const ChargeWarn = styled.p`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: -0.5px;
  color: #dd4124;
  b {
    font-weight: bold;
  }
`;

const AnswerTitle = styled.div`
  height: 24px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.56px;
  color: #000000;
`;

class HosRespEditor extends Component {
  constructor(props) {
    super(props);
    // 답변을 state로 세팅
    if (props.hosResp) {
      const { txItems, orthoItems } = props.hosResp.userRequest;
      const txPrices = props.hosResp.txPrices || [];
      const orthoPrices = props.hosResp.orthoPrices || [];
      const txPriceTable = getTxPriceTableByReq(txItems, txPrices);
      const orthoPriceTable = getOrthoPriceTableByReq(orthoItems, orthoPrices);
      const treatmentAnswer = props.hosResp.treatmentAnswer || '';
      const symptomAnswer = props.hosResp.symptomAnswer || '';
      const txMaxPrice = this.calcTxPriceTotal(txPriceTable);
      const orthoMaxPrice = this.calcOrthoPriceTotal(orthoPriceTable);
      const discountRatio =
        (props.hosResp.discount && props.hosResp.discount.discountRatio) || 0;
      const priceInputType = calcPriceInputType(
        discountRatio,
        txMaxPrice,
        orthoMaxPrice,
      );

      const expectedPeriod =
        (props.hosResp.metadata && props.hosResp.metadata.expectedPeriod) || '';

      this.state = {
        treatmentAnswer,
        symptomAnswer,
        txMaxPrice,
        orthoMaxPrice,
        txPriceTable,
        orthoPriceTable,
        expectedPeriod,
        priceInputType,
        discountRatio,
      };
    }
  }

  componentDidMount() {
    blockEscape(this.onUnloadListener);
    const { checked } = this.props;
    const container = document.getElementById('hos_resp_editor');
    const inputs = Array.prototype.slice.call(
      container.getElementsByTagName('input'),
    );
    const selects = Array.prototype.slice.call(
      container.getElementsByTagName('select'),
    );
    const textareas = Array.prototype.slice.call(
      container.getElementsByTagName('textarea'),
    );

    // 확인 된 답변일 경우 모든 input, select 비활성화
    if (checked) {
      inputs.map((input) => (input.disabled = true));
      selects.map((select) => (select.disabled = true));
      textareas.map((textarea) => (textarea.disabled = true));
    }
  }

  componentWillUnmount = () => {
    unBlockEscape(this.onUnloadListener);
  };

  onUnloadListener = (e) => {
    if (this.state.edited) {
      const message = '수정하신 내용이 저장되지 않습니다. 정말 닫으시겠어요?';
      e.preventDefault();
      e.stopPropagation();
      // For IE and Firefox
      e.returnValue = message;
      // For Safari
      return message;
    }
  };

  calcTxPriceTotal = (txPriceTable) => {
    let totalPrice = 0;

    txPriceTable.map((data) => {
      if (data.price !== '') {
        totalPrice += data.price * data.unit;
      }
    });

    return totalPrice;
  };

  calcOrthoPriceTotal = (orthoPriceTable) => {
    let totalPrice = 0;

    orthoPriceTable.map((data) => {
      if (data.price !== '') {
        totalPrice += data.price;
      }
    });

    return totalPrice;
  };

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value }, this.props.setEdited(true));
  };

  handleTxPriceChange = ({ target }) => {
    const { name, value } = target;
    const re = /^[0-9\b]+$/;

    if (value === '' || re.test(value)) {
      const price = value === '' ? value : parseInt(value);

      let newTxPriceTable = { ...this.state.txPriceTable };
      newTxPriceTable = _.map(newTxPriceTable, (data) => {
        if (data.itemcode === name) {
          return {
            ...data,
            price,
          };
        }

        return data;
      });

      this.setState(
        {
          txPriceTable: newTxPriceTable,
          txMaxPrice: this.calcTxPriceTotal(newTxPriceTable),
        },
        this.props.setEdited(true),
      );
    }
  };

  handleOrthoPriceChange = (e, itemcode, part) => {
    const { value } = e.target;
    const re = /^[0-9\b]+$/;
    if (value === '' || re.test(value)) {
      const price = value === '' ? value : parseInt(value);

      let newOrthoPriceTable = { ...this.state.orthoPriceTable };
      newOrthoPriceTable = _.map(newOrthoPriceTable, (data) => {
        if (data.itemcode === itemcode && data.part === part) {
          return {
            ...data,
            price,
          };
        }

        return data;
      });

      this.setState(
        {
          orthoPriceTable: newOrthoPriceTable,
          orthoMaxPrice: this.calcOrthoPriceTotal(newOrthoPriceTable),
        },
        this.props.setEdited(true),
      );
    }
  };

  handleAnswerChange = (e) => {
    const { name, value } = e.target;
    const newState = { ...this.state };
    _.set(newState, name, value);
    this.setState(
      {
        ...newState,
      },
      this.props.setEdited(true),
    );
  };

  submitable = () => {
    const {
      treatmentAnswer,
      priceInputType,
      txPriceTable,
      orthoPriceTable,
      discountRatio,
    } = this.state;
    const request = this.props.hosResp.userRequest;

    if (!treatmentAnswer) return false;

    const txPriceEmpty = _.find(txPriceTable, { price: 0 }) ? true : false;
    const orthoPriceEmpty = _.find(orthoPriceTable, { price: 0 })
      ? true
      : false;

    if (priceInputType === 'discount' && parseInt(discountRatio) === 0) {
      return false;
    }

    if (request.reqType === 'all') {
      if (priceInputType !== 'noinput' && (txPriceEmpty || orthoPriceEmpty)) {
        return false;
      }
    } else if (request.reqType === 'tx') {
      if (priceInputType !== 'noinput' && txPriceEmpty) {
        return false;
      }
    } else if (request.reqType === 'ortho') {
      if (priceInputType !== 'noinput' && orthoPriceEmpty) {
        return false;
      }
    }
    return true;
  };

  getInvalidSubmitMessage = (priceInputType) => {
    if (priceInputType === 'noinput') {
      return '답변 내용을 작성해주세요';
    } else if (priceInputType === 'discount') {
      return '할인율, 예상 치료비와 답변 내용을 작성해주세요';
    } else {
      return '예상 치료비와 답변 내용을 작성해주세요';
    }
  };

  handleSubmit = async () => {
    const {
      txMaxPrice,
      orthoMaxPrice,
      treatmentAnswer,
      symptomAnswer,
      orthoPriceTable,
      txPriceTable,
      expectedPeriod,
      discountRatio,
      priceInputType,
    } = this.state;

    const {
      openAlert,
      closeAlert,
      updateHosResps,
      hosResp,
      setEdited,
    } = this.props;
    const { userRequest } = hosResp;

    // validation check start
    if (!this.submitable()) {
      openAlert({
        title: '알림',
        message: this.getInvalidSubmitMessage(priceInputType),
        showConfirmBtn: true,
        confirmBtnName: '확인',
        textAlign: 'left',
        showCancelBtn: false,
      });

      return;
    }

    // variable making
    const variables = {
      ureqId: userRequest.id,
      discountRatio: parseInt(discountRatio),
      txMaxPrice,
      orthoMaxPrice,
    };

    if (txPriceTable.length > 0) {
      variables.txPrices = [];
      await txPriceTable.forEach((data) => {
        variables.txPrices.push({
          itemcode: data.itemcode,
          price: data.price !== '' ? data.price : 0,
        });
      });
    }

    if (orthoPriceTable.length > 0) {
      variables.orthoPrices = [];
      await orthoPriceTable.forEach((data) => {
        variables.orthoPrices.push({
          itemcode: data.itemcode,
          part: data.part,
          price: data.price !== '' ? data.price : 0,
        });
      });
    }

    if (treatmentAnswer && treatmentAnswer.length > 0) {
      variables.treatmentAnswer = treatmentAnswer;
    }

    if (symptomAnswer && symptomAnswer.length > 0) {
      variables.symptomAnswer = symptomAnswer;
    }

    if (expectedPeriod) {
      variables.metadata = {
        expectedPeriod,
      };
    }

    // mutation
    try {
      let id = null;

      if (hosResp) {
        // 답변 수정
        variables.id = hosResp.id;
        const res = await updateHosResps({
          variables,
        });

        id = res.data.updateHosResps.id;
      }

      if (id) {
        openAlert({
          title: '알림',
          message: <div>답변 수정 완료</div>,
          showConfirmBtn: true,
          confirmBtnName: '확인',
          confirmBtnOnClick: closeAlert,
          textAlign: 'left',
          showCancelBtn: false,
        });

        setEdited(false);
      }
    } catch (e) {
      if (e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message) {
        openAlert({
          title: '알림',
          message: e.graphQLErrors[0].message,
          showConfirmBtn: true,
          confirmBtnName: '확인',
          textAlign: 'left',
          confirmBtnOnClick: closeAlert,
          showCancelBtn: false,
        });
      }
    }
  };

  onPriceInputTypeChange = ({ target }) => {
    const { name, value } = target;
    let { txPriceTable, orthoPriceTable } = this.state;
    if (value === 'noinput') {
      txPriceTable.forEach((e, i) => {
        if (e.price !== '') {
          txPriceTable[i].price = 0;
        }
      });
      orthoPriceTable.forEach((e, i) => {
        if (e.price !== '') {
          orthoPriceTable[i].price = 0;
        }
      });

      // 가격 입력 안함 가격, 할인율 리셋
      this.setState(
        {
          [name]: value,
          orthoPriceTable,
          orthoMaxPrice: this.calcOrthoPriceTotal(orthoPriceTable),
          txPriceTable,
          txMaxPrice: this.calcTxPriceTotal(txPriceTable),
          discountRatio: 0,
        },
        this.props.setEdited(true),
      );
    } else if (value === 'normal') {
      //정가 -> 할인율 리셋
      this.setState(
        { [name]: value, discountRatio: 0 },
        this.props.setEdited(true),
      );
    } else {
      this.setState({ [name]: value }, this.props.setEdited(true));
    }
  };

  onDiscountRatioChange = ({ target }) => {
    const { name, value } = target;

    const re = /^[0-9\b]+$/;
    if (!re.test(value)) {
      return;
    }

    let ratio = value;

    if (value === '') {
      ratio = parseInt(value);
    }

    if (ratio > MaxDiscountRatio || ratio < 0) {
      alert(`할인율은 최대 ${MaxDiscountRatio}%까지 입력할 수 있습니다.`);
      this.setState({ [name]: MaxDiscountRatio }, this.props.setEdited(true));
    } else {
      this.setState({ [name]: ratio }, this.props.setEdited(true));
    }
  };

  handleSymptomAnswerChange = (e) => {
    const { name, value } = e.target;
    const newState = { ...this.state };
    _.set(newState, name, value);
    this.setState(
      {
        ...newState,
      },
      this.props.setEdited(true),
    );
  };

  calcClosing = (closing) => {
    if (closing && closing.length == 0) {
      return '작성된 맺음말 템플릿 내용이 없습니다.';
    } else if (closing && closing.length > 26) {
      return closing.slice(0, 26) + '...';
    } else {
      return closing;
    }
  };

  render() {
    const {
      hosResp: { userRequest },
      checked,
      meh,
      myCounselSetting,
      edited,
      setEdited,
    } = this.props;
    const {
      txPriceTable,
      orthoPriceTable,
      treatmentAnswer,
      expectedPeriod,
      symptomAnswer,
      priceInputType,
      discountRatio,
    } = this.state;

    const closing =
      (myCounselSetting.myCounselSetting &&
        this.calcClosing(myCounselSetting.myCounselSetting.closing)) ||
      '';
    const terminatedUser = !!userRequest.user.username.includes('(탈퇴)');
    return (
      <HosRespEditorContainer id='hos_resp_editor'>
        <LeftRow style={{ marginTop: 92, alignItems: 'center' }}>
          <LeftLabel>가격입력</LeftLabel>
          <RadioWrapper>
            <RadioInput
              id='normal'
              name='priceInputType'
              value='normal'
              onChange={this.onPriceInputTypeChange}
              checked={priceInputType === 'normal'}
              labelName='정가 입력'
            />
          </RadioWrapper>
          <RadioWrapper>
            <RadioInput
              id='discount'
              name='priceInputType'
              value='discount'
              onChange={this.onPriceInputTypeChange}
              checked={priceInputType === 'discount'}
              labelName='할인가 입력'
            />
          </RadioWrapper>
          <RadioWrapper>
            <RadioInput
              id='noinput'
              name='priceInputType'
              value='noinput'
              onChange={this.onPriceInputTypeChange}
              checked={priceInputType === 'noinput'}
              labelName='가격 입력안함'
            />
          </RadioWrapper>
        </LeftRow>
        <LeftRow style={{ marginTop: 30, alignItems: 'center' }}>
          <LeftLabel style={{ lineHeight: '46px' }}>할인율</LeftLabel>
          <DiscountRatioInput
            type='phone'
            name='discountRatio'
            value={discountRatio ? discountRatio : ''}
            onChange={this.onDiscountRatioChange}
            disabled={priceInputType !== 'discount'}
            readOnly={priceInputType !== 'discount'}
          />
          <UnitPercent>%</UnitPercent>
        </LeftRow>
        {/* 일반 치료 */}
        {(userRequest.reqType === 'all' || userRequest.reqType === 'tx') && (
          <LeftRow style={{ paddingTop: 90 }}>
            <LeftLabel style={{ paddingTop: 30 }}>치과치료</LeftLabel>
            <div>
              {txPriceTable.map((data, idx) => (
                <PriceConatiner key={`tx_${data.itemcode}`}>
                  <PriceLabel style={{ textAlign: 'left' }}>
                    {therapyInfoById[data.itemcode].viewName}
                  </PriceLabel>
                  <PriceInput
                    name={data.itemcode}
                    type='phone'
                    value={data.price ? data.price : ''}
                    onChange={this.handleTxPriceChange}
                    disabled={terminatedUser || priceInputType === 'noinput'}
                    readOnly={terminatedUser || priceInputType === 'noinput'}
                  />
                  <PriceUnit>만원</PriceUnit>
                  <PriceMultiple></PriceMultiple>
                  <PriceCount style={{ textAlign: 'right' }}>
                    <span>{data.unit}</span>개(회)
                  </PriceCount>
                  <PriceEqual></PriceEqual>
                  <PriceSum style={{ textAlign: 'right' }}>
                    <b>
                      {data.price * data.unit
                        ? getPriceFormat(data.price * data.unit)
                        : 0}
                    </b>
                    만원
                  </PriceSum>
                </PriceConatiner>
              ))}
              <Separater></Separater>
              <ExpectedPrice>
                <div>예상 치료비 (약)</div>
                <div>
                  <big>
                    {this.state.txMaxPrice
                      ? getPriceFormat(this.state.txMaxPrice)
                      : 0}
                  </big>
                  만원
                </div>
              </ExpectedPrice>
            </div>
          </LeftRow>
        )}
        {/* 교정 치료 */}
        {(userRequest.reqType === 'all' || userRequest.reqType === 'ortho') && (
          <LeftRow style={{ marginTop: 92 }}>
            <LeftLabel style={{ paddingTop: 30 }}>교정치료</LeftLabel>
            <div>
              {orthoPriceTable.map((data, idx) => (
                <PriceConatiner key={`${data.part}`}>
                  <PriceLabel style={{ textAlign: 'left' }}>
                    {therapyInfoById[data.itemcode].viewName}
                  </PriceLabel>
                  <PriceInput
                    name={data.itemcode}
                    type='phone'
                    value={data.price ? data.price : ''}
                    disabled={terminatedUser}
                    readOnly={terminatedUser}
                    onChange={(e) =>
                      this.handleOrthoPriceChange(e, data.itemcode, data.part)
                    }
                  />
                  <PriceUnit>만원</PriceUnit>
                  <PricePart style={{ textAlign: 'right' }}>
                    {getOrthoPartName(data.part)}
                  </PricePart>
                  <PriceSum style={{ textAlign: 'right' }}>
                    <b>{data.price ? getPriceFormat(data.price) : ''}</b>만원
                  </PriceSum>
                </PriceConatiner>
              ))}
              <Separater></Separater>
              <ExpectedPrice>
                <div>예상 치료비 (약)</div>
                <div>
                  <big>
                    {this.state.orthoMaxPrice
                      ? getPriceFormat(this.state.orthoMaxPrice)
                      : 0}
                  </big>
                  만원
                </div>
              </ExpectedPrice>
            </div>
          </LeftRow>
        )}
        <LeftRow style={{ marginTop: 30, height: 24 }}>
          {priceInputType === 'discount' && (
            <>
              <LeftLabel></LeftLabel>
              <ChargeWarn
                style={{
                  whiteSpace: 'pre',
                  textAlign: 'left',
                }}>
                할인된 가격을 입력해주세요{'\n'}
                급여(보험) 치료는 할인 대상에서 제외됩니다.
              </ChargeWarn>
            </>
          )}
        </LeftRow>
        <LeftRow
          style={{
            marginTop: 128,
          }}>
          <AnswerTitle>답변 내용</AnswerTitle>
        </LeftRow>
        <LeftRow>
          <Separater style={{ marginTop: 10 }} color='#e1e3e2'></Separater>
        </LeftRow>
        <LeftRow style={{ marginTop: 60, alignItems: 'center' }}>
          <LeftLabel>인사말</LeftLabel>
          {meh?.hospital?.name && (
            <Greeting>{`안녕하세요, ${userRequest.user.username}님. ${meh.hospital.name}입니다.`}</Greeting>
          )}
          {/* 인사말 */}
        </LeftRow>
        <LeftRow style={{ marginTop: 60 }}>
          <LeftLabel style={{ paddingTop: 7 }}>증상답변</LeftLabel>
          <div>
            <SymptomAnswerTextarea
              placeholder={
                !checked
                  ? '예) 이전에 때웠던 부분에 시린 증상이 다시 발생하셨군요. 이가 시린 경우 보통 충치라고 생각하시는데, 충치 외에도 다양한 원인이 있을 수 있습니다. 잘못된 습관으로 인한 치아 마모 때문일 수도 있고, 잇몸질환 때문일 수도 있습니다. 각 원인에 따라 필요한 치료방법이 달라지므로 우선은 정확한 검진을 통해 어떤 문제인지 먼저 확인하시는 것을 권장드립니다.'
                  : ''
              }
              name='symptomAnswer'
              value={
                checked && !symptomAnswer
                  ? '작성된 증상답변 내용이 없습니다.'
                  : symptomAnswer
              }
              onChange={this.handleSymptomAnswerChange}
              disabled={terminatedUser || checked}
              readOnly={terminatedUser || checked}
            />
            <TreatmentTextNoti>
              더 많은 병원 노출을 위한 상담 사례로 증상답변 내용이 공개됩니다
              (치료비 절대 비공개)
            </TreatmentTextNoti>
          </div>
        </LeftRow>
        <LeftRow style={{ marginTop: 60 }}>
          <LeftLabel style={{ marginTop: 14 }}>예상 치료기간</LeftLabel>
          <div>
            <TreatPreriodContainer>
              {PeriodPresetShort.map((e, i) => {
                return (
                  <TreatPeriodButton
                    key={i}
                    style={{
                      marginLeft: i === 0 ? 0 : 11,
                      border:
                        e === expectedPeriod &&
                        (checked ? 'solid 1px #b1b3b2' : 'solid 1px #033e52'),
                      cursor: !checked && 'pointer',
                      color: checked && '#bbbcbc',
                    }}
                    onClick={() => {
                      if (!checked) {
                        this.setState(
                          {
                            expectedPeriod: e,
                          },
                          setEdited(true),
                        );
                      }
                    }}>
                    {e}
                  </TreatPeriodButton>
                );
              })}
            </TreatPreriodContainer>
            <TreatPreriodContainer>
              {PeriodPresetLong.map((e, i) => {
                return (
                  <TreatPeriodButton
                    style={{
                      marginLeft: i === 0 ? 0 : 11,
                      marginTop: 10,
                      border:
                        e === expectedPeriod &&
                        (checked ? 'solid 1px #b1b3b2' : 'solid 1px #033e52'),
                      cursor: !checked && 'pointer',
                      color: checked && '#bbbcbc',
                    }}
                    key={i}
                    onClick={() => {
                      if (!checked) {
                        this.setState(
                          {
                            expectedPeriod: e,
                          },
                          setEdited(true),
                        );
                      }
                    }}>
                    {e}
                  </TreatPeriodButton>
                );
              })}
            </TreatPreriodContainer>
          </div>
        </LeftRow>
        <LeftRow style={{ marginTop: 20 }}>
          <LeftLabel style={{ paddingTop: 7 }}>치료안내</LeftLabel>
          <RespsTextarea
            placeholder='상담 답변 내용'
            name='treatmentAnswer'
            value={treatmentAnswer}
            onChange={this.handleAnswerChange}
            disabled={terminatedUser || checked}
            readOnly={terminatedUser || checked}
          />
        </LeftRow>
        <LeftRow style={{ marginTop: 60, alignItems: 'center' }}>
          <LeftLabel>맺음말</LeftLabel>
          <EndGreeting>{closing}</EndGreeting>
          <CheckEndGreeting
            onClick={() => {
              window.open('/myhospital#autoCounsel');
            }}>
            맺음말 확인하기
          </CheckEndGreeting>
        </LeftRow>
        {!checked && (
          <LeftRow style={{ marginTop: 90 }}>
            <LeftLabel></LeftLabel>
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
              <SubmitBtn
                disabled={!this.submitable() || !edited}
                onClick={this.handleSubmit}>
                저장
              </SubmitBtn>
            </div>
          </LeftRow>
        )}
        <div style={{ height: 280 }}></div>
      </HosRespEditorContainer>
    );
  }
}

export default compose(
  graphql(updateHosRespsMutation, {
    name: 'updateHosResps',
  }),
  graphql(myCounselSettingQuery, {
    options: {
      fetchPolicy: 'network-only',
    },
    name: 'myCounselSetting',
  }),
)(withAuth(withRouter(withAlertModal(withToast(HosRespEditor)))));
