export const getPassValidationMessage = (pass) => {
  if (!pass) {
    return '';
  }

  // 8자 미만인 경우
  if (pass.length < 8) {
    return '앗! 비밀번호는 8자 이상이에요!';
  }

  // 숫자만 입력시
  const onlyDigitReg = /^[0-9]*$/;
  if (onlyDigitReg.test(pass)) {
    return '숫자로만 된 비밀번호는 사용할 수 없어요! ';
  }

  // 영문자, 특수문자 중 1개로만 반복해서 비번을 입력한 경우
  const firstChar = pass.charAt(0);
  const list = pass.split(firstChar).join('');
  if (list === '') {
    return '다른 사람이 추측하기 쉬운 비밀번호는 사용할 수 없어요!';
  }

  // 8글자 이상이고 문자, 숫자, 특수문자 중 2가지 이상 입력하지 않은 경우

  // 숫자와 문자
  const digitAndCharacter = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
  // 숫자와 특수문자
  const digitAndSpecialChar = /^(?=.*\d)(?=.*[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E])[\d\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]{8,}$/;
  // 문자와 특수문자
  const characterAndSpecialChar = /^(?=.*[A-Za-z])(?=.*[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E])[A-Za-z\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]{8,}$/;
  // 숫자와 문자와 특수문자
  const digitAndCharacterAndSpecialChar = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E])[A-Za-z\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E\d]{8,}$/;

  if (
    !digitAndCharacter.test(pass) &&
    !digitAndSpecialChar.test(pass) &&
    !characterAndSpecialChar.test(pass) &&
    !digitAndCharacterAndSpecialChar.test(pass)
  ) {
    return '문자, 숫자, 기호를 조합하여 8자 이상을 사용하세요';
  }

  return '';
};

export const isValidPass = (pass) => {
  if (getPassValidationMessage(pass) === '') {
    return true;
  }

  return false;
};


export const isValidEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (email && !re.test(String(email).toLowerCase())) {
    return false;
  }

  return true;
};

const validationInput = '';

export default validationInput;
