import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import Cs from './cs/Cs';
import Analytics from './Analytics';
import Login from './Login';
import ReqList from './ReqList';
import FindPassword from './FindPassword';
import UpdatePassword from './UpdatePassword';
// import Reservations from './Reservations';
import MyHospital from './MyHospital';
import Compliment from './Compliment';

import { isAuthenticated, isConfirmedAuthenticated } from '../utils/auth';
import PageNotFound from '../components/basic/PageNotFound';
import Errors from './Errors';
import SignupNew from './SignupNew';
import SignupNew2 from './SignupNew2';
import Certification from './Certification';
import FindEmail from './FindEmail';
import OdoctorCash from './OdoctorCash';
import AppLayout from '../components/basic/AppLayout';
import Terms from './Terms';
import PrivacyPolicy from './PrivacyPolicy';
import CommonErrorBoundary from '../utils/CommonErrorBoundary';
import { logPageView } from '../utils/logging';
import HosResps from './HosResps';
import HosRespDetail from './HosRespDetail';
import UserRequestDetail from './UserRequestDetail';

// GA pageview setting
const OdocRedirect = ({ location, to, ...rest }) => {
  logPageView((location && location.pathname) || (to && to.pathname) || to);
  return <Redirect {...rest} to={to} location={location} />;
};

const OdocRoute = ({
  component: Component, location, ...rest
}) => {
  logPageView(location.pathname);
  return (
    <Route
      {...rest}
      render={props => (
        <CommonErrorBoundary {...rest} {...props}>
          <Component {...rest} {...props} />
        </CommonErrorBoundary>
      )}
    />
  );
};

const PrivateRoute = ({ component: Component, location, ...rest }) => (
  isAuthenticated() ? (
    <OdocRoute {...rest} component={Component} location={location} />
  ) : (
    <OdocRedirect
      to={{
        pathname: '/login',
        state: { referrer: location.pathname },
      }}
      {...rest}
    />
  )
);

const ConfirmedOnlyRoute = ({ component: Component, location, ...rest }) => (
  isConfirmedAuthenticated() ? (
    <OdocRoute {...rest} component={Component} location={location} />
  ) : (
    <OdocRedirect
      to={{
        pathname: '/login',
        state: { referrer: location.pathname },
      }}
      {...rest}
    />
  )
);

const AppRoute = ({ component: Component, requireAuth, desktopOnly, ...rest }) => {
  if (requireAuth === 'all') {
    return (
      <AppLayout desktopOnly={desktopOnly}>
        <PrivateRoute {...rest} component={Component} />
      </AppLayout>
    );
  }

  if (requireAuth === 'confirmed') {
    return (
      <AppLayout desktopOnly={desktopOnly}>
        <ConfirmedOnlyRoute {...rest} component={Component} />
      </AppLayout>
    );
  }

  return (
    <AppLayout desktopOnly={desktopOnly}>
      <OdocRoute {...rest} component={Component} />
    </AppLayout>
  );
};

export default () => (
  <BrowserRouter>
    <Switch>
      <OdocRoute
        path="/"
        exact
        component={Login}
      />
      <OdocRoute path="/certification" exact component={Certification} />
      <OdocRoute path="/complimentsignup" exact component={SignupNew} />
      <OdocRoute path="/signup" exact component={SignupNew2} />
      <OdocRoute
        path="/updatepassword/:resetToken/:email"
        component={UpdatePassword}
      />
      {/* 패스워드 reset token 가지고 들어오는 페이지 */}
      <OdocRoute path="/findpassword" component={FindPassword} />
      <OdocRoute path="/findemail" component={FindEmail} />
      <OdocRoute path="/login" exact component={Login} />
      <AppRoute
        requireAuth="confirmed"
        path="/odoctorcash"
        exact
        desktopOnly
        component={OdoctorCash}
      />
      <AppRoute
        requireAuth="confirmed"
        path="/analytics"
        exact
        component={Analytics}
      />
      <AppRoute path="/cs" component={Cs} />
      <AppRoute path="/terms" component={Terms} />
      <AppRoute path="/privacypolicy" component={PrivacyPolicy} />
      <AppRoute
        requireAuth="all"
        path="/myhospital"
        component={MyHospital}
      />
      {/* <AppRoute
        requireAuth="confirmed"
        path="/reservations"
        component={Reservations}
      /> */}
      <AppRoute
        requireAuth="confirmed"
        path="/hosresps"
        exact
        desktopOnly
        component={HosResps}
      />
      <AppRoute
        requireAuth="confirmed"
        path="/hosresps/:hrId"
        exact
        component={HosRespDetail}
      />
      <AppRoute
        requireAuth="confirmed"
        path="/reqlist"
        exact
        component={ReqList}
      />
      <AppRoute
        requireAuth="confirmed"
        path="/reqs/:id"
        exact
        component={UserRequestDetail}
      />
      <AppRoute
        requireAuth="all"
        path="/compliment"
        component={Compliment}
      />
      <Route
        path="/errors"
        component={Errors}
      />
      {/* 404 PAGE */}
      <OdocRoute component={PageNotFound} />
    </Switch>
  </BrowserRouter>
);
