import React from 'react';
import { graphql } from 'react-apollo';
import ProfileCommonModal, {
  InputBox,
  ProfileContentContiner,
} from './ProfileCommonModal';
import PasswordInput from '../form/PasswordInput';
import withToast from '../../utils/toasthoc';
import { checkPasswordMutation } from '../../graphql';
import { ModalMessage, ModalContentContainer, ModalBtnContainer, ModalCancleBtn, ModalConfirmBtn } from '../basic/ModalComponents';

class PasswordCheckModal extends React.Component {
  state = {
    password: '',
    fireSubmitValidation: false,
    errorMsg: '',
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      fireSubmitValidation: false,
      errorMsg: '',
    });
  };

  handleSubmit = async () => {
    const { password } = this.state;

    if (!password) {
      this.setState({ fireSubmitValidation: true });
      return;
    }

    try {
      const res = await this.props.checkPassword({
        variables: {
          password,
        },
      });

      const {
        data: { checkPassword },
      } = res;

      if (checkPassword.ok) {
        this.props.callback();
        this.close();
        return;
      }

      this.setState({ errorMsg: '비밀번호가 일치하지 않습니다.' });
    } catch (e) {}
  };

  close = () => {
    this.setState({
      password: '',
      fireSubmitValidation: false,
      errorMsg: '',
    });
    this.props.onClose();
  };

  render() {
    const { isOpen } = this.props;
    return (
      <ProfileCommonModal
        title="비밀번호 입력"
        isOpen={isOpen}
      >
        <ModalContentContainer>
          <ModalMessage>개인정보보호를 위해 비밀번호를 입력해 주세요</ModalMessage>
          <ProfileContentContiner>
            <InputBox>
              <PasswordInput
                name="password"
                onChange={this.onChange}
                value={this.state.password}
                placeholder="비밀번호 입력"
                noValidate
                fireSubmitValidation={this.state.fireSubmitValidation}
                border
                errorMsg={this.state.errorMsg}
              />
            </InputBox>
          </ProfileContentContiner>  
        </ModalContentContainer>
        <ModalBtnContainer>
          <ModalCancleBtn onClick={this.close}>취소</ModalCancleBtn>
          <ModalConfirmBtn onClick={this.handleSubmit}>확인</ModalConfirmBtn>
        </ModalBtnContainer>
      </ProfileCommonModal>
    );
  }
}

export default graphql(checkPasswordMutation, {
  name: 'checkPassword',
})(withToast(PasswordCheckModal));
