import React, { Component } from 'react';
import { PaddingDiv } from '../basic/HtmlComponents';
import {
  Title,
  SubTitle,
  Textarea,
  AddPlusBtn,
  DisabledPlusBtn,
} from './MyHospitalCommonComponents';

export default class HosCounselWordEditComponent extends Component {
  toggleCheckbox = (e) => {
    const { name, checked } = e.target;

    this.props.onChange(...e, {
      target: {
        name,
        value: checked,
      },
    });
  };

  render() {
    const {
      greeting,
      closing,
      showGreeting,
      showClosing,
      onChange,
      addCounselWord,
      confirmed,
    } = this.props;

    const disabled = !confirmed;

    if (disabled) {
      return (
        <div>
          <Title>상담 안내문구 설정</Title>
          <PaddingDiv padding="14px 0">
            <DisabledPlusBtn
              title="우리 병원만의 차별성과 특별함을 알려줄 맺음말"
            />
          </PaddingDiv>
        </div>
      );
    }

    return (
      <div>
        <Title>상담 안내문구 설정</Title>
        <div>
          <SubTitle>우리 병원만의 차별성과 특별함을 알려줄 맺음말(250자)</SubTitle>
          <Textarea
            name="closing"
            onChange={onChange}
            defaultValue={closing}
            placeholder="상담 맺음말을 입력해주세요"
            height="102px"
            maxLength="250"
          />
        </div>
      </div>
    );
  }
}
