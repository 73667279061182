import React, { Component } from 'react';
import styled from 'styled-components';
import ReqDetail from './ReqDetail';

class UserRequestDetail extends Component {
  render() {
    return <ReqDetail reqId={this.props?.match?.params?.id} />;
  }
}

export default UserRequestDetail;
