import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import { signS3Mutation } from '../graphql';

export function formatFilename(filename) {
  const date = moment().format('YYYYMMDD');
  const randomString = Math.random()
    .toString(36)
    .substring(2, 7);
  const cleanFileName = filename.toLowerCase().replace(/[^a-z0-9]/g, '-');
  const newFilename = `images/${date}-${randomString}-${cleanFileName}`;
  return newFilename.substring(0, 60);
}

export async function uploadToS3(file, signedRequest) {
  const options = {
    headers: {
      'Content-Type': file.type,
    },
  };
  const resp = await axios.put(signedRequest, file, options);
  return resp;
}

export default async function imageUpload(file, apolloClient) {
  if (!file) {
    alert('사진을 등록해주세요.');
    return;
  }

  if (!apolloClient) {
    return;
  }

  const result = await apolloClient.mutate({
    mutation: signS3Mutation,
    variables: {
      filename: formatFilename(file.name),
      filetype: file.type,
    },
  });

  const { signedRequest, url } = result.data.signS3;

  try {
    await uploadToS3(file, signedRequest);
    return url;
  } catch (e) {
    console.error('Image Upload Failed!');
    return null;
  }
}
