import React, { Component } from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import { getComplimentFromName } from '../../graphql';
import { media } from '../../globalStyle';

const ComplimentHeader = styled.div`
  padding: 16px 0;
  background-color: #e1f6fb;
  font-size: 18px;
  text-align: center;
  color: #38c8e5;
  strong {
    color: #1c8daa;
    font-weight: bold;
  }
`;

const Br = styled.br`
  display: none;
  ${media.tablet`
    display: block;
  `};
`;

export default class ComplimentInvitation extends Component {
  render() {
    const { compId } = this.props;

    return (
      <Query query={getComplimentFromName} variables={{ compId }}>
        {({ loading, data }) => {
          if (loading) return null;
          if (!data || !data.getCompliment) return null;
          const { username } = data.getCompliment.fromHuser;
          return (
            <ComplimentHeader>
              <strong>{username}원장님</strong>이 칭찬과 함께 <strong>₩25,000</strong>의 <Br />무료 홍보 비용을 선물하셨어요!
            </ComplimentHeader>
          );
        }}
      </Query>
    );
  }
}
