import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import BSNavigation from '../Navigation/BSNavigation';
import { media } from '../../globalStyle';
import withAuth from '../../utils/authhoc';
import DesktopOnlyContent from './DesktopOnlyContent';

const MainContentWrapper = styled.div`
  width: 100%;
  max-width: 1080px;
  padding-top: 80px;
  margin: 0 auto;
  position: relative;
  background-color: #ffffff;
  ${media.miniDesktop`
    padding-left: 0;
    padding-top: 59px;
    border: 0;
  `};
`;

const MainContentInner = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

class AppLayout extends Component {
  render() {
    const { desktopOnly, children } = this.props;
    return (
      <div>
        <BSNavigation {...this.props} />
        <MainContentWrapper>
          <MainContentInner id="app-right-container">
            {desktopOnly ? <DesktopOnlyContent>{children}</DesktopOnlyContent> : children}
          </MainContentInner>
        </MainContentWrapper>
      </div>
    );
  }
}

export default withAuth(withRouter(AppLayout));
