import React from 'react';
import styled from 'styled-components';
import PlusIcon from '../../images/icons-add-24.svg';
import DisabledPlusIcon from '../../images/icons-add-disable-24.svg';
import { PaddingDiv, marginProps, ScrollBarStyledTextArea } from '../basic/HtmlComponents';
import { media } from '../../globalStyle';

export const TextInput = styled.input`
  width: 100%;
  max-width: ${props => props.width || '400px'};
  height: 48px;
  border-radius: 1px;
  background-color: #ffffff;
  border: solid 1px #e0e0e0;
  padding: 12px 8px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  text-align: ${props => props.textAlign || 'start'};
  &:disabled {
    background-color: #f5f5f5;
    color: #bcbcbc;
  }
`;

export const Textarea = styled(ScrollBarStyledTextArea)`
  width: 100%;
  height: 240px;
  border-radius: 1px;
  background-color: #ffffff;
  border: solid 1px #e0e0e0;
  resize: none;
  padding: 12px 10px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 22px;
  letter-spacing: -1px;
  color: #000000;
  ${props => props.height && `height: ${props.height}`};
  &:disabled {
    background-color: #f7f7f7;
    color: #bcbcbc;
  }
`;

const PlusImg = styled.img`
  vertical-align: middle;
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-left: 14px;
  cursor: pointer;
`;

const DisabledPlusImg = styled(PlusImg)`
  cursor: default;
`;

export const AddPlusBtn = props => (
  <SubTitle>
    {props.title} <PlusImg {...props} src={PlusIcon} alt="추가하기" />
  </SubTitle>
);

export const DisabledPlusBtn = props => (
  <SubTitle>
    {props.title} <DisabledPlusImg disabled src={DisabledPlusIcon} alt="추가하기" />
  </SubTitle>
);

export const SubTitle = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  margin-bottom: 20px;
  span {
    display: inline-block;
    vertical-align: middle;
    margin-left: 4px;
    opacity: 0.78;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -1px;
    color: #f6501b;
  }
  ${marginProps}
`;

export const Section = styled.div`
  & ~ & {
    margin-top: 26px;
  }
`;

export const SubSection = styled.div`
  & ~ & {
    margin-top: 8px;
  }
`;

export const LeftTitle = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  margin-right: 10px;
  min-width: 60px;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: -1px;
  color: #000000;
  margin-bottom: 30px;
`;

const MyHospitalCommonComponents = {};

export default MyHospitalCommonComponents;
