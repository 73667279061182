import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter, Link } from 'react-router-dom';
import { graphql } from 'react-apollo';
import { media } from '../globalStyle';
import {
  FormBackground,
  FormWrapper,
  FormTitle,
  FormSubTitle,
  FormContent,
  FormInputRow,
  FormBtnContainer,
  FormConfirmBtn,
  FormInputRowTitle,
} from '../components/basic/HtmlComponents';
import AuthFooter from '../components/AuthFooter';
import FormInput from '../components/form/FormInput';
import PhonenumInput from '../components/form/PhonenumInput';
import { findEmailForHuserMutation } from '../graphql';
import withAlertModal from '../utils/alerthoc';

const FooterContainer = styled.div`
  margin-top: 112px;
`;

const StyledLink = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  a {
    &:hover {
      text-decoration: underline;
    }
    color: inherit;
  }
  ${media.miniDesktop`
    font-size: 14px;
  `};
`;

const ErrorInfo = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.7px;
  color: #bbbcbc;
  margin-top: 18px;
`;

const Desc = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.7px;
  color: #bbbcbc;
  margin: 6px 0 30px;
`;

const EmailView = styled.div`
  width: 100%;
  height: 57px;
  background-color: rgba(255, 255, 255, 0.12);
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  color: #033e52;
`;

const Blue = styled.span`
  font-weight: bold;
  color: #033e52;
`;

class FindEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      phonenum: '',
      email: '',
      fireSubmitValidation: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange({ target }) {
    const { name, value } = target;

    this.setState({
      [name]: value,
      fireSubmitValidation: false,
    });
  }

  handleSubmit = async () => {
    const { name, phonenum } = this.state;
    if (!name || !phonenum || !(phonenum.length === 10 || phonenum.length === 11)) {
      this.setState({ fireSubmitValidation: true });
      return;
    }

    // email 찾기 API
    const res = await this.props.findEmailForHuser({
      variables: {
        username: name,
        phonenum,
      },
    });

    if (res.data.findEmailForHuser) {
      this.setState({ email: res.data.findEmailForHuser });
    } else {
      this.props.openAlert({
        title: '이메일 찾기 오류',
        message: (
          <div>
            <Blue>{`${name}/${phonenum}`}</Blue>로 찾은 결과, 일치하는 오닥터 계정이 없습니다. 다시
            시도하거나 회원가입을 진행해 주세요.
          </div>
        ),
        showConfirmBtn: true,
        confirmBtnName: '확인',
        textAlign: 'left',
        confirmBtnOnClick: this.props.closeAlert,
      });
    }
  };

  render() {
    const { name, phonenum, fireSubmitValidation, email } = this.state;
    return (
      <FormBackground>
        <FormWrapper>
          {email ? (
            <div>
              <FormTitle>이메일 확인</FormTitle>
              <FormSubTitle>고객님의 정보와 일치하는 이메일이 확인되었습니다.</FormSubTitle>
              <FormContent>
                <EmailView>{email}</EmailView>
                <FormBtnContainer>
                  <FormConfirmBtn onClick={() => this.props.history.push('/login')}>
                    로그인
                  </FormConfirmBtn>
                </FormBtnContainer>
                <Link style={{ textAlign: 'right' }}>
                  <Link to="/findpassword">
                    비밀번호찾기
                  </Link>
                </Link>
              </FormContent>
            </div>
          ) : (
            <div>
              <FormTitle>이메일 찾기</FormTitle>
              <FormSubTitle>
                이메일을 찾기 위해 회원정보에 등록한 이름과 휴대폰번호를 입력해 주세요.
              </FormSubTitle>
              <FormContent>
                <FormInputRow>
                  <FormInputRowTitle>이름</FormInputRowTitle>
                  <FormInput
                    name="name"
                    onChange={this.onChange}
                    value={name}
                    placeholder="이름"
                    fireSubmitValidation={fireSubmitValidation}
                  />
                </FormInputRow>
                <FormInputRow>
                  <FormInputRowTitle>휴대폰번호</FormInputRowTitle>
                  <PhonenumInput
                    name="phonenum"
                    onChange={this.onChange}
                    value={phonenum}
                    placeholder="회원가입 시 등록한 휴대폰번호"
                    fireSubmitValidation={fireSubmitValidation}
                  />
                </FormInputRow>
                <Desc>
                  회원정보에 등록한 이름과 휴대폰 번호가 같아야 이메일을 찾을 수 있습니다.
                  휴대폰번호는 숫자만 입력해 주세요.
                </Desc>
                <FormBtnContainer>
                  <FormConfirmBtn disabledView={!name || !phonenum} onClick={this.handleSubmit}>
                    이메일 찾기
                  </FormConfirmBtn>
                </FormBtnContainer>
                <StyledLink>
                  <Link to="/login">
                    로그인으로 돌아가기
                  </Link>
                  <Link style={{ float: 'right' }} to="/findpassword">
                    비밀번호찾기
                  </Link>
                </StyledLink>
                <ErrorInfo>
                  아이디 찾기에 문제가 있는 경우 partner@odoctor.co.kr로 문의해주세요
                </ErrorInfo>
              </FormContent>
            </div>
          )}
        </FormWrapper>
        <FooterContainer>
          <AuthFooter />
        </FooterContainer>
      </FormBackground>
    );
  }
}

export default graphql(findEmailForHuserMutation, {
  name: 'findEmailForHuser',
})(withRouter(withAlertModal(FindEmail)));
