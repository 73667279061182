import React, { Component } from 'react';
import { compose, graphql } from 'react-apollo';
import { deleteCompliment, updateCompliment } from '../../graphql';
import withToast from '../../utils/toasthoc';
import CloseImg from '../../images/ic-close-24.svg';
import {
  ComplimentStyledModal,
  CloseBtn,
  ModalHeader,
  ModalBody,
  ModalBodySection,
  ModalBodyTitle,
  TextBox,
  DescTextBox,
  DescTextInnerBox,
  ButtonContainer,
  Button,
  ConfirmBtnContainer,
  ConfirmBtn,
  EditBtn,
  ComplimentTextarea,
  DescCountNum,
} from './ComplimentCommonComponents';

class ComplimentViewModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      desc: '',
      fireSubmitValidation: false,
      isEditing: false,
    };
  }

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value, fireSubmitValidation: false });
  };

  handleEdit = () => {
    this.setState({ isEditing: true });
    this.setState({ desc: this.props.compliment.desc });
  }

  handleCancel = () => {
    this.setState({ isEditing: false });
    this.setState({ desc: this.props.compliment.desc });
    this.props.closeModal();
  }

  handleSubmit = async () => {
    if (this.props.compliment.status === 'published') {
      this.props.closeModal();
      return;
    }

    const { desc, isEditing } = this.state;

    if (!isEditing) {
      this.props.closeModal();
      return;
    }

    // validation check
    if (!desc || desc.length < 50) {
      this.setState({ fireSubmitValidation: true });
      return;
    }

    const resp = await this.props.updateCompliment({
      variables: {
        compId: this.props.compliment.id,
        desc: this.state.desc,
      }
    });

    if (resp.data && resp.data.updateCompliment) {
      this.props.toast('칭찬을 수정하였습니다.', 'info');
    } else {
      this.props.toast('이미 공개된 칭찬은 수정 불가능합니다.', 'error');
    }

    this.setState({ desc: '', fireSubmitValidation: false, isEditing: false });
    this.props.closeModal();
  };

  render() {
    const {
      isOpen,
      compliment,
      closeModal,
      type,
      openComplimentReplyModal,
    } = this.props;

    if (!isOpen || !compliment) {
      return null;
    }

    // 받은칭찬
    if (type === 'received') {
      return (
        <ComplimentStyledModal isOpen={isOpen}>
          <CloseBtn src={CloseImg} alt="closeImg" onClick={closeModal} />
          <ModalHeader>칭찬 한마디</ModalHeader>
          <ModalBody>
            <ModalBodySection>
              <ModalBodyTitle>이름</ModalBodyTitle>
              <TextBox>{compliment.fromHuser.username}</TextBox>
            </ModalBodySection>
            <ModalBodySection>
              <ModalBodyTitle>소속병원</ModalBodyTitle>
              <TextBox>{compliment.fromHuser.hospital.name}</TextBox>
            </ModalBodySection>
            <ModalBodySection>
              <ModalBodyTitle>칭찬 한마디</ModalBodyTitle>
              <DescTextBox>
                <DescTextInnerBox>{compliment.desc}</DescTextInnerBox>
              </DescTextBox>
            </ModalBodySection>
            <ButtonContainer>
              <Button onClick={openComplimentReplyModal}>답변하기</Button>
            </ButtonContainer>
          </ModalBody>
        </ComplimentStyledModal>
      );
    }

    // 보낸 칭찬
    if (type === 'send') {
      return (
        <ComplimentStyledModal isOpen={isOpen}>
          <CloseBtn src={CloseImg} alt="closeImg" onClick={closeModal} />
          <ModalHeader>칭찬 한마디</ModalHeader>
          <ModalBody>
            <ModalBodySection>
              <ModalBodyTitle>이름</ModalBodyTitle>
              <TextBox>
                {compliment.toHuser
                  ? compliment.toHuser.username
                  : compliment.toName}
              </TextBox>
            </ModalBodySection>
            <ModalBodySection>
              <ModalBodyTitle>소속병원</ModalBodyTitle>
              <TextBox>
                {compliment.toHuser && compliment.toHuser.hospital
                  ? compliment.toHuser.hospital.name
                  : compliment.toHosName || '병원 정보 없음'}
              </TextBox>
            </ModalBodySection>
            <ModalBodySection>
              <ModalBodyTitle>칭찬 한마디</ModalBodyTitle>
              
                {this.state.isEditing ? (
                  <React.Fragment>
                    <ComplimentTextarea
                      onChange={this.handleChange}
                      value={this.state.desc}
                      fireSubmitValidation={this.state.fireSubmitValidation}
                    />
                    <DescCountNum>
                      <span>{this.state.desc.length}자</span> / 1000자 (최소 50자)
                    </DescCountNum>
                  </React.Fragment>
                ) : (
                  <DescTextBox>
                    <DescTextInnerBox>{compliment.desc}</DescTextInnerBox>
                  </DescTextBox>
                )}
              
            </ModalBodySection>
            <ConfirmBtnContainer>
              {this.state.isEditing && <EditBtn onClick={this.handleCancel.bind(this)}>취소</EditBtn>}
              {!this.state.isEditing && compliment.status !== 'published' && (
                <EditBtn onClick={this.handleEdit.bind(this)}>수정</EditBtn>
              )}
              <ConfirmBtn onClick={this.handleSubmit.bind(this)}>확인</ConfirmBtn>
            </ConfirmBtnContainer>
          </ModalBody>
        </ComplimentStyledModal>
      );
    }

    return null;
  }
}

export default compose(
  graphql(updateCompliment, { name: 'updateCompliment' }),
  graphql(deleteCompliment, { name: 'deleteCompliment' }),
)(withToast(ComplimentViewModal));
