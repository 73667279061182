import React, { Component } from 'react';
import AlertModal from '../components/AlertModal';

// HOC - 알림 모달 재활용.
function withAlertModal(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isOpen: false,
        title: '',
        message: '',
        showConfirmBtn: false,
        confirmBtnName: '',
        confirmBtnOnClick: () => {},
        showCancelBtn: false,
        cancleBtnName: '',
        cancleBtnOnClick: () => {},
        onClose: this.closeAlert.bind(this),
        textAlign: 'center',
        hideCloseBtn: false,
      };
    }

    openAlert = ({
      title = '알림',
      message = '',
      showConfirmBtn = true,
      confirmBtnName = '확인',
      confirmBtnOnClick = this.closeAlert.bind(this),
      showCancelBtn = false,
      cancleBtnName = '취소',
      cancleBtnOnClick = this.closeAlert.bind(this),
      textAlign = 'center',
      hideCloseBtn = false,
      onClose = this.closeAlert,
    }) => {
      this.setState({
        isOpen: true,
        title,
        message,
        showConfirmBtn,
        confirmBtnName,
        confirmBtnOnClick: () => {
          confirmBtnOnClick();
          this.closeAlert();
        },
        showCancelBtn,
        cancleBtnName,
        cancleBtnOnClick: () => {
          cancleBtnOnClick();
          this.closeAlert();
        },
        textAlign,
        onClose: () => {
          onClose();
          this.closeAlert();
        },
        hideCloseBtn,
      });
    };

    closeAlert = () => {
      this.setState({
        isOpen: false,
        title: '',
        message: '',
        showConfirmBtn: false,
        confirmBtnName: '',
        confirmBtnOnClick: () => {},
        showCancelBtn: false,
        cancleBtnName: '',
        cancleBtnOnClick: () => {},
        onClose: this.closeAlert.bind(this),
        textAlign: 'center',
        hideCloseBtn: false,
      });
    };

    render() {
      return (
        <React.Fragment>
          <WrappedComponent
            {...this.props}
            openAlert={this.openAlert}
            closeAlert={this.closeAlert}
          />
          <AlertModal
            isOpen={this.state.isOpen}
            title={this.state.title}
            onClose={this.state.onClose}
            message={this.state.message}
            showConfirmBtn={this.state.showConfirmBtn}
            confirmBtnName={this.state.confirmBtnName}
            confirmBtnOnClick={this.state.confirmBtnOnClick}
            showCancelBtn={this.state.showCancelBtn}
            cancleBtnName={this.state.cancleBtnName}
            cancleBtnOnClick={this.state.cancleBtnOnClick}
            textAlign={this.state.textAlign}
            hideCloseBtn={this.state.hideCloseBtn}
          />
        </React.Fragment>
      );
    }
  };
}

export default withAlertModal;
