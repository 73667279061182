import React, { Component } from 'react';
import styled from 'styled-components';
import Selector from '../form/Selector';
import withToast from '../../utils/toasthoc';
import {
  updateCheckedRespCallStateMutation,
  getHosRespsDetailForPartnerQuery,
} from '../../graphql';
import {
  RespCallStateOptions,
  LeftRow,
  LeftLabel,
} from '../HosResps/HosRespsCommonComponents';

const MemoTextarea = styled.textarea`
  width: 100%;
  height: 100%;
  padding: 20px 16px 10px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -1px;
  text-align: justify;
  color: #000000;
  border-radius: 4px;
  border: solid 1px #e1e3e2;
  resize: none;
`;

const Button = styled.button`
  width: 100%;
  height: 100%;
  border: solid 1px #033e52;
  background-color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: -1px;
  text-align: center;
  color: #033e52;
  border-radius: 4px;
  &.blue {
    background-color: #033e52;
    color: #ffffff;
    font-weight: bold;
  }
  &:disabled {
    border: none;
    border-radius: 4px;
    background-color: #e1e3e2;
  }
  /* & ~ & {
    margin-left: 8px;
  } */
`;

class HosRespMemoEditorInDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      memo: props.initMemo,
      initMemo: props.initMemo,
      callState: props.initCallState,
      initCallState: props.initCallState,
    };
  }
  render() {
    const {
      caId,
      initCallState,
      initMemo,
      saveMemo,
      toast,
      setEdited,
    } = this.props;
    const { memo, callState } = this.state;
    return (
      <>
        <LeftRow style={{ marginTop: 60 }}>
          <LeftLabel style={{ lineHeight: '46px' }}>연락현황</LeftLabel>
          <div style={{ width: 157, height: 46, padding: 0, margin: 0 }}>
            <Selector
              value={callState}
              items={RespCallStateOptions}
              renderItem={(option, index) => (
                <option key={index} value={option.value}>
                  {option.labelName}
                </option>
              )}
              name='callState'
              onChange={({ target }) => {
                this.setState(
                  {
                    callState: target.value,
                  },
                  setEdited(true),
                );
              }}
            />
          </div>
        </LeftRow>
        <LeftRow style={{ marginTop: 24 }}>
          <LeftLabel style={{ paddingTop: 7 }}>메모</LeftLabel>
          <div>
            <div style={{ height: 124, width: 422 }}>
              <MemoTextarea
                value={memo}
                onChange={({ target }) => {
                  this.setState(
                    {
                      memo: target.value,
                    },
                    setEdited(true),
                  );
                }}
                maxLength='200'
              />
            </div>
            <div style={{ width: 140, height: 56, marginTop: 30 }}>
              <Button
                disabled={initMemo === memo && callState === initCallState}
                className='blue'
                onClick={async () => {
                  try {
                    await saveMemo(caId, memo, callState);
                    setEdited(false);
                    toast('메모 저장 완료!', 'info');
                    this.setState({
                      initCallState: callState,
                      initMemo: memo,
                    });
                  } catch (e) {
                    alert('다시 시도해주세요!');
                  }
                }}>
                저장
              </Button>
            </div>
          </div>
        </LeftRow>
      </>
    );
  }
}

export default withToast(HosRespMemoEditorInDetail);
