import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql } from 'react-apollo';
import CloseImg from '../../images/ic-close-24.svg';
import {
  ComplimentStyledModal,
  CloseBtn,
  ModalHeader,
  ModalBody,
  ModalBodySection,
  ModalBodyTitle,
  TextBox,
  ButtonContainer,
  Button,
  DescCountNum,
  ComplimentTextarea,
} from './ComplimentCommonComponents';
import CheckboxUncheckedIcon from '../../images/checkbox-unchecked-32.svg';
import CheckboxDisabledIcon from '../../images/checkbox-disabled-32.svg';
import CheckboxCheckedIcon from '../../images/checkbox-checked-32.svg';
import { addComplimentMutation, myComplimentsToOtherQuery } from '../../graphql';
import { FlexRow } from '../basic/HtmlComponents';
import { sendComplimentLink } from '../../utils/kakao';
import { ModalCloseBtn, ModalTitle } from '../basic/ModalComponents';



const HospitalCheckbox = styled.input`
  display: none;
  :checked + label {
    background-image: url(${CheckboxCheckedIcon});
    ${props => props.disabled && `background-image: url(${CheckboxDisabledIcon});`};
  }
  ${props =>
    props.error &&
    `
    border-color: red;
  `};
`;

const HospitalCheckboxIcon = styled.label`
  width: 20px;
  height: 20px;
  background-image: url(${CheckboxUncheckedIcon});
  background-size: cover;
  vertical-align: middle;
  cursor: pointer;
  ${props =>
    props.disabled &&
    `
    background-image: url(${CheckboxDisabledIcon});
    cursor: auto;
  `};
  ${props =>
    props.error &&
    `
    display: inline-block;
    background: none;
    width: 17.5px;
    height: 17.5px;
    border: solid 1px #f6501b;
    border-radius: 3px;
  `};
`;

const HospitalCheckboxLabel = styled.label`
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #8a8a8a;
  cursor: pointer;
  ${props => props.disabled && 'cursor: auto'};
`;

const AgreeMsg = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #8a8a8a;
`;
class ComplimentReplyModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      desc: '',
      fireSubmitValidation: false,
      agree: false,
    };
  }

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value, fireSubmitValidation: false });
  };

  handleSubmit = async () => {
    // validation check
    const { desc, agree } = this.state;
    const { compliment } = this.props;

    if (!desc || desc.length < 50 || !agree) {
      this.setState({ fireSubmitValidation: true });
      return;
    }

    const variables = {
      toId: compliment.fromHuser.id,
      desc: this.state.desc,
    };

    try {
      const res = await this.props.addCompliment({
        variables,
      });

      const { addCompliment } = res.data;

      if (addCompliment) {
        if (addCompliment.error) {
          this.props.toast(<div>칭찬 답변 오류</div>, 'warn');
          return;
        }

        const { id, fromHuser: { username }, toHuser, toName } = addCompliment.compliment;
        
        // 카카오링크로 칭찬 공유
        const kakaoLinkRes = await sendComplimentLink({complimentId: id, from: username, to: (toHuser && toHuser.username) || toName});
      }
    } catch (e) {
      
    }
    this.closeModal();
  };

  closeModal = () => {
    this.setState({ desc: '', fireSubmitValidation: false, agree: false });
    this.props.closeModal();
  };

  render() {
    const { isOpen, compliment } = this.props;

    if (!isOpen || !compliment) {
      return null;
    }

    return (
      <ComplimentStyledModal isOpen={isOpen}>
        <ModalCloseBtn src={CloseImg} alt="closeImg" onClick={this.closeModal} />
        <ModalTitle>칭찬 답변하기</ModalTitle>
        <ModalBody>
          <ModalBodySection>
            <ModalBodyTitle>이름</ModalBodyTitle>
            <TextBox>{compliment.fromHuser.username}</TextBox>
          </ModalBodySection>
          <ModalBodySection>
            <ModalBodyTitle>소속병원</ModalBodyTitle>
            <TextBox>{compliment.fromHuser.hospital.name}</TextBox>
          </ModalBodySection>
          <ModalBodySection>
            <ModalBodyTitle>칭찬 한마디</ModalBodyTitle>
            <ComplimentTextarea
              onChange={this.handleChange}
              value={this.state.desc}
              fireSubmitValidation={this.state.fireSubmitValidation}
            />
            <DescCountNum>
              <span>{this.state.desc.length}자</span> / 1000자 (최소 50자)
            </DescCountNum>
          </ModalBodySection>
          <ModalBodySection>
            <FlexRow justifyContent="space-between" alignItems="center">
              <div>
                <HospitalCheckbox
                  type="checkbox"
                  id="agree"
                  checked={this.state.agree}
                  onChange={() =>
                    this.setState({
                      agree: !this.state.agree,
                      fireSubmitValidation: false,
                    })
                  }
                />
                <HospitalCheckboxIcon
                  htmlFor="agree"
                  error={this.state.fireSubmitValidation && !this.state.agree}
                />
                <HospitalCheckboxLabel htmlFor="agree">동의</HospitalCheckboxLabel>
              </div>
              <AgreeMsg>위와 같이 칭찬 메세지를 전송합니다.</AgreeMsg>
            </FlexRow>
          </ModalBodySection>
          <ButtonContainer>
            <Button onClick={this.handleSubmit}>칭찬 보내고 공유하기</Button>
          </ButtonContainer>
        </ModalBody>
      </ComplimentStyledModal>
    );
  }
}

export default graphql(addComplimentMutation, {
  name: 'addCompliment',
})(ComplimentReplyModal);
