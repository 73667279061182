import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { graphql } from 'react-apollo';
import styled from 'styled-components';

import { logout } from '../../utils/auth';
import ProfilePhoto from '../../images/img-profile-default-80.svg';
import Logo from '../../images/logo.png';
import CloseIcon from '../../images/ic-close-44.png';
import MenuIcon from '../../images/menu.png';
import ConsultImg from '../../images/ic-consult-28.png';
import ReservationImg from '../../images/ic-reservation-28.png';
import HospitalImg from '../../images/ic-hospital-28.png';
import CashImg from '../../images/ic-noti-28.png';
import { media } from '../../globalStyle';
import { announcementsListQuery } from '../../graphql';
import PartnerGuideModal from '../PartnerGuideModal';
import NavChargeStateQuery from '../queries/NavChargeStateQuery';

const LogoImage = styled.img`
  width: 90px;
  height: 18px;
  object-fit: contain;
  vertical-align: middle;
`;

const AppName = styled.div`
  margin-left: 8px;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1.2px;
  color: #033e52;
  display: inline-block;
  vertical-align: middle;
`;

const StyledMobileHeader = styled.div`
  display: none;
  background-color: #ffffff;
  width: 100%;
  padding: 20px;
  text-align: left;
  top: 0;
  ${media.miniDesktop`
    display: block;
  `};
`;

const Wrapper = styled.div`
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 320px;
  padding-right: 0;
  padding-left: 0;
  padding-top: 10px;
  padding-bottom: 20px;
  left: 0;
  bottom: 0;
  position: fixed;
  float: none;
  z-index: 11;
  background-color: #fafafa;
  top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  border-right: 1px solid #e0e0e0;
  display: none;
  ${media.miniDesktop`
    display: flex;
    background-color: #ffffff;
    border: 0;
    width: 100%;
    top: 0;
    bottom: unset;
    bottom: auto;
    padding: 0;
    height: auto;
    flex: 1;
    overflow: hidden;
    ${props => props.open
      && `
      height: 100vh;
      padding: 0;
      padding-bottom: 60px;
    `};
  `};

  ${AppName}, ${LogoImage} {
    ${props => props.open
      && `
      visibility: hidden;
    `};
  }
`;

const MenuContainer = styled.div`
  ${media.miniDesktop`
    display: none;
    ${props => props.open
      && `
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: auto;
      padding: 0 30px 60px;
    `};
  `};
`;

const MainMenuList = styled.ul`
  padding-left: 0;
  list-style-type: none;
`;

const MenuLi = styled.li`
  width: 100%;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: -0.8px;
  color: #000000;
  min-height: 88px;
  position: relative;
  display: ${props => (props.invisible ? 'none' : 'block')};
  ${media.miniDesktop`
    min-height: unset;
    min-height: auto;
  `};
`;

const ChargeMobileMenu = styled(MenuLi)`
  margin-top: 22px;
  min-height: 0;
  display: none;
  ${media.miniDesktop`
    display: block;
  `};
`;


const Image = styled.img`
  width: 28px;
  height: 28px;
  object-fit: contain;
  margin-right: 21px;
  vertical-align: middle;
  ${media.miniDesktop`
    width: 24px;
    height: 24px;
    margin-right: 14px;
  `};
`;

const LinkMenu = styled(NavLink)`
  width: 100%;
  display: block;
  height: 100%;
  background-color: transparent;
  color: inherit;
  padding: 30px 20px 30px 40px;
  border-right: solid 4px transparent;
  span {
    display: inline-block;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: -0.8px;
    color: #000000;
    vertical-align: middle;
  }
  &:hover {
    background-color: #ffffff;
  }
  &.active {
    span {
      font-weight: bold;
      color: #033e52;
    }
    img {
      ${props => (props.activelmg ? `content: url(${props.activelmg});` : '')};
    }
  }
  ${media.miniDesktop`
    padding: 10px 0;
    &:hover, &:focus {
      border-color: transparent;
      background: inherit;
    }
    img {
      ${props => (props.img ? `content: url(${props.img});` : '')};
    }
    &.active {
      border-color: transparent;
      background: inherit;
      span {
        font-weight: normal;
        font-size: 14px;
        line-height: 1.29;
        letter-spacing: -0.9px;
        color: #000000;
      }
      img {
        ${props => (props.img ? `content: url(${props.img});` : '')};
      }
    }
    span {
      font-weight: normal;
      font-size: 14px;
      line-height: 1.29;
      letter-spacing: -0.9px;
      color: #000000;
    }
  `};
`;

const CloseImg = styled.img`
  width: 22;
  height: 22px;
  object-fit: contain;
  cursor: pointer;
  float: right;
  ${media.miniDesktop`
    position: absolute;
    right: 20px;
  `};
`;

const UserName = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #000000;
`;

const MyInfoContainer = styled.div`
  ${media.miniDesktop`
    width: 100%;
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    margin-bottom: 34px;
  `};
`;

const PhotoContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  min-width: 40px;
  img {
    vertical-align: middle;
    width: 40px;
    height: 40px;
    object-fit: contain;
    border-radius: 50px;
    background-color: #d8d8d8;
  }
`;

const InfoRightContainer = styled.div`
  display: flex;
  width: 100%;
  vertical-align: middle;
  align-items: center;
  justify-content: space-between;
`;

const InfoNameContainer = styled.div`
  height: 44px;
  width: 100%;
`;

const HosName = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
`;

const BlueU = styled.u`
  color: #033e52;
`;

const BtnMenuContainer = styled.div`
  width: 100%;
  padding: 0;
  margin-top: 40px;
`;

const GuideModalBtn = styled.div`
  width: 100%;
  border: solid 1px #e0e0e0;
  padding: 14px 20px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #000000;
  cursor: pointer;
`;

const BMFileDownloadBtnMenu = styled.div`
  width: 100%;
  border: solid 1px #e0e0e0;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #000000;
  cursor: pointer;
  a,
  a:active,
  a:link,
  a:focus {
    width: 100%;
    display: block;
    color: #000000;
    padding: 14px 20px;
    text-decoration: none;
  }
  margin-top: 10px;
  ${media.miniDesktop`
    padding: 0;
  `};
`;

const MobileHeader = ({ open, goToMain, menuToggle }) => (
  <StyledMobileHeader className="smallVisible">
    <LogoImage onClick={goToMain} src={Logo} />
    <AppName onClick={goToMain}>파트너 병원</AppName>
    <CloseImg src={open ? CloseIcon : MenuIcon} onClick={menuToggle} />
  </StyledMobileHeader>
);

const ChargeStateMenu = ({ goToCash }) => (
  <ChargeMobileMenu>
    <NavChargeStateQuery isMobile goToCash={goToCash}/>
  </ChargeMobileMenu>
);

const Menu = ({
  onClick = () => null, to = '/login', name = '이름을 적어주세요', icon, visible = true,
}) => {
  if (visible) {
    return (
      <MenuLi onClick={onClick}>
        <LinkMenu to={to}>
          {icon ? <Image src={icon} /> : null}
          <span className="text">{name}</span>
        </LinkMenu>
      </MenuLi>
    );
  }

  return null;
};

// 로그인 한 유저의 메뉴
const loginUserMenuList = [
  {
    to: '/myhospital',
    name: '나의정보',
    icon: HospitalImg,
  },
  {
    to: '/reqlist',
    name: '상담관리',
    icon: ConsultImg,
    requireRole: 'confirmed',
  },
  {
    to: '/hosresps',
    name: '답변관리',
    icon: ReservationImg,
  },
];

// 로그인 한 유저의 서브메뉴
const loginUserSubMenuList = [
  {
    to: '/myhospital',
    name: <BlueU>+ 병원인증하기</BlueU>,
    requireRole: 'unconfirmed',
  },
  {
    to: '/cs',
    name: '고객센터',
  },
];

// 로그인 하지 않은 유저의 메뉴
const logoutUserMenuList = [
  {
    to: '/terms',
    name: '이용약관',
    icon: CashImg,
  },
  {
    to: '/privacypolicy',
    name: '개인정보처리방침',
    icon: CashImg,
  },
];

// 로그인 하지 않은 유저의 서브메뉴
const logoutUserSubMenuList = [
  {
    to: '/cs',
    name: '고객센터',
  },
  {
    to: '/login',
    name: '로그인',
  },
];

// 로그인 정보 header
const MyInfoHeader = ({
  photo = ProfilePhoto,
  nameComp = <HosName>로그인해주세요</HosName>,
}) => (
  <MyInfoContainer>
    <PhotoContainer>
      <img src={photo} alt="" />
    </PhotoContainer>
    <InfoRightContainer>
      <InfoNameContainer>{nameComp}</InfoNameContainer>
    </InfoRightContainer>
  </MyInfoContainer>
);

class MobileDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false, isOpenGuideModal: false };
  }

  handleLogOut() {
    const { updateMeh, history } = this.props;
    logout();
    updateMeh(null);
    history.push('/login');
  }

  render() {
    const { meh, history } = this.props;
    const { open, isOpenGuideModal } = this.state;

    // 비로그인 메뉴 구성
    let loginInfo = (
      <MyInfoHeader />
    );

    let menus = (
      <MainMenuList>
        {/* 상단 메뉴 */}
        {logoutUserMenuList.map((menuInfo, index) => (
          <Menu
            key={index}
            onClick={() => this.setState({ open: false })}
            to={menuInfo.to}
            name={menuInfo.name}
            icon={menuInfo.icon}
            key={`drawer-menu-${menuInfo.name}`}
          />
        ))}
        <hr />

        {/* 하위메뉴 */}
        {logoutUserSubMenuList.map((menuInfo, index) => (
          <Menu
            key={index}
            onClick={() => this.setState({ open: false })}
            to={menuInfo.to}
            name={menuInfo.name}
          />
        ))}
      </MainMenuList>
    );

    // 로그인시 메뉴 구성
    if (meh) {
      const {
        photos, status, hospital, username,
      } = meh;
      const photo = photos && photos.length > 0 ? photos[0].url : ProfilePhoto;

      loginInfo = (
        <MyInfoHeader
          photo={photo}
          nameComp={(
            <React.Fragment>
              <HosName>{hospital.name}</HosName>
              <UserName>{username}</UserName>
            </React.Fragment>
          )}
        />
      );

      menus = (
        <MainMenuList>
          {/* 상단메뉴 */}
          {loginUserMenuList.map((menuInfo, index) => (
            <Menu 
              key={index}
              onClick={() => this.setState({ open: false })}
              to={menuInfo.to}
              name={menuInfo.name}
              icon={menuInfo.icon}
              visible={menuInfo.requireRole && status ? menuInfo.requireRole === status : true}
            />
          ))}

          {/* 충전금 메뉴, 현황 */}
          <ChargeStateMenu
            goToCash={() => {
              this.setState({ open: false });
              history.push({
                pathname: '/odoctorcash',
                state: { openedTab: 'list' },
              });
            }}
            // goToCashHistory={(e) => {
            //   e.stopPropagation();
            //   this.setState({ open: false });
            //   history.push({
            //     pathname: '/odoctorcash',
            //     state: { openedTab: 'list' },
            //   });
            // }}
            // goToChargeCash={(e) => {
            //   e.stopPropagation();
            //   this.setState({ open: false });
            //   history.push({
            //     pathname: '/odoctorcash',
            //     state: { openedTab: 'charge' },
            //   });
            // }}
          />

          <hr />

          {/* 하위메뉴 */}
          {loginUserSubMenuList.map((menuInfo, index) => (
            <Menu
              key={index}
              onClick={() => this.setState({ open: false })}
              to={menuInfo.to}
              name={menuInfo.name}
              visible={menuInfo.requireRole && status ? menuInfo.requireRole === status : true}
            />
          ))}

          {/* 로그아웃메뉴 */}
          <Menu
            to=""
            onClick={() => {
              this.handleLogOut();
              this.setState({ open: false });
            }}
            name="로그아웃"
          />
        </MainMenuList>
      );
    }

    return (
      <Wrapper open={open}>
        {/* header */}
        <MobileHeader
          open={open}
          goToMain={() => history.push('/reqlist')}
          menuToggle={() => this.setState({ open: !open })}
        />

        {/* 메뉴 */}
        <MenuContainer open={open}>
          {/* 로그인정보,  */}
          {loginInfo}

          {/* 메뉴들 */}
          {menus}

          {/* 버튼식 메뉴 */}
          <BtnMenuContainer>
            <GuideModalBtn
              onClick={() => this.setState({ isOpenGuideModal: true })}
            >
              파트너 이용가이드
            </GuideModalBtn>
            <BMFileDownloadBtnMenu>
              <a
                href="https://odoctor-assets.s3.ap-northeast-2.amazonaws.com/files/odoctor_%E1%84%91%E1%85%A1%E1%84%90%E1%85%B3%E1%84%82%E1%85%A5%E1%84%80%E1%85%AA%E1%84%80%E1%85%B3%E1%86%B7%E1%84%8B%E1%85%A1%E1%86%AB%E1%84%82%E1%85%A2.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                파트너 과금안내
              </a>
            </BMFileDownloadBtnMenu>
          </BtnMenuContainer>
        </MenuContainer>
        <PartnerGuideModal
          isOpen={isOpenGuideModal}
          close={() => this.setState({ isOpenGuideModal: false })}
        />
      </Wrapper>
    );
  }
}

export default graphql(announcementsListQuery, {
  name: 'announcementsList',
  options: {
    variables: { forWho: 'partner' },
  },
})(MobileDrawer);
