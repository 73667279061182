import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql } from 'react-apollo';
import _ from 'lodash';
import { Title, SubTitle, TextInput, SubSection, LeftTitle, Section, Textarea } from './MyHospitalCommonComponents';
import AddPhotoWidget from './AddPhotoWidget';
import { HosAddInfoImage } from '../../containers/MyHospital/HosBasicInfoContainer';
import CloseWhite from '../../images/ic-close-white-20.svg';
import { Hr, marginProps } from '../basic/HtmlComponents';
import { media } from '../../globalStyle';
import { deleteHospitalPhotoMutation, myHospitalQuery } from '../../graphql';
import withToast from '../../utils/toasthoc';
import withAlertModal from '../../utils/alerthoc';

const AdditionalInfo = styled.div`
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  margin-right: 30px;
  cursor: pointer;
  img {
    vertical-align: middle;
    width: 64px;
    height: 64px;
    object-fit: contain;
  }
  ${props => props.disabled && `
    cursor: default;
  `};
`;

const CheckLabel = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 20px;
  background-color: #ffffff;
  border: solid 1px #b2b4b2;
  padding: 8px 20px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #bbbcbc;
  margin-right: 15px;
  margin-bottom: 13px;

  ${props => props.active && `
    border-color: #033e52;
    color: #033e52;
  `};

  ${props => props.disabled && `
    border-color: #b2b4b2;
    color: #bbbcbc;
    cursor: default;
  `};
`;

const InfoLabel = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.7px;
  text-align: center;
  color: #bbbcbc;
  margin-top: 4px;
  ${props => props.active && 'color: #033e52;'};
  ${props => props.disabled && `
    color: #bbbcbc;
  `};
`;


const CaptionContainer = styled.div`
  width: 100%;
  padding: 6px 0 12px;
`;

export const PhotoCard = styled.div`
  text-align: center;
  vertical-align: top;
  display: inline-block;
  position: relative;
  width: 220px;
  margin-right: 12px;
  margin-bottom: 20px;
  background-color: #f4f6f8;
  height: auto;
  background-color: transparent;
  &:hover {
    background-color: rgba(248, 248, 248, 0.6);
  }
`;

const RemoveBtn = styled.div`
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  padding-top:40px;
  img {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
  div {
    display: block;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: -0.9px;
    text-align: center;
    color: #ffffff;
    margin-top: -8px;
  }
`;

const Photo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: rgba(248, 248, 248, 0.6);
`;


export const CaptionTitle = styled.div`
  height: 24px;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: -1px;
  text-align: center;
  color: #000000;
`;

export const CaptionBody = styled.div`
  height: 24px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: -1px;
  text-align: center;
  color: #000000;
`;

const PhotoArea = styled.div`
  position: relative;
  width: 220px;
  height: 140px;
  border: solid 1px #e4e4e4;
  background-color: rgba(248, 248, 248, 0.6);
  img {
    z-index: 1;
  }
  div {
    z-index: -1;
  }
  &:hover {
    img {
      z-index: -1;
    }
    div {
      z-index: 1;
    }
  }
`;
const PhotoContainer = styled.div`
  margin-bottom: -20px;
`;

const TimeInput = styled(TextInput)`
  letter-spacing: normal;
  ${media.miniDesktop`
    margin: 0 4px;
  `};
`;

const StyledSubTitle = styled(SubTitle)`
  letter-spacing: normal;
`;

const TimeRow = styled(SubSection)`
  color: #e0e0e0;
  ${media.miniDesktop`
    display: flex;
    align-items: center;
  `};
  &:last-of-type {
    margin-top: 30px;
  }
`;

class HosAddInfoEditComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      introImgFile: [], // 병원 소개 사진.
    };
  }

  toggleData = (name, value) => {
    const list = this.props[name];
    const idx = _.indexOf(list, value);
    if (idx === -1) {
      if (name === 'tags' && list.length > 2) {
        return;
      }

      list.push(value);
    } else {
      list.splice(idx, 1);
    }

    this.props.onChange(name, list);
  };


  openDeleteConfirmPopup = (id) => {
    this.props.openAlert({
      title: '사진 삭제',
      message: '선택하신 사진을 정말로 삭제하시겠습니까?',
      showConfirmBtn: true,
      confirmBtnName: '확인',
      textAlign: 'left',
      confirmBtnOnClick: this.removePhoto.bind(this, id),
      showCancelBtn: true,
      cancleBtnName: '취소',
      cancleBtnOnClick: this.props.closeAlert,
    });
  }

  removePhoto = async (id) => {
    const response = await this.props.mutate({ variables: { id } });
    if (response.data.deleteHospitalPhoto.ok) {
      this.props.toast('사진을 삭제하였습니다.', 'info');
    } else {
      this.props.toast('사진 삭제에 실패하였습니다.', 'warn');
    }
  };


  render() {
    const { 
      tags,
      infos,
      dutyTime1s,
      dutyTime1c,
      dutyTime2s,
      dutyTime2c,
      dutyTime3s,
      dutyTime3c,
      dutyTime4s,
      dutyTime4c,
      dutyTime5s,
      dutyTime5c,
      dutyTime6s,
      dutyTime6c,
      dutyTime7s,
      dutyTime7c,
      lunchTimes,
      lunchTimec,
      intro,
      confirmed,
      onChangeHosBasicInfo,
    } = this.props;

    let mainPhotos = [];
    let lifePhotos = [];
    let casePhotos = [];

    if (this.props.info) {
      mainPhotos = this.props.info.photos.filter(photo => photo.category === 'main');
      lifePhotos = this.props.info.photos.filter(photo => photo.category === 'life');
      casePhotos = this.props.info.photos.filter(photo => photo.category === 'casebook');
    }

    const basicTags = [
      '최신 시설 및 설비',
      '자세한 설명과 친절함',
      '편안하고 안아픈 진료',
      '치료분야별 주치의',
      '합리적 가격',
    ];

    const basicInfos = [
      '전문의',
      '야간진료',
      '주말진료',
      '주차가능',
      '무통마취',
      '기공실 구비',
      '소아전문',
    ];

    return (
      <div>
        <Title>상세정보</Title>

        <Section>
          <SubTitle>진료시간</SubTitle>
          <TimeRow>
            <LeftTitle>월요일</LeftTitle>
            <TimeInput
              width="100px"
              type="text"
              name="dutyTime1s"
              value={dutyTime1s}
              onChange={onChangeHosBasicInfo}
              placeholder="9:00"
              disabled={!confirmed}
              textAlign="center"
            />
            {'  -  '}
            <TimeInput
              width="100px"
              type="text"
              name="dutyTime1c"
              value={dutyTime1c}
              onChange={onChangeHosBasicInfo}
              placeholder="18:00"
              disabled={!confirmed}
              textAlign="center"
            />
          </TimeRow>
          <TimeRow>
            <LeftTitle>화요일</LeftTitle>
            <TimeInput
              width="100px"
              type="text"
              name="dutyTime2s"
              value={dutyTime2s}
              onChange={onChangeHosBasicInfo}
              placeholder="9:00"
              disabled={!confirmed}
              textAlign="center"
            />
            {'  -  '}
            <TimeInput
              width="100px"
              type="text"
              name="dutyTime2c"
              value={dutyTime2c}
              onChange={onChangeHosBasicInfo}
              placeholder="18:00"
              disabled={!confirmed}
              textAlign="center"
            />
          </TimeRow>
          <TimeRow>
            <LeftTitle>수요일</LeftTitle>
            <TimeInput
              width="100px"
              type="text"
              name="dutyTime3s"
              value={dutyTime3s}
              onChange={onChangeHosBasicInfo}
              placeholder="9:00"
              disabled={!confirmed}
              textAlign="center"
            />
            {'  -  '}
            <TimeInput
              width="100px"
              type="text"
              name="dutyTime3c"
              value={dutyTime3c}
              onChange={onChangeHosBasicInfo}
              placeholder="18:00"
              disabled={!confirmed}
              textAlign="center"
            />
          </TimeRow>
          <TimeRow>
            <LeftTitle>목요일</LeftTitle>
            <TimeInput
              width="100px"
              type="text"
              name="dutyTime4s"
              value={dutyTime4s}
              onChange={onChangeHosBasicInfo}
              placeholder="9:00"
              disabled={!confirmed}
              textAlign="center"
            />
            {'  -  '}
            <TimeInput
              width="100px"
              type="text"
              name="dutyTime4c"
              value={dutyTime4c}
              onChange={onChangeHosBasicInfo}
              placeholder="18:00"
              disabled={!confirmed}
              textAlign="center"
            />
          </TimeRow>
          <TimeRow>
            <LeftTitle>금요일</LeftTitle>
            <TimeInput
              width="100px"
              type="text"
              name="dutyTime5s"
              value={dutyTime5s}
              onChange={onChangeHosBasicInfo}
              placeholder="9:00"
              disabled={!confirmed}
              textAlign="center"
            />
            {'  -  '}
            <TimeInput
              width="100px"
              type="text"
              name="dutyTime5c"
              value={dutyTime5c}
              onChange={onChangeHosBasicInfo}
              placeholder="18:00"
              disabled={!confirmed}
              textAlign="center"
            />
          </TimeRow>
          <TimeRow>
            <LeftTitle>토요일</LeftTitle>
            <TimeInput
              width="100px"
              type="text"
              name="dutyTime6s"
              value={dutyTime6s}
              onChange={onChangeHosBasicInfo}
              placeholder="9:00"
              disabled={!confirmed}
              textAlign="center"
            />
            {'  -  '}
            <TimeInput
              width="100px"
              type="text"
              name="dutyTime6c"
              value={dutyTime6c}
              onChange={onChangeHosBasicInfo}
              placeholder="18:00"
              disabled={!confirmed}
              textAlign="center"
            />
          </TimeRow>
          <TimeRow>
            <LeftTitle>일요일</LeftTitle>
            <TimeInput
              width="100px"
              type="text"
              name="dutyTime7s"
              value={dutyTime7s}
              onChange={onChangeHosBasicInfo}
              placeholder="9:00"
              disabled={!confirmed}
              textAlign="center"
            />
            {'  -  '}
            <TimeInput
              width="100px"
              type="text"
              name="dutyTime7c"
              value={dutyTime7c}
              onChange={onChangeHosBasicInfo}
              placeholder="18:00"
              disabled={!confirmed}
              textAlign="center"
            />
          </TimeRow>
          <TimeRow>
            <LeftTitle>점심시간</LeftTitle>
            <TimeInput
              width="100px"
              type="text"
              name="lunchTimes"
              value={lunchTimes}
              onChange={onChangeHosBasicInfo}
              placeholder="13:00"
              disabled={!confirmed}
              textAlign="center"
            />
            {'  -  '}
            <TimeInput
              width="100px"
              type="text"
              name="lunchTimec"
              value={lunchTimec}
              onChange={onChangeHosBasicInfo}
              placeholder="14:00"
              disabled={!confirmed}
              textAlign="center"
            />
          </TimeRow>
        </Section>
        <Hr margin="30px 0" color="#e0e0e0" />
        <Section>
          <SubTitle>병원 소개말</SubTitle>
          <Textarea value={intro} name="intro" onChange={onChangeHosBasicInfo} placeholder="환자들에게 병원을 소개해 보세요 :)" disabled={!confirmed} />
        </Section>
        <Section>
          <SubTitle>특성 선택 (최대 3개)</SubTitle>
          <div>
            {basicTags.map(tag => (
              <CheckLabel
                key={`basic_${tag}`}
                active={tags.includes(tag)}
                onClick={confirmed ? this.toggleData.bind(this, 'tags', tag) : () => null}
                disabled={!confirmed}
              >
                {tag}
              </CheckLabel>
            ))}
          </div>
        </Section>
        <Section>
          <SubTitle>병원 추가 정보</SubTitle>
          <div style={{ marginTop: '20px' }}>
            {basicInfos.map(info => (
              <AdditionalInfo
                key={`info_${info}`}
                onClick={confirmed ? this.toggleData.bind(this, 'infos', info) : () => null}
                disabled={!confirmed}
              >
                <img
                  src={
                    infos.includes(info) && confirmed
                      ? HosAddInfoImage[info].on
                      : HosAddInfoImage[info].off
                  }
                  alt="specialist"
                />
                <InfoLabel
                  active={infos.includes(info)}
                  disabled={!confirmed}
                >
                  {info}
                </InfoLabel>
              </AdditionalInfo>
            ))}
          </div>
        </Section>
        <Hr margin="29.5px 0" color="#e0e0e0" />
        <Section>
          <SubTitle>대표사진</SubTitle>
          <PhotoContainer>
            {confirmed && mainPhotos &&
              mainPhotos.length > 0 &&
              mainPhotos.map((photo, idx) => (
                <PhotoCard key={`${photo.url}_main_${idx}`}>
                  <PhotoArea>
                    <RemoveBtn onClick={this.openDeleteConfirmPopup.bind(this, photo.id)}>
                      <img src={CloseWhite} alt="병원 사진 삭제" />
                      <div>사진삭제</div>
                    </RemoveBtn>
                    <Photo src={photo.url} alt="hoslogo" />
                  </PhotoArea>
                  <CaptionContainer>
                    <CaptionTitle>{photo.title}</CaptionTitle>
                    <CaptionBody>{photo.description}</CaptionBody>
                  </CaptionContainer>
                </PhotoCard>
              ))}
            <AddPhotoWidget category="main" disabled={!confirmed} />
          </PhotoContainer>
        </Section>
        <Section>
          <StyledSubTitle>병원 LIFE & EPISODE</StyledSubTitle>
          <PhotoContainer>
            {confirmed && lifePhotos &&
              lifePhotos.length > 0 &&
              lifePhotos.map((photo, idx) => (
                <PhotoCard key={`${photo.url}_life_${idx}`}>
                  <PhotoArea>
                    <RemoveBtn onClick={this.openDeleteConfirmPopup.bind(this, photo.id)}>
                      <img src={CloseWhite} alt="병원 사진 삭제" />
                      <div>사진삭제</div>
                    </RemoveBtn>
                    <Photo src={photo.url} alt="hoslogo" />
                  </PhotoArea>
                  <CaptionContainer>
                    <CaptionTitle>{photo.title}</CaptionTitle>
                    <CaptionBody>{photo.description}</CaptionBody>
                  </CaptionContainer>
                </PhotoCard>
              ))}
            <AddPhotoWidget category="life" disabled={!confirmed} />
          </PhotoContainer>
        </Section>
        <Section>
          <StyledSubTitle>BEFORE & AFTER</StyledSubTitle>
          <PhotoContainer>
            {confirmed && casePhotos &&
              casePhotos.length > 0 &&
              casePhotos.map((photo, idx) => (
                <PhotoCard key={`${photo.url}_casebook_${idx}`}>
                  <PhotoArea>
                    <RemoveBtn onClick={this.openDeleteConfirmPopup.bind(this, photo.id)}>
                      <img src={CloseWhite} alt="병원 사진 삭제" />
                      <div>사진삭제</div>
                    </RemoveBtn>
                    <Photo src={photo.url} alt="hoslogo" />
                  </PhotoArea>
                  <CaptionContainer>
                    <CaptionTitle>{photo.title}</CaptionTitle>
                    <CaptionBody>{photo.description}</CaptionBody>
                  </CaptionContainer>
                </PhotoCard>
              ))}
            <AddPhotoWidget category="casebook" disabled={!confirmed} />
          </PhotoContainer>
        </Section>
      </div>
    );
  }
}

export default graphql(deleteHospitalPhotoMutation, {
  options: {
    refetchQueries: [
      {
        query: myHospitalQuery,
      },
    ],
  },
})(withAlertModal(withToast(HosAddInfoEditComponent)));
