import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Prompt } from 'react-router-dom';
import { Query } from 'react-apollo';
import { getHosRespsDetailForPartnerQuery } from '../graphql';
import HosRespEditor from '../components/HosRespDetail/HosRespEditor';
import withAuth from '../utils/authhoc';
import HosRespMemoEditorInDetail from '../components/HosRespDetail/HosRespMemoEditorInDetail';
import {
  Separater,
  RightRow,
  RightRowItem,
  RightTitle,
  LeftRow,
  LeftTitle,
  LeftTitleText,
  TherapyItemTitle,
  TherapyItem,
  getOrthoWannaString,
} from '../components/HosResps/HosRespsCommonComponents';
import { getLocationNames } from '../utils/locationdata';
import { getAgeGroupName } from '../utils/getAge';
import { parsePhonenum } from '../utils/parser';
import { getReqWantLevelLable } from '../utils/req-utils';
import UserReqPhotoGallery from '../components/ReqList/UserReqPhotoGallery';
import { media } from '../globalStyle';
import ModalCloseImg from '../images/close.svg';

const Container = styled.div`
  .route {
    display: flex;
    background-color: #ffffff;
    flex-direction: row;
    height: calc(100vh - 80px);
    ${media.miniDesktop`
      min-width: 540px;
      flex-wrap: wrap-reverse;
      height: 100%;
      justify-content: center;
    `};
  }
  .modal {
    position: relative;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    width: 1080px;
    height: 100vh;
    ${media.miniDesktop`
      minwidth: 540px;
      flex-wrap: wrap-reverse;
      height: 100%;
      justify-content: center;
    `};
  }
  .left {
    overflow: auto;
    ::-webkit-scrollbar {
      width: 0px; /* Remove scrollbar space */
      background: transparent; /* Optional: just make scrollbar invisible */
    }
    width: 550px;
    padding: 20px;
  }
  .route-right {
    right: 0;
    height: 100vh;
    position: fixed;
    overflow: auto;
    ::-webkit-scrollbar {
      width: 0px; /* Remove scrollbar space */
      background: transparent; /* Optional: just make scrollbar invisible */
    }
    width: 530px;
    padding: 20px;
    background-color: #f3f3f3;
    ${media.miniDesktop`
      position: relative;
    `};
  }
  .modal-right {
    overflow: auto;
    ::-webkit-scrollbar {
      width: 0px; /* Remove scrollbar space */
      background: transparent; /* Optional: just make scrollbar invisible */
    }
    width: 530px;
    padding: 20px;
    background-color: #f3f3f3;
  }
  .modal-close {
    position: absolute;
    padding: 20px;
    width: 30px;
    height: 30px;
    top: 0px;
    right: 0px;
    background: none;
    border: none;
    cursor: pointer;
  }
`;

const DirectFlag = styled.div`
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 12px;
  background-color: #ffb3aa;
  padding: 4px 14px;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.42;
  letter-spacing: -0.7px;
  text-align: center;
  color: #ffffff;
`;

class HosRespDetail extends Component {
  constructor(props) {
    super(props);
    this.hrId = props.hrId ? props.hrId : props.match.params.hrId;
    this.inModal = props.hrId ? true : false;
    this.state = {
      memoEdited: false,
      respEdited: false,
    };
  }
  setMemoEdited = (edited) => {
    this.setState({
      memoEdited: edited,
    });
  };
  setRespEdited = (edited) => {
    this.setState({
      respEdited: edited,
    });
  };
  render() {
    const { closeModal, saveMemo } = this.props;
    const { memoEdited, respEdited } = this.state;
    return (
      <Query
        query={getHosRespsDetailForPartnerQuery}
        variables={{ hrid: this.hrId }}
        skip={this.props.meh === null}>
        {({ data }) => {
          if (
            data &&
            data.getHosRespsDetailForPartner &&
            data.getHosRespsDetailForPartner.userRequest
          ) {
            const {
              userRequest,
              checkedAns,
            } = data.getHosRespsDetailForPartner;
            const isChecked = !!checkedAns; // 확인 || 미확인 플래그
            return (
              <Container>
                <Prompt
                  when={memoEdited || respEdited}
                  message='수정하신 내용이 저장되지 않습니다. 정말 닫으시겠어요?'
                />
                <div className={this.inModal ? 'modal' : 'route'}>
                  <div className='left'>
                    {isChecked && checkedAns ? (
                      <>
                        <LeftRow style={{ marginTop: 100 }}>
                          <LeftTitle>
                            <LeftTitleText style={{ fontWeight: 'bold' }}>
                              {userRequest.user.username}
                            </LeftTitleText>
                            <LeftTitleText>님의 확인된 답변</LeftTitleText>
                          </LeftTitle>
                        </LeftRow>
                        <HosRespMemoEditorInDetail
                          hrId={this.hrId}
                          caId={checkedAns.id}
                          initCallState={checkedAns.callState}
                          initMemo={checkedAns.memo || ''}
                          edited={memoEdited}
                          setEdited={this.setMemoEdited}
                          saveMemo={saveMemo}
                        />
                        <LeftRow style={{ marginTop: 90 }}>
                          <Separater></Separater>
                        </LeftRow>
                        <HosRespEditor
                          hosResp={data.getHosRespsDetailForPartner}
                          checked={isChecked}
                          edited={respEdited}
                          setEdited={this.setRespEdited}
                        />
                      </>
                    ) : (
                      <>
                        <LeftRow style={{ marginTop: 100 }}>
                          <LeftTitle>
                            <LeftTitleText style={{ fontWeight: 'bold' }}>
                              {userRequest.user.username}
                            </LeftTitleText>
                            <LeftTitleText>님의 미확인 답변</LeftTitleText>
                          </LeftTitle>
                        </LeftRow>
                        <HosRespEditor
                          hosResp={data.getHosRespsDetailForPartner}
                          checked={isChecked}
                          edited={respEdited}
                          setEdited={this.setRespEdited}
                        />
                      </>
                    )}
                  </div>
                  <div className={this.inModal ? 'modal-right' : 'route-right'}>
                    <RightTitle style={{ marginTop: 100 }}>
                      {userRequest.user.username}
                      {/* {userRequest.directReq && (
                        <DirectFlag>(다이렉트)</DirectFlag>
                      )} */}
                    </RightTitle>
                    <Separater
                      color='#d4d6d5'
                      style={{ marginTop: 30 }}></Separater>
                    <RightRow style={{ height: 64 }}>
                      <RightRowItem>
                        {userRequest.directReq
                          ? '다이렉트 상담'
                          : getLocationNames(userRequest.locations, ', ')}
                      </RightRowItem>
                      <RightRowItem>
                        {isChecked ? (
                          <span>
                            {parsePhonenum(userRequest.user.phonenum)}{' '}
                            {userRequest.user.metadata &&
                            userRequest.user.metadata.callmethod
                              ? userRequest.user.metadata.callmethod
                              : ''}
                          </span>
                        ) : (
                          '답변 확인 후 전화번호 조회가능'
                        )}
                      </RightRowItem>
                    </RightRow>
                    <Separater color='#d4d6d5'></Separater>
                    <RightRow style={{ height: 64 }}>
                      <RightRowItem>
                        {userRequest.gender &&
                          (userRequest.gender === 'female' ? '여자' : '남자')}
                        {userRequest.gender && userRequest.ageGroup && ', '}
                        {userRequest.ageGroup &&
                          getAgeGroupName(userRequest.ageGroup)}
                        {!userRequest.ageGroup &&
                          !userRequest.gender &&
                          '성별, 연령 정보 없음'}
                      </RightRowItem>
                      {userRequest.wantLevel && (
                        <RightRowItem>
                          {getReqWantLevelLable(userRequest.wantLevel)}
                        </RightRowItem>
                      )}
                    </RightRow>
                    <Separater color='#d4d6d5'></Separater>
                    <RightRow style={{ height: 64 }}>
                      <RightRowItem>
                        상담 신청일:{' '}
                        <span>
                          {moment(userRequest.createdAt).format(
                            'YYYY.MM.DD HH:mm',
                          )}
                        </span>
                      </RightRowItem>
                      {isChecked && checkedAns ? (
                        <RightRowItem>
                          답변 확인일:{' '}
                          <span>
                            {moment(checkedAns.createdAt).format(
                              'YYYY.MM.DD HH:mm',
                            )}
                          </span>
                        </RightRowItem>
                      ) : (
                        <RightRowItem></RightRowItem>
                      )}
                    </RightRow>
                    {((userRequest.txImageUrls &&
                      userRequest.txImageUrls.length > 0) ||
                      (userRequest.orthoImageUrls &&
                        userRequest.orthoImageUrls.length > 0)) && (
                      <>
                        <Separater color='#d4d6d5'></Separater>
                        <RightRow style={{ marginTop: 20, paddingBottom: 20 }}>
                          <UserReqPhotoGallery
                            txImageUrls={userRequest.txImageUrls}
                            orthoImageUrls={userRequest.orthoImageUrls}
                          />
                        </RightRow>
                      </>
                    )}
                    {userRequest.orthoWanna &&
                      userRequest.orthoWanna.length > 0 && (
                        <>
                          <Separater color='#d4d6d5'></Separater>
                          <RightRow
                            style={{
                              marginTop: 20,
                              textAlign: 'left',
                              height: 20,
                            }}>
                            <TherapyItemTitle>희망 개선사항:</TherapyItemTitle>
                          </RightRow>
                          <RightRow
                            style={{
                              textAlign: 'left',
                              paddingBottom: 20,
                            }}>
                            <TherapyItem>
                              {getOrthoWannaString(userRequest.orthoWanna)}
                            </TherapyItem>
                          </RightRow>
                        </>
                      )}
                    {userRequest.desc && (
                      <>
                        <Separater color='#d4d6d5'></Separater>
                        <RightRow style={{ paddingTop: 20, textAlign: 'left', whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                          {userRequest.desc}
                        </RightRow>
                      </>
                    )}
                    {this.inModal && (
                      <img
                        style={{
                          position: 'absolute',
                          top: 20,
                          right: 20,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          if (
                            (memoEdited || respEdited) &&
                            !window.confirm(
                              '수정하신 내용이 저장되지 않습니다. 정말 닫으시겠어요?',
                            )
                          ) {
                            return;
                          }
                          closeModal();
                        }}
                        width={30}
                        height={30}
                        src={ModalCloseImg}></img>
                    )}
                  </div>
                </div>
              </Container>
            );
          }

          return null;
        }}
      </Query>
    );
  }
}

export default withAuth(HosRespDetail);
