export const parsePhonenum = (phone) => {
  if (!phone) {
    return '';
  }

  // 탈퇴상태인 경우 빈칸을 반환하지 않고 탈퇴를 반환하도록 수정
  if (phone === '탈퇴') {
    return '탈퇴';
  }

  if (phone.length === 10) {
    return (
      `${phone.substr(0, 3)}` +
      '-' +
      `${phone.substr(3, 3)}` +
      '-' +
      `${phone.substr(6, 4)}`
    );
  }

  if (phone.length === 11) {
    return (
      `${phone.substr(0, 3)}` +
      '-' +
      `${phone.substr(3, 4)}` +
      '-' +
      `${phone.substr(7, 4)}`
    );
  }

  return '';
};

export default {};
