import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink, from } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { IntlProvider, addLocaleData } from 'react-intl';
import moment from 'moment';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/browser';

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';

import './styles/bootstrap/css/bootstrap.min.css'; // bootstrap 은 React Package 말고 따로 받아서 쓴다. (나중에는 걷어낼 것이다.) - 2019/7/15 kosick

import 'react-dates/lib/css/_datepicker.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-toastify/dist/ReactToastify.css';
import 'rc-pagination/assets/index.css';

import 'react-dates/initialize';
import 'airbnb-js-shims';
// import registerServiceWorker from './registerServiceWorker';

import en from 'react-intl/locale-data/en';
import ko from 'react-intl/locale-data/ko';
import koreanString from './locale/ko';

import { unregister } from './registerServiceWorker';
import Routes from './routes';

// Put any other imports below so that CSS from your
// components takes precedence over default styles.
import GlobalStyle from './globalStyle';
import { initialize } from './utils/channel';
import OdocToastContainer from './utils/OdocToastContainer';
import AuthProvider from './utils/AuthProvider';
import { logout } from './utils/auth';

// Google Analytics
ReactGA.initialize('UA-128569863-1');

Sentry.init({
 dsn: "https://9a2de266d38e4c51910802978281738d@sentry.io/1354736"
});

const BACKEND_URL = process.env.REACT_APP_API_URL;
const httpLink = new HttpLink({ uri: `${BACKEND_URL}/graphql` });

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'x-odoc-hospital-token': localStorage.getItem('hospitalToken'),
      'x-odoc-hospital-refresh-token': localStorage.getItem('refreshHospitalToken'),
    },
  }));

  return forward(operation);
});

const afterwareLink = new ApolloLink((operation, forward) => forward(operation).map((response) => {
  const { response: { headers } } = operation.getContext();
  if (headers) {
    const token = headers.get('x-odoc-hospital-token');
    const refreshToken = headers.get('x-odoc-hospital-refresh-token');

    if (token) {
      localStorage.setItem('hospitalToken', token);
    }

    if (refreshToken) {
      localStorage.setItem('refreshHospitalToken', refreshToken);
    }
  }

  return response;
}));

const errorCheckLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      console.log(`[GraphQl error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      if (message.includes('Not authenticated')) {
        logout();
        document.location.href = '/login';
      }
    });
  }

  if (networkError?.message?.includes('Failed to fetch')) {
    console.log(`[Network error]: ${networkError}`);
    alert('서버가 응답하지 않습니다.');
    document.location.href = '/';
  }
});

moment.locale('kr', {
  months: '1월_2월_3월_4월_5월_6월_7월_8월_9월_10월_11월_12월'.split('_'),
  monthsShort: '1월_2월_3월_4월_5월_6월_7월_8월_9월_10월_11월_12월'.split('_'),
  weekdays: '일요일_월요일_화요일_수요일_목요일_금요일_토요일'.split('_'),
  weekdaysShort: '일_월_화_수_목_금_토'.split('_'),
  weekdaysMin: '일_월_화_수_목_금_토'.split('_'),
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY LT',
    LLLL: 'dddd D MMMM YYYY LT',
  },

  ordinal(number) {
    return `${number}일`;
  },
});

// 국제화 설정. (react-intl)
addLocaleData([...en, ...ko]);

// channel initilize
// initialize();

const client = new ApolloClient({
  link: from([authMiddleware, afterwareLink, errorCheckLink.concat(httpLink)]),
  cache: new InMemoryCache(),
});

const App = (
  <AuthProvider>
    <IntlProvider locale="ko" messages={koreanString}>
      <ApolloProvider client={client}>
        <Routes />
        <OdocToastContainer />
      </ApolloProvider>
    </IntlProvider>
    <GlobalStyle />
  </AuthProvider>
);

ReactDOM.render(App, document.getElementById('root'));
// registerServiceWorker();
unregister();
