import React from 'react';
import { withApollo, compose, graphql } from 'react-apollo';
import { loginMutation, registerHuserMutation, mehQuery } from '../graphql';
import { setToken, setMehStatus, logout, isAuthenticated } from './auth';
import { AuthContext } from './AuthProvider';

function withAuth(WrappedComponent) {
  class Auth extends React.Component {
    constructor(props) {
      super(props);
      this.autoLogin = this.autoLogin.bind(this);
    }

    componentDidMount() {
      this.autoLogin();
    }

    autoLogin = async () => {
      if (isAuthenticated() && !this.context.meh) {
        const mehRes = await this.props.client.query({
          query: mehQuery,
          options: { fetchPolicy: 'network-only' },
        });

        if (mehRes.data.meh) {
          const { meh } = mehRes.data;

          // status 저장
          setMehStatus(meh.status);

          // me 저장
          this.context.updateMeh(meh);
        }
      }
    };

    // 회원 가입
    regist = async ({ variables }) => {
      try {
        const registRes = await this.props.regist({ variables });
        return registRes.data.registerHuser;
      } catch (e) {
        throw e;
      }
    };

    // 로그인 mutation, meQuery, 채널 체크인, meh 정보 저장.
    login = async ({ variables }) => {
      try {
        const loginRes = await this.props.login({ variables });
        const { ok, token, refreshToken, isPartner } = loginRes.data.loginUserByEmail;

        if (ok && isPartner) {
          // 토큰 저장
          setToken(token, refreshToken);

          const mehRes = await this.props.client.query({
            query: mehQuery,
            options: { fetchPolicy: 'network-only' },
          });

          if (mehRes.data.meh) {
            const { meh } = mehRes.data;

            // status 저장
            setMehStatus(meh.status);

            // me 저장
            this.context.updateMeh(meh);
            return mehRes.data.meh;
          }
        }

        this.props.toast(
          <div>
            입력한 아이디와 비밀번호가 일치하지 않습니다.<br />아이디 또는
            비밀번호를 다시 한번 입력해주세요.
          </div>,
          'left',
        );
        logout();
        return null;
      } catch (e) {
        throw e;
      }
    };

    render() {
      return (
        <React.Fragment>
          <WrappedComponent
            {...this.props}
            login={this.login}
            regist={this.regist}
            meh={this.context.meh}
            updateMeh={this.context.updateMeh}
          />
        </React.Fragment>
      );
    }
  }

  Auth.contextType = AuthContext;
  return compose(
    graphql(registerHuserMutation, {
      name: 'regist',
    }),
    graphql(loginMutation, { name: 'login' }),
  )(withApollo(Auth));
}

export default withAuth;
