const locations = [
  {
    name: '서울',
    code: '11',
    child: [
      { name: '종로구', code: '110' },
      { name: '중구', code: '140' },
      { name: '용산구', code: '170' },
      { name: '성동구', code: '200' },
      { name: '광진구', code: '215' },
      { name: '동대문구', code: '230' },
      { name: '중랑구', code: '260' },
      { name: '성북구', code: '290' },
      { name: '강북구', code: '305' },
      { name: '도봉구', code: '320' },
      { name: '노원구', code: '350' },
      { name: '은평구', code: '380' },
      { name: '서대문구', code: '410' },
      { name: '마포구', code: '440' },
      { name: '양천구', code: '470' },
      { name: '강서구', code: '500' },
      { name: '구로구', code: '530' },
      { name: '금천구', code: '545' },
      { name: '영등포구', code: '560' },
      { name: '동작구', code: '590' },
      { name: '관악구', code: '620' },
      { name: '서초구', code: '650' },
      { name: '강남구', code: '680' },
      { name: '송파구', code: '710' },
      { name: '강동구', code: '740' },
    ],
  },
  {
    name: '경기',
    code: '41',
    child: [
      { name: '가평군', code: '820' },
      { name: '고양시 덕양구', code: '281' },
      { name: '고양시 일산동구', code: '285' },
      { name: '고양시 일산서구', code: '287' },
      { name: '과천시', code: '290' },
      { name: '광명시', code: '210' },
      { name: '광주시', code: '610' },
      { name: '구리시', code: '310' },
      { name: '군포시', code: '410' },
      { name: '김포시', code: '570' },
      { name: '남양주시', code: '360' },
      { name: '동두천시', code: '250' },
      { name: '부천시', code: '190' },
      { name: '성남시 분당구', code: '135' },
      { name: '성남시 수정구', code: '131' },
      { name: '성남지 중원구', code: '133' },
      { name: '수원시 권선구', code: '113' },
      { name: '수원시 영통구', code: '117' },
      { name: '수원시 장안구', code: '111' },
      { name: '수원시 팔달구', code: '115' },
      { name: '시흥시', code: '390' },
      { name: '안산시 단원구', code: '273' },
      { name: '안산시 상록구', code: '271' },
      { name: '안성시', code: '550' },
      { name: '안양시 동안구', code: '173' },
      { name: '안양시 만안구', code: '171' },
      { name: '양주시', code: '630' },
      { name: '양평군', code: '830' },
      { name: '여주시', code: '670' },
      { name: '연천군', code: '800' },
      { name: '오산시', code: '370' },
      { name: '용인시 기흥구', code: '363' },
      { name: '용인시 수지구', code: '465' },
      { name: '용인시 처인구', code: '461' },
      { name: '의왕시', code: '430' },
      { name: '의정부시', code: '150' },
      { name: '이천시', code: '500' },
      { name: '파주시', code: '480' },
      { name: '평택시', code: '220' },
      { name: '포천시', code: '650' },
      { name: '하남시', code: '450' },
      { name: '화성시', code: '590' },
    ],
  },
  {
    name: '인천',
    code: '28',
    child: [
      { name: '강화군', code: '710' },
      { name: '계양구', code: '245' },
      { name: '남동구', code: '200' },
      { name: '동구', code: '140' },
      { name: '미추홀구', code: '177' },
      { name: '부평구', code: '237' },
      { name: '서구', code: '260' },
      { name: '연수구', code: '185' },
      { name: '옹진군', code: '720' },
      { name: '중구', code: '110' },
    ],
  },
  {
    name: '부산',
    code: '26',
    child: [
      { name: '강서구', code: '440' },
      { name: '금정구', code: '410' },
      { name: '기장군', code: '710' },
      { name: '남구', code: '290' },
      { name: '동구', code: '170' },
      { name: '동래구', code: '260' },
      { name: '부산진구', code: '230' },
      { name: '북구', code: '320' },
      { name: '사상구', code: '530' },
      { name: '사하구', code: '380' },
      { name: '서구', code: '140' },
      { name: '수영구', code: '500' },
      { name: '연제구', code: '470' },
      { name: '영도구', code: '200' },
      { name: '중구', code: '110' },
      { name: '해운대구', code: '350' },
    ],
  },
  {
    name: '대구',
    code: '27',
    child: [
      { name: '남구', code: '200' },
      { name: '달서구', code: '290' },
      { name: '달성군', code: '710' },
      { name: '동구', code: '140' },
      { name: '북구', code: '230' },
      { name: '서구', code: '170' },
      { name: '수성구', code: '260' },
      { name: '중구', code: '110' },
    ],
  },
  {
    name: '울산',
    code: '31',
    child: [
      { name: '남구', code: '140' },
      { name: '동구', code: '170' },
      { name: '북구', code: '200' },
      { name: '울주군', code: '710' },
      { name: '중구', code: '110' },
    ],
  },
  {
    name: '대전',
    code: '30',
    child: [
      { name: '대덕구', code: '230' },
      { name: '동구', code: '110' },
      { name: '서구', code: '170' },
      { name: '유성구', code: '200' },
      { name: '중구', code: '140' },
    ],
  },
  {
    name: '광주',
    code: '29',
    child: [
      { name: '광산구', code: '200' },
      { name: '남구', code: '155' },
      { name: '동구', code: '110' },
      { name: '북구', code: '170' },
      { name: '서구', code: '140' },
    ],
  },
  {
    name: '경남',
    code: '48',
    child: [
      { name: '거제시', code: '310' },
      { name: '거창군', code: '880' },
      { name: '고성군', code: '820' },
      { name: '김해시', code: '250' },
      { name: '남해군', code: '840' },
      { name: '밀양시', code: '270' },
      { name: '사천시', code: '240' },
      { name: '산청군', code: '860' },
      { name: '양산시', code: '330' },
      { name: '의령군', code: '720' },
      { name: '진주시', code: '170' },
      { name: '창녕군', code: '740' },
      { name: '창원시 마포합포구', code: '125' },
      { name: '창원시 마산회원구', code: '127' },
      { name: '창원시 성산구', code: '123' },
      { name: '창원시 의창구', code: '121' },
      { name: '창원시 진해구', code: '129' },
      { name: '통영시', code: '220' },
      { name: '하동군', code: '850' },
      { name: '함안군', code: '730' },
      { name: '함양군', code: '870' },
      { name: '합천군', code: '890' },
    ],
  },
  {
    name: '경북',
    code: '47',
    child: [
      { name: '경산시', code: '290' },
      { name: '경주시', code: '130' },
      { name: '고령군', code: '830' },
      { name: '구미시', code: '190' },
      { name: '군위군', code: '720' },
      { name: '김천시', code: '150' },
      { name: '문경시', code: '280' },
      { name: '봉화군', code: '920' },
      { name: '상주시', code: '250' },
      { name: '성주군', code: '840' },
      { name: '안동시', code: '170' },
      { name: '영덕군', code: '770' },
      { name: '영양군', code: '760' },
      { name: '영주시', code: '210' },
      { name: '영천시', code: '230' },
      { name: '예천군', code: '900' },
      { name: '울릉군', code: '940' },
      { name: '울진군', code: '930' },
      { name: '의성군', code: '730' },
      { name: '청도군', code: '820' },
      { name: '청송군', code: '750' },
      { name: '칠곡군', code: '850' },
      { name: '포항시 남구', code: '111' },
      { name: '포항시 북구', code: '113' },
    ],
  },
  {
    name: '전남',
    code: '46',
    child: [
      { name: '강진군', code: '810' },
      { name: '고흥군', code: '770' },
      { name: '곡성군', code: '720' },
      { name: '광양시', code: '230' },
      { name: '구례군', code: '730' },
      { name: '나주시', code: '170' },
      { name: '담양군', code: '710' },
      { name: '목포시', code: '110' },
      { name: '무안군', code: '840' },
      { name: '보성군', code: '780' },
      { name: '순천시', code: '150' },
      { name: '신안군', code: '910' },
      { name: '여수시', code: '130' },
      { name: '영광군', code: '870' },
      { name: '영암군', code: '830' },
      { name: '완도군', code: '890' },
      { name: '장성군', code: '880' },
      { name: '장흥군', code: '800' },
      { name: '진도군', code: '900' },
      { name: '함평군', code: '860' },
      { name: '해남군', code: '820' },
      { name: '화순군', code: '790' },
    ],
  },
  {
    name: '전북',
    code: '45',
    child: [
      { name: '고창군', code: '790' },
      { name: '군산시', code: '130' },
      { name: '김제시', code: '210' },
      { name: '남원시', code: '190' },
      { name: '무주군', code: '730' },
      { name: '부안군', code: '800' },
      { name: '순창군', code: '770' },
      { name: '완주군', code: '710' },
      { name: '익산시', code: '140' },
      { name: '임실군', code: '750' },
      { name: '장수군', code: '740' },
      { name: '전주시 덕진구', code: '113' },
      { name: '전주시 완산구', code: '111' },
      { name: '정읍시', code: '180' },
      { name: '진안군', code: '720' },
    ],
  },
  {
    name: '충남',
    code: '44',
    child: [
      { name: '계룡시', code: '250' },
      { name: '공주시', code: '150' },
      { name: '금산군', code: '710' },
      { name: '논산시', code: '230' },
      { name: '당진시', code: '270' },
      { name: '보령시', code: '180' },
      { name: '부여군', code: '760' },
      { name: '서산시', code: '210' },
      { name: '서천군', code: '770' },
      { name: '아산시', code: '200' },
      { name: '예산군', code: '810' },
      { name: '천안시 동남구', code: '131' },
      { name: '천안시 서북구', code: '133' },
      { name: '청양군', code: '790' },
      { name: '태안군', code: '825' },
      { name: '홍성군', code: '800' },
    ],
  },
  {
    name: '충북',
    code: '43',
    child: [
      { name: '괴산군', code: '760' },
      { name: '단양군', code: '800' },
      { name: '보은군', code: '720' },
      { name: '영동군', code: '740' },
      { name: '옥천군', code: '730' },
      { name: '음성군', code: '770' },
      { name: '제천시', code: '150' },
      { name: '증평군', code: '745' },
      { name: '진천군', code: '750' },
      { name: '청주시 상당구', code: '111' },
      { name: '청주시 서원구', code: '112' },
      { name: '청주시 청원구', code: '114' },
      { name: '청주시 흥덕구', code: '113' },
      { name: '충주시', code: '130' },
    ],
  },
  {
    name: '강원',
    code: '42',
    child: [
      { name: '강릉시', code: '150' },
      { name: '고성군', code: '820' },
      { name: '동해시', code: '170' },
      { name: '삼척시', code: '230' },
      { name: '속초시', code: '210' },
      { name: '양구군', code: '800' },
      { name: '양양군', code: '830' },
      { name: '영월군', code: '750' },
      { name: '원주시', code: '130' },
      { name: '인제군', code: '810' },
      { name: '정선군', code: '770' },
      { name: '철원군', code: '780' },
      { name: '춘천시', code: '110' },
      { name: '태백시', code: '190' },
      { name: '평창군', code: '760' },
      { name: '홍천군', code: '720' },
      { name: '화천군', code: '790' },
      { name: '횡성군', code: '730' },
    ],
  },
  {
    name: '세종',
    code: '36110',
    child: [{ name: '세종시', code: '000' }],
  },
  {
    name: '제주',
    code: '50',
    child: [{ name: '서귀포시', code: '130' }, { name: '제주시', code: '110' }],
  },
];

export const getLocationName = (regionId, cityId) => {
  try {
    if (!regionId) {
      return '';
    }

    const region = locations.find(loc => loc.code === regionId);

    if (regionId === '36110') {
      return '세종';
    }

    const city = region.child.find(c => c.code === cityId);
    return `${region.name} ${city.name}`;
  } catch (err) {
    return '';
  }
};

export const getLocationNames = (locations, splitter) => {
  try {
    let locationString = '';
    if (locations.length > 0) {
      locations.forEach((e, i) => {
        locationString += `${e.siName} ${e.gunguName}`;
        if (i !== locations.length - 1) {
          locationString += splitter ? splitter : ', ';
        }
      });
    }

    return locationString;
  } catch (err) {
    return '';
  }
};

export default locations;
