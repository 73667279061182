import React, { Component } from 'react';
import { compose, graphql } from 'react-apollo';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import HosBasicInfoContainer from '../containers/MyHospital/HosBasicInfoContainer';

import WhiteArrow from '../images/ic-arrow-white-20.svg';

import { myHospitalQuery } from '../graphql';

import {
  PageContainer, PageHeader, PageTabBtnContainer, PageTabBtn, PageTabContent,
} from '../components/basic/HtmlComponents';
import HosAutoCounselInfoContainer from '../containers/MyHospital/HosAutoCounselInfoContainer';
import HosConfirmContainer from '../containers/MyHospital/HosConfirmContainer';
import { AuthContext } from '../utils/AuthProvider';
import Profile from '../containers/MyHospital/Profile';

const ConfirmBanner = styled.div`
  width: 100%;
  background-color: #ffb3aa;
  padding: 14px 40px;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -1px;
  color: #ffffff;
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
`;

const ConfirmBannerU = styled.u`
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -1px;
  text-align: right;
  color: #ffffff;
  cursor: pointer;
  min-width: 105px;
  text-align: left;
  img {
    margin-left: 4px;
    width: 20px;
    height: 20px;
    object-fit: contain;
    display: inline-block;
    vertical-align: middle;
  }
`;

const tabs = {
  basicInfo: 1,
  myProfile: 2,
  autoCounsel: 3,
  unConfirmed: 4,
};

const Banner = ({ onClick }) => (
  <ConfirmBanner>
    <div>추가기능을 사용하려면 병원인증을 완료해주세요 :)</div>
    <ConfirmBannerU
      onClick={onClick}
    >
      인증하러가기
      <img src={WhiteArrow} alt="인증하기" />
    </ConfirmBannerU>
  </ConfirmBanner>
);


class MyHospital extends Component {
  constructor(props) {
    super(props);

    let selectedTab = tabs.myProfile;
    if (props.location.hash) {
      if (props.location.hash === '#myProfile') {
        selectedTab = tabs.myProfile;
      }
      if (props.location.hash === '#basicInfo') {
        selectedTab = tabs.basicInfo;
      }
      if (props.location.hash === '#autoCounsel') {
        selectedTab = tabs.autoCounsel;
      }
      if (props.location.hash === '#unConfirmed') {
        selectedTab = tabs.unConfirmed;
      }
    }

    this.state = {
      selectedTab,
    };
  }

  render() {
    const {
      myHospital: { loading, myHospital },
    } = this.props;
    const { meh } = this.context;
    const { selectedTab } = this.state;

    if (loading) {
      return null;
    }

    if (!meh) {
      return null;
    }

    const confirmed = meh.status === 'confirmed';

    return (
      <PageContainer>
        <PageHeader>
          <PageTabBtnContainer>
            <PageTabBtn
              href="#myProfile"
              onClick={() => this.setState({ selectedTab: tabs.myProfile })}
              active={selectedTab === tabs.myProfile}
            >
              개인정보
            </PageTabBtn>
            <PageTabBtn
              href="#basicInfo"
              onClick={() => this.setState({ selectedTab: tabs.basicInfo })}
              active={selectedTab === tabs.basicInfo}
            >
              병원정보
            </PageTabBtn>
            <PageTabBtn
              href="#autoCounsel"
              onClick={() => this.setState({ selectedTab: tabs.autoCounsel })}
              active={selectedTab === tabs.autoCounsel}
            >
              상담설정
            </PageTabBtn>
            {/* <PageTabBtn
              href="#unConfirmed"
              onClick={() => this.setState({ selectedTab: tabs.unConfirmed })}
              active={selectedTab === tabs.unConfirmed}
            >
              병원인증
            </PageTabBtn> */}
          </PageTabBtnContainer>
        </PageHeader>
        <PageTabContent>
          {selectedTab === tabs.basicInfo && (
            <div>
              {meh.status === 'unconfirmed' && (
                <Banner onClick={() => this.setState({ selectedTab: tabs.unConfirmed })} />
              )}
              <HosBasicInfoContainer info={myHospital} confirmed={confirmed} />
            </div>
          )}
          {selectedTab === tabs.myProfile && (
            <div>
              <Profile {...this.props} />
            </div>
          )}
          {selectedTab === tabs.autoCounsel && (
            <div>
              {meh.status === 'unconfirmed' && (
                <Banner onClick={() => this.setState({ selectedTab: tabs.unConfirmed })} />
              )}
              <HosAutoCounselInfoContainer confirmed={confirmed} />
            </div>
          )}
          {selectedTab === tabs.unConfirmed && (
            <div>
              <HosConfirmContainer confirmed={confirmed} />
            </div>
          )}
        </PageTabContent>
      </PageContainer>
    );
  }
}

MyHospital.contextType = AuthContext;

const MyHospitalWithFunction = compose(graphql(myHospitalQuery, {
  options: {
    fetchPolicy: 'network-only',
  },
  name: 'myHospital',
}))(withRouter(MyHospital));

export default MyHospitalWithFunction;
