// 환자들 견적 상담 요청서들 모음 페이지
import React from 'react';
import ReqListContainer from '../containers/ReqList/ReqListContainer';
import DesktopBanner from '../components/event/DesktopBanner';
import EventBannerImg from '../images/event-banner.png';

class ReqList extends React.Component {
  render() {
    return (
      <>
        <DesktopBanner srcImg={EventBannerImg} url="https://docs.google.com/forms/d/1tjUE-AfWMqWHxAc2le1x4ICMmIaLQ3KufFynpTvcjns"/>
        <ReqListContainer />
      </>
    );
  }
}

export default ReqList;
