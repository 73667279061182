import React from 'react';
import styled from 'styled-components';
import { media } from '../../globalStyle';
import { StyledModal, ModalTitle } from '../basic/ModalComponents';


export const ProfileContentContiner = styled.div`
  margin-top: 16px;
`;

export const InputBox = styled.div`
  position: relative;
  input {
    width: 100%;
    height: 48px;
    border-radius: 1px;
    background-color: #ffffff;
  }
`;

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const AlertConfirmBtn = styled.button`
  width: 100px;
  height: 60px;
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #017fa0;
  border: none;
  padding: 0;
  &:hover,
  &:focus {
    outline: none;
  }
`;

export const AlertCancelBtn = styled.button`
  width: 100px;
  height: 60px;
  padding: 0;
  border-radius: 3px;
  background-color: #ffffff;
  border: none; 
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #8a8a8a;
  margin-left: 40px;
  &:hover,
  &:focus {
    outline: none;
  }
`;

const ProfileCommonModal = ({
  children, title, isOpen, style,
}) => (
  <StyledModal
    isOpen={isOpen}
    style={{
      overlay: {
        zIndex: 100,
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
      },
      ...style,
    }}
    ariaHideApp={false}
    shouldCloseOnOverlayClick
  >
    <div>
      <ModalTitle>{title}</ModalTitle>
    </div>
    <div>
      {children}
    </div>
  </StyledModal>
);

export default ProfileCommonModal;
