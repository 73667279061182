import React from 'react';
import styled from 'styled-components';
import { compose, graphql } from 'react-apollo';
import { myCounselSettingQuery, updateCounselSettingMutate, myPriceTableQuery, updatePriceTableMutate } from '../../graphql';
import withToast from '../../utils/toasthoc';
import HosCounselWordEditComponent from '../../components/MyHospital/HosCounselWordEditComponent';
import { media } from '../../globalStyle';

import HosDescTemplateEditableComponent from '../../components/MyHospital/HosDescTemplateEditableComponent';

const Section = styled.div`
  width: 100%;
  height: auto;
  padding: 40px 40px;
  border-radius: 1px;
  background-color: #ffffff;
  border: solid 1px #e0e0e0;
  & ~ & {
    margin-top: 10px;
  }
`;

const BottomSaveBtnContainer = styled.div`
  margin-top: 90px;
  background-color: #ffffff;
  text-align: center;
`;

const SaveBtn = styled.button`
  border: none;
  width: 240px;
  height: 52px;
  border-radius: 1px;
  background-color: #033e52;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
`;

const Desc = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.8px;
  color: #bbbcbc;
  margin-top: 10px;
`;


class HosAutoCounselInfoContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      autoCounsel: true,
      greeting: '',
      closing: '',
      showGreeting: true,
      showClosing: true,
      showDescriptions: true,
      showPrices: true,
      txPriceTable: null,
      orthoPriceTable: null,
    };

    this.updatePriceState = this.updatePriceState.bind(this);
  }

  componentWillReceiveProps(props) {
    const {
      myCounselSetting,
      myPriceTable,
    } = props;

    if (!myCounselSetting.loading && myCounselSetting.myCounselSetting) {
      this.setState({
        autoCounsel: myCounselSetting.myCounselSetting.autoCounsel,
        greeting: myCounselSetting.myCounselSetting.greeting,
        closing: myCounselSetting.myCounselSetting.closing,
        showGreeting: myCounselSetting.myCounselSetting.showGreeting,
        showClosing: myCounselSetting.myCounselSetting.showClosing,
        showDescriptions: myCounselSetting.myCounselSetting.showDescriptions,
        showPrices: myCounselSetting.myCounselSetting.showPrices,
      });
    }

    if (!myPriceTable.loading && myPriceTable.myPriceTable) {
      const { txPrices, orthoPrices } = myPriceTable.myPriceTable;
      const txPriceTable = this.createTxPriceData(txPrices);
      const orthoPriceTable = this.createOrthoPriceData(orthoPrices);

      this.setState({ txPriceTable, orthoPriceTable });
    }
  }

  updateCounselSetting = async () => {
    try {
      const {
        // autoCounsel,
        greeting,
        closing,
        // showGreeting,
        // showClosing,
        // showDescriptions,
        // showPrices,
      } = this.state;

      const res = await this.props.updateCounselSetting({
        variables: {
          greeting,
          closing,
          showGreeting: true,
          showClosing: true,
        },
        refetchQueries: () => [{
          query: myCounselSettingQuery,
        }],
      });

      const {
        updateCounselSetting: { ok, errors },
      } = res.data;

      if (ok) {
        return;
      }

      if (errors) {
        throw new Error(errors);
      }
    } catch (e) {
      throw e;
    }
  };

  updateCouselSettingState = ({ target }) => {
    let { value } = target;
    const { name } = target;

    if (name === 'autoCounsel' || name === 'showDescriptions' || name === 'showPrices') {
      value = value === 'true';
    }

    this.setState({ [name]: value });
  };

  // 인사말, 맺음말 추가
  addCounselWord = (name) => {
    this.setState({ [name]: true });
  }

  createTxPriceData = array =>
    array.reduce((obj, item) => {
      obj[item.itemcode] = {
        price: item.price,
        desc: item.description,
        id: item.id,
      };
      return obj;
    }, {});

  createOrthoPriceData = array =>
    array.reduce((obj, item) => {
      obj[item.itemcode] = {
        ...obj[item.itemcode],
        [item.part]: { price: item.price, desc: item.description, id: item.id },
      };
      return obj;
    }, {});

  updatePriceTable = async () => {
    try {
      const { txPriceTable, orthoPriceTable } = this.state;

      const txPriceArr = [];
      await await Promise.all(Object.keys(txPriceTable).map(async (itemcode) => {
        const item = txPriceTable[itemcode];
        const txPrice = {
          id: item.id,
          itemcode,
          description: item.desc || '',
        };

        if (item.price) {
          txPrice.price = parseFloat(item.price);
        }

        // price 가 '' 빈 스트링일수도 있다.
        if (item.price === '') {
          txPrice.price = null;
        }

        if (item.price || item.desc) {
          txPriceArr.push(txPrice);
        }

        return item;
      }));

      const orthoPriceArr = [];
      await Promise.all(Object.keys(orthoPriceTable).map(async (itemcode) => {
        const items = orthoPriceTable[itemcode];

        await Object.keys(items).map(async (part) => {
          const item = orthoPriceTable[itemcode][part];
          const orthoPrice = {
            id: item.id,
            itemcode,
            part,
            description: item.desc || '',
          };

          if (item.price) {
            orthoPrice.price = parseFloat(item.price);
          }

          // price 가 '' 빈 스트링일수도 있다.
          if (item.price === '') {
            orthoPrice.price = null;
          }

          if (item.price || item.desc) {
            orthoPriceArr.push(orthoPrice);
          }
        });

        return items;
      }));

      const res = await this.props.updatePriceTable({
        variables: {
          txPriceArr,
          orthoPriceArr,
        },
        refetchQueries: () => [
          {
            query: myPriceTableQuery,
          },
        ],
      });

      const {
        updatePriceTable: { ok, errors },
      } = res.data;

      if (ok) {
        return;
      }

      if (errors) {
        throw new Error(errors);
      }
    } catch (e) {
      throw e;
    }
  };

  updatePriceState = (type, prices) => {
    if (type === 'tx') {
      const newTxPriceTable = Object.assign(this.state.txPriceTable, prices);
      this.setState({ txPriceTable: newTxPriceTable });
    }

    if (type === 'ortho') {
      const newOrthoPriceTable = Object.assign(this.state.orthoPriceTable, prices);
      this.setState({ orthoPriceTable: newOrthoPriceTable });
    }
  };

  handleSubmit = async () => {
    try {
      await this.updatePriceTable();
      await this.updateCounselSetting();
      this.props.toast('수정 완료', 'info');
    } catch (e) {
      this.props.toast('수정 실패', 'warn');
    }
  }

  render() {
    const { confirmed, myPriceTable, myCounselSetting } = this.props;
    const { txPriceTable, orthoPriceTable } = this.state;

    if (myCounselSetting.loading || myPriceTable.loading) {
      return null;
    }

    return (
      <div>
        {/* <Section>
          <HosCounselSettingEditComponent
            autoCounsel={this.state.autoCounsel}
            showDescriptions={this.state.showDescriptions}
            showPrices={this.state.showPrices}
            onChange={this.updateCouselSettingState}
            confirmed={confirmed}
          />
        </Section> */}
        <Section>
          <HosCounselWordEditComponent
            greeting={this.state.greeting}
            closing={this.state.closing}
            showGreeting={this.state.showGreeting}
            showClosing={this.state.showClosing}
            onChange={this.updateCouselSettingState}
            addCounselWord={this.addCounselWord}
            confirmed={confirmed}
          />
        </Section>
        <Section>
          {(txPriceTable || orthoPriceTable) && (
            <HosDescTemplateEditableComponent
              updatePriceState={this.updatePriceState}
              txPriceTable={txPriceTable}
              orthoPriceTable={orthoPriceTable}
              confirmed={confirmed}
            />
          )}
        </Section>
        {/* <Section>
          {(txPriceTable || orthoPriceTable) && (
            <HosQuickPriceEditableComponent
              updatePriceState={this.updatePriceState}
              txPriceTable={txPriceTable}
              orthoPriceTable={orthoPriceTable}
              confirmed={confirmed}
            />
          )}
        </Section> */}
        {/* <Section>
          {txPriceTable && (
            <HosTxPriceEditableComponent
              updatePriceState={this.updatePriceState}
              txPriceTable={txPriceTable}
              confirmed={confirmed}
            />
          )}
        </Section>
        <Section>
          {orthoPriceTable && (
            <HosOrthoPriceEditableComponent
              updatePriceState={this.updatePriceState}
              orthoPriceTable={orthoPriceTable}
              confirmed={confirmed}
            />
          )}
        </Section> */}
        <Desc>수가표 및 이미지 첨부 등 상담 편의성을 위한 기능이 곧 업데이트됩니다.</Desc>
        {confirmed && (
          <BottomSaveBtnContainer>
            <SaveBtn onClick={this.handleSubmit}>저장</SaveBtn>
          </BottomSaveBtnContainer>
        )}
      </div>
    );
  }
}

export default compose(
  graphql(myCounselSettingQuery, {
    options: {
      fetchPolicy: 'network-only',
    },
    name: 'myCounselSetting',
  }),
  graphql(updateCounselSettingMutate, {
    options: {
      refetchQueries: [
        {
          query: myCounselSettingQuery,
        },
      ],
    },
    name: 'updateCounselSetting',
  }),
  graphql(myPriceTableQuery, {
    options: {
      fetchPolicy: 'network-only',
    },
    name: 'myPriceTable',
  }),
  graphql(updatePriceTableMutate, {
    options: {
      refetchQueries: [
        {
          query: myPriceTableQuery,
        },
      ],
    },
    name: 'updatePriceTable',
  }),
)(withToast(HosAutoCounselInfoContainer));
