import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import Notice from './Notice';
import Guide from './Guide';
import Inquiry from './Inquiry';
import {
  PageContainer,
  PageTabBtnContainer,
  PageTabBtn,
  PageTabContent,
} from '../../components/basic/HtmlComponents';
import KakaoChannelBtn from '../../components/basic/KakaoChannelBtn';


class Cs extends Component {
  render() {
    const { location, match, history } = this.props;
    return (
      <PageContainer>
        <PageTabBtnContainer>
          <PageTabBtn
            active={
              location.pathname === '/cs'
              || location.pathname === '/cs/notice'
            }
            onClick={() => history.push('/cs/notice')}
          >
            공지사항
          </PageTabBtn>
          <PageTabBtn
            active={location.pathname === '/cs/guide'}
            onClick={() => history.push('/cs/guide')}
          >
            이용가이드
          </PageTabBtn>
          <PageTabBtn
            active={location.pathname === '/cs/inquiry'}
            onClick={() => history.push('/cs/inquiry')}
          >
            문의하기
          </PageTabBtn>
        </PageTabBtnContainer>
        <PageTabContent>
          <Route exact path={match.path} component={Notice} />
          <Route path={`${match.path}/notice`} component={Notice} />
          <Route path={`${match.path}/guide`} component={Guide} />
          <Route path={`${match.path}/inquiry`} component={Inquiry} />
        </PageTabContent>
        {/* 카카오 채널 버튼 */}
        <KakaoChannelBtn />
      </PageContainer>
    );
  }
}

export default Cs;
