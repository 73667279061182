import React, { Component } from 'react';
import { Query } from 'react-apollo';
import moment from 'moment';
import styled from 'styled-components';
import {
  HosRespTabContent,
  FilterContainer,
  FilterRow,
  RespDateRangePicker,
  RespPresetDatePicker,
  AddressSelector,
  UserNameFilter,
  SearchBtn,
  Separator,
  RespsTableContainer,
  CardItemContainer,
  HosRespsPagination,
  EmptyDataInfoMsg,
  FilterLabel,
  SeparatorDash,
  getTherapyString,
  Therapy,
} from './HosRespsCommonComponents';
import { unCheckedHosRespsForPartnerQuery } from '../../graphql';
import { getReqTypeLabel } from '../../utils/req-utils';
import { getOdocDateWithHourMin } from '../../utils/date';
import { rightContainerScrollTop } from '../../utils/dom-util';
import { getLocationNames } from '../../utils/locationdata';
import { FullScreenContainerModal } from '../basic/ModalComponents';
import HosRespDetail from '../../routes/HosRespDetail';

const ITEM_PER_PAGE = 15;

const UserName = styled.div`
  height: 34px;
  line-height: 34px;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: -0.6px;
  color: #000000;
`;

const UserLocation = styled.div`
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  letter-spacing: -0.4px;
  color: #000000;
`;

const UserReqCreatedAt = styled.div`
  height: 22px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  color: #b1b3b2;
  span {
    letter-spacing: normal;
    font-family: Saira Semi Condensed;
    font-stretch: semi-condensed;
  }
`;

const UserReqType = styled.div`
  height: 17px;
  line-height: 17px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.4px;
  text-align: right;
  color: #b1b3b2;
`;
class UncheckedResps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      locationSi: '',
      locationGu: '',
      username: '',
      variables: {
        // filtering variables, 검색 버튼 클릭시에만 update 하여 필터링한다.
        startDate: null,
        endDate: null,
        locationSi: '',
        locationGu: '',
        username: '',
        currentPage: 1,
        perPage: ITEM_PER_PAGE,
      },
      curHosRespId: null,
      showModal: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (!prevProps.visible && this.props.visible !== prevProps.visible) {
      this.refetch();
    }
  };

  onChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  onLocationSiChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value, locationGu: '' });
  };

  onDatesChange = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate });
  };

  // filtering variables 셋팅
  filterHosResps = () => {
    const { startDate, endDate, locationSi, locationGu, username } = this.state;

    const variables = {
      locationSi,
      locationGu,
      username,
      currentPage: 1,
      startDate: null,
      endDate: null,
      perPage: ITEM_PER_PAGE,
    };

    if (startDate && endDate) {
      variables.startDate = moment(startDate).format('YYYY-MM-DD');
      variables.endDate = moment(endDate).format('YYYY-MM-DD');
    }

    this.setState({ variables });
  };

  // filteringVariable에 currentPage update
  onPageChange = (page) => {
    this.setState({
      variables: { ...this.state.variables, currentPage: page },
    });
    rightContainerScrollTop();
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      curHosRespId: null,
    });
  };

  render() {
    const {
      startDate,
      endDate,
      locationSi,
      locationGu,
      username,
      variables,
    } = this.state;
    const { visible } = this.props;
    return (
      <HosRespTabContent visible={visible}>
        <FilterContainer>
          <Separator></Separator>
          <FilterRow>
            <FilterLabel>날짜 검색</FilterLabel>
            <RespDateRangePicker
              startDate={startDate}
              endDate={endDate}
              onDatesChange={this.onDatesChange}
            />
            <RespPresetDatePicker
              startDate={startDate}
              endDate={endDate}
              onDatesChange={this.onDatesChange}
            />
          </FilterRow>
          <FilterRow>
            <FilterLabel>지역 검색</FilterLabel>
            <AddressSelector
              locationSi={locationSi}
              locationGu={locationGu}
              onLocationSiChange={this.onLocationSiChange}
              onLocationGuChange={this.onChange}
            />
          </FilterRow>
          <FilterRow>
            <FilterLabel>고객 검색</FilterLabel>
            <UserNameFilter username={username} onChange={this.onChange} />
          </FilterRow>
          <Separator></Separator>
          <SearchBtn style={{ marginTop: 30 }} onClick={this.filterHosResps}>
            검색
          </SearchBtn>
        </FilterContainer>
        <Query query={unCheckedHosRespsForPartnerQuery} variables={variables}>
          {({ data, refetch }) => {
            const { unCheckedHosRespsForPartner } = data;
            this.refetch = refetch;
            return (
              <>
                <RespsTableContainer>
                  {unCheckedHosRespsForPartner &&
                  unCheckedHosRespsForPartner?.hosResps &&
                  unCheckedHosRespsForPartner?.hosResps?.length > 0 ? (
                    unCheckedHosRespsForPartner?.hosResps?.map(
                      (hosResp, index) => (
                        <CardItemContainer
                          key={index}
                          onClick={() => {
                            this.setState({
                              curHosRespId: hosResp.id,
                              showModal: true,
                            });
                          }}>
                          <div
                            style={{
                              height: 22,
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}>
                            <UserReqCreatedAt>
                              상담신청:{' '}
                              <span>
                                {getOdocDateWithHourMin(
                                  hosResp.userRequest.createdAt,
                                )}
                              </span>
                            </UserReqCreatedAt>
                            <UserReqType>
                              {getReqTypeLabel(hosResp.userRequest.reqType)}
                            </UserReqType>
                          </div>
                          <UserName style={{ marginTop: 30 }}>
                            {hosResp.userRequest.user.username}
                          </UserName>
                          <UserLocation style={{ marginTop: 8 }}>
                            {hosResp.userRequest.directReq
                              ? '다이렉트 상담'
                              : getLocationNames(
                                  hosResp.userRequest.locations,
                                  ', ',
                                )}
                          </UserLocation>
                          <SeparatorDash
                            style={{ marginTop: 40 }}></SeparatorDash>
                          <Therapy>
                            {getTherapyString(
                              hosResp.orthoPrices,
                              hosResp.txPrices,
                            )}
                          </Therapy>
                        </CardItemContainer>
                      ),
                    )
                  ) : (
                    <EmptyDataInfoMsg style={{ marginTop: 120 }}>
                      조회 내역이 없습니다.
                    </EmptyDataInfoMsg>
                  )}
                </RespsTableContainer>
                {unCheckedHosRespsForPartner &&
                  unCheckedHosRespsForPartner.pagination && (
                    <HosRespsPagination
                      pagination={unCheckedHosRespsForPartner.pagination}
                      onPageChange={this.onPageChange}
                    />
                  )}
              </>
            );
          }}
        </Query>
        <FullScreenContainerModal
          shouldReturnFocusAfterClose={false}
          isOpen={this.state.showModal}
          ariaHideApp={false}
          style={{
            overlay: {
              zIndex: 100,
              height: '100%',
            },
          }}>
          <HosRespDetail
            hrId={this.state.curHosRespId}
            closeModal={this.closeModal}></HosRespDetail>
        </FullScreenContainerModal>
      </HosRespTabContent>
    );
  }
}

export default UncheckedResps;
