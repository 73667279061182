import React from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { withRouter } from 'react-router-dom';
import { graphql, compose, Query } from 'react-apollo';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';

import {
  RadioInput, PageTabBtn, PageTabBtnContainer, PageTabContent, PageContainer,
} from '../components/basic/HtmlComponents';
import withToast from '../utils/toasthoc';
import withAlertModal from '../utils/alerthoc';
import InfoImgMobile from '../images/img-cash-info-mobile.png';
import HanaLogoImg from '../images/hana-bank-logo.png';
import { media } from '../globalStyle';
import {
  addOdocLogMutation,
  getBalanceQuery,
  getAccountHistoryQuery,
} from '../graphql';
import PhonenumInput from '../components/form/PhonenumInput';
import CheckboxOffIcon from '../images/ic-checkbox-off-20-px.png';
import CheckboxOnIcon from '../images/ic-checkbox-on-20-px.png';
import OdocCashImg from '../images/img-odoctorcash.jpg';
import isSameDay from '../utils/isSameDay';
import StyledPagination from '../components/basic/StyledPagination';
import { getAccountHistoryMethod } from '../utils/cash-util';
import { AuthContext } from '../utils/AuthProvider';
import { cashLogToSlack } from '../utils/logging';
import { rightContainerScrollTop } from '../utils/dom-util';

const OdocCashContainer = styled.div`
  min-width: 920px;
  ${media.miniDesktop`
    min-width: 0;
  `};
`;

const ContentContainer = styled.div`
  width: 100%;
  border-radius: 1px;
  background-color: #ffffff;
  border: solid 1px #e0e0e0;
  padding: 40px 40px;
  margin-bottom: 13px;
  ${media.miniDesktop`
    padding: 40px 20px;
  `};
`;

const InfoImgContainer = styled(ContentContainer)`
  padding: 0;
  position: relative;
  ${media.miniDesktop`
    padding: 0;
  `};
`;

const InfoImgTag = styled.img`
  width: 100%;
  object-fit: contain;
  ${media.miniDesktop`
    content: url(${InfoImgMobile});
  `};
`;

const InfoTextSection = styled.div`
  div {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.11;
    letter-spacing: -1px;
    color: #000000;
    margin-bottom: 10px;
  }
  ul {
    list-style: none;
    li {
      margin-left: 8px;
      line-height: 1.57;
      text-indent: -8px;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: -0.88px;
      color: #000000;
    }
    li ~ li {
      margin-top: 9px;
    }
    & > li:before {
      content: '-';
      text-indent: -8px;
      margin-right: 5px;
    }
  }
`;

const InfoTextContainer = styled.div`
  position: relative;
  top: -40px;
  padding: 40px 40px 0 40px;
  ${InfoTextSection} ~ ${InfoTextSection} {
    border-top: 1px solid rgba(224, 224, 224, 0.6);
    margin-top: 30px;
    padding-top: 30px;
  }
`;

const PriceBig = styled(NumberFormat)`
  ${props => props.small && 'font-size: 14px'};
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 45px;
  letter-spacing: normal;
  color: #033e52;
`;

const Header = styled.div`
  width: 100%;
  background-color: #ffffff;
  ${media.miniDesktop`
    margin-bottom: 0px;
  `};
`;

const BtnContainer = styled.div`
  text-align: left;
  margin-top: 60px;
  button {
    padding: 14px 44px;
    border-radius: 1px;
    background-color: #033e52;
    border: 0;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -1.14px;
    text-align: center;
    color: #ffffff;
    &:disabled {
      background-color: #bbbcbc;
    }
  }
`;

const BankAccountInfo = styled.div`
  padding: 0 0 40px;
  & > div {
    display: inline-block;
    vertical-align: top;
  }
`;

const BankLogoContainer = styled.div`
  img {
    width: 42px;
    height: 42px;
    display: inline-block;
  }
  margin-right: 20px;
`;

const BankAccountTop = styled.div`
  span {
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.4;
    letter-spacing: -1.58px;
    color: #000000;
    display: inline-block;
    vertical-align: middle;
  }
  span:last-of-type {
    letter-spacing: normal;
  }
  span ~ span {
    margin-left: 8px;
  }
`;
const BankAccountBottom = styled.div`
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.84px;
  color: #033e52;
  margin-top: 2px;
`;

const ChargeInput = styled.input`
  width: 100%;
  padding: 14px 8px;
  border-radius: 1px;
  border: solid 1px #e0e0e0;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  &:disabled {
    background-color: #f7f7f7;
    color: #bbbcbc;
  }
`;

const ChargeNumberInput = styled(NumberFormat)`
  width: 100%;
  padding: 14px 50px 14px 8px;
  border-radius: 1px;
  border: solid 1px #e0e0e0;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  position: relative;
  ${props => props.error
    && `
    border: solid 1px #dd4124;
  `};
  &:disabled {
    background-color: #f7f7f7;
    color: #bbbcbc;
  }
`;

const ChargePriceErrorMsg = styled.div`
  position: absolute;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: -1px;
  color: #dd4124;
  bottom: -16px;
  left: 0;
`;

const ChargeNumberInputLabel = styled.div`
  display: inline-block;
  position: absolute;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #bbbcbc;
  right: 30px;
  bottom: 11px;
  ${props => (props.active ? 'display: inline-block' : 'display: none')};
`;

const ChargeInputContainer = styled.div`
  margin-right: -20px;
  margin-bottom: 40px;
  & > div {
    width: 33.333%;
    padding-right: 20px;
    display: inline-block;
    margin-bottom: 20px;
  }
`;

const ChargeNumInputContainer = styled.div`
  position: relative;
`;

const ChargeInputTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.75px;
  color: #000000;
  margin-bottom: 8px;
`;

const ChargeInputDesc = styled.span`
  float: right;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.75px;
  text-align: right;
  color: #bbbcbc;
`;

const ChargeInfoTitle = styled.div`
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: -1px;
  color: #000000;
  margin-bottom: 10px;
`;

const ChargeInfoList = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: -0.88px;
  color: #000000;
  ul {
    list-style: none;
    li {
      margin-left: 8px;
      text-indent: -8px;
    }
    & > li:before {
      content: '-';
      text-indent: -8px;
      margin-right: 5px;
    }
  }

  a, a:active, a:link, a:focus, a:hover {
    color: #000000;
  }
`;

const CheckboxInput = styled.div`
  margin-top: 20px;
  input {
    display: none;
  }
  input + label {
    width: 18px;
    height: 18px;
    background-image: url(${CheckboxOffIcon});
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
  }
  input:checked + label {
    background-image: url(${CheckboxOnIcon});
  }
  label:last-of-type {
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: -0.5px;
    color: #000000;
    display: inline-block;
    margin-left: 9px;
    vertical-align: middle;
  }
`;

const ChargeInfoLink = styled.u`
  cursor: pointer;
`;

const ChargeListContainer = styled.div``;

const ChargeListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    display: inline-block;
  }
`;

const ChargeFilter = styled.div`
  padding: 20px 0;
  border-bottom: 1px solid #d6d6d6;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
`;

const RadioInputList = styled.div`
  display: inline-block;
  margin-left: 20px;
`;

const ChargeListFilterContainer = styled.div`
  margin-top: 60px;
  border-top: 1px solid #000000;
`;

const ChargeListTitle = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
`;

const ChargeBtn = styled.button`
  width: 200px;
  padding: 14px 0;
  border-radius: 1px;
  background-color: #033e52;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  float: right;
`;

const StyledDateRangePicker = styled.div`
  display: inline-block;
  .DateRangePicker {
    .DateRangePickerInput__withBorder {
      border: none;
      .DateInput {
        width: 144px;
        height: 40px;
        margin-left: 16px;
        input {
          padding: 0;
          border: 1px solid #d6d6d6;
          text-align: left;
          width: 100%;
          height: 100%;
          padding: 0 17px;
          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: -1px;
          color: #000000;
        }
      }
      .DateRangePickerInput_arrow {
        display: none;
      }
    }
    .DateRangePickerInput_clearDates {
      right: -23px;
    }
  }
  .CalendarDay__default {
    line-height: 38px;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover,
  .CalendarDay__selected_span {
    background: #033e52;
    border-color: #033e52;
  }
`;

const PresetDatePickerContainer = styled.div`
  display: inline-block;
  margin-left: 72px;
`;

const PresetDatePicker = styled.span`
  display: inline-block;
  width: 70px;
  height: 40px;
  border: solid 1px #d6d6d6;
  background-color: #ffffff;
  padding: 9px 2px;
  margin-right: 6px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #b2b4b2;
  cursor: pointer;
  &.active {
    background-color: #033e52;
    border: solid 1px #033e52;
    color: #ffffff;
  }
`;

const ChargeTd = styled.td`
  button {
    border: 0;
    padding: 0;
    margin: 0;
    text-decoration: underline;
    &:disabled {
      text-decoration: none;
    }
  }
`;

const BlankTd = styled.td``;

const ChargeListTable = styled.table`
  border: 0;
  margin-top: 60px;
  width: 100%;
  thead {
    border-bottom: solid 1px #000000;
  }
  th {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    text-align: center;
    letter-spacing: -1px;
    color: #000000;
    padding-bottom: 6px;
  }
  tr {
    td:nth-of-type(2),
    td:last-of-type {
      letter-spacing: -1px;
    }
    td:nth-of-type(2), td:nth-of-type(3) {
      color: #dd4124;
    }
    &.add {
      td:nth-of-type(2), td:nth-of-type(3) {
        color: #0a588b;
      }
    }
  }
  td {
    border-bottom: solid 1px #e0e0e0;
    text-align: center;
    padding: 10px 0;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }
  ${BlankTd} {
    border: 0;
    padding: 60px 0;
    color: #bbbcbc;
  }
`;

const HistoryPaginationWapper = styled.div`
  margin-top: 34px;
`;

const ITEM_PER_PAGE = 20;

class OdoctorCash extends React.Component {
  static defaultProps = {
    presets: [
      {
        text: '오늘',
        start: moment(),
        end: moment(),
      },
      {
        text: '1주일',
        start: moment().subtract(1, 'week'),
        end: moment(),
      },
      {
        text: '1개월',
        start: moment().subtract(1, 'month'),
        end: moment(),
      },
      {
        text: '3개월',
        start: moment().subtract(3, 'months'),
        end: moment(),
      },
    ],
  };

  state = {
    openedTab: (this.props.location.state && this.props.location.state.openedTab) || 'charge', // 선택된 탭
    agreeCharge: false, // 충전금 이용 정책 동의
    senderContactNum: '', // 담당자 연락처
    senderName: '', // 입금자명
    chargePrice: '', // 입금액
    chargePriceError: false, // 입금액 valid
    startDate: null, // 충전금 내역 날짜 필터
    endDate: null, //충전금 내역 날짜필터
    direction: 'all', //충전금 내역 구분 필터
    historyCurrentPage: 1,
  };

  calcDepositAmount = (chargePrice) => {
    if (!chargePrice || chargePrice === '0') {
      return '';
    }

    const intChargePrice = parseInt(chargePrice);
    const depositAmount = (intChargePrice * 1.1).toFixed(1); // string 형

    // 소숫점 자리가 0일 경우 int 형으로 리턴
    if (depositAmount.indexOf('.0') !== -1) {
      return parseInt(depositAmount);
    }

    // 소숫점 자리가 0이 아닐경우 float 형태로 그대로 리턴 
    return depositAmount;
  }

  checkDeposit = async () => {
    const { chargePrice, senderName, senderContactNum } = { ...this.state };
    const chargePriceNum = parseInt(chargePrice);
    const chargePriceWithComma = chargePriceNum.toLocaleString();
    const { meh } = this.context;

    if (!chargePrice || chargePrice < 10 || chargePrice > 1000) {
      this.setState({ chargePriceError: true });
      return;
    }

    const logMsg = `[입금확인 요청]\n - 병원: ${meh.hospital.name} (${meh.hospital.id})\n - 입금자명: ${senderName}(${senderContactNum})\n - 충전금액: ${chargePriceWithComma} 만원`;

    try {
      // addLog
      const addLogResp = await this.props.addOdocLog({
        variables: {
          message: logMsg,
        },
      });

      // webhook 이용하여 slack에 메세지 보내기
      const resp = await cashLogToSlack(logMsg);

      if (resp.status !== 200) {
        this.props.toast(
          '입금 확인이 요청실패. 잠시후에 다시 시도해주세요',
          'warn',
        );
        return;
      }

      this.props.openAlert({
        title: '츙전 신청 완료',
        message: (
          <div>
            충전 신청이 완료되었습니다.
            <br />
            입금하실 금액은 {this.calcDepositAmount(chargePriceNum)}만원 이며, 충전금 잔액 업데이트 시 담당자 연락처로 메시지가 발송됩니다.
          </div>
        ),
        showConfirmBtn: true,
        confirmBtnName: '확인',
        textAlign: 'left',
        confirmBtnOnClick: () => this.setState({ openedTab: 'info' }),
        showCancelBtn: false,
      });
    } catch (err) {
      console.log(err);
    }
  };

  onChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  render() {
    const {
      openedTab,
      agreeCharge,
      chargePrice,
      senderName,
      senderContactNum,
      chargePriceError,
      startDate,
      endDate,
      direction,
      historyCurrentPage,
    } = this.state;

    const { presets } = this.props;
    const historyQueryVariables = {
      direction,
      perPage: ITEM_PER_PAGE,
      currentPage: historyCurrentPage,
    };

    if (startDate && endDate) {
      historyQueryVariables.startDate = moment(startDate).format('YYYY-MM-DD');
      historyQueryVariables.endDate = moment(endDate).format('YYYY-MM-DD');
    }

    const depositAmount = this.calcDepositAmount(chargePrice);

    return (
      <PageContainer>
        <OdocCashContainer>
          <Header>
            <PageTabBtnContainer>
              <PageTabBtn
                active={openedTab === 'charge'}
                onClick={() => this.setState({ openedTab: 'charge' })}
              >
                충전신청
              </PageTabBtn>
              <PageTabBtn
                active={openedTab === 'list'}
                onClick={() => this.setState({ openedTab: 'list' })}
              >
                충전금내역
              </PageTabBtn>
              <PageTabBtn
                active={openedTab === 'info'}
                onClick={() => this.setState({ openedTab: 'info' })}
              >
                충전금안내
              </PageTabBtn>
            </PageTabBtnContainer>
          </Header>
          <PageTabContent>
            {/* 충전신청 */}
            {openedTab === 'charge' && (
              <div>
                <BankAccountInfo>
                  <BankLogoContainer>
                    <img src={HanaLogoImg} alt="충전신청" />
                  </BankLogoContainer>
                  <div>
                    <BankAccountTop>
                      <span>하나은행</span>
                      <span>189-910039-78404</span>
                    </BankAccountTop>
                    <BankAccountBottom>주식회사 오닥터</BankAccountBottom>
                  </div>
                </BankAccountInfo>
                <ContentContainer>
                  <ChargeInputContainer>
                    <div>
                      <ChargeInputTitle>담당자 연락처</ChargeInputTitle>
                      <PhonenumInput
                        name="senderContactNum"
                        value={senderContactNum}
                        onChange={this.onChange}
                        placeholder="휴대폰번호 (예시: 01012345678)"
                        border
                      />
                    </div>
                    <div>
                      <ChargeInputTitle>입금자명</ChargeInputTitle>
                      <ChargeInput
                        type="text"
                        placeholder="입금자명"
                        name="senderName"
                        value={senderName}
                        onChange={this.onChange}
                      />
                    </div>
                    <ChargeNumInputContainer>
                      <ChargeInputTitle>
                        충전금액
                        <ChargeInputDesc>
                          최소 10만원~최대 1,000만원
                        </ChargeInputDesc>
                      </ChargeInputTitle>
                      <ChargeNumberInput
                        thousandSeparator
                        error={chargePriceError ? 'chargePriceError' : undefined}
                        placeholder="충전금액 (만원)"
                        value={chargePrice}
                        onValueChange={(values) => {
                          this.setState({
                            chargePrice: values.value,
                            chargePriceError: false,
                          });
                        }}
                      />
                      <ChargeNumberInputLabel active={chargePrice}>
                        만원
                      </ChargeNumberInputLabel>
                      {chargePriceError && (
                        <ChargePriceErrorMsg>
                          올바른 금액을 입력하세요
                        </ChargePriceErrorMsg>
                      )}
                    </ChargeNumInputContainer>
                    <ChargeNumInputContainer>
                      <ChargeInputTitle>
                        입금필요금액
                        <ChargeInputDesc>
                          충전금액에 부가가치세(VAT)를 포함한 금액
                        </ChargeInputDesc>
                      </ChargeInputTitle>
                      <ChargeNumberInput
                        thousandSeparator
                        placeholder="입금필요금액(만원)"
                        value={depositAmount}
                        disabled
                      />
                      <ChargeNumberInputLabel active={depositAmount}>
                        만원
                      </ChargeNumberInputLabel>
                    </ChargeNumInputContainer>
                  </ChargeInputContainer>
                  <div>
                    <ChargeInfoTitle>충전 유의사항</ChargeInfoTitle>
                    <ChargeInfoList>
                      <ul>
                        <li>입금 계좌번호와 예금주명을 확인하여 입금 오류에 주의하시기 바랍니다.</li>
                        <li>입금 시 입금자명이 충전 신청한 입금자명과 반드시 일치해야 합니다.</li>
                        <li>입금 완료 후 충전 처리까지는 보통 5분 정도가 소요되며 상황에 따라 지연될 수 있습니다.</li>
                        <li>충전 완료 시, 담당자 연락처로 충전 완료 문자가 발송됩니다.</li>
                        <li>입금한 금액에서 부가가치세(VAT)를 제외한 금액이 충전됩니다.</li>
                        <li>입금 과정에서 오류 발생 시, 고객센터(<u><a href="mailto: partner@odoctor.co.kr?subject=문의하기&body=오닥터%20고객센터입니다.%0A문의할%20사항이나%20서비스%20사용중%20궁금하신점을%20작성해주세요%0A%0A성함%20혹은%20아이디%20:%0A%0A문의내용%20:">partner@odoctor.co.kr</a></u>)로 연락 주시기 바랍니다.</li>
                        <li>입금 계좌번호와 예금주명을 확인하여 입금 오류에 주의하시기 바랍니다.</li>
                      </ul>
                    </ChargeInfoList>
                    <CheckboxInput>
                      <input
                        id="agreeCharge"
                        name="agreeCharge"
                        checked={agreeCharge}
                        onChange={() => this.setState({ agreeCharge: !agreeCharge })
                        }
                        type="checkbox"
                      />
                      <label htmlFor="agreeCharge" />
                      <label htmlFor="agreeCharge">
                        오닥터의
                        {' '}
                        <ChargeInfoLink
                          onClick={() => this.setState({ openedTab: 'info' })}
                        >
                          충전금 이용 및 환불 정책
                        </ChargeInfoLink>
                        에 동의합니다.
                      </label>
                    </CheckboxInput>
                  </div>
                  <BtnContainer>
                    <button
                      disabled={
                        !senderContactNum
                        || !senderName
                        || !chargePrice
                        || !agreeCharge
                      }
                      onClick={this.checkDeposit}
                    >
                      충전 신청하기
                    </button>
                  </BtnContainer>
                </ContentContainer>
              </div>
            )}
            {/* 충전금내역 */}
            {openedTab === 'list' && (
              <ChargeListContainer>
                <ChargeListHeader>
                  <div>
                    <ChargeListTitle>잔여 충전금</ChargeListTitle>
                    <Query query={getBalanceQuery} fetchPolicy="network-only">
                      {({ loading, data }) => {
                        if (
                          !loading
                          && data.getBalance
                          && data.getBalance.balanceTotal
                        ) {
                          const balance = parseInt(data.getBalance.balanceTotal);

                          return (
                            <PriceBig
                              value={balance}
                              displayType="text"
                              thousandSeparator
                              suffix="원"
                            />
                          );
                        }

                        return (
                          <PriceBig
                            value={0}
                            displayType="text"
                            thousandSeparator
                            suffix="원"
                          />
                        );
                      }}
                    </Query>
                  </div>
                  <ChargeBtn
                    onClick={() => this.setState({ openedTab: 'charge' })}
                  >
                    충전신청
                  </ChargeBtn>
                </ChargeListHeader>
                <ChargeListFilterContainer>
                  <ChargeFilter>
                    날짜 검색
                    <StyledDateRangePicker>
                      <DateRangePicker
                        startDate={startDate} // momentPropTypes.momentObj or null,
                        startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                        endDate={endDate} // momentPropTypes.momentObj or null,
                        endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                        onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })
                        } // PropTypes.func.isRequired,
                        focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={focusedInput => this.setState({ focusedInput })
                        } // PropTypes.func.isRequired,
                        isOutsideRange={() => false}
                        startDatePlaceholderText="시작 날짜"
                        endDatePlaceholderText="종료 날짜"
                        displayFormat="YYYY.MM.DD"
                        showClearDates
                      />
                    </StyledDateRangePicker>
                    <PresetDatePickerContainer>
                      {presets.map(({ text, start, end }) => {
                        const isSelected = isSameDay(start, startDate) && isSameDay(end, endDate);
                        return (
                          <PresetDatePicker
                            key={text}
                            onClick={() => this.setState({
                              startDate: start,
                              endDate: end,
                            })
                            }
                            className={isSelected ? 'active' : ''}
                          >
                            {text}
                          </PresetDatePicker>
                        );
                      })}
                    </PresetDatePickerContainer>
                  </ChargeFilter>
                  <ChargeFilter>
                    내역구분
                    <RadioInputList>
                      <RadioInput
                        id="all"
                        name="direction"
                        onChange={this.onChange}
                        value="all"
                        checked={direction === 'all'}
                        labelName="전체"
                      />
                      <RadioInput
                        id="in"
                        name="direction"
                        onChange={this.onChange}
                        value="in"
                        checked={direction === 'in'}
                        labelName="충전"
                      />
                      <RadioInput
                        id="out"
                        name="direction"
                        onChange={this.onChange}
                        value="out"
                        checked={direction === 'out'}
                        labelName="소진"
                      />
                    </RadioInputList>
                  </ChargeFilter>
                </ChargeListFilterContainer>
                <Query
                  query={getAccountHistoryQuery}
                  variables={historyQueryVariables}
                  fetchPolicy="network-only"
                >
                  {({ data }) => (
                    <div>
                      <ChargeListTable>
                        <thead>
                          <tr>
                            <th>일자</th>
                            <th>내역</th>
                            <th>금액</th>
                            <th>잔액</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(
                            data
                            && data.getAccountHistory
                            && data.getAccountHistory.history
                            && data.getAccountHistory.history.length > 0) ? (
                              <React.Fragment>
                                {
                                  data.getAccountHistory.history.map(account => (
                                    <tr key={`accounthistory_${account.id}`} className={account.direction === 'in' ? 'add' : ''}>
                                      <td>{moment(account.createdAt).format('YYYY.MM.DD')}</td>
                                      <ChargeTd>
                                        {account.method === 'withdraw_counsel'
                                        || account.method === 'refund_duplicated'
                                        || account.method === 'refund_invalidphone' 
                                        || account.method === 'withdraw_counsel_by_package_plan' ? (
                                          <button
                                            disabled={!account.hosResps}
                                            onClick={() => window.open(`/hosresps/${account.hosResps.id}`)}
                                          >
                                            {account.direction === 'in' ? '충전' : '소진'}
                                            {account.method
                                              ? `(${getAccountHistoryMethod(account.method)})`
                                              : null}
                                          </button>
                                          ) : (
                                            <div>
                                              {account.direction === 'in'
                                                ? '충전'
                                                : '소진'}
                                              {account.method
                                                ? `(${getAccountHistoryMethod(
                                                  account.method,
                                                )})`
                                                : null}
                                            </div>
                                        )}
                                      </ChargeTd>
                                      <ChargeTd>
                                        {account.direction === 'in' && '+'}
                                        {account.amount && parseInt(account.amount).toLocaleString()}
                                      </ChargeTd>
                                      <td>{account.cumSum && parseInt(account.cumSum).toLocaleString()}</td>
                                    </tr>
                                  ))
                                }
                              </React.Fragment>
                            ) : (
                              <tr><BlankTd colSpan="5">충전금 내역이 없습니다.</BlankTd></tr>
                            )
                          }
                        </tbody>
                      </ChargeListTable>
                      { data
                        && data.getAccountHistory
                        && data.getAccountHistory.pagination
                        && data.getAccountHistory.pagination.total > ITEM_PER_PAGE
                        && (
                          <HistoryPaginationWapper>
                            <StyledPagination
                              activePage={data.getAccountHistory.pagination.currentPage}
                              itemsCountPerPage={ITEM_PER_PAGE}
                              totalItemsCount={data.getAccountHistory.pagination.total}
                              pageRangeDisplayed={5}
                              onChange={(page) => {
                                rightContainerScrollTop();
                                this.setState({ historyCurrentPage: page });
                              }}
                            />
                          </HistoryPaginationWapper>
                        )
                      }
                    </div>
                  )}
                </Query>
              </ChargeListContainer>
            )}
            {/* 충전금안내 */}
            {openedTab === 'info' && (
              <InfoImgContainer>
                <InfoImgTag src={OdocCashImg} />
                <InfoTextContainer>
                  <InfoTextSection>
                    <div>충전금이란</div>
                    <ul>
                      <li>
                        일정 금액을 사전 결제하여 충전한 후, 오닥터 플랫폼의 광고
                        비용 결제 시에 이용하는 선불식 충전금입니다.
                      </li>
                      <li>충전금 10원은 현금 10원과 같습니다.</li>
                    </ul>
                  </InfoTextSection>
                  <InfoTextSection>
                    <div>이용안내</div>
                    <ul>
                      <li>
                        1회 충전 시 최소 100,000원 ~ 최대 10,000,000원까지 충전
                        가능하며, 누적 한도 10,000,000원까지 충전할 수 있습니다.
                      </li>
                      <li>
                        충전금의 유효기간은 최종 사용일로부터 회원 탈퇴
                        전까지입니다.
                      </li>
                      <li>
                        경우에 따라 충전한 충전금의 금액이 모두 소진된 이후에도
                        추가적인 과금(마이너스 잔액)이 발생할 수 있습니다.
                      </li>
                      <li>
                        추가적인 과금(마이너스 잔액)으로 인한 마이너스 잔액 소진을
                        위해 추가 입금이 필요할 수 있습니다.
                      </li>
                      <li>
                        충전금의 충전 금액에는 부가가치세(VAT)가 포함되어 있지
                        않으며, 회사는 충전금에 대해 회원에게 이자를 지급하지
                        않습니다.
                      </li>
                      <li>
                        세금계산서 상의 광고비 내역은 유상 소진이며, 무상으로
                        충전된 무상 충전금의 소진액은 세금계산서에 포함되지
                        않습니다.
                        <br />
                        (단, 세금계산서 발행 대상이 아닌 위약금이나 보상 등이
                        발생한 경우는 세금계산서 금액과 유상 소진액에 차이가 있을
                        수 있습니다.)
                      </li>
                    </ul>
                  </InfoTextSection>
                  <InfoTextSection>
                    <div>충전금의 환불/취소</div>
                    <ul>
                      <li>
                        각종 이벤트를 통해 회사가 무료로 제공한 충전금은 환불이
                        불가합니다.
                      </li>
                      <li>
                        회원이 타인의 명의나 결제 정보를 도용하는 등 부정한
                        방법으로 이용계약을 체결하거나 대금을 결제한 사실이
                        인정되는 경우 환불이 불가합니다.
                      </li>
                      <li>
                        충전금을 이용하고 남은 잔액은 위약금 없이 환불하여
                        드립니다. 단, 환불 금액이 1,000원 미만인 경우 환불이
                        불가합니다.
                      </li>
                      <li>
                        탈퇴 시 충전금은 파트너사에서 지정한 환불계좌로
                        환불됩니다.
                        <br />
                        단, 파트너사가 직접 충전한 충전금 이외에 오닥터에서 무료로
                        제공한 충전금은 환불되지 않고 자동 소멸되며, 소멸 이후
                        사용 및 환불이 불가능합니다.
                      </li>
                    </ul>
                  </InfoTextSection>
                </InfoTextContainer>
              </InfoImgContainer>
            )}
          </PageTabContent>
        </OdocCashContainer>
      </PageContainer>
    );
  }
}

OdoctorCash.contextType = AuthContext;

const OdoctorCashWithFunc = compose(
  graphql(addOdocLogMutation, {
    name: 'addOdocLog',
  }),
)(withRouter(withToast(withAlertModal(OdoctorCash))));

export default OdoctorCashWithFunc;
