import React from 'react';
import styled from 'styled-components';
import Logo from '../images/logo-white.svg';
import Footer from '../components/Footer';

const CertificationContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: white;
  margin: 0 auto;
`;

const Header = styled.div`
  width: 100%;
  height: 60px;
  object-fit: contain;
  background-color: #38c8e5;
  padding: 15px 20px;
  margin-bottom: 30px;
`;

const LogoImg = styled.img`
  margin-top: -7px;
`;

const StyledSpan = styled.span`
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  color: #ffffff;
  float: right;
  margin-top: 10px;
`;

const Title = styled.div`
  font-weight: 500;
  text-align: center;
  margin-bottom: 30px;
  font-size: 16px;
`;

const Body = styled.div`
  padding: 30px 0 50px;
  text-align: center;
`;

const Content = styled.div`
  font-size: 13px;
  line-height: 20px;
`;

const AbsoluteFooter = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
`;

const Certification = () => (
  <div>
    <CertificationContainer>
      <Header>
        <LogoImg alt="logo" src={Logo} />
        <StyledSpan>파트너병원</StyledSpan>
      </Header>
      <Body>
        <Title>오닥터 파트너 회원이 되신것을 환영합니다.</Title>
        <Content>
          회원님은 인증 대기중입니다.<br />
          관리자가 회원 인증 후 서비스를 이용하실 수 있습니다.<br />
          문의사항은 오른쪽 하단 문의하기 버튼을 눌러 문의해주세요.
        </Content>
      </Body>
    </CertificationContainer>
    <AbsoluteFooter>
      <Footer />
    </AbsoluteFooter>
  </div>
);

export default Certification;
