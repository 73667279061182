import React from 'react';
import styled from 'styled-components';
import PassInvisibleToggleBtnImg from '../../images/ic-none-view-24.svg';
import PassVisibleToggleBtnImg from '../../images/ic-view-24.svg';
import { getPassValidationMessage } from '../../utils/validationInput';
import FormInput from './FormInput';

const PassVisibleToggleBtn = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
  z-index: 2;
`;

const PasswordInputContainer = styled.div`
  position: relative;
`;

class PasswordInput extends React.Component {
  state = {
    passInputType: 'password',
    passValidationMsg: '',
  };

  onChange = (e) => {
    this.props.onChange(e);
    const passValidationMsg = getPassValidationMessage(e.target.value);
    this.setState({ passValidationMsg });
  }

  togglePassVisibleBtn = () => {
    const { passInputType } = this.state;

    if (passInputType === 'password') {
      this.setState({ passInputType: 'text' });
    }

    if (passInputType === 'text') {
      this.setState({ passInputType: 'password' });
    }
  };

  render() {
    const { passInputType, passValidationMsg } = this.state;
    const {
      value, name, placeholder, noValidate, fireSubmitValidation, errorMsg,
    } = this.props;

    let passErrorMsg = '';

    if (!noValidate && passValidationMsg) {
      passErrorMsg = passValidationMsg;
    }

    return (
      <PasswordInputContainer>
        <FormInput
          {...this.props}
          type={passInputType}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={this.onChange}
          style={{ paddingRight: '42px' }}
          fireSubmitValidation={fireSubmitValidation}
          errorMsg={errorMsg || passErrorMsg || ''}
        />
        {passInputType === 'password' ? (
          <PassVisibleToggleBtn onClick={this.togglePassVisibleBtn}>
            <img
              src={PassInvisibleToggleBtnImg}
              alt="PassInvisibleToggleBtnImg"
            />
          </PassVisibleToggleBtn>
        ) : (
          <PassVisibleToggleBtn onClick={this.togglePassVisibleBtn}>
            <img src={PassVisibleToggleBtnImg} alt="PassVisibleToggleBtnImg" />
          </PassVisibleToggleBtn>
        )}
      </PasswordInputContainer>
    );
  }
}
export default PasswordInput;
