import React, { Component } from 'react';
import styled from 'styled-components';

import analtemp from '../images/analytic-904.png';

const ImgContainer = styled.img`
  width: 100%;
  max-width: 904px;
`;

class Analytics extends Component {
  render() {
    return (
      <div>
        <ImgContainer src={analtemp} alt="" />
      </div>
    );
  }
}

export default Analytics;
