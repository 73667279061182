import React from 'react';
import styled from 'styled-components';
import DownloadIcon from '../../images/download.svg';

const DownloadBox = styled.a`
  display: block;
  width: 240px;
  padding: 15px 18px 15px 20px;
  border: solid 1px #000000;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: -0.78px;
  color: #000000;
  text-align: left;
  margin-bottom: 90px;
  img {
    width: 20px;
    height: 20px;
    float: right;
  }
  :active, :focus, :hover {
    color: #000000;
  }
`;

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  padding-top: 30px; height: 0; overflow: hidden;
`;

const Video = styled.iframe`
  border: solid 1px #d6d6d6;
  background-color: #f4f4f4;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Guide = () => (
  <div>
    <DownloadBox
      href="https://odoctor-assets.s3.ap-northeast-2.amazonaws.com/files/odoctor_%E1%84%91%E1%85%A1%E1%84%90%E1%85%B3%E1%84%82%E1%85%A5%E1%84%80%E1%85%AA%E1%84%80%E1%85%B3%E1%86%B7%E1%84%8B%E1%85%A1%E1%86%AB%E1%84%82%E1%85%A2.pdf"
      target="_blank"
    >
      파트너 과금안내 PDF
      <img alt="파트너 과금안내 PDF" src={DownloadIcon} />
    </DownloadBox>
    <div style={{ maxWidth: '920px' }}>
      <VideoContainer>
        <Video
          title="오닥터 파트너 이용가이드"
          width="920"
          height="518"
          src="https://www.youtube.com/embed/YAELt8qZtJM?autoplay=1"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </VideoContainer>
    </div>
    
  </div>
);

export default Guide;
