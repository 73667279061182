import React from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { media } from '../../globalStyle';

export const ComplimentModal = props => (
  <Modal
    {...props}
    ariaHideApp={false}
    style={{
      overlay: {
        zIndex: 100,
        padding: '120px 0',
        position: 'absolute',
        top: '0px',
        left: '0px',
        right: '0px',
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        height: 'auto',
        minHeight: '100vh',
        overflow: 'auto',
      },
    }}
  >
    {props.children}
  </Modal>
);

export const ComplimentStyledModal = styled(ComplimentModal)`
  width: 460px;
  position: relative;
  margin: auto;
  background: white;
  border-radius: 2px;
  box-sizing: border-box;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: solid 1px #e0e0e0;
  padding: 30px;
  overflow: auto;
  &:focus,
  &:active {
    outline: none;
  }
  ${media.miniDesktop`
    width: 100%;
    top: 0;
    border: none;
    border-radius: 0;
    height: 100vh;
    overflow: auto;
    position: absolute;
    left: 0;
  `};
`;

export const CloseBtn = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
  right: 22px;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
`;

export const TextBox = styled.div`
  width: 100%;
  min-height: 48px;
  border-radius: 1px;
  background-color: #fdfdfd;
  border: solid 1px #e0e0e0;
  padding: 15px 8px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: -1px;
  color: #000000;
  ${props => props.disabled && 'color: #bcbcbc'};
`;

export const DescTextBox = styled(TextBox)`
  height: 180px;
  padding: 11px 10px;
`;

export const TextInput = styled.input`
  width: 100%;
  min-height: 48px;
  border-radius: 1px;
  background-color: #ffffff;
  border: solid 1px #e0e0e0;
  padding: 15px 8px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: -1px;
  color: #000000;
  &:hover,
  &:focus {
    outline: none;
  }
`;

const DescTextArea = styled.textarea`
  display: block;
  width: 100%;
  height: 160px;
  padding: 11px 10px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  overflow-y: auto;
  border-radius: 1px;
  background-color: #ffffff;
  border: solid 1px #e0e0e0;
  resize: none;
  ${props => props.error && 'border-color: #dd4124'};
`;

export const DescCountNum = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: right;
  color: #8a8a8a;
  margin-top: 8px;
  color: #bbbcbc;
  span {
    color: #000000;
  }
`;

export const DescTextInnerBox = styled.div`
  overflow-y: auto;
  line-height: normal;
  letter-spacing: -1px;
  white-space: pre-wrap;
  word-break: break-all;
  width: 100%;
  height: 100%;
`;

export const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`;

export const Button = styled.button`
  min-width: 240px;
  min-height: 52px;
  border-radius: 1px;
  background-color: #033e52;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 0 20px;
`;

export const ConfirmBtnContainer = styled.div`
  margin-top: 26px;
  text-align: right;
`;

export const ConfirmBtn = styled.button`
  border: 0;
  padding: 0;
  background-color: transparent;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #033e52;
`;

export const EditBtn = styled(ConfirmBtn)`
  margin-right: 30px;
`;

export const ModalHeader = styled.div`
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: -1px;
  color: #484848;
  margin-bottom: 30px;
  padding: 2px 2px 0;
  ${media.miniDesktop`
    margin-bottom: 20px;
  `}
`;

export const ModalBody = styled.div`
  margin-top: 30px;
`;

export const ModalBodyTitle = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  margin-bottom: 8px;
`;

export const ModalBodySection = styled.div`
  & ~ & {
    margin-top: 26px;
  }
`;

const DescTextareaContainer = styled.div`
  position: relative;
`;

const ValidationMsg = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #dd4124;
  position: absolute;
  bottom: -18px;
  left: 0;
`;

export const ComplimentTextarea = ({
  onChange,
  value,
  fireSubmitValidation,
}) => {
  let nullError = false;
  let shortValueError = false;

  if (fireSubmitValidation) {
    if (!value) {
      nullError = true;
    }

    if (value.length < 50) {
      shortValueError = true;
    }
  }

  return (
    <DescTextareaContainer>
      <DescTextArea
        placeholder="서로 훈훈해지는 칭찬 남겨주세요 :)
장난성 글이나 의미없는 칭찬은 삼가주세요"
        onChange={onChange}
        name="desc"
        value={value}
        error={nullError || shortValueError}
      />
      {nullError && <ValidationMsg>필수정보입니다.</ValidationMsg>}
      {!nullError &&
        shortValueError && (
          <ValidationMsg>앗! 좀 더 작성해 주세요</ValidationMsg>
        )}
    </DescTextareaContainer>
  );
};

const ComplimentViewModalCommonComponent = '';

export default ComplimentViewModalCommonComponent;
