import styled from 'styled-components';

export const FormTitle = styled.div`
  font-size: 26px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.23;
  letter-spacing: -1px;
  text-align: center;
  color: #ffffff;
  margin: 0 auto 8px;
  max-width: 360px;
  padding: 0 20px;
`;

export const FormSubTitle = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #ffffff;
  margin: 8px auto 30px;
  max-width: 360px;
  padding: 0 20px;
`;

export const FormContent = styled.div`
  max-width: 400px;
  padding: 0 20px;
  margin: 0 auto;
`;

export const FormInputRow = styled.div`
  width: 100%;
  position: relative;
  & ~ & {
    margin-top: 14px;
  }
`;

export const FormBtnContainer = styled.div`
  padding: 22px 0 40px;
  text-align: center;
`;

export const FormConfirmBtn = styled.button`
  width: 100%;
  height: 60px;
  border-radius: 30px;
  background-color: #ffffff;
  border: none;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #34bcd7;
  opacity: 1;
  &:disabled {
    opacity: 0.4;
    background-color: #ffffff;
  }
  ${props => props.disabledView && `
    opacity: 0.4;
    background-color: #ffffff;
  `}
`;