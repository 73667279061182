import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

const Message = styled.div`
  font-size: 35px;
`;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  margin: auto;
  text-align: center;
  padding: 30vh 0;
`;

const Button = styled.button`
  background-color: #38c8e5;
  border: solid 1px #38c8e5;
  color: #ffffff;
  font-size: 20px;
  padding: 12px 20px;
  border-radius: 5px;
  margin-top: 20px;
`;

class PageNotFound extends React.Component {
  render() {
    return (
      <Container>
        <Message>페이지를 찾을 수 없습니다.</Message>
        <Button onClick={() => this.props.history.push('/reqlist')}>
          홈으로 가기
        </Button>
      </Container>
    );
  }
}

export default withRouter(PageNotFound);
