import React, { Component } from 'react';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
import { TextInput, RadioInput } from '../basic/HtmlComponents';
import ArrowImg from '../../images/drop-down.svg';
import { media } from '../../globalStyle';
import { locationGunguQuery } from '../../graphql';
import { FilterRow, FilterLabel, Separator } from '../HosResps/HosRespsCommonComponents';



const QueryOptions = styled.div`
  margin-left: 10px;
  display: table-cell;
  vertical-align: middle;
`;

const QueryOptionHr = styled.hr`
  border-bottom: 1px solid #000000;
  margin: 0;
`;

const InlineBlockBox = styled.div`
  display: inline-block;
  margin: 5px 0;
`;

const SearchBtn = styled.div`
  width: 245px;
  height: 56px;
  border-radius: 4px;
  background-color: #033e52;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  line-height: 56px;
  cursor: pointer;
  color: #ffffff;
`;


const SelectBox = styled.div`
  display: inline-block;
  vertical-align: middle;
  select {
    width: 160px;
    height: 46px;
    border: solid 1px #e1e3e2;
    background-color: #ffffff;
    border-radius: 4px;
    cursor: pointer;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: -0.8px;
    color: #000000;
    padding: 14px;
    background-image: url(${ArrowImg});
    background-position-x: 95%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: none;
    &:disabled {
      color: #bbbcbc;
      cursor: not-allowed;
    }
    &:hover,
    &:focus {
      outline: none;
    }
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #bbbcbc;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #bbbcbc;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #bbbcbc;
    }
    &:disabled {
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: transparent;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: transparent;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: transparent;
      }
      + img {
        display: none;
      }
    }
    ${media.tablet`
      width: 100%;
      letter-spacing: -1px;
    `};
  }
`;


const AddressSelect = styled.select`
  cursor: pointer;
  border: none;
  height: 51px;
  width: 100%;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000000;
  background-color: #ffffff;
  background-image: url(${ArrowImg});
  background-position-x: right;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none;
  ::-ms-expand {
    display: none;
  }
  &:disabled {
    color: #bcbcbc;
    cursor: not-allowed;
  }
  &:hover,
  &:focus {
    outline: none;
  }
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #bcbcbc;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #bcbcbc;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #bcbcbc;
  }
  &:disabled {
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: transparent;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: transparent;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: transparent;
    }
    + img {
      display: none;
    }
  }
  ${media.tablet`
    width: 100%;
    letter-spacing: -1px;
  `};
`;

const UserFilterInput = styled.input`
  width: 160px;
  height: 46px;
  border: solid 1px #e1e3e2;
  border-radius: 4px;
  padding-left: 14px;
  font-size: 13px;
  text-align: left;
  color: #000000;
`;

const locationSiNames = {
  11: '서울',
  41: '경기',
  28: '인천',
};

class QueryOptionComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reqType: props.reqType,
      replied: props.replied,
      // orderBy: 'latest',
      username: props.username,
      locationSi: '',
      locationGu: '',
      cityList: [], // 선택된 도시에 대한 군구를 담는 state
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onFilterChange = ({ target }) => {
    const { name, value } = target;
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    this.props.filterUserReqs(this.state);
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleRegionChange = async (e) => {
    // 시/도를 고르고 시/군/구 세팅해준다.
    const { name, value } = e.target;
    if (!value) {
      this.setState({ cityList: [], [name]: value, locationGu: '' });
      return;
    }
    
    this.setState({ [name]: value, locationGu: '' });
    const gungu = await this.props.client.query({
      query: locationGunguQuery,
      variables: {
        siName: locationSiNames[value],
      },
    });

    if (gungu && gungu.data && gungu.data.locationGungu) {
      const newSorted = gungu.data.locationGungu.sort((a,b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
      this.setState({ cityList: newSorted });
    }
  };

  render() {
    const { locationGu, locationSi, cityList } = this.state;
    return (
      <div>
        <FilterRow>
          <FilterLabel>치료 과목</FilterLabel>
          <QueryOptions>
            <InlineBlockBox>
              <RadioInput id='all' name='reqType' value='all' onChange={this.onFilterChange} checked={this.state.reqType === 'all'} labelName='전체' />
            </InlineBlockBox>
            <InlineBlockBox>
              <RadioInput id='tx' name='reqType' value='tx' onChange={this.onFilterChange} checked={this.state.reqType === 'tx'} labelName='일반' />
            </InlineBlockBox>
            <InlineBlockBox>
              <RadioInput id='ortho' name='reqType' value='ortho' onChange={this.onFilterChange} checked={this.state.reqType === 'ortho'} labelName='교정' />
            </InlineBlockBox>
          </QueryOptions>
        </FilterRow>
        <FilterRow>
          <FilterLabel>답변 상태</FilterLabel>
          <QueryOptions>
            <InlineBlockBox>
              <RadioInput id='replyAll' name='replied' value='all' onChange={this.onFilterChange} checked={this.state.replied === 'all'} labelName='전체' />
            </InlineBlockBox>
            <InlineBlockBox>
              <RadioInput id='waiting' name='replied' value={'no'} onChange={this.onFilterChange} checked={this.state.replied === 'no'} labelName='답변 대기' />
            </InlineBlockBox>
            <InlineBlockBox>
              <RadioInput id='replied' name='replied' value={'yes'} onChange={this.onFilterChange} checked={this.state.replied === 'yes'} labelName='답변 완료' />
            </InlineBlockBox>
          </QueryOptions>
        </FilterRow>
        <FilterRow>
          <FilterLabel>지역 검색</FilterLabel>
          <QueryOptions>
            <SelectBox>
              <AddressSelect name='locationSi' placeholder='시/도 선택' onChange={this.handleRegionChange} value={locationSi} required>
                <option key='' value=''>
                  전체 지역
                </option>
                <option key={11} value='11'>
                  서울
                </option>
                <option key={41} value='41'>
                  경기
                </option>
                <option key={28} value='28'>
                  인천
                </option>
              </AddressSelect>
            </SelectBox>
            {locationSi && (
              <SelectBox style={{marginLeft: 10}}>
                <AddressSelect name='locationGu' placeholder='군/구 선택' value={locationGu} onChange={this.handleChange} required>
                  <option key='' value=''>
                    전체 지역
                  </option>
                  {cityList.map(({ id, code, name }) => (
                    <option key={`${id}-${code}`} value={id}>
                      {name}
                    </option>
                  ))}
                </AddressSelect>
              </SelectBox>
            )}
          </QueryOptions>
        </FilterRow>
        <FilterRow>
          <FilterLabel>고객 검색</FilterLabel>
          <QueryOptions>
            <UserFilterInput width='160px' height='46px' type='text' name='username' placeholder='이름 검색' onChange={this.onFilterChange} textAlign='left' value={this.state.username} />
          </QueryOptions>
        </FilterRow>
        <Separator></Separator>
        {/* <QueryOptionHr />
        <FilterRow>
          <FilterLabel>정렬 조건</FilterLabel>
          <QueryOptions>
            <InlineBlockBox>
              <input
                type="radio"
                id="latest"
                name="orderBy"
                value="latest"
                onChange={this.onFilterChange}
                defaultChecked
              />
              <InputLabel htmlFor="latest">최신 등록순</InputLabel>
            </InlineBlockBox>
            <InlineBlockBox>
              <input
                type="radio"
                id="highPrice"
                name="orderBy"
                value="highPrice"
                onChange={this.onFilterChange}
              />
              <InputLabel htmlFor="highPrice">높은 가격순</InputLabel>
            </InlineBlockBox>
            <InlineBlockBox>
              <input
                type="radio"
                id="lowPrice"
                name="orderBy"
                value="lowPrice"
                onChange={this.onFilterChange}
              />
              <InputLabel htmlFor="lowPrice">낮은 가격순</InputLabel>
            </InlineBlockBox>
          </QueryOptions>
        </QueryOptionRow> */}
        <div style={{marginTop: 30, display: 'flex', justifyContent: 'center'}}>
          <SearchBtn onClick={this.handleSubmit}>검색</SearchBtn>
        </div>
      </div>
    );
  }
}

export default withApollo(QueryOptionComponent);
