import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { graphql, compose } from 'react-apollo';
import {
  FormBackground,
  FormTitle,
  FormSubTitle,
  FormContent,
  FormInputRow,
  FormInputRowTitle,
  FormConfirmBtn,
  FormBtnContainer,
  Hr,
} from '../components/basic/HtmlComponents';
import AuthFooter from '../components/AuthFooter';
import withAlertModal from '../utils/alerthoc';
import PasswordInput from '../components/form/PasswordInput';
import withToast from '../utils/toasthoc';
import { isValidPass } from '../utils/validationInput';
import FormInput from '../components/form/FormInput';
import { passwordResetAvailableQuery, passwordResetMutation } from '../graphql';

const FooterContainer = styled.div`
  margin-top: 112px;
`;

const ModalBold = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: -1px;
  color: #767676;
`;

const ModalInfo = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: -1px;
  color: #8a8a8a;
  margin-top: 4px;
`;

class UpdatePassword extends Component {
  state = {
    newPassword: '',
    newPasswordConfirm: '',
    invalidLink: false,
    fireSubmitValidation: false,
  };

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value, fireSubmitValidation: false });
  };

  handleSubmit = async () => {
    const { newPassword, newPasswordConfirm } = this.state;
    const { email, resetToken } = this.props.match.params;

    if (!newPassword || !newPasswordConfirm) {
      this.setState({ fireSubmitValidation: true });
      return;
    }

    if (!isValidPass(newPassword) || newPassword !== newPasswordConfirm) {
      return;
    }

    if (newPassword !== newPasswordConfirm) {
      this.props.toast(
        <div>
          비밀번호가 일치하지 않습니다.<br />다시 시도해 주세요.{' '}
        </div>,
        'warn',
      );
      return;
    }

    const resp = await this.props.passwordReset({
      variables: {
        email,
        resetToken,
        newPass: newPassword,
        forHuser: true,
      },
    });

    if (!resp.data.passwordReset.ok) {
      // 1시간이 넘지 않았거나 기타 여러가지 이유로 패스워드 변경이 일어나지 않은 경우,
      // error 처리. -> 비밀번호 재설정으로 넘어가야한다.
      this.setState({ invalidLink: true });
      return;
    }

    // 성공시
    this.props.openAlert({
      title: '비밀번호 변경 완료',
      message:
        '비밀번호 변경이 완료되었습니다. 변경된 비밀번호로 로그인 하실 수 있습니다.',
      showConfirmBtn: true,
      confirmBtnName: '로그인',
      textAlign: 'left',
      confirmBtnOnClick: () => this.props.history.push('/login'),
    });
  };

  componentWillReceiveProps(props) {
    const {
      passwordResetAvailable: { loading, passwordResetAvailable: { ok } },
    } = props;

    if (!loading && ok) {
      this.setState({ invalidLink: false });
    } else if (!loading) {
      this.setState({ invalidLink: true });
    }
  }

  render() {
    const { email, resetToken } = this.props.match.params;
    const {
      newPassword,
      newPasswordConfirm,
      invalidLink,
      fireSubmitValidation,
    } = this.state;

    return (
      <div>
        {invalidLink ? (
          <FormBackground>
            <FormTitle>비밀번호 변경 오류</FormTitle>
            <FormSubTitle>
              비밀번호 재설정 링크가 유효하지 않습니다.<br />
              다시 비밀번호 재설정 링크를 요청하세요.
            </FormSubTitle>
            <FormContent>
              <FormBtnContainer>
                <FormConfirmBtn onClick={() => this.props.history.push('/findpassword')}>
                  비밀번호 재설정
                </FormConfirmBtn>
              </FormBtnContainer>
            </FormContent>
            <FooterContainer>
              <AuthFooter />
            </FooterContainer>
          </FormBackground>
        ) : (
          <FormBackground>
            <FormTitle>비밀번호 변경</FormTitle>
            <FormSubTitle>새로운 비밀번호를 입력해 주세요.</FormSubTitle>
            <FormContent>
              <FormInputRow>
                <FormInputRowTitle>비밀번호</FormInputRowTitle>
                <PasswordInput
                  value={newPassword}
                  name="newPassword"
                  onChange={this.handleChange}
                  placeholder="문자, 숫자, 기호를 조합하여 8자 이상"
                  fireSubmitValidation={fireSubmitValidation}
                />
              </FormInputRow>
              <FormInputRow>
                <FormInputRowTitle>비밀번호 확인</FormInputRowTitle>
                <FormInput
                  type="password"
                  placeholder="비밀번호 재확인"
                  name="newPasswordConfirm"
                  onChange={this.handleChange}
                  value={newPasswordConfirm}
                  errorMsg={
                    newPassword &&
                    newPasswordConfirm &&
                    newPassword !== newPasswordConfirm
                      ? '비밀번호가 일치하지 않습니다. 다시 시도해 주세요.'
                      : ''
                  }
                  fireSubmitValidation={fireSubmitValidation}
                />
              </FormInputRow>
              <FormBtnContainer>
                <FormConfirmBtn
                  disabledView={
                    !newPassword ||
                    !newPasswordConfirm ||
                    newPassword !== newPasswordConfirm ||
                    !isValidPass(newPassword)
                  }
                  onClick={this.handleSubmit}
                >
                  확인
                </FormConfirmBtn>
              </FormBtnContainer>
            </FormContent>
            <FooterContainer>
              <AuthFooter />
            </FooterContainer>
            {/* <pre>{JSON.stringify(this.state, null, 4)}</pre> */}
          </FormBackground>
        )}
      </div>
    );
  }
}

export default compose(
  graphql(passwordResetMutation, {
    name: 'passwordReset',
  }),
  graphql(passwordResetAvailableQuery, {
    options: ({ match }) => ({
      variables: { email: match.params.email, forHuser: true, },
    }),
    name: 'passwordResetAvailable'
  }),
)(withAlertModal(withRouter(withToast(UpdatePassword))));
