import React from 'react';
import styled from 'styled-components';

import ReqDetail from '../../routes/ReqDetail';
import AllUserReqsContainer from '../../containers/ReqList/AllUserReqsContainer';
import { media } from '../../globalStyle';

const OnlyDesktopVisible = styled.div`
  display: block;
  ${media.miniDesktop`
    display: none;
  `};
`;

const DesktopReqListComponent = ({
  reqId,
  reqType,
  replied,
  username,
  locationSi,
  locationGu,
  onClickCard,
  setFilterState,
}) => (
  <OnlyDesktopVisible>
    <AllUserReqsContainer
      reqId={reqId}
      reqType={reqType}
      replied={replied}
      username={username}
      locationSi={locationSi}
      locationGu={locationGu}
      onClickCard={onClickCard}
      setFilterState={setFilterState}
    />
  </OnlyDesktopVisible>
);

export default DesktopReqListComponent;
