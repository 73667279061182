import ReactGA from 'react-ga';
import axios from 'axios';

const isProductionEnv = () => process.env.REACT_APP_NODE_ENV === 'production';
const isDevEnv = () => process.env.REACT_APP_NODE_ENV === 'development';

export const logPageView = (pathname) => {
  // production에서만 ga 정보 수집하도록
  if (isProductionEnv()) {
    ReactGA.pageview(pathname);
  }
};

// GA event log
export const logEvent = ({
  category, action, label, value,
}) => {
  if (!category || !action || !label) {
    return;
  }

  const evtObj = {
    category,
    action,
    label,
  };

  if (value !== undefined && value !== null) {
    evtObj.value = value;
  }

  if (isProductionEnv()) {
    ReactGA.event(evtObj);
  }
};


const sendSlackMsg = async (msg, webhookUrl) => {
  if (!isDevEnv()) {
    const resp = await axios.post(
      webhookUrl,
      JSON.stringify({
        text: msg,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );
    return resp;
  }

  // production 경우에만 post, 나머지는 200으로 처리.
  return {
    status: 200,
  };
};

export const logToSlack = async (msg) => {
  const res = await sendSlackMsg(msg, `${process.env.REACT_APP_SLACK_WEBHOOK_URL}`);
  return res;
};

export const cashLogToSlack = async (msg) => {
  const res = await sendSlackMsg(msg, `${process.env.REACT_APP_SLACK_CASH_WEBHOOK_URL}`);
  return res;
};

export default {};
