import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import _ from 'lodash';
import styled from 'styled-components';
import HosBasicInfoEditableComponent from '../../components/MyHospital/HosBasicInfoEditableComponent';
import {
  myHospitalQuery,
  updateHosBasicInfoMutate,
  updateHospitalAddInfoMutate,
  updateHospitalPeopleMutate,
} from '../../graphql';
import HosAddInfoEditComponent from '../../components/MyHospital/HosAddInfoEditComponent';
import InfoDefaultIcon1 from '../../images/01-dr-default.png';
import InfoIcon1 from '../../images/01-dr.png';
import InfoDefaultIcon2 from '../../images/02-night-default.png';
import InfoIcon2 from '../../images/02-night.png';
import InfoDefaultIcon3 from '../../images/03-weekend-default.png';
import InfoIcon3 from '../../images/03-weekend.png';
import InfoDefaultIcon4 from '../../images/04-park-default.png';
import InfoIcon4 from '../../images/04-park.png';
import InfoDefaultIcon5 from '../../images/05-shot-default.png';
import InfoIcon5 from '../../images/05-shot.png';
import InfoDefaultIcon6 from '../../images/06-make-default.png';
import InfoIcon6 from '../../images/06-make.png';
import InfoDefaultIcon7 from '../../images/07-baby-default.png';
import InfoIcon7 from '../../images/07-baby.png';
import { media } from '../../globalStyle';
import withToast from '../../utils/toasthoc';
import { getFourDigitSingleTime, translateDBOperTime } from '../../utils/FourDigitTime';
import HosContactPhoneComponent from '../../components/MyHospital/HosContactPhoneComponent';

const Section = styled.div`
  width: 100%;
  height: auto;
  padding: 40px;
  border-radius: 1px;
  background-color: #ffffff;
  border: solid 1px #e0e0e0;
  & ~ & {
    margin-top: 10px;
  }
`;

const BottomSaveBtnContainer = styled.div`
  margin-top: 90px;
  background-color: #ffffff;
  text-align: center;
`;

const PreviewHospitalBtn = styled.button`
  width: 240px;
  height: 52px;
  border-radius: 1px;
  border: solid 1px #033e52;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #033e52;
  position: relative;
  margin-right: 10px;
  :after {
    content: '';
    position: absolute;
    top: 18px;
    left: 0;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    height: 32px;
    width: 100%;
  }
`;

const SaveBtn = styled.button`
  border: none;
  width: 240px;
  height: 52px;
  border-radius: 1px;
  background-color: #033e52;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
`;

export const HosAddInfoImage = {
  전문의: {
    on: InfoIcon1,
    off: InfoDefaultIcon1,
  },
  야간진료: {
    on: InfoIcon2,
    off: InfoDefaultIcon2,
  },
  주말진료: {
    on: InfoIcon3,
    off: InfoDefaultIcon3,
  },
  주차가능: {
    on: InfoIcon4,
    off: InfoDefaultIcon4,
  },
  무통마취: {
    on: InfoIcon5,
    off: InfoDefaultIcon5,
  },
  '기공실 구비': {
    on: InfoIcon6,
    off: InfoDefaultIcon6,
  },
  소아전문: {
    on: InfoIcon7,
    off: InfoDefaultIcon7,
  },
};

const basicTags = [
  '최신 시설 및 설비',
  '자세한 설명과 친절함',
  '편안하고 안아픈 진료',
  '치료분야별 주치의',
  '합리적 가격',
];

const basicInfos = [
  '전문의',
  '야간진료',
  '주말진료',
  '주차가능',
  '무통마취',
  '기공실 구비',
  '소아전문',
];


class HosBasicInfoContainer extends Component {
  constructor(props) {
    super(props);

    if (props.info) {
      this.state = {
        name: props.info.name || '',
        phone: props.info.phone || '',
        address: props.info.address || '',
        infos: this.createInfoTextList(props.info.infos),
        tags: this.createTagNameList(props.info.tags),
        hospitalpeople: (props.info.hospitalPeople && props.info.hospitalPeople.length > 0) ? props.info.hospitalPeople : [{ name: "", phonenum: "" }],
      };

      if (props.info.metadata) {
        this.state.dutyTime1s =
          props.info.metadata.openhour && props.info.metadata.openhour.dutyTime1s ?
          getFourDigitSingleTime(props.info.metadata.openhour.dutyTime1s) : '';
        this.state.dutyTime1c =
          props.info.metadata.openhour && props.info.metadata.openhour.dutyTime1c ?
          getFourDigitSingleTime(props.info.metadata.openhour.dutyTime1c) : '';

        this.state.dutyTime2s =
          props.info.metadata.openhour && props.info.metadata.openhour.dutyTime2s ?
          getFourDigitSingleTime(props.info.metadata.openhour.dutyTime2s) : '';
        this.state.dutyTime2c =
          props.info.metadata.openhour && props.info.metadata.openhour.dutyTime2c ?
          getFourDigitSingleTime(props.info.metadata.openhour.dutyTime2c) : '';

        this.state.dutyTime3s =
          props.info.metadata.openhour && props.info.metadata.openhour.dutyTime3s ?
          getFourDigitSingleTime(props.info.metadata.openhour.dutyTime3s) : '';
        this.state.dutyTime3c =
          props.info.metadata.openhour && props.info.metadata.openhour.dutyTime3c ?
          getFourDigitSingleTime(props.info.metadata.openhour.dutyTime3c) : '';

        this.state.dutyTime4s =
          props.info.metadata.openhour && props.info.metadata.openhour.dutyTime4s ?
          getFourDigitSingleTime(props.info.metadata.openhour.dutyTime4s) : '';
        this.state.dutyTime4c =
          props.info.metadata.openhour && props.info.metadata.openhour.dutyTime4c ?
          getFourDigitSingleTime(props.info.metadata.openhour.dutyTime4c) : '';
        
          this.state.dutyTime5s =
          props.info.metadata.openhour && props.info.metadata.openhour.dutyTime5s ?
          getFourDigitSingleTime(props.info.metadata.openhour.dutyTime5s) : '';
        this.state.dutyTime5c =
          props.info.metadata.openhour && props.info.metadata.openhour.dutyTime5c ?
          getFourDigitSingleTime(props.info.metadata.openhour.dutyTime5c) : '';
        
          this.state.dutyTime6s =
          props.info.metadata.openhour && props.info.metadata.openhour.dutyTime6s ?
          getFourDigitSingleTime(props.info.metadata.openhour.dutyTime6s) : '';
        this.state.dutyTime6c =
          props.info.metadata.openhour && props.info.metadata.openhour.dutyTime6c ?
          getFourDigitSingleTime(props.info.metadata.openhour.dutyTime6c) : '';
        
        this.state.dutyTime7s =
          props.info.metadata.openhour && props.info.metadata.openhour.dutyTime7s ?
          getFourDigitSingleTime(props.info.metadata.openhour.dutyTime7s) : '';
        this.state.dutyTime7c =
          props.info.metadata.openhour && props.info.metadata.openhour.dutyTime7c ?
          getFourDigitSingleTime(props.info.metadata.openhour.dutyTime7c) : '';

        this.state.lunchTimes =
          props.info.metadata.openhour && props.info.metadata.openhour.lunchTimes ?
          getFourDigitSingleTime(props.info.metadata.openhour.lunchTimes) : '';
        this.state.lunchTimec =
          props.info.metadata.openhour && props.info.metadata.openhour.lunchTimec ?
          getFourDigitSingleTime(props.info.metadata.openhour.lunchTimec) : '';

        this.state.intro = props.info.metadata.intro
          ? props.info.metadata.intro
          : '';
      }

      this.myHospitalUrl = `${process.env.REACT_APP_CUSTOMER_URL}/hospitals/${props.info.id}`; // 미리보기용 URL
    } else {
      this.state = {
        name: '',
        phone: '',
        address: '',
        dayOpenHour: '',
        nightOpenHour: '',
        weekendOpenHour: '',
        lunchHour: '',
        intro: '',
        infos: [],
        tags: [],
        hospitalpeople: [{ name: "", phonenum: "" }],
      };
    }

    this.addHospitalPeople = this.addHospitalPeople.bind(this);
    this.removeHospitalPeople = this.removeHospitalPeople.bind(this);
    this.onHospitalPeopleChange = this.onHospitalPeopleChange.bind(this);
    this.checkHospitalPeople = this.checkHospitalPeople.bind(this);
    this.openHospitalPage = this.openHospitalPage.bind(this);
  }

  generateMetadata = (
    dutyTime1s,
    dutyTime1c,
    dutyTime2s,
    dutyTime2c,
    dutyTime3s,
    dutyTime3c,
    dutyTime4s,
    dutyTime4c,
    dutyTime5s,
    dutyTime5c,
    dutyTime6s,
    dutyTime6c,
    dutyTime7s,
    dutyTime7c,
    lunchTimes,
    lunchTimec,
    intro,
  ) => {
    const metadata = {
      openhour: {},
    };

    if (dutyTime1s) {
      metadata.openhour = { ...(metadata.openhour || {}) };
      metadata.openhour.dutyTime1s = dutyTime1s;
    }
    if (dutyTime1c) {
      metadata.openhour = { ...(metadata.openhour || {}) };
      metadata.openhour.dutyTime1c = dutyTime1c;
    }

    if (dutyTime2s) {
      metadata.openhour = { ...(metadata.openhour || {}) };
      metadata.openhour.dutyTime2s = dutyTime2s;
    }
    if (dutyTime2c) {
      metadata.openhour = { ...(metadata.openhour || {}) };
      metadata.openhour.dutyTime2c = dutyTime2c;
    }

    if (dutyTime3s) {
      metadata.openhour = { ...(metadata.openhour || {}) };
      metadata.openhour.dutyTime3s = dutyTime3s;
    }
    if (dutyTime3c) {
      metadata.openhour = { ...(metadata.openhour || {}) };
      metadata.openhour.dutyTime3c = dutyTime3c;
    }

    if (dutyTime4s) {
      metadata.openhour = { ...(metadata.openhour || {}) };
      metadata.openhour.dutyTime4s = dutyTime4s;
    }
    if (dutyTime4c) {
      metadata.openhour = { ...(metadata.openhour || {}) };
      metadata.openhour.dutyTime4c = dutyTime4c;
    }

    if (dutyTime5s) {
      metadata.openhour = { ...(metadata.openhour || {}) };
      metadata.openhour.dutyTime5s = dutyTime5s;
    }
    if (dutyTime5c) {
      metadata.openhour = { ...(metadata.openhour || {}) };
      metadata.openhour.dutyTime5c = dutyTime5c;
    }

    if (dutyTime6s) {
      metadata.openhour = { ...(metadata.openhour || {}) };
      metadata.openhour.dutyTime6s = dutyTime6s;
    }
    if (dutyTime6c) {
      metadata.openhour = { ...(metadata.openhour || {}) };
      metadata.openhour.dutyTime6c = dutyTime6c;
    }

    if (dutyTime7s) {
      metadata.openhour = { ...(metadata.openhour || {}) };
      metadata.openhour.dutyTime7s = dutyTime7s;
    }
    if (dutyTime7c) {
      metadata.openhour = { ...(metadata.openhour || {}) };
      metadata.openhour.dutyTime7c = dutyTime7c;
    }

    if (lunchTimes) {
      metadata.openhour = { ...(metadata.openhour || {}) };
      metadata.openhour.lunchTimes = lunchTimes;
    }
    if (lunchTimec) {
      metadata.openhour = { ...(metadata.openhour || {}) };
      metadata.openhour.lunchTimec = lunchTimec;
    }

    if (intro) {
      metadata.intro = intro;
    }

    return metadata;
  };

  updateHosBasicInfo = async () => {
    const { confirmed } = this.props;
    let variables = {};
    if (confirmed) {
      const {
        name,
        phone,
        address,
        dutyTime1s,
        dutyTime1c,
        dutyTime2s,
        dutyTime2c,
        dutyTime3s,
        dutyTime3c,
        dutyTime4s,
        dutyTime4c,
        dutyTime5s,
        dutyTime5c,
        dutyTime6s,
        dutyTime6c,
        dutyTime7s,
        dutyTime7c,
        lunchTimes,
        lunchTimec,
        intro,
      } = this.state;

      const metadata = await this.generateMetadata(
        translateDBOperTime(dutyTime1s),
        translateDBOperTime(dutyTime1c),
        translateDBOperTime(dutyTime2s),
        translateDBOperTime(dutyTime2c),
        translateDBOperTime(dutyTime3s),
        translateDBOperTime(dutyTime3c),
        translateDBOperTime(dutyTime4s),
        translateDBOperTime(dutyTime4c),
        translateDBOperTime(dutyTime5s),
        translateDBOperTime(dutyTime5c),
        translateDBOperTime(dutyTime6s),
        translateDBOperTime(dutyTime6c),
        translateDBOperTime(dutyTime7s),
        translateDBOperTime(dutyTime7c),
        translateDBOperTime(lunchTimes),
        translateDBOperTime(lunchTimec),
        intro,
      );

      variables = {
        name,
        phone,
        address,
        metadata,
      };
    } else {
      const { name, phone, address } = this.state;

      variables = {
        name,
        phone,
        address,
      };
    }

    const res = await this.props.updateHosBasicInfo({
      variables,
    });

    const {
      updateHospitalBasicInfo: { ok, errors },
    } = await res.data;

    if (ok) {
      return;
    }

    if (errors) {
      throw new Error(JSON.stringify(errors));
    }
  };

  // 병원 스태프 연락처 기능 starts...
  addHospitalPeople = (e) => {
    this.setState((prevState) => ({
      hospitalpeople: [...prevState.hospitalpeople, { name: "", phonenum: "" }],
    }));
  }

  removeHospitalPeople = (idx) => () => {
    if (this.state.hospitalpeople.length === 1) {
      this.setState({ hospitalpeople: [{ name: "", phonenum: "" }] });
      return;
    }

    this.setState({ hospitalpeople: this.state.hospitalpeople.filter((s, sidx) => idx !== sidx) });
  }

  onHospitalPeopleChange = (idx) => (e) => {
    const newHospitalPeople = this.state.hospitalpeople.map((person, pidx) => {
      const { name, value } = e.target;
      if (idx !== pidx) return person;
      if (name.includes('name')) {
        return { ...person, name: value };
      } else if (name.includes('phonenum')) {
        return { ...person, phonenum: value };
      }
    });

    this.setState({ hospitalpeople: newHospitalPeople });
  }
  // 병원 스태프 연락처 기능 ends...

  handleInputChange = ({ target }) => {
    const { name, value } = target;
    this.setState({ [name]: value });
  };

  updateInfoState = (name, value) => {
    this.setState({ [name]: value });
  };

  updateCounselAddInfo = async () => {
    const { confirmed } = this.props;

    if (confirmed) {
      const { tags, infos } = this.state;
      const res = await this.props.updateHospitalAddInfo({
        variables: {
          tags,
          infos,
        },
      });
      const { ok, errors } = res.data.updateHospitalAddInfo;

      if (ok) {
        return;
      }

      if (errors) {
        throw new Error(JSON.stringify(errors));
      }
    }
  };

  checkHospitalPeople = async () => {
    let { hospitalpeople } = this.state;

    const noneObjs = await _.find(hospitalpeople, (obj) => {
      return ((obj.name && obj.name !== '') && (!obj.phonenum || obj.phonenum === ''))
        || ((obj.phonenum && obj.phonenum !== '') && (!obj.name || obj.name === ''));
    });


    if (noneObjs) {
      this.props.toast(<div>담당자 정보를 모두 입력해주세요.</div>, 'warn');
      return false;
    }

    return true;
  }

  updateHospitalPeople = async () => {
    let { hospitalpeople } = this.state;

    hospitalpeople = await hospitalpeople.filter((obj) => (
      obj.name && obj.name !== '' && obj.phonenum && obj.phonenum !== ''
    ));

    hospitalpeople = await hospitalpeople.map((obj) => (
      { name: obj.name, phonenum: obj.phonenum }
    ));

    const res = await this.props.updateHospitalPeople({
      variables: {
        people: hospitalpeople,
      },
    });

    const { ok, errors } = res.data.updateHospitalPeople;

    if (ok) {
      return;
    }

    if (errors) {
      throw new Error(JSON.stringify(errors));
    }
  };

  createInfoTextList = (infos) => {
    const list = [];
    infos.map((info) => {
      if (_.indexOf(basicInfos, info.infoText) > -1) {
        list.push(info.infoText);
        return info.infoText;
      }
      return info.infoText;
    });
    return list;
  };

  createTagNameList = (tags) => {
    const list = [];
    tags.map((tag) => {
      if (_.indexOf(basicTags, tag.tagName) > -1) {
        list.push(tag.tagName);
        return tag.tagName;
      }
      return tag.tagName;
    });
    return list;
  };

  handleSubmit = async () => {
    try {
      if(await this.checkHospitalPeople()) {
        // 병원 기본정보 update
        await this.updateHosBasicInfo();

        // 병원 부가정보 update
        await this.updateCounselAddInfo();

        // 병원 스태프들 폰번호 추가 로직
        await this.updateHospitalPeople();

        this.props.toast('저장 성공', 'info');
      }
    } catch (e) {
      this.props.toast('저장 실패', 'warn');
    }
  };

  openHospitalPage = () => {
    if (this.myHospitalUrl) {
      window.open(this.myHospitalUrl);
    }
  };

  render() {
    const {
      name,
      phone,
      address,
      intro,
      dutyTime1s,
      dutyTime1c,
      dutyTime2s,
      dutyTime2c,
      dutyTime3s,
      dutyTime3c,
      dutyTime4s,
      dutyTime4c,
      dutyTime5s,
      dutyTime5c,
      dutyTime6s,
      dutyTime6c,
      dutyTime7s,
      dutyTime7c,
      lunchTimes,
      lunchTimec,
      hospitalpeople,
    } = this.state;

    const { info, confirmed } = this.props;

    return (
      <div>
        <Section>
          <HosBasicInfoEditableComponent
            confirmed={confirmed}
            name={name}
            phone={phone}
            address={address}
            onChange={this.handleInputChange}
          />
        </Section>
        <Section>
          <HosContactPhoneComponent removeHospitalPeople={this.removeHospitalPeople} hospitalpeople={hospitalpeople} addHospitalPeople={this.addHospitalPeople} onHospitalPeopleChange={this.onHospitalPeopleChange} confirmed={confirmed} />
        </Section>
        <Section>
          <HosAddInfoEditComponent
            dutyTime1s={dutyTime1s}
            dutyTime1c={dutyTime1c}
            dutyTime2s={dutyTime2s}
            dutyTime2c={dutyTime2c}
            dutyTime3s={dutyTime3s}
            dutyTime3c={dutyTime3c}
            dutyTime4s={dutyTime4s}
            dutyTime4c={dutyTime4c}
            dutyTime5s={dutyTime5s}
            dutyTime5c={dutyTime5c}
            dutyTime6s={dutyTime6s}
            dutyTime6c={dutyTime6c}
            dutyTime7s={dutyTime7s}
            dutyTime7c={dutyTime7c}
            lunchTimes={lunchTimes}
            lunchTimec={lunchTimec}
            intro={intro}
            infos={this.state.infos}
            tags={this.state.tags}
            onChange={this.updateInfoState}
            onChangeHosBasicInfo={this.handleInputChange}
            confirmed={confirmed}
            info={info}
          />
        </Section>
        <BottomSaveBtnContainer>
          <PreviewHospitalBtn onClick={this.openHospitalPage}>미리보기</PreviewHospitalBtn>
          <SaveBtn onClick={this.handleSubmit}>저장</SaveBtn>
        </BottomSaveBtnContainer>
      </div>
    );
  }
}

export default compose(
  graphql(updateHosBasicInfoMutate, {
    name: 'updateHosBasicInfo',
    options: {
      refetchQueries: [{ query: myHospitalQuery }],
    },
  }),
  graphql(updateHospitalAddInfoMutate, {
    name: 'updateHospitalAddInfo',
    options: { refetchQueries: [{ query: myHospitalQuery }] },
  }),
  graphql(updateHospitalPeopleMutate, {
    name: 'updateHospitalPeople',
    options: { refetchQueries: [{ query: myHospitalQuery }] },
  }),
)(withToast(HosBasicInfoContainer));
