import moment from 'moment';

export default function getAge(birthyear) {
  return (moment().year() - birthyear) + 1;
}

export const getAgeGroupName = (ageGroup) => {
  if (!ageGroup) {
    return '';
  }

  if (ageGroup === 'less_teens') {
    return '10대 이하';
  }

  if (ageGroup === 'twenties') {
    return '20대';
  }

  if (ageGroup === 'thirties') {
    return '30대';
  }

  if (ageGroup === 'forties') {
    return '40대';
  }

  if (ageGroup === 'fifties') {
    return '50대';
  }

  if (ageGroup === 'over_sixties') {
    return '60대 이상';
  }

  return '';
};