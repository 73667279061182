import React, { Component } from 'react';
import styled from 'styled-components';

const InquiryContainer = styled.div`
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #000000;
  .inquiry-title {
    font-weight: bold;
    letter-spacing: -1px;
  }
  .inquiry-desc {
    font-weight: normal;
    letter-spacing: -1px;
    margin-top: 6px;
  }
  & ~ & {
    margin-top: 30px;
  }
`;

const A = styled.a`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  text-decoration: underline;
  :active,
  :hover,
  :link,
  :visited {
    color: #000000;
    text-decoration: underline;
  }
`;

const NumberEn = styled.span`
  letter-spacing: normal;
`;

const InquiryRow = ({ title, desc }) => (
  <InquiryContainer>
    <div className="inquiry-title">{title}</div>
    <div className="inquiry-desc">{desc}</div>
  </InquiryContainer>
);

const InquiryMailToComponent = () => (
  <A href="mailto: partner@odoctor.co.kr?subject=문의하기&body=오닥터%20고객센터입니다.%0A문의할%20사항이나%20서비스%20사용중%20궁금하신점을%20작성해주세요%0A%0A성함%20혹은%20아이디%20:%0A%0A문의내용%20:">
    <NumberEn>partner@odoctor.co.kr</NumberEn>
  </A>
);

class Inquiry extends Component {
  render() {
    return (
      <div>
        <InquiryRow
          title="상담시간"
          desc={
            (
              <div>
                평일
                {' '}
                <NumberEn>10:00 ~ 19:00 (Off-time 12:00 ~ 13:00)</NumberEn>
              </div>
            )
          }
        />
        <InquiryRow title="전화번호" desc={<NumberEn>02-3443-1357</NumberEn>} />
        <InquiryRow title="메일" desc={<InquiryMailToComponent />} />
      </div>
    );
  }
}

export default Inquiry;
