import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';

const NoticeHeader = styled.div`
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  min-height: 30px;
  padding: 10px 0;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.8px;
  color: #000000;
  cursor: pointer;
  display: flex;
  &:hover, &.active {
    background: #033e52;
    color: white;
  }
`;

const NoticeContent = styled.div`
  display: ${props => (props.open ? 'block' : 'none')};
  padding: 15px;
  border: 1px solid #d9d9d9;
  white-space: pre-wrap;
  word-break: break-word;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -1px;
  text-align: justify;
  color: #000000;
`;

const NoticeRowNum = styled.div`
  width: 100px;
  text-align: center;
`;
const NoticeRowTitle = styled.div`
  width: 870px;
  text-align: left;
`;
const NoticeRowDate = styled.div`
  width: 170px;
  text-align: left;
`;

class NoticeRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
  }

  render() {
    const {
      data: { description, title, createdAt }, idx,
    } = this.props;
    return (
      <div>
        <NoticeHeader className={this.state.collapse ? 'active' : ''} onClick={() => this.setState({ collapse: !this.state.collapse })}>
          <NoticeRowNum>{idx + 1}</NoticeRowNum>
          <NoticeRowTitle>{title}</NoticeRowTitle>
          <NoticeRowDate>{moment(createdAt).format('YYYY.MM.DD')}</NoticeRowDate>
        </NoticeHeader>
        <NoticeContent open={this.state.collapse} dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    );
  }
}

export default NoticeRow;
