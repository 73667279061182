import React, { Component } from 'react';
import styled from 'styled-components';
import HospitalPeopleInputs from './HospitalPeopleInputs';
import { Section, Title, SubTitle } from './MyHospitalCommonComponents';
import withAlertModal from '../../utils/alerthoc';

import { media } from '../../globalStyle';


const StyledSubTitle = styled(SubTitle)`
  margin-bottom: 10px;
  span {
    color: #000000;
  }
`;

const Container = styled.div`
  max-width: 412px;
  ${media.miniDesktop`
    max-width: unset;
    max-width: none;
  `};
`;

const TitleSmall = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: -0.8px;
  color: #bcbcbc;
  margin-bottom: 20px;
`;

const AddBtn = styled.button`
  text-decoration: underline;
  border: none;
  float: right;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: right;
  color: #033e52;
  cursor: pointer;
  background: none;
  :disabled {
    color: #bdbdbd;
  }
  ${media.miniDesktop`
    background: none;
  `};
`;

class HosContactPhoneComponent extends Component {
  render() {
    const { hospitalpeople, confirmed } = this.props;

    return (
      <Container>
        <Title>상담 정보</Title>
        <Section>
          <StyledSubTitle>
            담당자 휴대폰 번호(최대 5명)
            <AddBtn onClick={this.props.addHospitalPeople} disabled={(this.props.hospitalpeople && this.props.hospitalpeople.length > 4) || !confirmed}>추가하기</AddBtn>
          </StyledSubTitle>
          <TitleSmall>신규 상담접수 및 답변 조회 시, 알림 메시지를 수신받을 번호입니다.</TitleSmall>
          <HospitalPeopleInputs
            confirmed={confirmed}
            people={hospitalpeople}
            handleRemovePeople={this.props.removeHospitalPeople}
            handleChange={this.props.onHospitalPeopleChange}
          />
        </Section>
      </Container>
    );
  }
}

export default withAlertModal(HosContactPhoneComponent);
