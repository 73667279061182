import React from 'react';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';

import { media } from '../../globalStyle';
import ArrowImg from '../../images/ic-more-gray-24.png';
import { locationGunguQuery } from '../../graphql';


const Container = styled.div`
  margin-right: -10px;
`;

const SelectBox = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 50%;
  padding-right: 10px;
  select {
    width: 100%;
    height: 56px;
    border: solid 1px #d6d6d6;
    background-color: #ffffff;
    cursor: pointer;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: -0.4px;
    color: #000000;
    padding: 14px;
    background-image: url(${ArrowImg});
    background-position-x: 95%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: none;
    &:disabled {
      color: #bbbcbc;
      cursor: not-allowed;
    }
    &:hover,
    &:focus {
      outline: none;
    }
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #bbbcbc;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #bbbcbc;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #bbbcbc;
    }
    &:disabled {
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: transparent;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: transparent;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: transparent;
      }
      + img {
        display: none;
      }
    }
    ${media.tablet`
      width: 100%;
      letter-spacing: -1px;
    `};
  }
`;

const locationSiNames = {
  11: '서울',
  41: '경기',
  28: '인천',
};

class LocationFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      locationSi: '',
      locationGu: '',
      cityList: [], // 선택된 도시에 대한 군구를 담는 state
    };
  }

  handleLocationSiChange = async (e) => {
    // 시/도를 고르고 시/군/구 세팅해준다.
    const { name, value } = e.target;
    const { client, handleLocationSiChange, handleLocationGuChange } = this.props;
    if (!value) {
      this.setState({ cityList: [], [name]: value, locationGu: '' });
      handleLocationSiChange(value);
      handleLocationGuChange('');
      return;
    }

    this.setState({ [name]: value, locationGu: '' });
    handleLocationSiChange(value);
    handleLocationGuChange('');
    const gungu = await client.query({
      query: locationGunguQuery,
      variables: {
        siName: locationSiNames[value],
      },
    });

    if (gungu && gungu.data && gungu.data.locationGungu) {
      const newSorted = gungu.data.locationGungu.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      this.setState({ cityList: newSorted });
    }
  };

  handleLocationGuChange = (e) => {
    const { value } = e.target;
    const { handleLocationGuChange } = this.props;
    this.setState({ locationGu: value });
    handleLocationGuChange(value);
  }

  render() {
    const {
      cityList,
      locationSi,
      locationGu,
    } = this.state;

    return (
      <Container>
        <SelectBox>
          <select
            name="locationSi"
            placeholder="시/도 선택"
            onChange={this.handleLocationSiChange}
            value={locationSi}
            required
          >
            <option key="" value="">
              전체
            </option>
            <option key={11} value="11">
              서울
            </option>
            <option key={41} value="41">
              경기
            </option>
            <option key={28} value="28">
              인천
            </option>
          </select>
        </SelectBox>
        <SelectBox>
          <select
            name="locationGu"
            placeholder="군/구 선택"
            value={locationGu}
            onChange={this.handleLocationGuChange}
            required
            disabled={!locationSi}
          >
            <option key="" value="">
              전체 지역
            </option>
            {cityList.map(({ id, code, name }) => (
              <option key={`${id}-${code}`} value={id}>
                {name}
              </option>
            ))}
          </select>
        </SelectBox>
      </Container>
    );
  }
}

export default withApollo(LocationFilter);
