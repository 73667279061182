import _ from 'lodash';

export const getTxPriceTableByReq = (txItems, txPrices) => {
  const priceTable = [];

  if (txItems && txItems.length > 0) {
    txItems.map((txItem) => {
      const txPrice = _.find(txPrices, { itemcode: txItem.itemcode });

      if (txPrice) {
        priceTable.push({
          itemcode: txItem.itemcode,
          unit: txItem.unit,
          price: txPrice.price,
        });
      } else {
        priceTable.push({
          itemcode: txItem.itemcode,
          unit: txItem.unit,
          price: 0,
          noPrice: true,
        });
      }
    });

    return priceTable;
  }
  return [];
};

export const getOrthoPriceTableByReq = (orthoItems, orthoPrices) => {
  const priceTable = [];
  if (orthoItems && orthoItems.length > 0) {
    orthoItems.map((orthoItem) => {
      const orthoPrice = _.find(orthoPrices, { itemcode: orthoItem.itemcode, part: orthoItem.part });

      if (orthoPrice) {
        priceTable.push({
          itemcode: orthoItem.itemcode,
          part: orthoItem.part,
          price: orthoPrice.price,
        });
      } else {
        priceTable.push({
          itemcode: orthoItem.itemcode,
          part: orthoItem.part,
          price: 0,
          noPrice: true,
        });
      }
    });

    return priceTable;
  }
  return [];
};


const getPriceTableByReq = () => {};
export default getPriceTableByReq;
