const { Kakao } = window;

// 카카오링크로 칭찬 url 공유하기.
export const sendComplimentLink = async ({ complimentId, from, to }) => {
  try {
    return new Promise(async (resolve) => {
      Kakao.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: "칭찬메세지 '1건' 도착",
          description: `${from}님께서 보내신 칭찬메세지`,
          imageUrl: 'https://s3.ap-northeast-2.amazonaws.com/odoctor-assets/images/compliment_kakaolink.png',
          link: {
            mobileWebUrl: `https://odoctor.co.kr/hospitals/demo/${complimentId}`,
            webUrl: `https://odoctor.co.kr/hospitals/demo/${complimentId}`,
          },
        },
        buttons: [
          {
            title: '칭찬 확인하러 가기',
            link: {
              mobileWebUrl: `https://odoctor.co.kr/hospitals/demo/${complimentId}`,
              webUrl: `https://odoctor.co.kr/hospitals/demo/${complimentId}`,
            },
          },
        ],
        success: (res) => {
          resolve(res);
        },
      });
    });
  } catch (e) {
    console.error(e);
  }
};

export default Kakao;
