import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { withRouter, Link } from 'react-router-dom';
import { graphql, compose } from 'react-apollo';
import { media } from '../globalStyle';
import { FlexRow, marginProps } from '../components/basic/HtmlComponents';
import {
  FormInputRow,
  FormBtnContainer,
  FormConfirmBtn,
} from '../components/basic/NewFormComponents';
import FormInput from '../components/form/FormInput';
import EmailInput from '../components/form/EmailInput';
import PhonenumInput from '../components/form/PhonenumInput';
import PasswordInput from '../components/form/PasswordInput';
import HospitalSearchModal from '../components/HospitalSearchModal';
import { isValidEmail, isValidPass } from '../utils/validationInput';
import withAlertModal from '../utils/alerthoc';
import withAuth from '../utils/authhoc';
import { isSafeEmailMutation, isSafePhoneMutation } from '../graphql';
import { logToSlack } from '../utils/logging';

const queryString = require('query-string');

const Head1 = styled.h1`
  font-size: 36px;
  line-height: 1.33;
  letter-spacing: -1.4px;
  color: #017fa0;
`;

const Label1 = styled.p`
  margin-top: 30px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
`;

const Label2 = styled.p`
  font-size: 12px;
  line-height: 1.5;
  font-weight: 500;
  letter-spacing: -0.8px;
  color: #fff;
  word-break: keep-all;
  a, a:active, a:hover, a:link, a:visited {
    text-decoration: underline;
    color: #fff;
  }
`;

const ModalLink = styled.div`
  margin-top: 16px;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.53;
  letter-spacing: -1px;
  color: #8a8a8a;
  a {
    text-decoration: underline;
    color: inherit;
    &:active,
    &:hover,
    &:link,
    &:visited {
      text-decoration: underline;
    }
  }
`;

const Br = styled.br`
  display: none;
  ${media.tablet`
    display: block;
  `};
`;

const Hr = styled.hr`
  ${marginProps} border: 0;
  border-top: 1px solid #28bcda;
`;

const Blue = styled.span`
  font-weight: bold;
  color: #033e52;
`;
const HospitalSearchBtn = styled.button`
  border: solid 1px rgb(255, 255, 255);
  min-width: 112px;
  height: 48px;
  border-radius: 1px;
  background-color: rgb(56, 200, 229);
  margin-left: 8px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: -0.9px;
  text-align: center;
  color: #ffffff;
`;

const FormHead = styled.h1`
  padding-top: 42px;
  padding-bottom: 8px;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: -1.4px;
  text-align: center;
  color: #ffffff;
  ${media.tablet`
    font-size: 22px;
  `};
`;


const LoginAnnounceWrapper = styled.div`
  text-align: center;
  margin-bottom: 30px;
`;

const Small1 = styled.span`
  text-align: center;
  font-size: 14px;
  letter-spacing: -0.9px;
  color: #ffffff;
`;

const LoginUnderline = styled.span`
  margin-left: 12px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.9px;
  color: #ffffff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const MobileCollapseHeader = styled.div`
  position: relative;
  text-align: center;
  height: 44px;
`;
const StyledCollapse = styled.div`
  float: right;
  margin-left: -24px;
  margin-top: 12px;
  &::after {
    content: '';
    clear: both;
  }
  & > ${MobileCollapseHeader} {
    display: none;
  }
  ${media.tablet`
    ${props => (props.open ? 'display: flex' : 'display: none')};
    flex-direction: column;
    float: none;
    text-align: center;
    position: fixed;
    background-color: rgba(1, 127, 160, 0.89);
    margin: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 450px;
    & > ${MobileCollapseHeader} {
      display: block;
    } 
  `};
`;
class SignupComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      passwordConfirm: '',
      name: '',
      phone: '',
      referralId: props.referral || '',
      hospital: {
        name: '',
      },
      fireSubmitValidation: false,
      emailDuplicationError: '',
      isHosModalOpen: false,
    };
  }
  

  onSelectHospital = hospital => {
    this.setState({ hospital });
  };

  onChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value, fireSubmitValidation: false });
  };

  onEmailChange = e => {
    this.onChange(e);
    this.setState({ emailDuplicationError: false });
  };

  checkEmailDuplication = async e => {
    const email = e.target.value;
    if (email && isValidEmail(email)) {
      const res = await this.props.isSafeEmail({
        variables: {
          email,
        },
      });

      const {
        isSafeEmail: { ok, isPartner },
      } = res.data;

      if (!ok) {
        this.setState({
          emailDuplicationError: '이미 사용된 이메일주소입니다. 다른 이메일을 입력해 주세요.',
        });
        return;
      }
    }
  };

  checkPhonenumDuplication = async e => {
    const phonenum = e.target.value;
    if (phonenum) {
      const res = await this.props.isSafePhone({
        variables: {
          phonenum,
        },
      });

      const {
        isSafePhone: { ok, email, isPartner },
      } = res.data;

      // 핸드폰 중복
      if (!ok) {
        let href = '';
        let confirmBtnOnClick = null;

        if (isPartner) {
          href = '/findemail';
          confirmBtnOnClick = () => {
            this.props.history.push('/login');
            this.setState({ phone: '' });
          };
        } else {
          href = 'https://odoctor.co.kr?q=findemail';
          confirmBtnOnClick = () => {
            window.location = 'https://odoctor.co.kr?q=login';
          };
        }

        // 핸드폰 중복 모달 pop
        this.props.openAlert({
          title: '이미 가입되어 있어요!',
          message: (
            <div>
              <Blue>{email}</Blue>로 이미 가입된 휴대폰 번호입니다.
              <br />
              해당 이메일로 로그인을 진행해 주세요.
              <ModalLink>
                이메일을 잊으셨나요? <a href={href}>이메일 찾기{'>'}</a>
              </ModalLink>
            </div>
          ),
          showConfirmBtn: true,
          confirmBtnName: '로그인',
          textAlign: 'left',
          onClose: () => {
            this.setState({ phone: '' });
          },
          confirmBtnOnClick,
        });
      }
    }
  };
  handleSubmit = async () => {
    const { name, email, phone, password, passwordConfirm, referralId, hospital } = this.state;

    // 버튼 클릭시 확인해야하는 정보가 없을때에 fireSubmitValidation 활성화
    if (
      !name ||
      !email ||
      !phone ||
      !password ||
      !hospital.name ||
      !(phone.length === 10 || phone.length === 11)
    ) {
      this.setState({ fireSubmitValidation: true });

      return;
    }

    // 유효하지 않은 이메일, 비밀번호를 입력했다면 무시
    if (!isValidEmail(email) || !isValidPass(password) || password !== passwordConfirm) {
      return;
    }

    // 칭찬받아서 가입하는 경우
    const searchParams = queryString.parse(this.props.location.search);
    const { compId } = searchParams;

    try {
      // 회원가입 Mutation
      const registRes = await this.props.regist({
        variables: {
          username: name,
          email,
          phonenum: phone,
          hospitalId: hospital.id,
          password,
          compId,
          referralId, // TODO : 추천인코드
        },
      });

      // 회원가입 성공시
      if (registRes.ok) {
        // 로그인 Mutation
        const loginRes = await this.props.login({
          variables: { email, password },
        });

        // webhook 이용하여 slack에 메세지 보내기
        const resp = await logToSlack(
          `${name}(${hospital.name}/${email})님이 파트너로 가입하셨습니다.`,
        );

        // 칭찬하기 페이지로 redirect
        if (loginRes) {
          this.props.history.push('/compliment');
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const {
      email,
      password,
      passwordConfirm,
      name,
      phone,
      hospital,
      referralId,
      fireSubmitValidation,
      emailDuplicationError,
    } = this.state;
    return (
      <div>
        <FormHead>병원/의료인 파트너 가입하기</FormHead>
        <LoginAnnounceWrapper>
          <Small1>이미 파트너 회원이시라면?</Small1>
          <LoginUnderline onClick={() => this.props.history.push('/login')}>
            로그인 하러가기
          </LoginUnderline>
        </LoginAnnounceWrapper>
        <FormInputRow>
          <EmailInput
            name="email"
            onChange={this.onEmailChange}
            value={email}
            placeholder="이메일 입력 (아이디로 사용됩니다)"
            onBlur={this.checkEmailDuplication}
            fireSubmitValidation={fireSubmitValidation}
            errorMsg={emailDuplicationError}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                document.getElementById('signupPassword').focus();
                this.handleSubmit();
              }
            }}
          />
        </FormInputRow>
        <FormInputRow>
          <PasswordInput
            id="signupPassword"
            name="password"
            onChange={this.onChange}
            value={password}
            placeholder="비밀번호 (영문자, 특수문자, 숫자를 조합하여 8자 이상)"
            fireSubmitValidation={fireSubmitValidation}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                document.getElementById('signupPasswordConfirm').focus();
                this.handleSubmit();
              }
            }}
          />
        </FormInputRow>
        <FormInputRow>
          <FormInput
            type="password"
            placeholder="비밀번호 확인"
            id="signupPasswordConfirm"
            name="passwordConfirm"
            onChange={this.onChange}
            value={passwordConfirm}
            errorMsg={
              password && passwordConfirm && password !== passwordConfirm
                ? '비밀번호가 일치하지 않습니다. 다시 시도해 주세요.'
                : ''
            }
            fireSubmitValidation={fireSubmitValidation}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                document.getElementById('signupName').focus();
                this.handleSubmit();
              }
            }}
          />
        </FormInputRow>

        <Hr />

        <FormInputRow>
          <FormInput
            id="signupName"
            type="text"
            placeholder="이름"
            name="name"
            onChange={this.onChange}
            value={name}
            fireSubmitValidation={fireSubmitValidation}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                document.getElementById('signupPhone').focus();
                this.handleSubmit();
              }
            }}
          />
        </FormInputRow>
        <FormInputRow>
          <PhonenumInput
            id="signupPhone"
            placeholder="휴대폰 번호 (예시: 01012345678)"
            name="phone"
            onChange={this.onChange}
            value={phone}
            fireSubmitValidation={fireSubmitValidation}
            onBlur={this.checkPhonenumDuplication}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                document.getElementById('signupHos').focus();
                this.handleSubmit();
              }
            }}
          />
        </FormInputRow>

        <Label1>소속병원</Label1>
        <FormInputRow>
          <FlexRow>
            <FlexRow style={{ flexDirection: 'column' }}>
              <FormInput
                id="signupHos"
                type="text"
                placeholder="병원 입력"
                value={hospital.name}
                readOnly
                onClick={() => this.setState({ isHosModalOpen: true })}
                fireSubmitValidation={fireSubmitValidation}
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    this.handleSubmit();
                  }
                }}
              />
            </FlexRow>
            <HospitalSearchBtn onClick={() => this.setState({ isHosModalOpen: true })}>
              병원 찾기
            </HospitalSearchBtn>
          </FlexRow>
        </FormInputRow>

        <FormInputRow>
          <FormInput
            id="referralId"
            type="text"
            placeholder="추천인 코드(선택사항)"
            name="referralId"
            onChange={this.onChange}
            value={referralId}
          />
        </FormInputRow>

        <div style={{ height: '40px' }} />

        <Label2>
          계속 진행하면 Odoctor 의 <Link to="/terms">이용약관</Link>에 동의하며{' '}
          <Link to="/privacypolicy">개인정보보호정책</Link>을 읽은 것으로 간주됩니다.
        </Label2>
        <Label2>
          또한 Odoctor 또는 Odoctor 직원이 마케팅 목적 등을 이유로 본인이 제공한 이메일 주소나
          전화번호로 이메일, 전화, SMS(자동 방식 포함)를 통해 연락할 수 있다는 데 동의합니다.
        </Label2>

        <FormBtnContainer>
          <FormConfirmBtn
            disabledView={
              !email ||
              !password ||
              !passwordConfirm ||
              password !== passwordConfirm ||
              !name ||
              !phone ||
              !hospital.name ||
              !isValidEmail(email) ||
              !isValidPass(password)
            }
            onClick={this.handleSubmit}
          >
            가입하기
          </FormConfirmBtn>
        </FormBtnContainer>
        <HospitalSearchModal
          isOpen={this.state.isHosModalOpen}
          closeModal={() => this.setState({ isHosModalOpen: false })}
          onSelectHospital={this.onSelectHospital}
        />
      </div>
    );
  }
}

export default compose(
  graphql(isSafeEmailMutation, {
    name: 'isSafeEmail',
  }),
  graphql(isSafePhoneMutation, {
    name: 'isSafePhone',
  }),
)(withAuth(withAlertModal(withRouter(SignupComponent))));
