import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const StyledLink = styled(NavLink)`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.8px;
  text-align: right;
  color: #000000;
  &:active, :focus, :hover {
    color: #000000;
  }
  &.my-menu {
    font-size: 14px;
    letter-spacing: -0.7px;
  }
`;

const NavBrand = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  display: flex;
  margin-left: 0 !important;
  &:focus,
  &:hover {
    text-decoration: none;
    color: #ffffff;
  }
`;

const LogoImage = styled.img`
  width: 125px;
  height: 24px;
  object-fit: contain;
  vertical-align: bottom;
`;

const AppName = styled.div`
  margin-left: 4px;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1.4px;
  color: #033e52;
  display: inline-block;
  vertical-align: bottom;
`;

const Nav = styled.div`
  .menu ~ .menu {
    margin-left: 24px;
  }
`;

const Menu = ({ to, children, className }) => (
  <StyledLink
    activeStyle={{
      color: '#033e52',
      fontWeight: 'bold',
    }}
    className={className ? `${className} menu` : 'menu'}
    to={to}
  >
    {children}
  </StyledLink>
);

const Brand = ({ to, appName, logoImg }) => (
  <NavBrand>
    <NavLink to={to}>
      <LogoImage src={logoImg} />
      <AppName>{appName}</AppName>
    </NavLink>
  </NavBrand>
);

Nav.Menu = Menu;
Nav.Brand = Brand;

export default Nav;
