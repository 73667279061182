import React, { Component } from 'react';
import styled from 'styled-components';
import ImageGallery from 'react-image-gallery';
import './ImageGalleryCustomCss.css';
import { sizeProps } from '../basic/HtmlComponents';
// import { media } from '../../globalStyle';
import Pre from '../../images/pre.svg';
import Next from '../../images/next.svg';
import Expand from '../../images/expand.svg';
import Close from '../../images/close-white.svg';
import { isMobile } from '../../globalStyle';

const StyledGalleryImage = styled.img`
  width: 490px;
  min-width: 490px;
  height: 280px;
  object-fit: contain;
  object-position: 50% 50%;
  background-color: #000000;
  .fullscreen & {
    height: calc(100vh - 95px);
  }
  ${sizeProps}
`;

const NavLeftButtonWrapper = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  z-index: 300;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  &:hover,
  &:focus {
    outline: none;
  }
`;

const NavRightButtonWrapper = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  z-index: 300;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  &:hover,
  &:focus {
    outline: none;
  }
`;

const FullScreenButtonWrapper = styled.button`
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  width: 490px;
  height: 280px;
  background-color: rgba(0, 0, 0, 0.18);
  z-index: 300;
  position: absolute;
  &:hover,
  &:focus {
    outline: none;
  }
`;
const CloseFullScreenButtonWrapper = styled.div`
  text-align: right;
`;

const CloseFullScreenButton = styled.img`
  padding: 20px;
  &:hover,
  &:focus {
    outline: none;
  }
`;

class UserReqPhotoGallery extends Component {
  constructor(props) {
    super(props);
    this.txImages = props.txImageUrls
      ? this.getImageObject(props.txImageUrls, 'tx')
      : [];
    this.orthoImages = props.orthoImageUrls
      ? this.getImageObject(props.orthoImageUrls, 'ortho')
      : [];
    this.images = this.txImages.concat(this.orthoImages);
    this.state = {
      showFull: false,
    };
  }

  componentWillReceiveProps(props) {
    this.txImages = props.txImageUrls
      ? this.getImageObject(props.txImageUrls, 'tx')
      : [];
    this.orthoImages = props.orthoImageUrls
      ? this.getImageObject(props.orthoImageUrls, 'ortho')
      : [];
    this.images = this.txImages.concat(this.orthoImages);
  }

  getImageObject = (urls, type) => {
    if (urls) {
      const images = [];
      for (let i = 0; i < urls.length; i++) {
        images.push({
          url: urls[i],
        });
      }
      return images;
    }
    return [];
  };

  renderItem = (item) => <StyledGalleryImage src={item.url} />;

  render() {
    return (
      <div
        onMouseOver={() => {
          this.setState({
            showFull: true,
          });
        }}
        onMouseLeave={() => {
          this.setState({
            showFull: false,
          });
        }}>
        <ImageGallery
          ref={this.gallery}
          useTranslate3D={false}
          renderLeftNav={(onClick) => {
            return (
              <NavLeftButtonWrapper onClick={onClick}>
                <img src={Pre}></img>
              </NavLeftButtonWrapper>
            );
          }}
          renderRightNav={(onClick) => {
            return (
              <NavRightButtonWrapper onClick={onClick}>
                <img src={Next}></img>
              </NavRightButtonWrapper>
            );
          }}
          items={this.images}
          showIndex={!this.state.showFull}
          showFullscreenButton={!isMobile()}
          showPlayButton={false}
          renderItem={this.renderItem}
          showThumbnails={false}
          renderFullscreenButton={(onClick, isFullscreen) => {
            return isFullscreen ? (
              <CloseFullScreenButtonWrapper>
                <CloseFullScreenButton src={Close} onClick={onClick} />
              </CloseFullScreenButtonWrapper>
            ) : (
              this.state.showFull && (
                <FullScreenButtonWrapper onClick={onClick}>
                  <img src={Expand}></img>
                </FullScreenButtonWrapper>
              )
            );
          }}
        />
      </div>
    );
  }
}

export default UserReqPhotoGallery;
