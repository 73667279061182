import React, { Component } from 'react';
import styled from 'styled-components';
import { StyledModal as Modal } from './basic/ModalComponents';
import { media } from '../globalStyle';

const StyledModal = styled(Modal)`
  width: auto;
  height: auto;
  padding: 0;
  border: 0;
  border-radius: 0;
  ${media.miniDesktop`
    width: 100%;
    
  `}
`;

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  padding-top: 30px; height: 0; overflow: hidden;
  iframe {
    border: solid 1px #d6d6d6;
    background-color: #f4f4f4;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

class PartnerGuideModal extends Component {
  render() {
    const { isOpen, close } = this.props;
    return (
      <StyledModal
        isOpen={isOpen}
        style={{
          overlay: {
            zIndex: 100,
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
          },
        }}
        ariaHideApp={false}
        onRequestClose={close}
        shouldCloseOnOverlayClick
      >
        <VideoContainer>
          <iframe title="오닥터 파트너 가이드영상" width="960" height="540" src="https://www.youtube.com/embed/YAELt8qZtJM?autoplay=1" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
        </VideoContainer>
      </StyledModal>
    );
  }
}

export default PartnerGuideModal;
