import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import LogoSvg from '../images/img-logo-gnb.svg';
import { media } from '../globalStyle';
import { MobileShow, DesktopShow } from './basic/HtmlComponents';

const FooterContainer = styled.div`
  width: 100%;
  border-top: 1px solid #e1e3e2;
  margin: 0 auto;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  ${media.miniDesktop`
    padding-top: 20px;
  `};
`;

const FooterWrapper = styled.div`
  max-width: 1040px;
  margin: 0 auto;
`;

const MobileMenus = styled.ul`
  display: none;
  ${media.miniDesktop`
    display: block;
    margin-bottom: 20px;
    list-style: none;
    & > li ~ li {
      border-left: 1px solid #e1e3e2;
      padding-left: 14px;
      margin-left: 14px;
    }

    li {
      display: inline-block;
      cursor: pointer;
      a {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -1px;
        color: #000000;
      }
    }
  `};
`;

const Menus = styled.ul`
  ${media.miniDesktop`
    display: none;
  `};
  float: right;
  list-style: none;
  & > li ~ li {
    border-left: 1px solid #e1e3e2;
    padding-left: 14px;
    margin-left: 14px;
  }

  li {
    display: inline-block;
    cursor: pointer;
    a {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -1px;
      color: #000000;
    }
  }
`;

const CopyRight = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-top: 16px;
`;

const DisclaimerPolicy = styled.div`
  font-size: 14px;
  margin: auto;
  letter-spacing: -0.8px;
  color: #000000;
  text-align: left;
`;

const Row = styled.div`
  display: block;
  margin-bottom: 3px;
  div {
    display: inline-block;
    margin-right: 4px;
  }
`;

const SpanWithRightDash = styled.div`
  position: relative;
  display: inline-block;
  &:after {
    position: absolute;
    right: -10px;
    content: '';
    height: 14px;
    border-right: 1px solid #e1e3e2;
  }
  ${media.miniDesktop`
    &:after {
      content: none;
    }
  `};
`;

const LogoImg = styled.img`
  margin-bottom: 19px;
`;

export default class AuthFooter extends Component {
  render() {
    return (
      <FooterContainer>
        <FooterWrapper>
          <MobileMenus>
            <li>
              <Link to="/cs/notice">공지사항</Link>
            </li>
            <li>
              <Link to="/terms">이용약관</Link>
            </li>
            <li>
              <Link to="/privacypolicy">
                개인정보처리방침
              </Link>
            </li>
          </MobileMenus>
          <LogoImg src={LogoSvg} alt="" />
          <DisclaimerPolicy>
            <Row>
              <SpanWithRightDash>대표 고석호</SpanWithRightDash>
              <div style={{ marginLeft: '10px' }}>사업자번호 522-81-01167</div>
              <Menus>
                <li>
                  <Link to="/cs/notice">공지사항</Link>
                </li>
                <li>
                  <Link to="/terms">이용약관</Link>
                </li>
                <li>
                  <Link to="/privacypolicy">
                    개인정보처리방침
                  </Link>
                </li>
              </Menus>
            </Row>
            <DesktopShow>
              <Row style={{ marginTop: '9px' }}>
                <SpanWithRightDash>서울 강남구 언주로85길 29 5층</SpanWithRightDash>
                <SpanWithRightDash style={{ marginLeft: '10px' }}>전화 02-3443-1357 (평일 10:00 - 19:00)</SpanWithRightDash>
                <div style={{ marginLeft: '10px' }}>이메일 partner@odoctor.co.kr</div>
              </Row>
            </DesktopShow>
            <MobileShow>
              <Row style={{ marginTop: '9px' }}>
                <div style={{ marginBottom: '6px' }}>서울 강남구 언주로85길 29 5층</div><br />
                <SpanWithRightDash>전화 02-3443-1357 (평일 10:00 - 19:00)</SpanWithRightDash><br />
                <div style={{ marginTop: '6px' }}>이메일 partner@odoctor.co.kr</div>
              </Row>
            </MobileShow>
          </DisclaimerPolicy>
          <CopyRight>© 2019 Odoctor all rights reserved.</CopyRight>
        </FooterWrapper>
      </FooterContainer>
    );
  }
}
