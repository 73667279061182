import React, { Component } from 'react';
import { graphql, compose, Query } from 'react-apollo';
import _ from 'lodash';
import { withRouter, Prompt } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import { BigBtn, RadioInput } from '../basic/HtmlComponents';
import { therapyInfoById } from '../UserRequest/therapyInfo';
import UserReqPhotoGallery from './UserReqPhotoGallery';
import {
  getOrthoPartName,
  getPriceFormat,
  getReqWantLevelLable,
} from '../../utils/req-utils';
import {
  submitHosRespsMutation,
  updateHosRespsMutation,
  userRequestQuery,
  allUserReqsQuery,
  getAccountQuery,
  getHosRespsDetailForPartnerQuery,
} from '../../graphql';
import withAlertModal from '../../utils/alerthoc';
import withToast from '../../utils/toasthoc';
import { getAgeGroupName } from '../../utils/getAge';
import WarningImg from '../../images/alert-charge.svg';
import { getLocationNames } from '../../utils/locationdata';
import { AuthContext } from '../../utils/AuthProvider';
import {
  LeftTitle,
  LeftRow,
  LeftLabel,
  LeftTitleText,
  RightTitle,
  Separater,
  PriceConatiner,
  PriceLabel,
  PriceEqual,
  PriceCount,
  PriceMultiple,
  PricePart,
  PriceSum,
  PriceUnit,
  ExpectedPrice,
  PriceInput,
  RightRow,
  RightRowItem,
  PeriodPresetShort,
  PeriodPresetLong,
  TreatPreriodContainer,
  TreatPeriodButton,
  RadioWrapper,
  DiscountRatioInput,
  UnitPercent,
  QuestionMark,
  SymptomRespInfo,
  TreatmentTextNoti,
  SymptomAnswerTextarea,
  MaxDiscountRatio,
  Greeting,
  EndGreeting,
  CheckEndGreeting,
  TreatRespInfo,
  blockEscape,
  unBlockEscape,
  TherapyItemTitle,
  TherapyItem,
  calcPriceInputType,
  getOrthoWannaString,
} from '../HosResps/HosRespsCommonComponents';
import QuestionMarkIcon from '../../images/ic-question-20.png';
import ModalCloseImg from '../../images/close.svg';
import withAuth from '../../utils/authhoc';
import { media } from '../../globalStyle';

const Container = styled.div`
  position: relative;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  width: 1080px;
  height: 100vh;
  ${media.miniDesktop`
    padding: 0;
    width: 540px;
    min-width: 540px;
    flex-wrap: wrap-reverse;
    height: 100%;
    justify-content: center;
  `};
  .route-right {
    right: 0;
    height: 100vh;
    position: fixed;
    overflow: auto;
    ::-webkit-scrollbar {
      width: 0px; /* Remove scrollbar space */
      background: transparent; /* Optional: just make scrollbar invisible */
    }
    width: 540px;
    padding: 20px;
    padding-top: 120px;
    background-color: #f3f3f3;
    ${media.miniDesktop`
      position: relative;
      min-width: 540px;
      height: 100%;
    `};
  }
  .modal-right {
    overflow: auto;
    ::-webkit-scrollbar {
      width: 0px; /* Remove scrollbar space */
      background: transparent; /* Optional: just make scrollbar invisible */
    }
    width: 540px;
    padding: 20px;
    padding-top: 120px;
    background-color: #f3f3f3;
  }
`;

const LeftContainer = styled.div`
  overflow: auto;
  ::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  width: 550px;
  padding: 20px;
`;

const Noti = styled.div`
  height: 40px;
  font-size: 14px;
  text-align: left;
  line-height: 1.43;
  letter-spacing: -0.44px;
  color: #000000;
  span {
    font-family: Saira Semi Condensed;
    font-stretch: semi-condensed;
    letter-spacing: normal;
    font-weight: bold;
    color: #033e52;
  }
`;

const WarningContainer = styled.div`
  margin-top: 30px;
  text-align: left;
`;

const WarningIconContainer = styled.div`
  display: inline-block;
  margin-right: 5px;
  vertical-align: top;
  img {
    width: 14px;
    height: 18px;
  }
`;

const WarningParagraph = styled.div`
  display: inline-block;
  vertical-align: top;
`;

const ChargeWarn = styled.p`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: -0.5px;
  color: #dd4124;
  b {
    font-weight: bold;
  }
`;

const SubmitBtn = styled.button`
  width: 244px;
  height: 56px;
  border-radius: 4px;
  background-color: #033e52;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 56px;
  letter-spacing: -0.6px;
  text-align: center;
  color: #ffffff;
  &:disabled {
    background-color: #bbbcbc;
    border-color: #bbbcbc;
  }
`;

const DirectFlag = styled.div`
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 12px;
  background-color: #ffb3aa;
  padding: 4px 14px;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.42;
  letter-spacing: -0.7px;
  text-align: center;
  color: #ffffff;
`;

const State = styled.div`
  float: left;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.4px;
  color: #033e52;
  ${(props) => props.answered && 'color: #bbbcbc;'};
`;

const RespsTextarea = styled.textarea`
  width: 422px;
  height: 230px;
  border-radius: 4px;
  border: solid 1px #e1e3e2;
  padding: 14px;
  font-size: 13px;
  line-height: 1.54;
  letter-spacing: -0.6px;
  text-align: justify;
  color: #000000;
  resize: none;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b1b3b2;
    opacity: 1; /* Firefox */
  }
`;

const TemplateEditBtn = styled.button`
  width: 120px;
  height: 46px;
  border-radius: 4px;
  background-color: #033e52;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.54;
  letter-spacing: -0.6px;
  text-align: center;
  color: #ffffff;
  &:disabled {
    background-color: #bbbcbc;
    border-color: #bbbcbc;
  }
`;

const Warning = styled.div`
  text-align: left;
  height: 40px;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: -0.4px;
  color: #000000;
  span {
    font-weight: bold;
  }
`;

const AnswerTitle = styled.div`
  height: 24px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.56px;
  color: #000000;
`;

class UserRequestComponent extends Component {
  constructor(props) {
    super(props);
    this.inModal = props.closeModal ? true : false;

    let priceInputType = 'normal';
    let expectedPeriod = '';
    let txMaxPrice = 0;
    let orthoMaxPrice = 0;
    let discountRatio = props.discountRatio ? props.discountRatio : 0;

    if (props.request.hosResps && props.request.hosResps.length > 0) {
      if (props.request.hosResps[0].metadata) {
        const { metadata } = props.request.hosResps[0];
        expectedPeriod = metadata.expectedPeriod || '';
      }
    }

    txMaxPrice = this.calcTxPriceTotal(props.txPriceTable);
    orthoMaxPrice = this.calcOrthoPriceTotal(props.orthoPriceTable);
    priceInputType = calcPriceInputType(
      discountRatio,
      txMaxPrice,
      orthoMaxPrice,
    );

    this.state = {
      treatmentAnswer: props.treatmentAnswer || '',
      symptomAnswer: props.symptomAnswer || '',
      txMaxPrice,
      orthoMaxPrice,
      txPriceTable: props.txPriceTable,
      orthoPriceTable: props.orthoPriceTable,
      expectedPeriod,
      priceInputType,
      closing: props.closing,
      discountRatio,
      showSymptomRespInfo: false,
      showTreatRespInfo: false,
      edited: false,
    };
  }
  componentDidMount = () => {
    const { history, closeModal, request } = this.props;
    blockEscape(this.onUnloadListener);
    if (request.status === 'terminated') {
      alert('이미 종료되어 답변을 할 수 없는 상담입니다.');
      if (closeModal) {
        closeModal();
      } else {
        history.push('/reqlist');
      }
    }
  };
  componentWillUnmount = () => {
    unBlockEscape(this.onUnloadListener);
  };

  onUnloadListener = (e) => {
    if (this.state.edited) {
      const message = '작성하신 답변이 저장되지 않습니다. 정말 닫으시겠어요?';
      e.preventDefault();
      e.stopPropagation();
      // For IE and Firefox
      e.returnValue = message;
      // For Safari
      return message;
    }
  };

  calcTxPriceTotal = (txPriceTable) => {
    let totalPrice = 0;

    txPriceTable.map((data) => {
      if (data.price !== '') {
        totalPrice += data.price * data.unit;
      }
    });

    return totalPrice;
  };

  calcOrthoPriceTotal = (orthoPriceTable) => {
    let totalPrice = 0;

    orthoPriceTable.map((data) => {
      if (data.price !== '') {
        totalPrice += data.price;
      }
    });

    return totalPrice;
  };

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value, edited: true });
  };

  handleTxPriceChange = ({ target }) => {
    const { name, value } = target;
    const re = /^[0-9\b]+$/;

    if (re.test(value)) {
      const price = parseInt(value);

      let newTxPriceTable = { ...this.state.txPriceTable };
      newTxPriceTable = _.map(newTxPriceTable, (data) => {
        if (data.itemcode === name) {
          return {
            ...data,
            price,
          };
        }

        return data;
      });

      this.setState({
        txPriceTable: newTxPriceTable,
        txMaxPrice: this.calcTxPriceTotal(newTxPriceTable),
        edited: true,
      });
    }
  };

  handleOrthoPriceChange = (e, itemcode, part) => {
    const { value } = e.target;
    const re = /^[0-9\b]+$/;
    if (re.test(value)) {
      const price = parseInt(value);

      let newOrthoPriceTable = { ...this.state.orthoPriceTable };
      newOrthoPriceTable = _.map(newOrthoPriceTable, (data) => {
        if (data.itemcode === itemcode && data.part === part) {
          return {
            ...data,
            price,
          };
        }

        return data;
      });

      this.setState({
        orthoPriceTable: newOrthoPriceTable,
        orthoMaxPrice: this.calcOrthoPriceTotal(newOrthoPriceTable),
        edited: true,
      });
    }
  };

  onPriceInputTypeChange = ({ target }) => {
    const { name, value } = target;
    let { txPriceTable, orthoPriceTable } = this.state;
    if (value === 'noinput') {
      txPriceTable.forEach((e, i) => {
        if (e.price !== '') {
          txPriceTable[i].price = 0;
        }
      });
      orthoPriceTable.forEach((e, i) => {
        if (e.price !== '') {
          orthoPriceTable[i].price = 0;
        }
      });

      // 가격 입력 안함 가격, 할인율 리셋
      this.setState({
        [name]: value,
        orthoPriceTable,
        orthoMaxPrice: this.calcOrthoPriceTotal(orthoPriceTable),
        txPriceTable,
        txMaxPrice: this.calcTxPriceTotal(txPriceTable),
        discountRatio: 0,
        edited: true,
      });
    } else if (value === 'normal') {
      //정가 -> 할인율 리셋
      this.setState({ [name]: value, discountRatio: 0, edited: true });
    } else {
      this.setState({ [name]: value, edited: true });
    }
  };

  onDiscountRatioChange = ({ target }) => {
    const { name, value } = target;

    const re = /^[0-9\b]+$/;
    if (!re.test(value)) {
      return;
    }

    let ratio = value;

    if (value === '') {
      ratio = parseInt(value);
    }

    if (ratio > MaxDiscountRatio || ratio < 0) {
      alert(`할인율은 최대 ${MaxDiscountRatio}%까지 입력할 수 있습니다.`);
      this.setState({ [name]: MaxDiscountRatio, edited: true });
    } else {
      this.setState({ [name]: ratio, edited: true });
    }
  };

  handleAnswerChange = (e) => {
    const { name, value } = e.target;
    const newState = { ...this.state, edited: true };
    _.set(newState, name, value);
    this.setState(newState);
  };

  handleSymptomAnswerChange = (e) => {
    const { name, value } = e.target;
    const newState = { ...this.state, edited: true };
    _.set(newState, name, value);
    this.setState(newState);
  };

  getInvalidSubmitMessage = (priceInputType) => {
    if (priceInputType === 'noinput') {
      return '답변 내용을 작성해주세요';
    } else if (priceInputType === 'discount') {
      return '할인율, 예상 치료비와 답변 내용을 작성해주세요';
    } else {
      return '예상 치료비와 답변 내용을 작성해주세요';
    }
  };
  handleSubmit = async () => {
    const {
      txMaxPrice,
      orthoMaxPrice,
      treatmentAnswer,
      orthoPriceTable,
      txPriceTable,
      expectedPeriod,
      symptomAnswer,
      discountRatio,
      priceInputType,
    } = this.state;

    const {
      request: { hosResps },
      reqId,
      toast,
      openAlert,
      closeAlert,
      history,
      updateHosResps,
      submitHosResps,
    } = this.props;

    if (!this.submitable()) {
      openAlert({
        title: '알림',
        message: this.getInvalidSubmitMessage(priceInputType),
        showConfirmBtn: true,
        confirmBtnName: '확인',
        textAlign: 'left',
        showCancelBtn: false,
      });
      return;
    }

    // variable making
    const variables = {
      ureqId: reqId,
      discountRatio: parseInt(discountRatio),
      txMaxPrice,
      orthoMaxPrice,
    };

    if (txPriceTable.length > 0) {
      variables.txPrices = [];
      await txPriceTable.forEach((data) => {
        variables.txPrices.push({
          itemcode: data.itemcode,
          price: data.price !== '' ? data.price : 0,
        });
      });
    }

    if (orthoPriceTable.length > 0) {
      variables.orthoPrices = [];
      await orthoPriceTable.forEach((data) => {
        variables.orthoPrices.push({
          itemcode: data.itemcode,
          part: data.part,
          price: data.price !== '' ? data.price : 0,
        });
      });
    }

    if (treatmentAnswer && treatmentAnswer.length > 0) {
      variables.treatmentAnswer = treatmentAnswer;
    }

    if (symptomAnswer && symptomAnswer.length > 0) {
      variables.symptomAnswer = symptomAnswer;
    }

    if (expectedPeriod) {
      variables.metadata = {
        expectedPeriod,
      };
    }

    try {
      let id = null;

      if (hosResps.length > 0) {
        // 답변 수정
        variables.id = hosResps[0].id;
        const res = await updateHosResps({
          variables,
        });

        id = res.data.updateHosResps.id;
      } else {
        // 답변 생성
        const res = await submitHosResps({
          variables,
        });

        id = res.data.submitHosResps.id;
      }

      localStorage.setItem('discountRatio', discountRatio);
      if (id) {
        toast(<div>등록완료</div>, 'center');
        return this.props.closeModal();
      }
    } catch (e) {
      // 현재 답변 submit 잔액부족하면 alert and 충전신청페이지로 redirect
      if (e.graphQLErrors && e.graphQLErrors[0] && e.graphQLErrors[0].message) {
        if (e.graphQLErrors[0].message === '충전이 필요합니다.') {
          openAlert({
            title: '충전이 필요합니다.',
            message: (
              <div>
                충전금이 부족합니다.
                <br />
                답변을 등록하려면 추가로 충전해주세요.
              </div>
            ),
            showConfirmBtn: true,
            confirmBtnName: '확인',
            confirmBtnOnClick: () => {
              history.push('odoctorcash');
            },
            textAlign: 'left',
            showCancelBtn: false,
          });
          return;
        }

        if (e.graphQLErrors[0].message === '답변 개수 초과') {
          openAlert({
            title: '답변 개수 초과',
            message: (
              <div>
                이번 달 답변 건수를 모두 사용하였습니다.
                <br />
                답변을 더 등록하려면 고객센터로 문의해주세요.
              </div>
            ),
            showConfirmBtn: true,
            confirmBtnName: '확인',
            confirmBtnOnClick: closeAlert,
            textAlign: 'left',
            showCancelBtn: false,
          });
          return;
        }

        openAlert({
          title: '알림',
          message: e.graphQLErrors[0].message,
          showConfirmBtn: true,
          confirmBtnName: '확인',
          textAlign: 'left',
          confirmBtnOnClick: closeAlert,
          showCancelBtn: false,
        });
      }
    }
  };

  submitable = () => {
    const {
      treatmentAnswer,
      priceInputType,
      txPriceTable,
      orthoPriceTable,
      discountRatio,
    } = this.state;
    const { request } = this.props;
    if (!treatmentAnswer) return false;

    const txPriceEmpty = _.find(txPriceTable, { price: 0 }) ? true : false;
    const orthoPriceEmpty = _.find(orthoPriceTable, { price: 0 })
      ? true
      : false;

    if (priceInputType === 'discount' && parseInt(discountRatio) === 0) {
      return false;
    }

    if (request.reqType === 'all') {
      if (priceInputType !== 'noinput' && (txPriceEmpty || orthoPriceEmpty)) {
        return false;
      }
    } else if (request.reqType === 'tx') {
      if (priceInputType !== 'noinput' && txPriceEmpty) {
        return false;
      }
    } else if (request.reqType === 'ortho') {
      if (priceInputType !== 'noinput' && orthoPriceEmpty) {
        return false;
      }
    }

    return true;
  };

  calcClosing = (closing) => {
    if (closing && closing.length == 0) {
      return '작성된 맺음말 템플릿 내용이 없습니다.';
    } else if (closing && closing.length > 26) {
      return closing.slice(0, 26) + '...';
    } else {
      return closing;
    }
  };

  render() {
    const { request, closeModal, meh, getAccount } = this.props;
    const { hosResps } = request;
    let answered = false;
    let checkedAns = false;
    let terminatedUser = false;
    const {
      orthoPriceTable,
      txPriceTable,
      treatmentAnswer,
      expectedPeriod,
      symptomAnswer,
      closing,
      showSymptomRespInfo,
      edited,
      discountRatio,
      priceInputType,
    } = this.state;
    const {
      user: { username, phonenum, metadata },
      gender,
      ageGroup,
      reqType,
      txImageUrls,
      orthoImageUrls,
      createdAt,
      directReq,
      locations,
      chargePrice,
    } = request;

    if (username.includes('(탈퇴)')) {
      terminatedUser = true;
    }

    if (hosResps.length > 0) answered = true;
    if (answered && hosResps[0].checkedAns) checkedAns = true;
    if (!request) {
      return '존재하지 않는 상담입니다';
    }

    const hospitalRespPlan = getAccount.getHospitalRespPlan;
    const balance =
      getAccount.getBalance && getAccount.getBalance.balanceTotal
        ? parseInt(getAccount.getBalance.balanceTotal)
        : 0;

    return (
      <Container>
        <Prompt
          when={edited}
          message='작성하신 답변이 저장되지 않습니다. 정말 닫으시겠어요?'
        />
        <LeftContainer style={{ marginTop: 100 }}>
          <LeftTitle>
            <LeftTitleText style={{ fontWeight: 'bold' }}>
              {username}
            </LeftTitleText>
            <LeftTitleText>님의 상담상세</LeftTitleText>
          </LeftTitle>
          <LeftRow style={{ marginTop: 20, whiteSpace: 'pre' }}>
            <Warning>
              <span>할인가</span> 입력 시, <span>할인된 가격</span>을 기준으로
              입력해주세요{'\n'}
              <span>가격 입력안함</span>을 선택하실 경우, 이용자에게 가격이{' '}
              <span>문의필요</span>로 표시됩니다.
            </Warning>
          </LeftRow>
          <LeftRow style={{ marginTop: 92, alignItems: 'center' }}>
            <LeftLabel>가격입력</LeftLabel>
            <RadioWrapper>
              <RadioInput
                id='normal'
                name='priceInputType'
                value='normal'
                onChange={this.onPriceInputTypeChange}
                checked={priceInputType === 'normal'}
                labelName='정가 입력'
              />
            </RadioWrapper>
            <RadioWrapper>
              <RadioInput
                id='discount'
                name='priceInputType'
                value='discount'
                onChange={this.onPriceInputTypeChange}
                checked={priceInputType === 'discount'}
                labelName='할인가 입력'
              />
            </RadioWrapper>
            <RadioWrapper>
              <RadioInput
                id='noinput'
                name='priceInputType'
                value='noinput'
                onChange={this.onPriceInputTypeChange}
                checked={priceInputType === 'noinput'}
                labelName='가격 입력안함'
              />
            </RadioWrapper>
          </LeftRow>
          <LeftRow style={{ marginTop: 30, alignItems: 'center' }}>
            <LeftLabel>할인율</LeftLabel>
            <DiscountRatioInput
              placeholder='1~45'
              name='discountRatio'
              value={discountRatio ? discountRatio : ''}
              onChange={this.onDiscountRatioChange}
              disabled={priceInputType !== 'discount'}
              readOnly={priceInputType !== 'discount'}
            />
            <UnitPercent>%</UnitPercent>
          </LeftRow>
          {(reqType === 'all' || reqType === 'tx') && (
            <LeftRow style={{ marginTop: 90 }}>
              <LeftLabel style={{ paddingTop: 29 }}>치과치료</LeftLabel>
              <div>
                {txPriceTable.map((data, idx) => (
                  <PriceConatiner key={`tx_${data.itemcode}`}>
                    <PriceLabel style={{ textAlign: 'left' }}>
                      {therapyInfoById[data.itemcode].viewName}
                    </PriceLabel>
                    <PriceInput
                      name={data.itemcode}
                      type='phone'
                      value={data.price ? data.price : ''}
                      onChange={this.handleTxPriceChange}
                      disabled={terminatedUser || priceInputType === 'noinput'}
                      readOnly={terminatedUser || priceInputType === 'noinput'}
                    />
                    <PriceUnit>만원</PriceUnit>
                    <PriceMultiple></PriceMultiple>
                    <PriceCount style={{ textAlign: 'right' }}>
                      <span>{data.unit}</span>개(회)
                    </PriceCount>
                    <PriceEqual></PriceEqual>
                    <PriceSum style={{ textAlign: 'right' }}>
                      <b>
                        {data.price * data.unit
                          ? getPriceFormat(data.price * data.unit)
                          : 0}
                      </b>
                      만원
                    </PriceSum>
                  </PriceConatiner>
                ))}
                <Separater></Separater>
                <ExpectedPrice>
                  <div>예상 치료비 (약)</div>
                  <div>
                    <big>
                      {this.state.txMaxPrice
                        ? getPriceFormat(this.state.txMaxPrice)
                        : 0}
                    </big>
                    만원
                  </div>
                </ExpectedPrice>
              </div>
            </LeftRow>
          )}
          {/* 교정 치료 */}
          {(reqType === 'all' || reqType === 'ortho') && (
            <LeftRow style={{ marginTop: 90 }}>
              <LeftLabel style={{ paddingTop: 29 }}>교정치료</LeftLabel>
              <div>
                {orthoPriceTable.map((data, idx) => (
                  <PriceConatiner key={`${data.part}`}>
                    <PriceLabel style={{ textAlign: 'left' }}>
                      {therapyInfoById[data.itemcode].viewName}
                    </PriceLabel>
                    <PriceInput
                      name={data.itemcode}
                      value={data.price ? data.price : ''}
                      disabled={terminatedUser || priceInputType === 'noinput'}
                      readOnly={terminatedUser || priceInputType === 'noinput'}
                      onChange={(e) =>
                        this.handleOrthoPriceChange(e, data.itemcode, data.part)
                      }
                    />
                    <PriceUnit>만원</PriceUnit>
                    <PricePart style={{ textAlign: 'right' }}>
                      {getOrthoPartName(data.part)}
                    </PricePart>
                    <PriceSum style={{ textAlign: 'right' }}>
                      <b>{data.price ? getPriceFormat(data.price) : ''}</b>만원
                    </PriceSum>
                  </PriceConatiner>
                ))}
                <Separater></Separater>
                <ExpectedPrice>
                  <div>예상 치료비 (약)</div>
                  <div>
                    <big>
                      {this.state.orthoMaxPrice
                        ? getPriceFormat(this.state.orthoMaxPrice)
                        : 0}
                    </big>
                    만원
                  </div>
                </ExpectedPrice>
              </div>
            </LeftRow>
          )}
          <LeftRow style={{ marginTop: 30, height: 24 }}>
            {priceInputType === 'discount' && (
              <>
                <LeftLabel></LeftLabel>
                <ChargeWarn
                  style={{
                    whiteSpace: 'pre',
                    textAlign: 'left',
                  }}>
                  할인된 가격을 입력해주세요{'\n'}
                  급여(보험) 치료는 할인 대상에서 제외됩니다.
                </ChargeWarn>
              </>
            )}
          </LeftRow>
          {/* 답변 내용 */}
          <LeftRow
            style={{
              marginTop: 128,
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}>
            <AnswerTitle>답변 내용</AnswerTitle>
            <TemplateEditBtn
              onClick={() => {
                window.open('/myhospital#autoCounsel');
              }}>
              상담 템플릿 수정
            </TemplateEditBtn>
          </LeftRow>
          <LeftRow>
            <Separater style={{ marginTop: 10 }} color='#e1e3e2'></Separater>
          </LeftRow>
          <LeftRow style={{ marginTop: 60, alignItems: 'center' }}>
            <LeftLabel>인사말</LeftLabel>
            {meh?.hospital?.name && (
              <Greeting>{`안녕하세요, ${username}님. ${meh.hospital.name}입니다.`}</Greeting>
            )}
            {/* 인사말 */}
          </LeftRow>
          <LeftRow style={{ marginTop: 60 }}>
            <LeftLabel style={{ paddingTop: 7 }}>
              증상답변
              {/* <ToolTip message="카드의 혜택을 직접 고르실 수 있습니다.\n다만 혜택에 따라 연회비가 추가될 수 있어요." /> */}
              <QuestionMark
                src={QuestionMarkIcon}
                onMouseEnter={() => {
                  this.setState({
                    showSymptomRespInfo: true,
                  });
                }}
                onMouseLeave={() => {
                  this.setState({
                    showSymptomRespInfo: false,
                  });
                }}
              />
              {showSymptomRespInfo && <SymptomRespInfo></SymptomRespInfo>}
            </LeftLabel>
            <div>
              <SymptomAnswerTextarea
                placeholder='예) 이전에 때웠던 부분에 시린 증상이 다시 발생하셨군요. 이가 시린 경우 보통 충치라고 생각하시는데, 충치 외에도 다양한 원인이 있을 수 있습니다. 잘못된 습관으로 인한 치아 마모 때문일 수도 있고, 잇몸질환 때문일 수도 있습니다. 각 원인에 따라 필요한 치료방법이 달라지므로 우선은 정확한 검진을 통해 어떤 문제인지 먼저 확인하시는 것을 권장드립니다.'
                name='symptomAnswer'
                value={symptomAnswer}
                onChange={this.handleSymptomAnswerChange}
                disabled={terminatedUser}
                readOnly={terminatedUser}
              />
              <TreatmentTextNoti>
                더 많은 병원 노출을 위한 상담 사례로 증상답변 내용이 공개됩니다
                (치료비 절대 비공개)
              </TreatmentTextNoti>
            </div>
          </LeftRow>
          {/* 치료 기간 */}
          <LeftRow style={{ marginTop: 60 }}>
            <LeftLabel style={{ marginTop: 14 }}>예상 치료기간</LeftLabel>
            <div>
              <TreatPreriodContainer>
                {PeriodPresetShort.map((e, i) => {
                  return (
                    <TreatPeriodButton
                      key={i}
                      style={{
                        marginLeft: i === 0 ? 0 : 11,
                        border: e === expectedPeriod && 'solid 1px #033e52',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        this.setState({
                          expectedPeriod: e,
                          edited: true,
                        });
                      }}>
                      {e}
                    </TreatPeriodButton>
                  );
                })}
              </TreatPreriodContainer>
              <TreatPreriodContainer>
                {PeriodPresetLong.map((e, i) => {
                  return (
                    <TreatPeriodButton
                      style={{
                        marginLeft: i === 0 ? 0 : 11,
                        marginTop: 10,
                        border: e === expectedPeriod && 'solid 1px #033e52',
                        cursor: 'pointer',
                      }}
                      key={i}
                      onClick={() => {
                        this.setState({
                          expectedPeriod: e,
                          edited: true,
                        });
                      }}>
                      {e}
                    </TreatPeriodButton>
                  );
                })}
              </TreatPreriodContainer>
            </div>
          </LeftRow>
          <LeftRow style={{ marginTop: 60 }}>
            <LeftLabel style={{ paddingTop: 7 }}>
              치료안내
              <QuestionMark
                src={QuestionMarkIcon}
                onMouseEnter={() => {
                  this.setState({
                    showTreatRespInfo: true,
                  });
                }}
                onMouseLeave={() => {
                  this.setState({
                    showTreatRespInfo: false,
                  });
                }}
              />
              {this.state.showTreatRespInfo && <TreatRespInfo></TreatRespInfo>}
            </LeftLabel>
            <RespsTextarea
              placeholder='상담 템플릿에 작성된 내용이 없습니다.&#13;&#10;상담 템플릿에 내용을 작성하고 편리하게 답변을 남기세요.'
              name='treatmentAnswer'
              value={treatmentAnswer}
              onChange={this.handleAnswerChange}
              disabled={terminatedUser}
              readOnly={terminatedUser}
            />
          </LeftRow>
          <LeftRow style={{ marginTop: 60, alignItems: 'center' }}>
            <LeftLabel>맺음말</LeftLabel>
            <EndGreeting>{this.calcClosing(closing)}</EndGreeting>
            <CheckEndGreeting
              onClick={() => {
                window.open('/myhospital#autoCounsel');
              }}>
              {closing ? '맺음말 확인하기' : '맺음말 작성하기'}
            </CheckEndGreeting>
          </LeftRow>
          <LeftRow style={{ marginTop: 120 }}>
            {directReq && (
              <Noti>
                <div>
                  다이렉트 상담은 무료로 답변하실 수 있습니다.
                  <br />
                  등록한 답변은 이용자가 조회한 후에는 수정할 수 없습니다.
                </div>
              </Noti>
            )}
            {!directReq && hospitalRespPlan && (
              <Noti>
                이용자가 답변을 조회 시, 이용자의 연락처를 확인 할 수 있으며
                등록한 답변은 이용자가 조회한 후에는 수정할 수 없습니다.
              </Noti>
            )}
            {!directReq && !hospitalRespPlan && (
              <Noti>
                {chargePrice && chargePrice !== '0' && (
                  <div>
                    이용자가 답변을 조회 시, 이용자의 연락처를 확인 할 수 있으며{' '}
                    <span>{getPriceFormat(chargePrice)} 원이 과금</span>
                    됩니다.
                    <br />
                    등록한 답변은 이용자가 조회한 후에는 수정할 수 없습니다.
                  </div>
                )}
              </Noti>
            )}
          </LeftRow>
          <LeftRow>
            {!directReq && !hospitalRespPlan && !answered && balance < 100000 && (
              <WarningContainer>
                <WarningIconContainer>
                  <img src={WarningImg} alt='' />
                </WarningIconContainer>
                <WarningParagraph>
                  <ChargeWarn>
                    충전금 잔액이{' '}
                    <b>
                      {`${balance > 50000 ? '10' : '5'}`}
                      만원
                    </b>{' '}
                    미만입니다.
                    <br />
                    충전금이 부족할 경우 답변 등록이 제한될 수 있으므로 미리
                    충전하는 것을 추천드려요.
                  </ChargeWarn>
                </WarningParagraph>
              </WarningContainer>
            )}
          </LeftRow>
          <LeftRow
            style={{
              justifyContent: 'center',
              marginTop: 60,
              marginBottom: 180,
            }}>
            {terminatedUser && (
              <div style={{ width: '100%', display: 'inline-block' }}>
                <BigBtn danger>탈퇴한 유저입니다.</BigBtn>
              </div>
            )}
            {!terminatedUser && !checkedAns && (
              <SubmitBtn onClick={this.handleSubmit}>
                {answered ? <span>답변 수정</span> : <span>답변 등록</span>}
              </SubmitBtn>
            )}
          </LeftRow>
        </LeftContainer>
        <div className={this.inModal ? 'modal-right' : 'route-right'}>
          <RightTitle>{username}</RightTitle>
          <Separater style={{ marginTop: 30 }} color='#d4d6d5'></Separater>
          <RightRow style={{ height: 64 }}>
            <RightRowItem>
              {directReq ? '다이렉트 상담' : getLocationNames(locations, ', ')}
            </RightRowItem>
            <RightRowItem>
              <State answered={answered}>
                {answered ? '답변완료' : '답변대기'}
              </State>
            </RightRowItem>
          </RightRow>
          <Separater color='#d4d6d5'></Separater>
          <RightRow style={{ height: 64 }}>
            <RightRowItem>
              {gender && (gender === 'female' ? '여자' : '남자')}
              {gender && ageGroup && ', '}
              {ageGroup && getAgeGroupName(ageGroup)}
              {!ageGroup && !gender && '성별, 연령 정보 없음'}
            </RightRowItem>
            <RightRowItem>
              {request.wantLevel && (
                <RightRowItem>
                  {getReqWantLevelLable(request.wantLevel)}
                </RightRowItem>
              )}
            </RightRowItem>
          </RightRow>
          <Separater color='#d4d6d5'></Separater>
          <RightRow style={{ height: 64 }}>
            <RightRowItem>
              상담 신청일:{' '}
              <span>{moment(createdAt).format('YYYY.MM.DD HH:mm')}</span>
            </RightRowItem>
            <RightRowItem></RightRowItem>
          </RightRow>
          {((txImageUrls && txImageUrls.length > 0) ||
            (orthoImageUrls && orthoImageUrls.length > 0)) && (
            <>
              <Separater color='#d4d6d5'></Separater>
              <RightRow style={{ marginTop: 20, paddingBottom: 20 }}>
                <UserReqPhotoGallery
                  txImageUrls={txImageUrls}
                  orthoImageUrls={orthoImageUrls}
                />
              </RightRow>
            </>
          )}
          {request.orthoWanna && request.orthoWanna.length > 0 && (
            <>
              <Separater color='#d4d6d5'></Separater>
              <RightRow
                style={{ marginTop: 20, textAlign: 'left', height: 20 }}>
                <TherapyItemTitle>희망 개선사항:</TherapyItemTitle>
              </RightRow>
              <RightRow style={{ textAlign: 'left', paddingBottom: 20 }}>
                <TherapyItem>
                  {getOrthoWannaString(request.orthoWanna)}
                </TherapyItem>
              </RightRow>
            </>
          )}
          {request.desc && (
            <>
              <Separater color='#d4d6d5'></Separater>
              <RightRow
                style={{
                  paddingTop: 20,
                  textAlign: 'left',
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-all',
                }}>
                {request.desc}
              </RightRow>
            </>
          )}
        </div>
        {this.inModal && (
          <img
            style={{
              position: 'absolute',
              top: 20,
              right: 20,
              cursor: 'pointer',
            }}
            onClick={() => {
              if (
                edited &&
                !window.confirm(
                  '작성하신 답변이 저장되지 않습니다. 정말 닫으시겠어요?',
                )
              ) {
                return;
              }
              closeModal();
            }}
            width={30}
            height={30}
            src={ModalCloseImg}></img>
        )}
      </Container>
    );
  }
}

UserRequestComponent.contextType = AuthContext;

export default compose(
  graphql(getAccountQuery, {
    name: 'getAccount',
  }),
  graphql(submitHosRespsMutation, {
    name: 'submitHosResps',
    options: ({ reqId }) => ({
      refetchQueries: [
        {
          query: userRequestQuery,
          variables: { reqId },
        },
        {
          query: allUserReqsQuery,
        },
      ],
    }),
  }),
  graphql(updateHosRespsMutation, {
    name: 'updateHosResps',
    options: ({ reqId }) => ({
      refetchQueries: [
        {
          query: userRequestQuery,
          variables: { reqId },
        },
        {
          query: allUserReqsQuery,
        },
      ],
    }),
  }),
)(withAuth(withRouter(withAlertModal(withToast(UserRequestComponent)))));
