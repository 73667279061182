import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';
import { graphql, compose } from 'react-apollo';
import { media } from '../globalStyle';
import {
  FormBackground,
  FormWrapper,
  FormTitle,
  FormSubTitle,
  FormContent,
  FormInputRow,
  FormBtnContainer,
  FormConfirmBtn,
  FormInputRowTitle,
  Hr,
} from '../components/basic/HtmlComponents';
import EmailInput from '../components/form/EmailInput';
import { isValidEmail } from '../utils/validationInput';
import AuthFooter from '../components/AuthFooter';
import withAlertModal from '../utils/alerthoc';
import withToast from '../utils/toasthoc';
import { sendPasswordUpdateMailToHuserMutation } from '../graphql';

const Blue = styled.span`
  font-weight: bold;
  color: #033e52;
`;

const Desc = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.7px;
  color: #bbbcbc;
  margin-top: 6px;
  b {
    font-weight: bold;
  }
`;

const FooterContainer = styled.div`
  margin-top: 112px;
`;

const FindEmailLink = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: #000000;
  a {
    &:hover {
      text-decoration: underline;
    }
    color: inherit;
  }
  ${media.miniDesktop`
    font-size: 14px;
  `};
`;

const ModalBold = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: -1px;
  color: #767676;
`;

const ModalInfo = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: -1px;
  color: #8a8a8a;
  margin-top: 4px;
`;

class FindPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      fireSubmitValidation: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange({ target }) {
    const { name, value } = target;

    this.setState({
      [name]: value,
      fireSubmitValidation: false,
    });
  }

  handleSubmit = async () => {
    const { email } = this.state;
    if (!email) {
      this.setState({ fireSubmitValidation: true });
      return;
    }

    if (!isValidEmail(email)) {
      return;
    }

    const result = await this.props.sendPasswordUpdateMailToHuser({
      variables: {
        email,
      },
    });

    if (result.data.sendPasswordUpdateMailToHuser && result.data.sendPasswordUpdateMailToHuser.ok) {
      this.props.openAlert({
        title: '비밀번호 재설정 메일 발송',
        message: (
          <div>
            비밀번호 재설정 메일이 전송되었습니다. 안내에 따라 비밀번호 재설정 해주세요.
            <Hr margin="16px 0" color="#e0e0e0" />
            <ModalBold>메일이 오지 않는 경우</ModalBold>
            <ModalInfo>
              - 스팸 메일을 확인해 주세요
              <br />- 스팸 메일로 분류해두신 경우, 스팸 메일함으로 발송 될 수 있습니다.
            </ModalInfo>
          </div>
        ),
        showConfirmBtn: true,
        confirmBtnName: '확인',
        textAlign: 'left',
        confirmBtnOnClick: () => {
          this.props.history.push('/login');
        },
      });
      return;
    }

    this.props.openAlert({
      title: '비밀번호 찾기 오류',
      message: (
        <div>
          <Blue>{email}</Blue>로 찾은 결과, 일치하는 오닥터 계정이 없습니다. 다시 시도하거나 회원가입을 진행해 주세요.
        </div>
      ),
      showConfirmBtn: true,
      confirmBtnName: '확인',
      textAlign: 'left',
      confirmBtnOnClick: this.props.closeAlert,
    });
    
  };

  render() {
    const { email, fireSubmitValidation } = this.state;
    return (
      <FormBackground>
        <FormWrapper>
          <FormTitle>비밀번호 찾기</FormTitle>
          <FormSubTitle>비밀번호 변경을 안내 받으실 이메일 주소를 적어주세요.</FormSubTitle>
          <FormContent>
            <FormInputRow>
              <FormInputRowTitle>이메일</FormInputRowTitle>
              <EmailInput
                name="email"
                onChange={this.onChange}
                value={email}
                placeholder="회원가입 시 등록한 이메일 입력"
                fireSubmitValidation={fireSubmitValidation}
              />
            </FormInputRow>
            <Desc>
              가입한 이메일 주소를 입력해 주시면, 비밀번호 재설정 링크를 보내드립니다. 안내된 메일을
              따라 새로운 비밀번호로 변경해 주세요. 비밀번호 재설정 링크의 유효시간은 요청 후{' '}
              <b>1시간</b>입니다.
            </Desc>
            <FormBtnContainer>
              <FormConfirmBtn
                disabledView={!email || !isValidEmail(email)}
                onClick={this.handleSubmit}
              >
                비밀번호 찾기
              </FormConfirmBtn>
            </FormBtnContainer>
            <FindEmailLink>
              <Link to="/login">
                로그인으로 돌아가기
              </Link>
              <Link style={{ float: 'right' }} to="/findemail">
                이메일 찾기
              </Link>
            </FindEmailLink>
          </FormContent>
        </FormWrapper>
        <FooterContainer>
          <AuthFooter />
        </FooterContainer>
      </FormBackground>
    );
  }
}

export default compose(
  graphql(sendPasswordUpdateMailToHuserMutation, {
    name: 'sendPasswordUpdateMailToHuser',
  }),
)(withRouter(withAlertModal(withToast(FindPassword))));
