import React from 'react';
import styled from 'styled-components';
import { withApollo, graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import { media } from '../../globalStyle';
import { TextInput } from '../basic/HtmlComponents';

import { locationGunguQuery, allUserReqsQuery } from '../../graphql';
import withAlertModal from '../../utils/alerthoc';
import MomentDiff from '../basic/MomentDiff';
import LocationFilter from './LocationFilter';
import { getLocationNames } from '../../utils/locationdata';

const OnlyMobileVisible = styled.div`
  display: none;
  ${media.miniDesktop`
    display: block;
  `};
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: -0.6px;
  color: #000000;
  margin-bottom: 30px;
`;

const ReqCard = styled.div`
  &:first-of-type {
    border-top: 1px #e1e3e2 solid;
  }
  border-bottom: 1px #e1e3e2 solid;
  padding: 20px 0;
  & > div {
    display: flex;
    justify-content: space-between;
    &.mb-4 {
      margin-bottom: 4px;
    }
    &.mb-30 {
      margin-bottom: 30px;
    }
  }

  .username {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.51px;
    color: #000000;
  }
  .tier {
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: right;
    color: #000000;
  }
  .location {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: -0.34px;
    color: #000000;
  }
  .responded {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.34px;
    color: #bbbcbc;
  }
  .not-responded {
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.34px;
    color: #033e52;
  }
  .timediff {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.34px;
    text-align: right;
    color: #b1b3b2;
  }
`;

const ExtendedTextInput = styled(TextInput)`
  margin-top: 10px;
  margin-bottom: 30px;
  border-radius: 4px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: -0.6px;
  color: #000;
  padding: 0 14px;
  ${media.miniDesktop`
    width: 100%;
  `};
`;

const SearchBtn = styled.button`
  width: 100%;
  height: 56px;
  border-radius: 4px;
  background-color: #033e52;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: -0.6px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 60px;
`;

const MoreBtn = styled.button`
  margin-top: 30px;
  width: 100%;
  height: 56px;
  border-radius: 4px;
  border: solid 1px #e8e8e8;
  background-color: #fff;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: -0.6px;
  text-align: center;
  color: #000000;
`;

const BlankList = styled.div`
  text-align: center;
  color: #b1b3b2;
  font-size: 14px;
  letter-spacing: -0.4;
  margin-top: 30px;
`;

const ReqCardComponent = ({ item, onClick }) => (
  <ReqCard onClick={onClick}>
    <div className="mb-4">
      <div className="username">{item.user.username}</div>
      <div className="tier">
        {item.chargeTier ? `Tier ${item.chargeTier}` : '무료'}
      </div>
    </div>
    <div className="mb-30 location">
      {item.directReq
        ? '다이렉트 상담'
        : `${item.locations ? getLocationNames(item.locations, ', ') : ''}`}
    </div>
    <div>
      <div className={item.res ? 'responded' : 'not-responded'}>
        {item.res ? '답변완료' : '답변대기'}
      </div>
      <div className="timediff">
        <MomentDiff eventAt={item.createdAt} />
        &nbsp;전 등록
      </div>
    </div>
  </ReqCard>
);

class MobileReqListComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reqType: props.reqType,
      replied: props.replied,
      username: props.username,
      locationSi: '',
      locationGu: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onFilterChange = ({ target }) => {
    const { name, value } = target;
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    const { setFilterState } = this.props;
    setFilterState(this.state);
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { loading, allUserReqs, loadMoreReqs, history } = this.props;

    const {
      username,
    } = this.state;

    return (
      <OnlyMobileVisible>
        <Title>상담관리</Title>
        <LocationFilter
          handleLocationSiChange={value => this.setState({ locationSi: value })}
          handleLocationGuChange={value => this.setState({ locationGu: value })}
        />
        <div>
          <ExtendedTextInput
            width="100%"
            height="56px"
            type="text"
            name="username"
            onChange={this.onFilterChange}
            textAlign="left"
            placeholder="이름"
            value={username}
          />
        </div>
        <div>
          <SearchBtn onClick={this.handleSubmit}>검색</SearchBtn>
        </div>
        <div>
          {!loading && (
            <div>
              {allUserReqs?.userReqs?.length === 0 ? (
                <BlankList>
                  상담이 존재하지않습니다.
                </BlankList>
              ) : (
                <div>
                  {allUserReqs?.userReqs.map((item, index) => (
                    <ReqCardComponent key={index} item={item} onClick={() => window.open(`/reqs/${item.id}`)} />
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
        {allUserReqs?.userReqs?.length !== allUserReqs?.pagination?.total
          && allUserReqs?.userReqs?.length !== 0 && (
            <MoreBtn
              onClick={() => loadMoreReqs(allUserReqs?.pagination?.currentPage + 1)}
            >
              더보기
            </MoreBtn>
          )}
      </OnlyMobileVisible>
    );
  }
}

const ITEM_PER_PAGE = 5;

export default graphql(allUserReqsQuery, {
  options: ({
    reqType, replied, username, locationSi, locationGu,
  }) => ({
    fetchPolicy: 'network-only',
    variables: {
      perPage: ITEM_PER_PAGE,
      currentPage: 1,
      reqType,
      replied,
      username,
      locationSi,
      locationGu,
    },
  }),
  props: ({ data: { loading, allUserReqs, fetchMore } }) => ({
    loading,
    allUserReqs,
    loadMoreReqs(page) {
      return fetchMore({
        variables: {
          currentPage: page,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (fetchMoreResult?.allUserReqs?.userReqs?.length < 1) {
            return previousResult;
          }
          const userReqs = previousResult.allUserReqs.userReqs.concat(
            fetchMoreResult.allUserReqs.userReqs,
          );
          return Object.assign({}, fetchMoreResult, {
            allUserReqs: {
              ...fetchMoreResult.allUserReqs,
              userReqs,
              pagination: fetchMoreResult.allUserReqs.pagination,
            },
          });
        },
      });
    },
  }),
})(withAlertModal(withRouter(MobileReqListComponent)));
