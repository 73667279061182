import React, { Component } from 'react';
import styled from 'styled-components';
import { PaddingDiv } from '../basic/HtmlComponents';
import StyledPagination from '../basic/StyledPagination';
import MomentDiff from '../basic/MomentDiff';
import { getLocationNames } from '../../utils/locationdata';
import {
  CardItemContainerAccent,
  UserName,
  UserLocation,
  EmptyDataInfoMsg,
  SeparatorDash,
  getTherapyString,
  Therapy
} from '../HosResps/HosRespsCommonComponents';
import { FullScreenContainerModal } from '../basic/ModalComponents';
import ReqDetail from '../../routes/ReqDetail';

const ReqItemList = styled.div`
  margin-top: 120px;
  border-top: solid 1px rgba(255, 255, 255, 0.1);
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
`;

const MomentDiffDiv = styled.div`
  height: 22px;
  font-size: 14px;
  letter-spacing: -0.4px;
  color: #b1b3b2;
  span {
    letter-spacing: normal;
    font-family: Saira Semi Condensed;
    font-stretch: semi-condensed;
  }
`;

const DataRow = styled.div`
  display: flex;
  width: 100%;
  height: 97px;
  padding: 18px 30px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  cursor: pointer;
  background-color: ${(props) => (props.active ? '#f9fafb' : 'transparent')};
  position: relative;
  justify-content: space-between;
  border-top: 1px solid #d6d6d6;
`;

const UserData = styled.div`
  font-size: 16px;
  color: #6b6b6b;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  vertical-align: middle;
  text-align: center;
`;

const AnsweredMark = styled.div`
  height: 17px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.4px;
  color: #b1b3b2;
  ${(props) => props.active && 'color: #033e52'};
`;


class ReqListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      curReqId: null,
      isReqDetailModalOpen: false,
    };
  }

  handlePageChange = (page) => {
    if (
      this.props.allUserReqs &&
      this.props.allUserReqs?.pagination &&
      this.props.allUserReqs?.pagination?.currentPage !== page
    ) {
      this.props.loadMoreReqs(page);
    }
  };

  render() {
    const { allUserReqs, itemPerPage } = this.props;
    const reqList = allUserReqs ? allUserReqs.userReqs : [];
    const { pagination } = allUserReqs;

    return (
      <React.Fragment>
        <ReqItemList>
          {reqList && reqList.length > 0 ? (
            reqList.map((item, index) => (
              <CardItemContainerAccent
                accent={!item.res}
                key={index}
                onClick={() => {
                  this.setState({
                    curReqId: item.id,
                    isReqDetailModalOpen: true,
                  });
                }}>
                <div
                  style={{
                    height: 22,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  <UserData>
                    <MomentDiffDiv>
                      <MomentDiff eventAt={item.createdAt} /> 전 등록
                    </MomentDiffDiv>
                  </UserData>
                  <AnsweredMark active={!item.res}>
                    {item.res ? '답변완료' : '답변대기'}
                  </AnsweredMark>
                </div>
                <UserName style={{ marginTop: 30 }}>
                  {item.user.username}
                </UserName>
                <UserLocation style={{ marginTop: 8 }}>
                  {' '}
                  {item.directReq
                    ? '다이렉트 상담'
                    : `${
                        item.locations
                          ? getLocationNames(item.locations, ', ')
                          : ''
                      }`}
                </UserLocation>
                <SeparatorDash style={{ marginTop: 40 }}></SeparatorDash>
                <Therapy>
                  {getTherapyString(item.orthoItems, item.txItems)}
                </Therapy>
              </CardItemContainerAccent>
            ))
          ) : (
            <EmptyDataInfoMsg style={{ marginTop: 120 }}>
              조회 내역이 없습니다.
            </EmptyDataInfoMsg>
          )}
        </ReqItemList>
        <PaddingDiv padding='10px 0'>
          {pagination && (
            <StyledPagination
              activePage={pagination.currentPage}
              itemsCountPerPage={itemPerPage}
              totalItemsCount={pagination.total}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange}
            />
          )}
        </PaddingDiv>
        <FullScreenContainerModal
          shouldReturnFocusAfterClose={false}
          isOpen={this.state.isReqDetailModalOpen}
          ariaHideApp={false}
          style={{
            overlay: {
              zIndex: 100,
              height: '100%',
            },
          }}>
          <ReqDetail
            reqId={this.state.curReqId}
            closeModal={() => {
              this.setState({
                curReqId: null,
                isReqDetailModalOpen: false,
              });
            }}
          />
        </FullScreenContainerModal>
      </React.Fragment>
    );
  }
}

export default ReqListComponent;
