import React, { Component } from 'react';
import UserRequestContainer from '../containers/ReqList/UserRequestContainer';

class ReqDetail extends Component {
  render() {
    if (this.props.reqId) {
      return (
        <div>
          <UserRequestContainer {...this.props} />
        </div>
      );
    }
    return null;
  }
}

export default ReqDetail;
