import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { media } from '../globalStyle';

const Message = styled.div`
  font-size: 35px;
  ${media.tablet`
    font-size: 20px;
    line-height: 1.4;
  `};
`;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  margin: auto;
  text-align: center;
  padding: 30vh 0;
`;

const Button = styled.button`
  background-color: #38c8e5;
  border: solid 1px #38c8e5;
  color: #ffffff;
  font-size: 20px;
  padding: 12px 20px;
  border-radius: 5px;
  margin-top: 20px;
  ${media.tablet`
    font-size: 16px;
    padding: 6px 10px;
    line-height: 1.4;
  `};
`;

class Errors extends React.Component {
  render() {
    return (
      <Container>
        <Message>알 수 없는 오류가 발생했습니다.</Message>
        <Button onClick={() => this.props.history.push('/reqlist')}>
          홈으로 가기
        </Button>
      </Container>
    );
  }
}

export default withRouter(Errors);
